import passwordHash from "password-hash";
import { app } from "../firebase-key";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  collection,
  getFirestore,
  setDoc,
  doc,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  getDoc,
  where,
  deleteDoc,
  addDoc,
  increment,
  Timestamp as FireTime,
  updateDoc,
} from "firebase/firestore";

const deleteUser = async (documentId, ind, settimeTodelete) => {
  try {
    settimeTodelete(ind);
    const db = getFirestore(app);
    const docRef = doc(db, "Customer", documentId);
    await deleteDoc(docRef);
    toast.success("User deleted Successfully.");
    window.location.reload();
  } catch (error) {
    settimeTodelete(-1);
    toast.error("Error occured, try again later.");
  }
};

const updateUser = async (
  documentId,
  updatedData,
  index,
  settimetoBlockTeam
) => {
  try {
    settimetoBlockTeam(index);
    const db = getFirestore(app);
    const docRef = doc(db, "Customer", documentId);
    await updateDoc(docRef, updatedData);
    toast.success("User Blocked Successfully.");
    window.location.reload();
  } catch (error) {
    settimetoBlockTeam(-1);
    toast.error("Error occured, try again later.");
  }
};

const fetchTeamMembers = async (startAfterDoc, setIsLoading, number) => {
  setIsLoading(true);
  try {
    const db = getFirestore();
    const customerCollection = collection(db, "Customer");

    const q = query(
      customerCollection,
      orderBy("dateOfJoined"), // Replace with your sorting field
      where("officeIncharge", "==", number),
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });
    return newTeamMembers;
  } catch (error) {
    toast.error("Error in fetching customer data");
    console.error("Error fetching team members:", error);
    return [];
  } finally {
    setIsLoading(false);
  }
};

async function updateOfficeProfile(
  documentId,
  updateProfileData,
  setupdateProfile,
  userData
) {
  try {
    setupdateProfile(true);
    let downloadURL = "";
    if (updateProfileData.profilePicture != null) {
      const storage = getStorage();
      const storageRef = ref(storage, `userProfile/${userData.phoneNumber}`);

      // Upload file to Firebase Storage
      await uploadBytes(storageRef, updateProfileData.profilePicture);

      // Get download URL of the uploaded file
      downloadURL = await getDownloadURL(storageRef);
    }

    const db = getFirestore(app);
    const docRef = doc(db, "Team", documentId);
    let hash = "";
    if (updateProfileData.password.length > 0) {
      hash = passwordHash.generate(updateProfileData.password);
    }
    console.log(hash, userData, updateProfileData);
    const updateContent = {
      ...updateProfileData,
      password: hash ? hash : userData.password,
      profilePicture:
        downloadURL.length > 0 ? downloadURL : userData.profilePicture,
    };

    await updateDoc(docRef, updateContent);
    toast.success("Profile Updated Successfully.");
    window.location.reload();
  } catch (error) {
    setupdateProfile(false);
    toast.error("Error occured, try again later.");
  }
}

const initialFinance = {
  dateOfEntry: FireTime.now(),
  phoneNumber: "",
  amount: 0,
  serviceName: "",
  uid: "",
  status: "pending",
};

const updateFinance = async (
  documentId,
  amount,
  updatedData,
  index,
  number,
  settimeToClearPayment
) => {
  try {
    settimeToClearPayment(index);
    const db = getFirestore(app);
    const teamCollection = collection(db, "Team");
    const teamDoc = doc(teamCollection, number);
    const customerCollection = collection(teamDoc, "Finance");
    const docRef = doc(customerCollection, documentId);
    await updateDoc(docRef, updatedData);
    await updateDoc(teamDoc, {
      totalAmtPending: increment(-amount),
      totalAmtRecieved: increment(amount),
    });
    toast.success("Entry Updated Successfully.");
    window.location.reload();
  } catch (error) {
    console.log(error, "Fesd");
    settimeToClearPayment(-1);
    toast.error("Error occured, try again later.");
  }
};

const fetchTeamFinance = async (
  startAfterDoc,
  setloadingWhileFetchingFinance,
  number
) => {
  setloadingWhileFetchingFinance(true);
  try {
    const db = getFirestore();
    const teamCollection = collection(db, "Team");
    const teamDoc = doc(teamCollection, number);
    const customerCollection = collection(teamDoc, "Finance");

    const q = query(
      customerCollection,
      orderBy("dateOfEntry"), // Replace with your sorting field
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });

    return newTeamMembers;
  } catch (error) {
    toast.error("Error in fetching Finance data");
    return [];
  } finally {
    setloadingWhileFetchingFinance(false);
  }
};

const fetchNextFinance = async (
  lastFinanceOfTeam,
  setloadingWhileFetchingFinance,
  setFinance,
  setlastFinanceOfTeam,
  number
) => {
  try {
    if (lastFinanceOfTeam == null) return;
    setloadingWhileFetchingFinance(true);
    const newTeamMembers = await fetchTeamFinance(
      lastFinanceOfTeam,
      setloadingWhileFetchingFinance,
      number
    );

    if (newTeamMembers.length > 0) {
      setFinance((prevTeamMembers) => [...prevTeamMembers, ...newTeamMembers]);
      // Update lastDocument with the last document from the fetched data
      setlastFinanceOfTeam(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      setlastFinanceOfTeam(null);
    }
  } catch (error) {
    toast.error("Error occured, try again");
  } finally {
    setloadingWhileFetchingFinance(false);
  }
};

const fetchTeamDocuments = async (
  startAfterDoc,
  setloadingWhileFetchingDoc,
  number
) => {
  setloadingWhileFetchingDoc(true);
  try {
    const db = getFirestore();
    const teamCollection = collection(db, "Team");
    const teamDoc = doc(teamCollection, number);
    const customerCollection = collection(teamDoc, "Documents");

    const q = query(
      customerCollection,
      orderBy("dateOfUpload"), // Replace with your sorting field
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });

    return newTeamMembers;
  } catch (error) {
    toast.error("Error in fetching document data");
    console.error("Error fetching team members:", error);
    return [];
  } finally {
    setloadingWhileFetchingDoc(false);
  }
};

const fetchNextDocument = async (
  lastDocumentOfTeam,
  setloadingWhileFetchingDoc,
  setdocuments,
  setlastDocumentOfTeam,
  number
) => {
  try {
    if (lastDocumentOfTeam == null) return;
    setloadingWhileFetchingDoc(true);
    const newTeamMembers = await fetchTeamDocuments(
      lastDocumentOfTeam,
      setloadingWhileFetchingDoc,
      number
    );

    if (newTeamMembers.length > 0) {
      setdocuments((prevTeamMembers) => [
        ...prevTeamMembers,
        ...newTeamMembers,
      ]);
      // Update lastDocument with the last document from the fetched data
      setlastDocumentOfTeam(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      setlastDocumentOfTeam(null);
    }
  } catch (error) {
    console.error("Error fetching team members:", error);
  } finally {
    setloadingWhileFetchingDoc(false);
  }
};

const fetchNext = async (
  lastDocument,
  setIsLoading,
  setTeamMembers,
  setLastDocument,
  number
) => {
  try {
    if (lastDocument == null) return;
    setIsLoading(true);
    const newTeamMembers = await fetchTeamMembers(
      lastDocument,
      setIsLoading,
      number
    );
    if (newTeamMembers.length > 0) {
      setTeamMembers((prevTeamMembers) => [
        ...prevTeamMembers,
        ...newTeamMembers,
      ]);
      // Update lastDocument with the last document from the fetched data
      setLastDocument(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      setLastDocument(null);
    }
  } catch (error) {
    console.error("Error fetching team members:", error);
  } finally {
    setIsLoading(false);
  }
};

const handleFetchUser = async (
  closeModal,
  phoneForSearch,
  number,
  setTeamMembers,
  setlastDocumentOfTeam,
  setdocuments,
  setLastDocument,
  setUserData,
  setupdateProfileData,
  navigate,
  setIsLoading,
  setloadingWhileFetchingDoc,
  setFinance,
  setlastFinanceOfTeam
) => {
  try {
    const db = getFirestore(app);
    // Assuming you have a function to add data to your database, for example, using Firebase Firestore
    const teamCollection = collection(db, "Team");
    const customDocRef = doc(teamCollection, number);
    const response = await getDoc(customDocRef);
    if (response.exists()) {
      let data = response.data();
      if (data.status === "active") {
        (async () => {
          if (phoneForSearch.length > 0) return;
          let userData = await fetchTeamMembers(null, setIsLoading, number);
          closeModal();
          setTeamMembers(userData);
          if (userData.length < 25) {
            setlastDocumentOfTeam(null);
          } else {
            setLastDocument(userData[userData.length - 1]);
          }
        })();
        (async () => {
          let docData = await fetchTeamDocuments(
            null,
            setloadingWhileFetchingDoc,
            number
          );
          setdocuments(docData);
          closeModal();
          if (docData.length < 25) {
            setlastDocumentOfTeam(null);
          } else {
            setlastDocumentOfTeam(docData[docData.length - 1]);
          }
        })();
        (async () => {
          let docData = await fetchTeamFinance(
            null,
            setloadingWhileFetchingDoc,
            number
          );
          setFinance(docData);
          closeModal();
          if (docData.length < 25) {
            setlastFinanceOfTeam(null);
          } else {
            setlastFinanceOfTeam(docData[docData.length - 1]);
          }
        })();
      }
      setUserData(data);
      setupdateProfileData({ ...data, profilePicture: null, password: "" });
    } else {
      navigate("/");
    }
  } catch (error) {
    // Handle errors and display them using React-Toastify
    toast.error(error.message);
  }
};

function validatePanCard(panNumber) {
  // Regular expression for validating Indian PAN card
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

  // Check if the input matches the PAN card pattern
  return panRegex.test(panNumber);
}

// Aadhaar Number
function validateAadhaarNumber(aadhaarNumber) {
  // Regular expression for validating Indian Aadhaar number
  const aadhaarRegex = /^\d{12}$/;

  // Check if the input matches the Aadhaar number pattern
  return aadhaarRegex.test(aadhaarNumber);
}

const createNewTeamMember = async (
  teamMember,
  setloaderWhileAdding,
  number,
  setTeamMembers,
  closeModal,
  initialTeam,
  setteamDoc,
  setIsLoading
) => {
  setloaderWhileAdding(true);
  // Check phone number length
  if (teamMember.phoneNumber.length !== 10) {
    setloaderWhileAdding(false);
    toast.error("Phone number must be 10 digits long.");
    return;
  }

  // if (!validatePanCard(teamMember.panNumber)) {
  //   setloaderWhileAdding(false);
  //   toast.error("PAN Number must be correct.");
  //   return;
  // }

  // if (!validateAadhaarNumber(teamMember.aadhar.toString())) {
  //   setloaderWhileAdding(false);
  //   toast.error("Aadhar Number must be correct.");
  //   return;
  // }

  // var hash = bcrypt.hashSync(password);
  var hash = passwordHash.generate(teamMember.password);
  // const salt = generateSalt();
  // const hash = hashPassword(teamMember.password, salt);
  // Create a new document in the "Team" collection
  const newTeamMemberData = {
    ...teamMember,
    password: hash,
    officeIncharge: number,
  };

  try {
    const db = getFirestore();
    // Assuming you have a function to add data to your database, for example, using Firebase Firestore
    const customerCollection = collection(db, "Customer");
    const customDocRef = doc(customerCollection, teamMember.phoneNumber);
    const response = await getDoc(customDocRef);

    if (response.exists()) {
      toast.error("This team member already exists, Try creating new");
      return;
    }
    await setDoc(customDocRef, newTeamMemberData);

    setloaderWhileAdding(false);
    toast.success("Successfully created new member");
    let userData = await fetchTeamMembers(null, setIsLoading, number);
    setTeamMembers(userData);
    closeModal();
  } catch (error) {
    setloaderWhileAdding(false);
    console.error("Error adding team member:", error);
    toast.error(error.message);
  } finally {
    setteamDoc(initialTeam);
  }
};

const getTeamMemberByPhoneNumber = async (
  phoneNumber,
  setloaderWhileFetchingFromPhone,
  number
) => {
  if (phoneNumber.length !== 10) return;
  setloaderWhileFetchingFromPhone(true);
  const db = getFirestore();
  const customerCollection = collection(db, "Customer");

  const q = query(
    customerCollection,
    where("phoneNumber", "==", phoneNumber),
    where("officeIncharge", "==", number)
  );

  try {
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setloaderWhileFetchingFromPhone(false);
      // Handle case where no document is found for the given phoneNumber
      return null;
    }

    // Assuming there's only one document with the given phoneNumber, return it
    const teamMemberData = querySnapshot.docs[0].data();
    setloaderWhileFetchingFromPhone(false);
    return teamMemberData;
  } catch (error) {
    console.error("Error getting team member by phone number:", error);
    // Handle error
    setloaderWhileFetchingFromPhone(false);
    toast.error("Error occured, try again later.");
    return null;
    // throw error;
  }
};

const getTeamDocumentByName = async (
  name,
  setloadingWhileFetchingDocFromName,
  number
) => {
  if (name.length === 0) return null;
  setloadingWhileFetchingDocFromName(true);
  const db = getFirestore();
  const teamCollection = collection(db, "Team");
  const teamDoc = doc(teamCollection, number);
  const documentsCollection = collection(teamDoc, "Documents");
  const q = query(documentsCollection, where("name", "==", name));

  try {
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setloadingWhileFetchingDocFromName(false);
      // Handle case where no document is found for the given phoneNumber
      return null;
    }

    // Assuming there's only one document with the given phoneNumber, return it
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });
    setloadingWhileFetchingDocFromName(false);
    return newTeamMembers;
  } catch (error) {
    console.error("Error getting team member by phone number:", error);
    // Handle error
    setloadingWhileFetchingDocFromName(false);
    toast.error("Error occured, try again later.");
    return null;
    // throw error;
  }
};

const uploadDocument = async (
  e,
  documentData,
  setloadingWhileUploadingFile,
  number,
  setdocuments,
  closeModal,
  initialDocument,
  setdocumentData
) => {
  e.preventDefault();
  if (documentData.file == null) {
    toast.error("File is required.");
    return;
  }
  if (documentData.name.length === 0) {
    toast.error("File Name is required.");
    return;
  }
  setloadingWhileUploadingFile(true);
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `documents/${documentData.file.name + number + Date.now()}`
  );

  try {
    // Upload file to Firebase Storage
    await uploadBytes(storageRef, documentData.file);

    // Get download URL of the uploaded file
    const downloadURL = await getDownloadURL(storageRef);

    // Create a new document in Firestore with the download URL
    const newDocument = {
      ind: 0,
      name: documentData.name,
      whyUploaded: documentData.whyUploaded,
      src: downloadURL,
      dateOfUpload: FireTime.fromDate(new Date()),
    };

    // Add the document to Firestore
    const db = getFirestore();
    const teamCollection = collection(db, "Team");
    const teamDoc = doc(teamCollection, number);
    const documentsCollection = collection(teamDoc, "Documents");
    await addDoc(documentsCollection, newDocument);
    let userData = await fetchTeamDocuments(null);
    setdocuments(userData);
    // Show success message using Toast
    toast.success("Document uploaded successfully!");
    closeModal();
    setdocumentData(initialDocument);
    setloadingWhileUploadingFile(false);
  } catch (error) {
    // Show error message using Toast in case of failure
    toast.error("Error uploading document. Please try again.");
    setloadingWhileUploadingFile(false);
    console.error("Error uploading document:", error);
  }
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 400,
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export {
  createNewTeamMember,
  uploadDocument,
  getTeamDocumentByName,
  getTeamMemberByPhoneNumber,
  handleFetchUser,
  style,
  updateOfficeProfile,
  fetchNext,
  fetchNextDocument,
  deleteUser,
  updateUser,
  fetchNextFinance,
  fetchTeamFinance,
  updateFinance,
  initialFinance,
};

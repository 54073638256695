import { UseAuthContext } from "../state/appContext";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Alert } from "@mui/material";
import Spinner from "../common/spinner";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDeleteLeft,
  faEdit,
  faFile,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { Timestamp as FireTime } from "firebase/firestore";
import { owneruid } from "../constant/services";
import {
  fetchNextFinance,
  updateFinance,
  createNewTeamMember,
  deleteUser,
  updateUser,
  uploadDocument,
  getTeamDocumentByName,
  getTeamMemberByPhoneNumber,
  handleFetchUser,
  style,
  updateOfficeProfile,
  fetchNext,
  fetchNextDocument,
} from "../functions/office";
import { useLocation, useNavigate } from "react-router-dom";
import EditComponent from "../common/edit";
import ShowingInfo from "../common/showingInfo";

const Office = () => {
  const initialDocument = {
    file: null,
    ind: 0,
    name: "",
    whyUploaded: "",
    dateOfUpload: FireTime.fromDate(new Date()),
  };
  const initialTeam = {
    officeIncharge: "",
    ind: 0,
    firmName: "",
    name: "",
    fatherName: "",
    email: "",
    phoneNumber: "",
    password: "",
    panNumber: "",
    gst: "",
    aadharNumber: "",
    address: "",
    bankName: "",
    ifscCode: "",
    AccountNo: "",
    profilePicture: "",
    backgroundPicture: "",
    totalAmtRecieved: 0,
    totalAmtPending: 0,
    district: "",
    dateOfJoined: FireTime.fromDate(new Date()),
    status: "active",
  };

  const navigate = useNavigate();

  const [lastFinanceOfTeam, setlastFinanceOfTeam] = useState(null);
  const [loadingWhileFetchingFinance, setloadingWhileFetchingFinance] =
    useState(null);
  const [Finance, setFinance] = useState([]);
  const [modalIsOpen, setIsOpen] = useState("");
  const { loader, loggedIn, uid } = UseAuthContext();
  const [timeToClearPayment, settimeToClearPayment] = useState("");
  const [documents, setdocuments] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setdocumentData] = useState(initialDocument);
  const [currentSection, setcurrentSection] = useState("profile");
  const [teamDoc, setteamDoc] = useState(initialTeam);
  const [loaderWhileFetchingFromPhone, setloaderWhileFetchingFromPhone] =
    useState(false);
  const [editProfile, seteditProfile] = useState(false);
  const [phoneForSearch, setphoneForSearch] = useState("");
  const [lastDocument, setLastDocument] = useState(null);
  const [loadingWhileUploadingFile, setloadingWhileUploadingFile] =
    useState(false);

  const [userData, setUserData] = useState(null); // State to store fetched user data
  const [loaderWhileAdding, setloaderWhileAdding] = useState(false);
  const [lastDocumentOfTeam, setlastDocumentOfTeam] = useState(null);
  const [loadingWhileFetchingDoc, setloadingWhileFetchingDoc] = useState(false);
  const [loadingWhileFetchingDocFromName, setloadingWhileFetchingDocFromName] =
    useState(false);
  const [updateProfile, setupdateProfile] = useState(false);
  const [updateProfileData, setupdateProfileData] = useState({});
  const router = useLocation();
  const number = router.pathname.includes("owner")
    ? router.pathname.split("/")[3]
    : router.pathname.substring(15);
  const district = router.pathname.includes("owner")
    ? router.pathname.split("/")[2]
    : "";

  const [timetoBlockTeam, settimetoBlockTeam] = useState(-1);
  const [timeTodelete, settimeTodelete] = useState(-1);

  useEffect(() => {
    if (!loader && !loggedIn) {
      navigate("/");
    } else if (!loader && loggedIn && (uid === owneruid || uid === number)) {
      if (number?.length !== 0) {
        handleFetchUser(
          closeModal,
          phoneForSearch,
          number,
          setTeamMembers,
          setlastDocumentOfTeam,
          setdocuments,
          setLastDocument,
          setUserData,
          setupdateProfileData,
          navigate,
          setIsLoading,
          setloadingWhileFetchingDoc,
          setFinance,
          setlastFinanceOfTeam
        );
      }
    } else if (!loader && loggedIn && (uid !== owneruid || uid !== number)) {
      navigate("/");
    }
  }, [uid, loader, loggedIn, phoneForSearch, number]);

  function closeModal() {
    setIsOpen("");
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await createNewTeamMember(
        teamDoc,
        setloaderWhileAdding,
        number,
        setTeamMembers,
        closeModal,
        initialTeam,
        setteamDoc,
        setIsLoading
      );
      // Handle success if needed
    } catch (error) {
      // Handle error, for example, show an error message to the user
      console.error(error.message);
    }
  };

  return (
    <div className="w-full min-h-screen">
      <ToastContainer position="bottom-center" />
      <Modal
        open={modalIsOpen.length > 0}
        onClose={closeModal}
        className="mt-28"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={`w-full`}>
            {modalIsOpen === "newteam" ? (
              <form className="w-full max-w-lg" onSubmit={handleFormSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-full-name"
                    >
                      Full Name
                    </label>
                    <input
                      required
                      value={teamDoc.name}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, name: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-full-name"
                      type="text"
                      placeholder="Jane"
                    />
                  </div>
                  <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-firm-name"
                    >
                      Firm Name
                    </label>
                    <input
                      required
                      value={teamDoc.firmName}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, firmName: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-firm-name"
                      type="text"
                      placeholder="Jane"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-2 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="perhone-numb"
                    >
                      Phone Number
                    </label>
                    <input
                      required
                      value={teamDoc.phoneNumber}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, phoneNumber: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="perhone-numb"
                      type="number"
                      placeholder="+911234567890"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Password
                    </label>
                    <input
                      required
                      value={teamDoc.password}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, password: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-password"
                      type="password"
                      placeholder="******************"
                    />
                    <p className="text-gray-600 text-xs italic">
                      Make it as long and as crazy as you'd like
                    </p>
                  </div>
                </div>
                <button
                  className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                  type="submit"
                >
                  {loaderWhileAdding ? <Spinner /> : "Create"}
                </button>
              </form>
            ) : modalIsOpen === "newDocument" ? (
              <div
                style={{ height: "450px" }}
                className="mx-auto w-full max-h-[600px] bg-white"
              >
                <form
                  onSubmit={(e) =>
                    uploadDocument(
                      e,
                      documentData,
                      setloadingWhileUploadingFile,
                      number,
                      setdocuments,
                      closeModal,
                      initialDocument,
                      setdocumentData
                    )
                  }
                  // className="py-6 px-9"
                  method="POST"
                >
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Name of File
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={documentData.name}
                      onChange={(e) =>
                        setdocumentData({
                          ...documentData,
                          name: e.target.value,
                        })
                      }
                      id="email"
                      placeholder="file Name"
                      className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>

                  <div className="mb-0 pt-0">
                    <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                      Upload File
                    </label>

                    <div className="mb-3">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="sr-only"
                        onChange={(e) => {
                          if (e.target.files == null) return;
                          const selectedFile = e.target.files[0];

                          if (selectedFile) {
                            const allowedTypes = [
                              "image/jpeg",
                              "image/png",
                              "image/gif",
                              "application/pdf",
                            ];

                            // Check if the selected file type is allowed
                            if (allowedTypes.includes(selectedFile.type)) {
                              // Update the state if the file type is allowed
                              setdocumentData({
                                ...documentData,
                                file: selectedFile,
                              });
                            } else {
                              // Display an error message or handle the invalid file type
                              console.error(
                                "Invalid file type. Please select an image (JPEG, PNG, GIF) or a PDF."
                              );
                            }
                          }
                        }}
                      />
                      <label
                        htmlFor="file"
                        className="relative flex min-h-[100px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-6 text-center"
                      >
                        {documentData.file == null ? (
                          <div>
                            <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                              Drop files here
                            </span>
                            <span className="mb-2 block text-base font-medium text-[#6B7280]">
                              Or
                            </span>
                            <span className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]">
                              Browse
                            </span>
                          </div>
                        ) : (
                          <div>
                            <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                              File Saved
                            </span>
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="mb-3 block text-base font-medium text-[#07074D]"
                      >
                        Why Uploading?
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={documentData.whyUploaded}
                        onChange={(e) =>
                          setdocumentData({
                            ...documentData,
                            whyUploaded: e.target.value,
                          })
                        }
                        id="email"
                        placeholder="file Name"
                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                      />
                    </div>
                  </div>

                  <div>
                    <button className="hover:shadow-form w-full flex items-center justify-center rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none">
                      {loadingWhileUploadingFile ? <Spinner /> : "Send File"}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Modal>
      <div className="mt-40 flex items-center justify-center">
        <div className="w-[90%] md:w-[75%] m-auto max-w-[768px]">
          <ul
            className="flex items-center gap-3 md:max-w-[768px] max-w-[350px] overflow-auto my-3"
            id="filter"
          >
            <li
              onClick={() => setcurrentSection("profile")}
              className={`${
                currentSection === "profile" ? "bg-gray-400" : "bg-gray-100"
              } border hover:border-gray-100 cursor-pointer border-gray-300 rounded-l-lg  text-gray-900 px-4 py-2`}
            >
              Profile
            </li>
            <li
              onClick={() => setcurrentSection("customer")}
              className={`${
                currentSection === "customer" ? "bg-gray-400" : "bg-gray-100"
              } border hover:border-gray-100 cursor-pointer border-gray-300 rounded-l-lg  text-gray-900 px-4 py-2`}
            >
              Customers
            </li>
            <li
              onClick={() => setcurrentSection("documents")}
              className={`${
                currentSection === "documents" ? "bg-gray-400" : "bg-gray-100"
              } border hover:border-gray-100 cursor-pointer border-gray-300 rounded-l-lg  text-gray-900 px-4 py-2`}
            >
              Documents
            </li>
            <li
              onClick={() => setcurrentSection("finance")}
              className={`${
                currentSection === "finance" ? "bg-gray-400" : "bg-gray-100"
              } border hover:border-gray-100 cursor-pointer border-gray-300 rounded-l-lg  text-gray-900 px-4 py-2`}
            >
              Finance
            </li>
          </ul>
          {userData !== null && userData.status !== "active" && (
            <Alert className="my-4" severity="error">
              You have been blocked by Team.
            </Alert>
          )}
          {currentSection !== "profile" && currentSection !== "finance" && (
            <div className="flex">
              <label
                htmlFor="search-dropdown"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Your Email
              </label>
              <button
                onClick={() => {
                  if (currentSection !== "finance") {
                    setIsOpen(
                      currentSection === "customer" ? "newteam" : "newDocument"
                    );
                  }
                }}
                id="dropdown-button"
                data-dropdown-toggle="dropdown"
                className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                type="button"
              >
                {currentSection === "customer"
                  ? "Create New+"
                  : currentSection === "documents"
                  ? "Add New+"
                  : ""}
              </button>
              <div className="relative w-full">
                <input
                  type="search"
                  value={phoneForSearch}
                  onChange={(e) => setphoneForSearch(e.target.value)}
                  id="search-dropdown"
                  className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                  placeholder={
                    currentSection === "customer" ||
                    currentSection === "finance"
                      ? "Search by Customer ID"
                      : "Search by Document Name"
                  }
                  required
                />
                <button
                  onClick={async () => {
                    if (currentSection === "customer") {
                      if (userData.status === "block") {
                        toast.error("Blocked users are not allowed to filter.");
                        return;
                      }
                      let userFromPhone = await getTeamMemberByPhoneNumber(
                        phoneForSearch,
                        setloaderWhileFetchingFromPhone,
                        number
                      );
                      if (userFromPhone != null) {
                        setTeamMembers([userFromPhone]);
                      } else {
                        toast.error("No data from this Number exists");
                      }
                    } else if (currentSection === "documents") {
                      if (userData.status === "block") {
                        toast.error("Blocked users are not allowed to filter.");
                        return;
                      }
                      let docFromName = await getTeamDocumentByName(
                        phoneForSearch,
                        setloadingWhileFetchingDocFromName,
                        number
                      );
                      if (docFromName != null) {
                        setdocuments(docFromName);
                      } else {
                        toast.error("No data from this name exists");
                      }
                    }
                  }}
                  className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {loaderWhileFetchingFromPhone ||
                  loadingWhileFetchingDocFromName ? (
                    <Spinner />
                  ) : (
                    <>
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span className="sr-only">Search</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
          <Box sx={{ height: "400" }}>
            {currentSection === "customer" ? (
              isLoading ? (
                <Spinner />
              ) : (
                <DataGrid
                  getRowClassName={(params) =>
                    `${
                      params.row.status === "active"
                        ? "bg-green-100"
                        : "bg-red-100"
                    }`
                  }
                  columns={[
                    // { field: 'id', headerName: 'ID', width: 70 },
                    {
                      field: "ind",
                      headerName: "Go to this Page",
                      width: 150,
                      renderCell: (params) => {
                        return (
                          <a
                            href={
                              uid === owneruid
                                ? `/owner/${district}/${number}/${params.row.phoneNumber}`
                                : `/portal/user/${uid}/${params.row.phoneNumber}`
                            }
                          >
                            Vist <FontAwesomeIcon icon={faLink} />{" "}
                          </a>
                        );
                      },
                    },
                    {
                      field: "name",
                      headerName: "Full Name",
                      width: 150,
                    },
                    {
                      field: "firmName",
                      headerName: "Firm Name",
                      width: 150,
                    },
                    {
                      field: "phoneNumber",
                      headerName: "Phone Number",
                      width: 150,
                    },
                    {
                      field: "dateOfJoined",
                      headerName: "Date of Joined",
                      width: 200,
                      renderCell: (params) => {
                        // Assuming dateOfJoined is a Firestore timestamp, you can format it using JavaScript Date methods
                        const formattedDate = new Date(
                          params.value?.seconds * 1000
                        ).toLocaleDateString();
                        return <div>{formattedDate}</div>;
                      },
                    },
                    {
                      field: "fsa",
                      headerName: "Change Status",
                      width: 150,
                      renderCell: (params) => {
                        return (
                          <button
                            onClick={() =>
                              updateUser(
                                params.row.phoneNumber,
                                params.row.status === "active"
                                  ? {
                                      status: "block",
                                    }
                                  : { status: "active" },
                                params.id,
                                settimetoBlockTeam
                              )
                            }
                            className="bg-gray-400 px-4 py-2 rounded-md"
                          >
                            {timetoBlockTeam === params.id ? (
                              <Spinner />
                            ) : params.row.status === "active" ? (
                              "Block"
                            ) : (
                              "Unblock"
                            )}
                          </button>
                        );
                      },
                    },
                    {
                      field: "fds",
                      headerName: "Delete",
                      width: 150,
                      renderCell: (params) => {
                        return (
                          <button
                            onClick={() =>
                              deleteUser(
                                params.row.phoneNumber,
                                params.id,
                                settimeTodelete
                              )
                            }
                            className="bg-gray-400 px-4 py-2 rounded-md"
                          >
                            {timeTodelete === params.id ? (
                              <Spinner />
                            ) : (
                              <>
                                <span>Delete</span>{" "}
                                <FontAwesomeIcon icon={faDeleteLeft} />
                              </>
                            )}
                          </button>
                        );
                      },
                    },
                  ]}
                  getRowId={(row) => row.phoneNumber}
                  rows={teamMembers}
                />
              )
            ) : currentSection === "documents" ? (
              loadingWhileFetchingDoc ? (
                <Spinner />
              ) : (
                <DataGrid
                  columns={[
                    // { field: 'id', headerName: 'ID', width: 70 },
                    {
                      field: "ind",
                      headerName: "",
                      width: 0,
                      renderCell: (params) => {
                        return (
                          <a
                            href={params.row.src}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </a>
                        );
                      },
                    },
                    { field: "name", headerName: "Document Name", width: 150 },
                    { field: "src", headerName: "Document Source", width: 150 },
                    {
                      field: "whyUploaded",
                      headerName: "Why Uploaded",
                      width: 150,
                    },
                    {
                      field: "dateOfUpload",
                      headerName: "Date of Upload",
                      width: 150,
                      renderCell: (params) => {
                        // Assuming dateOfJoined is a Firestore timestamp, you can format it using JavaScript Date methods
                        const formattedDate = new Date(
                          params.value?.seconds * 1000
                        ).toLocaleDateString();
                        return <div>{formattedDate}</div>;
                      },
                    },
                  ]}
                  getRowId={(row) => row.src}
                  rows={documents}
                />
              )
            ) : currentSection === "finance" ? (
              loadingWhileFetchingFinance ? (
                <Spinner />
              ) : (
                <DataGrid
                  getRowClassName={(params) =>
                    `${
                      params.row.entryType === "credit"
                        ? "bg-green-100"
                        : "bg-red-100"
                    }`
                  }
                  columns={[
                    {
                      field: "dateOfEntry",
                      headerName: "Date Of Entry",
                      width: 150,
                      renderCell: (params) => {
                        // Assuming dateOfJoined is a Firestore timestamp, you can format it using JavaScript Date methods
                        const formattedDate = new Date(
                          params.value?.seconds * 1000
                        ).toLocaleDateString();
                        return <div>{formattedDate}</div>;
                      },
                    },
                    {
                      field: "phoneNumber",
                      headerName: "phoneNumber",
                      width: 150,
                    },
                    {
                      field: "serviceName",
                      headerName: "serviceName",
                      width: 150,
                    },
                    {
                      field: "amount",
                      headerName: "amount",
                      width: 150,
                    },
                    {
                      field: "uid",
                      headerName: "Clear Payment",
                      width: 150,
                      renderCell: (params) => {
                        return params.row.status === "pending" ? (
                          <button
                            onClick={() =>
                              updateFinance(
                                params.row.uid,
                                Number(params.row.amount),
                                params.row.status === "pending"
                                  ? {
                                      status: "clear",
                                    }
                                  : { status: "pending" },
                                params.uid,
                                number,
                                settimeToClearPayment
                              )
                            }
                            className="bg-gray-400 px-4 py-2 rounded-md"
                          >
                            {timeToClearPayment === params.uid ? (
                              <Spinner />
                            ) : (
                              params.row.status === "pending" && "Clear"
                            )}
                          </button>
                        ) : (
                          <p>Cleared</p>
                        );
                      },
                    },
                  ]}
                  getRowId={(row) => row.dateOfEntry + row.phoneNumber}
                  rows={Finance}
                />
              )
            ) : currentSection === "profile" ? (
              <>
                {userData == null || userData === undefined ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <div className="relative shadow-shadow-500 border-2 border-black border-solid shadow-3xl rounded-primary mx-auto flex h-full w-full max-w-[550px] flex-col items-center bg-white bg-cover bg-clip-border p-[16px]">
                    {editProfile ? (
                      <>
                        <div className="flex items-center space-x-6">
                          <div className="shrink-0">
                            <img
                              id="preview_img"
                              className="h-16 w-16 object-cover rounded-full"
                              src={
                                updateProfileData.profilePicture
                                  ? URL.createObjectURL(
                                      updateProfileData.profilePicture
                                    )
                                  : userData.profilePicture
                              }
                              alt="Current profile ad"
                            />
                          </div>
                          <label className="block">
                            <span className="sr-only">
                              Choose profile photo
                            </span>
                            <input
                              type="file"
                              onChange={(e) => {
                                setupdateProfileData({
                                  ...updateProfileData,
                                  profilePicture: e.target.files[0],
                                });
                              }}
                              className="block w-full text-sm text-slate-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-violet-50 file:text-violet-700
        hover:file:bg-violet-100
      "
                            />
                          </label>
                        </div>
                        <div className="flex flex-wrap items-center justify-evenly gap-4 my-4">
                          <EditComponent
                            placeholder={updateProfileData.name}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                name: e.target.value,
                              });
                            }}
                            label="Name"
                          />{" "}
                          <EditComponent
                            placeholder={"*******"}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                password: e.target.value,
                              });
                            }}
                            label="Password"
                          />
                          <EditComponent
                            placeholder={updateProfileData.firmName}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                firmName: e.target.value,
                              });
                            }}
                            label="Firm Name"
                          />
                          <EditComponent
                            placeholder={updateProfileData.district}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                district: e.target.value,
                              });
                            }}
                            label="District"
                          />
                          <EditComponent
                            placeholder={updateProfileData.address}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                address: e.target.value,
                              });
                            }}
                            label="Address"
                          />
                          <EditComponent
                            placeholder={updateProfileData.email}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                email: e.target.value,
                              });
                            }}
                            label="email"
                          />
                          <EditComponent
                            placeholder={updateProfileData.fatherName}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                fatherName: e.target.value,
                              });
                            }}
                            label="Father's Name"
                          />
                          <EditComponent
                            placeholder={updateProfileData.panNumber}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                panNumber: e.target.value,
                              });
                            }}
                            label="PAN Number"
                          />
                          <EditComponent
                            placeholder={updateProfileData.aadharNumber}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                aadharNumber: e.target.value,
                              });
                            }}
                            label="Aadhar Number"
                          />
                          <EditComponent
                            placeholder={updateProfileData.gst}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                gst: e.target.value,
                              });
                            }}
                            label="GST"
                          />
                          <EditComponent
                            placeholder={updateProfileData.AccountNo}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                AccountNo: e.target.value,
                              });
                            }}
                            label="Account Number"
                          />
                          <EditComponent
                            placeholder={updateProfileData.bankName}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                bankName: e.target.value,
                              });
                            }}
                            label="Bank Name"
                          />
                          <EditComponent
                            placeholder={updateProfileData.ifscCode}
                            onChange={(e) => {
                              setupdateProfileData({
                                ...updateProfileData,
                                ifscCode: e.target.value,
                              });
                            }}
                            label="IFSC Code"
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              updateOfficeProfile(
                                userData.phoneNumber,
                                updateProfileData,
                                setupdateProfile,
                                userData
                              );
                            }}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
                          >
                            {updateProfile ? <Spinner /> : "Save"}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              seteditProfile(false);
                            }}
                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none "
                          >
                            Go Back
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
                          style={{
                            backgroundImage:
                              'url("https://i.ibb.co/FWggPq1/banner.png")',
                          }}
                        >
                          <div className="absolute -bottom-12 flex h-[88px] w-[88px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400">
                            <img
                              className="h-full w-full rounded-full"
                              src={userData.profilePicture}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="mt-16 flex flex-col items-center">
                          <h6>{userData.name}</h6>
                          <h4 className="text-bluePrimary text-xl font-bold">
                            {userData.firmName}
                          </h4>
                          <h6>{userData.district}</h6>
                        </div>
                        <div
                          className="absolute top-[150px] left-[15px] font-semibold cursor-pointer"
                          onClick={() => seteditProfile(true)}
                        >
                          Edit <FontAwesomeIcon icon={faEdit} />
                        </div>
                        <div className="absolute text-[15px] z-[100] top-[150px] right-[15px] font-semibold">
                          {new Date(
                            userData?.dateOfJoined?.seconds * 1000
                          ).toLocaleDateString()}
                        </div>
                        <div className="mt-6 mb-3 flex flex-wrap gap-4 md:!gap-14">
                          <ShowingInfo
                            label={"Total Amount Pending"}
                            value={userData.totalAmtPending}
                          />
                          <ShowingInfo
                            label={"Total Amount Recieved"}
                            value={userData.totalAmtRecieved}
                          />
                        </div>
                        <div className="mt-6 mb-3 flex flex-wrap gap-4 md:!gap-14">
                          <ShowingInfo
                            label={"Number of Clients"}
                            value={userData.clientCounts}
                          />
                          <ShowingInfo
                            label={"Number of Documents"}
                            value={userData.documentsCount}
                          />
                          {userData.fatherName && (
                            <ShowingInfo
                              label={"Father's Name"}
                              value={userData.fatherName}
                            />
                          )}
                          {userData.address && (
                            <ShowingInfo
                              label={"Address"}
                              value={userData.address}
                            />
                          )}
                          {userData.gst && (
                            <ShowingInfo
                              label={"GST Number"}
                              value={userData.gst}
                            />
                          )}
                          {userData.panNumber && (
                            <ShowingInfo
                              label={"Pan Number"}
                              value={userData.panNumber}
                            />
                          )}
                          {userData.fatherName && (
                            <ShowingInfo
                              label={"Father's Name"}
                              value={userData.fatherName}
                            />
                          )}
                          {userData.aadharNumber && (
                            <ShowingInfo
                              label={"Aadhar Number"}
                              value={userData.aadharNumber}
                            />
                          )}
                          {userData.bankName && (
                            <ShowingInfo
                              label={"Bank Name"}
                              value={userData.bankName}
                            />
                          )}
                          {userData.AccountNo && (
                            <ShowingInfo
                              label={"Account Number"}
                              value={userData.AccountNo}
                            />
                          )}
                          {userData.ifscCode && (
                            <ShowingInfo
                              label={"IFSC Code"}
                              value={userData.ifscCode}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
          {(lastDocument !== null ||
            lastDocumentOfTeam !== null ||
            lastFinanceOfTeam !== null) && (
            <button
              onClick={() =>
                currentSection === "customer"
                  ? fetchNext(
                      lastDocument,
                      setIsLoading,
                      setTeamMembers,
                      setLastDocument,
                      number
                    )
                  : currentSection === "documents"
                  ? fetchNextDocument(
                      lastDocumentOfTeam,
                      setloadingWhileFetchingDoc,
                      setdocuments,
                      setlastDocumentOfTeam,
                      number
                    )
                  : fetchNextFinance(
                      lastFinanceOfTeam,
                      setloadingWhileFetchingFinance,
                      setFinance,
                      setlastFinanceOfTeam,
                      number
                    )
              }
              className="mx-auto flex self-center my-5 p-3 rounded-md bg-blue-600 text-white"
            >
              Fetch More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Office;

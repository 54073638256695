import React from "react";
import { UseAuthContext } from "../state/appContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faSignOut,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { auth } from "../firebase-key";
import { signOut } from "firebase/auth";
import { owneruid } from "../constant/services";
import Spinner from "../common/spinner";
import "../animation.css";

const Navbar = ({ showMobileMenu, setshowMobileMenu }) => {
  const { loggedIn, userType, uid, officeId, loader, setLoggedIn, setUid } =
    UseAuthContext();

  const toggleMobileMenu = () => {
    setshowMobileMenu(!showMobileMenu);
  };

  const logout = async () => {
    await signOut(auth);
    setUid("");
    setLoggedIn(false);
    window.localStorage.removeItem("userid");
    window.localStorage.removeItem("userType");
    window.localStorage.removeItem("password");
  };

  return (
    <div className="fixed top-0 w-full bg-white z-[10000]">
      <nav className="flex items-center border-b-2 border-b-s border-b-black lg:justify-center justify-between lg:p-0 px-3 w-full gap-3 uppercase">
        <button
          onClick={toggleMobileMenu}
          className={`hamburger hamburger--elastic ${
            showMobileMenu ? "is-active" : ""
          }`}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
        <div className="w-[calc(50%-110px)] lg:block hidden">
          <ul className="flex items-center px-3 justify-evenly">
            <li>
              <a href="/">
                <img alt="logo" src={"/logo.png"} width={75} height={75} />
              </a>
            </li>
            <li>
              <ul className="flex items-center gap-1 w-max">
                <li className="cursor-pointer bg-black p-1 w-[25px] h-[25px] flex items-center justify-center">
                  <a href="http://wa.me/919269148541">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      color="white"
                      width={20}
                      height={20}
                    />
                  </a>
                </li>
                <li className="cursor-pointer bg-black p-1 w-[25px] h-[25px] flex items-center justify-center">
                  <a href="https://www.youtube.com/@Subsidy_Scheme">
                    <FontAwesomeIcon
                      icon={faYoutube}
                      color="white"
                      width={20}
                      height={20}
                    />
                  </a>
                </li>
                <li className="cursor-pointer bg-black p-[1px] px-[2px] w-[25px] h-[25px] flex items-center justify-center">
                  <a href="mailto:subsidyscheme01@gmail.com">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      color="white"
                      width={20}
                      height={20}
                    />
                  </a>
                </li>
              </ul>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/">Home</a>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/subsidy">Subsidy</a>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/startup">Startup</a>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/consultancy">Consultancy</a>
            </li>
          </ul>
        </div>
        <div className="lg:bg-[#000000] lg:w-52 h-[100px] cursor-pointer p-3 xl:w-72 flex lg:flex-row flex-col items-center justify-evenly lg:text-white">
          <a href="/">
            <div id="navbar" className="robot text-center relative">
              <p className="text-[20px] my-0 tracking-[.20em] m-0 lg:text-[30px]">
                Subsidy
              </p>
              <hr />
              <p className="text-center text-[10px] w-max mx-auto my-0 tracking-[2px]">
                Welcome to Business World
              </p>
              <hr />
            </div>
          </a>
        </div>
        <div className="w-[calc(50%-110px)] lg:block hidden">
          <ul className="flex items-center justify-evenly">
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/banking">Banking</a>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              {" "}
              <a href="/#aboutus">About Us</a>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/gallery">Gallery</a>
            </li>
            <li className="text-[11px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/contact">Contact Us</a>
            </li>

            <li className="text-[11px] px-3 py-2 text-white bg-[#000000] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a
                href={
                  loggedIn && !loader
                    ? userType === "office"
                      ? `/portal/office/${uid}`
                      : uid === owneruid
                      ? "/owner"
                      : userType === "user"
                      ? `/portal/user/${officeId}/${uid}`
                      : "/signIn"
                    : "/signIn"
                }
              >
                {" "}
                {loader ? (
                  <Spinner />
                ) : loggedIn ? (
                  <FontAwesomeIcon icon={faUserAlt} />
                ) : (
                  "login"
                )}
              </a>
            </li>
            {loggedIn && (
              <li
                className="text-[11px] px-3 py-2 text-white bg-[#000000] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
                onClick={logout}
              >
                <FontAwesomeIcon icon={faSignOut} size="lg" />
              </li>
            )}
          </ul>
        </div>
        <p className="lg:hidden flex text-[11px] px-3 py-2 text-white bg-[#000000] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
          <a
            href={
              loggedIn && !loader
                ? userType === "office"
                  ? `/portal/office/${uid}`
                  : uid === owneruid
                  ? "/owner"
                  : userType === "user"
                  ? `/portal/user/${officeId}/${uid}`
                  : "/signIn"
                : "/signIn"
            }
          >
            {loader ? (
              <Spinner />
            ) : loggedIn ? (
              <FontAwesomeIcon icon={faUserAlt} />
            ) : (
              "login"
            )}
          </a>
        </p>
      </nav>
      {showMobileMenu && (
        <nav className="absolute z-[1000] h-screen lg:hidden block bg-white w-full">
          <ul className="flex flex-col items-start justify-center p-4 gap-4">
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/">Home</a>
            </li>
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/subsidy">Subsidy</a>
            </li>
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/startup">Startup</a>
            </li>
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/consultancy">Consultancy</a>
            </li>
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/banking">banking</a>
            </li>
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/#aboutus">About Us</a>
            </li>
            <li
              onClick={() => {
                setshowMobileMenu(false);
              }}
              className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
            >
              <a href="/gallery">Gallery</a>
            </li>
            <li className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer">
              <a href="/contact">Contact Us</a>
            </li>
            {!loader && loggedIn && (
              <li
                onClick={() => {
                  setshowMobileMenu(false);
                  logout();
                }}
                className="border-b-[1px] border-b-s border-b-black w-full text-[12px] tracking-[.15em] font-normal hover:font-bold cursor-pointer"
              >
                Log out
              </li>
            )}
          </ul>
          <ul className="flex items-center justify-center gap-2 h-[30px]">
            <li className="cursor-pointer">
              <a href="http://wa.me/919269148541">
                <FontAwesomeIcon icon={faWhatsapp} width={30} height={30} />
              </a>
            </li>
            <li className="cursor-pointer">
              <a href="https://www.youtube.com/@Subsidy_Scheme">
                <FontAwesomeIcon icon={faYoutube} width={30} height={30} />
              </a>
            </li>
            <li className="cursor-pointer">
              <a href="mailto:subsidyscheme01@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} width={30} height={30} />
              </a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Navbar;

import React, {useEffect} from "react";
import { motion } from "framer-motion";
import {
  incomeTax,
  gstDatabase,
  otherIPR,
  isoDatabase,
  designRegistration,
  trademarkDatabase,
} from "../constant/database";
import { useNavigate } from "react-router-dom";

const Taxation = () => {
  const route = useNavigate();
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

  return (
    <div className="bg-[#ececec] mt-28">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 1.5xl:grid-cols-4 gap-y-8 py-12 px-1 sm:px-3">
        {[
          ...Object.entries(isoDatabase),
          ...Object.entries(trademarkDatabase),
          ...Object.entries(gstDatabase),
          ...Object.entries(designRegistration),
          ...Object.entries(incomeTax),
          ...Object.entries(otherIPR),
        ]
          // .slice(4)
          .map((itm, ind) => (
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              onClick={() => {
                route(`/consultancy/${itm[0]}`);
              }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0 },
              }}
              key={ind}
              style={{ margin: "0 auto" }}
              className="slickSlide slickSideOther"
            >
              <div className="w-[90%] mx-auto flex items-center h-[200px] mt-2 justify-center">
                <img
                  alt={itm[1].img}
                  src={itm[1].img}
                  style={{ height: "200px" }}
                />
              </div>
              <div className="p-3 flex flex-col gap-2">
                <h4 className="uppercase text-[14px] text-[#333] font-semibold">
                  Consultancy
                </h4>
                <h1 className="text-[#E55604] text-center text-[20px]">
                  {itm[0]}
                </h1>
                {/* <p>Upto 1 Cr loan sancation with 30% off on interest</p> */}
                <p className="text-[#223a72] cursor-pointer">Know More</p>
              </div>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default Taxation;

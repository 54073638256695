import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { motion } from "framer-motion";
import { subsidySchemeData } from "../constant/database";
import { useNavigate } from "react-router-dom";

const BannerCarousel = () => {
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1250 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1250, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 640 },
      items: 2,
    },
    minMobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      id="productSection"
      className="relative 
      "
      data-te-carousel-init
      data-te-ride="carousel"
    >
      {/* <video autoPlay loop muted>
        <source src="/banner.mp4" type="video/mp4" />
      </video> */}

      <div className="w-full px-3 pb-20">
        <div className=" relative">
          <h1 className="text-center my-4 font-bold text-[20px]">Schemes</h1>
          <Carousel infinite={false} autoPlay={false} responsive={responsive}>
            {Object.entries(subsidySchemeData).map((itm, ind) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 },
                }}
                key={ind}
                onClick={() => {
                  navigate(`/subsidy/${itm[0]}`);
                }}
                className="slickSlide"
              >
                <div className="w-[90%] mx-auto flex items-center mt-2 h-[250px] justify-center">
                  <img alt={itm[0]} src={itm[1].img} />
                </div>
                <div className="p-3 flex flex-col gap-2">
                  <h1 className="text-[#E55604] text-center text-[20px]">
                    {itm[0]}
                  </h1>
                  <h4 className="uppercase text-[14px] text-[#3D0C11] font-semibold">
                    Features
                  </h4>
                  <ul className="list-disc w-max px-4 max-w-[90%]">
                    {itm[1].feature.split(";").map((itm, ind) => (
                      <li key={ind} className="text-[12px] m-0 p-0">
                        {itm}
                      </li>
                    ))}
                  </ul>
                  <span className="text-[#223a72] mb-4 cursor-pointer">
                    Know More
                  </span>
                </div>
              </motion.div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default BannerCarousel;

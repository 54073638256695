export const schemeData = [
  "Agri Infra Fund",
  "MLUPY",
  "PMEGP",
  "PMFME",
  "MYUPY",
  "RIPS",
  "NABARD",
  "RSAMB",
  "CGTMSE",
  "Rajasthan Dalit Aadiwasi Udyam Protsahan Yojna",
  "Biomas (Non-bagasse) cogeneration Project under MNRE",
  "Briquette Pellet Manufacturing Plant Under MNRE",
  "MSME",
  "ASPIRE",
  "Standup India",
  "Minority Loan",
  "Common Facility Centre",
  "Zero Defect and Zero Effect",
  "Amended Technology Upgradation Fund Scheme",
];

export const navbar = {
  Startup: {
    Propriotiership: ["MSME Registration", "Business Registration"],
    Partnership: [
      "Partnership Deed",
      "Partnership PAN Card",
      "Firm Registration",
    ],
    "Company Incorporation": [
      "One Person Company",
      "Private Limited",
      "Limited Liability Partnership",
      "Startup India Registration",
      "Section 8 Company",
    ],
    "Other Startup Service": [
      "PF Registraion",
      "ESI Registration",
      "Udyam Registration",
      "Import Export Code",
      "APEDA Registration",
      "FSSAI Registration",
      "Shop Act Registration",
      "Societ, NGO, Trust Registration",
    ],
  },
  Subsidy: {
    Subsidy: [...schemeData],
  },
  Banking: {
    "Opening Current Account": [
      "Mudra Loan",
      "MSME Loan",
      "CGTMSE Coverage Loan",
      "Agri Loan",
      "Housing Loan",
      "Car Loan",
      "Mortgage Loan",
      "Personal Loan",
      "Business Loan",
      "CC & OD Limit",
    ],
  },
  Taxation: {
    "Income Tax": [
      "PAN Card",
      "Personal Tax Filing",
      "Business Tax Filing",
      "Form 16",
      "26 AS",
      "Tax Payment",
    ],
    GST: [
      "GST Registration",
      "GST Return Filing",
      "GST Annual Return",
      "GST E-way Bill",
      "GST E-Invoice",
      "GST Refund",
    ],
    TDS: ["TAN Registration", "TDS Return Filing"],
  },
  IPR: {
    Trademark: [
      "Trademark Registration",
      "Trademark Objection",
      "Trademark Opposition",
      "Trademark Renewal",
      "Trademark Public Search",
    ],
    ISO: [
      "ISO 9001",
      "ISO 14001",
      "ISO 45001",
      "ISO 22000",
      "ISO 27001",
      "ISO 13845",
    ],
    Design: ["Design Registration", "Logo Design", "Logo Registration"],
    "Other IPR": ["Bar code", "Copyright", "Patent"],
  },
  "Privacy Policy": {
    "Privacy Policy": ["Privacy Policy"],
  },
  "Terms & Conditions": {
    "Terms & Conditions": ["Terms & Conditions"],
  },
  "Forms & Downloads": {
    Forms: [
      "PAN Card",
      "PAN Correction Form",
      "Partnership Deed",
      "Rent Agreement",
      "GST Return Form",
      "Power Of Attorney",
    ],
  },
};

export const owneruid = "3afgVYsW9uOgupoMhn3jOxfjqes2";

import {
  collection,
  getFirestore,
  doc,
  query,
  orderBy,
  limit,
  getDoc,
  startAfter,
  increment,
  getDocs,
  where,
  setDoc,
  addDoc,
  updateDoc,
  Timestamp as FireTime,
} from "firebase/firestore";
import passwordHash from "password-hash";
import { toast } from "react-toastify";
import { app } from "../firebase-key";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const fetchTeamDocuments = async (
  startAfterDoc,
  setloadingWhileFetchingDoc,
  phone,
  sethasMoreDocuments
) => {
  setloadingWhileFetchingDoc(true);
  try {
    const db = getFirestore();
    const customerCollection = collection(db, "Customer");
    const customer = doc(customerCollection, phone);
    const documentsCollection = collection(customer, "Documents");

    const q = query(
      documentsCollection,
      orderBy("dateOfUpload"), // Replace with your sorting field
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });

    if (querySnapshot.size < 25) {
      sethasMoreDocuments(false);
    }

    return newTeamMembers;
  } catch (error) {
    toast.error("Error in fetching document data");
    console.error("Error fetching team members:", error);
    return [];
  } finally {
    setloadingWhileFetchingDoc(false);
  }
};

const fetchNextDocument = async (
  lastDocumentOfTeam,
  setloadingWhileFetchingDoc,
  setdocuments,
  setlastDocumentOfTeam,
  sethasMoreDocuments
) => {
  try {
    if (lastDocumentOfTeam == null) return;
    setloadingWhileFetchingDoc(true);
    const newTeamMembers = await fetchTeamDocuments(lastDocumentOfTeam);

    if (newTeamMembers.length > 0) {
      setdocuments((prevTeamMembers) => [
        ...prevTeamMembers,
        ...newTeamMembers,
      ]);
      // Update lastDocument with the last document from the fetched data
      setlastDocumentOfTeam(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      sethasMoreDocuments(false);
    }
  } catch (error) {
    console.error("Error fetching team members:", error);
  } finally {
    setloadingWhileFetchingDoc(false);
  }
};

const getTeamDocumentByName = async (
  name,
  setloadingWhileFetchingDocFromName,
  phone
) => {
  if (name.length === 0) return null;
  setloadingWhileFetchingDocFromName(true);
  const db = getFirestore();
  const customerCollection = collection(db, "Customer");
  const customer = doc(customerCollection, phone);
  const documentsCollection = collection(customer, "Documents");
  const q = query(documentsCollection, where("name", "==", name));

  try {
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setloadingWhileFetchingDocFromName(false);
      // Handle case where no document is found for the given phoneNumber
      return null;
    }

    // Assuming there's only one document with the given phoneNumber, return it
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });
    setloadingWhileFetchingDocFromName(false);
    return newTeamMembers;
  } catch (error) {
    console.error("Error getting team member by phone number:", error);
    // Handle error
    setloadingWhileFetchingDocFromName(false);
    toast.error("Error occured, try again later.");
    return null;
    // throw error;
  }
};

async function updateUserProfile(
  documentId,
  updateProfileData,
  setupdateProfile,
  userData
) {
  try {
    setupdateProfile(true);
    let downloadURL = "";
    if (updateProfileData.profilePicture != null) {
      const storage = getStorage();
      const storageRef = ref(storage, `userProfile/${userData.phoneNumber}`);

      // Upload file to Firebase Storage
      await uploadBytes(storageRef, updateProfileData.profilePicture);

      // Get download URL of the uploaded file
      downloadURL = await getDownloadURL(storageRef);
    }

    const db = getFirestore(app);
    const docRef = doc(db, "Customer", documentId);
    let hash = "";
    if (updateProfileData.password.length > 0) {
      hash = passwordHash.generate(updateProfileData.password);
    }
    const updateContent = {
      ...updateProfileData,
      password: hash ? hash : userData.password,
      profilePicture:
        downloadURL.length > 0 ? downloadURL : userData.profilePicture,
    };

    await updateDoc(docRef, updateContent);
    toast.success("Profile Updated Successfully.");
    window.location.reload();
  } catch (error) {
    console.log("fsds", error);
    setupdateProfile(false);
    toast.error("Error occured, try again later.");
  }
}

const handleFetchUser = async (
  phone,
  setdocuments,
  closeModal,
  setlastDocumentOfTeam,
  setUserData,
  setupdateProfileData,
  setloadingWhileFetchingDoc,
  sethasMoreDocuments,
  navigate,
  number,
  setFinance,
  setlastFinanceOfTeam,
  userid
) => {
  try {
    const db = getFirestore(app);
    // Assuming you have a function to add data to your database, for example, using Firebase Firestore
    const teamCollection = collection(db, "Customer");
    const customDocRef = doc(teamCollection, phone);
    const response = await getDoc(customDocRef);
    if (response.exists()) {
      let data = response.data();
      if (data.status === "active") {
        (async () => {
          let docData = await fetchTeamDocuments(
            null,
            setloadingWhileFetchingDoc,
            phone,
            sethasMoreDocuments
          );
          setdocuments(docData);
          closeModal();
          setlastDocumentOfTeam(docData[docData.length - 1]);
        })();
        (async () => {
          let docData = await fetchTeamFinance(
            null,
            setloadingWhileFetchingDoc,
            number,
            userid
          );
          setFinance(docData);
          closeModal();
          if (docData.length < 25) {
            setlastFinanceOfTeam(null);
          } else {
            setlastFinanceOfTeam(docData[docData.length - 1]);
          }
        })();
      }
      setUserData(data);
      setupdateProfileData({ ...data, profilePicture: null, password: "" });
    } else {
      navigate("/");
    }
  } catch (error) {
    // Handle errors and display them using React-Toastify
    toast.error(error.message);
  }
};

const uploadFinance = async (
  setloadingWhileCreatingNewFinance,
  number,
  financeData,
  userid,
  setfinanceDataArray,
  closeModal
) => {
  try {
    if (
      !financeData?.amount ||
      !financeData?.serviceName ||
      !financeData?.dateOfEntry
    ) {
      toast.error("All fields are required");
      return;
    }
    setloadingWhileCreatingNewFinance(true);
    const db = getFirestore();
    const teamCollection = collection(db, "Team");
    const teamDoc = doc(teamCollection, number);
    const customerCollection = collection(teamDoc, "Finance"); // Data you want to add to the document

    // Custom document ID
    const customDocId = userid + financeData.dateOfEntry;

    // Add document with custom ID
    await setDoc(doc(customerCollection, customDocId), {
      ...financeData,
      amount: Number(financeData.amount),
      phoneNumber: userid,
      uid: customDocId,
      dateOfEntry: FireTime.fromDate(new Date(financeData.dateOfEntry)),
    });
    await updateDoc(teamDoc, {
      totalAmtPending: increment(financeData.amount),
    });
    let userData = await fetchTeamFinance(
      null,
      setloadingWhileCreatingNewFinance,
      number,
      userid
    );
    setfinanceDataArray(userData);
    closeModal();
    toast.success("Successfully Created New Entry.");
  } catch (error) {
    // Show error message using Toast in case of failure
    toast.error("Error uploading document. Please try again.");
    setloadingWhileCreatingNewFinance(false);
    console.error("Error uploading document:", error);
  }
};

const uploadDocument = async (
  e,
  documentData,
  setloadingWhileUploadingFile,
  phone,
  setdocuments,
  closeModal,
  setdocumentData,
  initialDocument
) => {
  e.preventDefault();
  if (documentData.file == null) {
    toast.error("File is required.");
    return;
  }
  if (documentData.name.length === 0) {
    toast.error("File Name is required.");
    return;
  }
  setloadingWhileUploadingFile(true);
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `documents/${documentData.file.name + phone + Date.now()}`
  );

  try {
    // Upload file to Firebase Storage
    await uploadBytes(storageRef, documentData.file);

    // Get download URL of the uploaded file
    const downloadURL = await getDownloadURL(storageRef);

    // Create a new document in Firestore with the download URL
    const newDocument = {
      ind: 0,
      name: documentData.name,
      whyUploaded: documentData.whyUploaded,
      src: downloadURL,
      dateOfUpload: FireTime.fromDate(new Date()),
    };

    // Add the document to Firestore
    const db = getFirestore();

    const customerCollection = collection(db, "Customer");
    const customer = doc(customerCollection, phone);
    const documentsCollection = collection(customer, "Documents");
    await addDoc(documentsCollection, newDocument);
    let userData = await fetchTeamDocuments(null);
    setdocuments(userData);
    // Show success message using Toast
    toast.success("Document uploaded successfully!");
    closeModal();
    setdocumentData(initialDocument);
    setloadingWhileUploadingFile(false);
  } catch (error) {
    // Show error message using Toast in case of failure
    toast.error("Error uploading document. Please try again.");
    setloadingWhileUploadingFile(false);
    console.error("Error uploading document:", error);
  }
};

const fetchTeamFinance = async (
  startAfterDoc,
  setloadingWhileFetchingFinance,
  number,
  userid
) => {
  setloadingWhileFetchingFinance(true);
  try {
    const db = getFirestore();
    const teamCollection = collection(db, "Team");
    const teamDoc = doc(teamCollection, number);
    const customerCollection = collection(teamDoc, "Finance");

    const q = query(
      customerCollection,
      where("phoneNumber", "==", userid),
      orderBy("dateOfEntry"), // Replace with your sorting field
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });
    console.log(newTeamMembers, "newTeamMembers");
    return newTeamMembers;
  } catch (error) {
    console.log(error, "Fdsdc");
    toast.error("Error in fetching Finance data");
    return [];
  } finally {
    setloadingWhileFetchingFinance(false);
  }
};

const fetchNextFinance = async (
  lastFinanceOfTeam,
  setloadingWhileFetchingFinance,
  setFinance,
  setlastFinanceOfTeam,
  number,
  phone
) => {
  try {
    if (lastFinanceOfTeam == null) return;
    setloadingWhileFetchingFinance(true);
    const newTeamMembers = await fetchTeamFinance(
      lastFinanceOfTeam,
      setloadingWhileFetchingFinance,
      number,
      phone
    );

    if (newTeamMembers.length > 0) {
      setFinance((prevTeamMembers) => [...prevTeamMembers, ...newTeamMembers]);
      // Update lastDocument with the last document from the fetched data
      setlastFinanceOfTeam(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      setlastFinanceOfTeam(null);
    }
  } catch (error) {
    toast.error("Error occured, try again");
  } finally {
    setloadingWhileFetchingFinance(false);
  }
};

export {
  uploadDocument,
  updateUserProfile,
  handleFetchUser,
  getTeamDocumentByName,
  fetchNextDocument,
  fetchNextFinance,
  fetchTeamFinance,
  fetchTeamDocuments,
  uploadFinance,
};

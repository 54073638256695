import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowDown,
  faArrowUp,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { navbar } from "../constant/services";
import { documentsFormsDatabase } from "../constant/database";

const Footer = () => {
  const [expand, setexpand] = useState(false);
  return (
    <footer className="border-t-2 border-t-black border-t-solid">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div
          onClick={() => setexpand((prev) => !prev)}
          className="flex items-center p-3 cursor-pointer w-max mx-auto animate-bounce justify-center border-2 border-black border-solid rounded-full"
        >
          <FontAwesomeIcon icon={expand ? faArrowDown : faArrowUp} />
        </div>
        {expand && (
          <div className="flex flex-wrap justify-between w-full">
            {Object.entries(navbar).map((itm, ind) => (
              <div key={ind}>
                {Object.entries(itm[1]).map((head, index) => (
                  <div key={index}>
                    <h1 className="font-semibold">{head[0]}</h1>
                    {head[1].map((para, keys) => (
                      <p
                        className="text-[12px] font-normal hover:underline cursor-pointer"
                        key={keys}
                      >
                        <a
                          href={
                            itm[0] === "Forms & Downloads"
                              ? documentsFormsDatabase[para]
                              : head[0] === "Trademark" ||
                                head[0] === "ISO" ||
                                head[0] === "Design" ||
                                head[0] === "Other IPR" ||
                                head[0] === "Income Tax" ||
                                head[0] === "GST" ||
                                head[0] === "TDS"
                              ? `/consultancy/${para}`
                              : head[0] === "Propriotiership" ||
                                head[0] === "Partnership" ||
                                head[0] === "Company Incorporation" ||
                                head[0] === "Other Startup Service"
                              ? `/startup/${para}`
                              : head[0] === "Subsidy"
                              ? `/subsidy/${para}`
                              : head[0] === "Terms & Conditions"
                              ? "/terms"
                              : head[0] === "Privacy Policy"
                              ? "/privacy"
                              : head[0] === "Opening Current Account"
                              ? `/banking/${para}`
                              : `/`
                          }
                        >
                          {para}
                        </a>
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="text-white bg-black p-4 pt-0 pb-6 lg:py-8 lg:pt-0">
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div>
          <ul className="flex items-center gap-2 w-max">
            <li className="cursor-pointer">
              <a href="http://wa.me/919269148541">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </li>
            <li className="cursor-pointer">
              <a href="https://www.youtube.com/@Subsidy_Scheme">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li className="cursor-pointer">
              <a href="mailto:subsidyscheme01@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>
          </ul>
        </div>
        <div className="sm:flex sm:items-center sm:justify-center">
          <span className="text-sm">
            © 2023{" "}
            <a href="/" className="hover:underline">
              SubsidyScheme
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonial = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1250 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1250, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
    },
    minMobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      id="testimonial"
      className="border-b-[1px] border-b-s border-b-black"
    >
      <div className="w-[90%] py-12 sm:w-[75%] mx-auto">
        <h1 className="text-center my-4 font-bold text-[20px]">Testimonial</h1>
        <Carousel autoPlay={true} infinite={true} responsive={responsive}>
          {[1, 2, 3, 4].map((_, ind) => (
            <div
              key={ind}
              className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6"
            >
              <figure className="max-w-screen-md mx-auto">
                <svg
                  className="h-12 mx-auto mb-3 text-gray-400"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <blockquote>
                  <p className="text-2xl font-medium text-gray-900">
                    {ind === 0
                      ? "SubsidyScheme made the complicated world of government subsidies and grants incredibly straightforward for my business. Their expert team guided us every step of the way, ensuring we didn't miss out on valuable opportunities. Highly recommended!"
                      : ind == 1
                      ? "I am truly impressed with the personalized service provided by SubsidyScheme. Their attention to detail, combined with their in-depth knowledge of various subsidy programs, helped my startup gain access to crucial funds. Their dedication is unmatched!"
                      : ind === 2
                      ? "Working with SubsidyScheme has been a game-changer for my clients. Their expertise in navigating complex government schemes is unparalleled. Thanks to their guidance, my clients have maximized their benefits and achieved remarkable financial stability."
                      : "I can't express my gratitude enough for the assistance provided by SubsidyScheme. Their team's patience, professionalism, and commitment to our business's success were remarkable. We not only received subsidies but also gained valuable insights for sustainable growth. Truly exceptional service!"}
                  </p>
                </blockquote>
                <figcaption className="flex items-center justify-center mt-6 space-x-3">
                  {/* <img
                    className="w-6 h-6 rounded-full"
                    src=""
                    alt="profile picture"
                  /> */}
                  <div className="flex items-center divide-x-2 divide-gray-500">
                    <div className="pr-3 font-medium text-gray-900">
                      {ind === 0
                        ? "Ram Goyal"
                        : ind == 1
                        ? "Manoj Sharma"
                        : ind == 2
                        ? "Suraj Sharma"
                        : "Pankaj Jain"}
                    </div>
                    <div className="pl-3 text-sm font-light text-gray-500">
                      Business Owner
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonial;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { useNavigate } from "react-router-dom";
import { UseAuthContext } from "../state/appContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import passwordHash from "password-hash";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setloading] = useState(false);
  const { loader, loggedIn } = UseAuthContext();
  const [userType, setuserType] = useState("user");
  const [exploreDropdown, setexploreDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loader && loggedIn) {
      navigate("/");
    }
  }, [loggedIn, loader]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      // Reference to the document in the "Customer" collection
      setloading(true);
      const db = getFirestore();
      const docRef =
        userType === "user"
          ? doc(db, "Customer", email)
          : doc(db, "Team", email);

      // Get the document snapshot
      const docSnap = await getDoc(docRef);
      // Check if the document exists
      if (docSnap.exists()) {
        const teamMemberData = docSnap.data();
        var passwordMatch = passwordHash.verify(
          password,
          teamMemberData.password
        );
        var status = teamMemberData?.status === "active";
        if (passwordMatch) {
          if (status) {
            window.localStorage.setItem("userid", email);
            window.localStorage.setItem("userType", userType);
            window.localStorage.setItem("password", password);
            navigate("/");
            window.location.reload();
          } else {
            toast.error("Access is temporarily Blocked, Try contact our Team.");
          }
        } else {
          toast.error("Password is wrong");
        }
      } else {
        // Handle case where document does not exist
        toast.error("User not found.");
      }
      setloading(false);
      return null;
    } catch (error) {
      setloading(false);
      // Handle errors and show error message using Toast
      toast.error("Error fetching team member. Please try again later.");
      console.error("Error fetching team member:", error);
      return null;
    }
  };

  return (
    <section className="bg-gray-50 mt-28">
      <ToastContainer position="bottom-center" />
      <div className="flex flex-col items-center mt-16 justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Sign in to your account
            </h1>
            <form onSubmit={handleSignIn} className="space-y-4 md:space-y-6">
              <div className="relative">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  User Type
                </label>

                <button
                  onClick={() => setexploreDropdown((prev) => !prev)}
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="bg-gray-50 border flex flex-row items-center justify-between border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full p-2.5"
                  type="button"
                >
                  {userType}
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                {exploreDropdown && (
                  <div
                    id="dropdown"
                    className="z-10 absolute w-full bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 flex flex-col gap-3 p-3 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li
                        onClick={() => {
                          setuserType("user");
                          setexploreDropdown((prev) => !prev);
                        }}
                        className="cursor-pointer hover:font-bold"
                      >
                        user
                      </li>
                      <li
                        onClick={() => {
                          setuserType("office");
                          setexploreDropdown((prev) => !prev);
                        }}
                        className="cursor-pointer hover:font-bold"
                      >
                        office
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Your Login Code
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="9987876566"
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />
              </div>

              <button
                type="submit"
                className="w-full text-white flex items-center justify-center bg-[#223a72] bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                {loading ? <Spinner /> : "Sign in"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;

import { useLocation } from "react-router-dom";
import React from "react";
import { subsidySchemeData } from "../constant/database";
import YouTube from "react-youtube";

const SingleSubsidy = () => {
  const router = useLocation();
  let service = router.pathname.substring(9);
  service = service.split("%20").join(" ");

  if (!service) return;

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      showinfo: 0,
      rel: 0,
    },
  };
  function _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }

  const choosedScheme = subsidySchemeData[service];
  return (
    <div className="min-h-screen mt-28 bg-[#eeeeee] w-full">
      <div className="max-w-[1300px] mx-auto p-4 flex flex-col gap-4">
        <div className="w-[90%] sm:w-[75%] smd:w-[820px] smd:gap-2 gap-10 flex flex-col items-center justify-center bg-white mx-auto p-2 sm:p-3 rounded-md">
          <img alt="" src={choosedScheme.img} />
          <div className="w-[80%] mx-auto">
            <h1 className="font-bold text-[25px]">{choosedScheme.title}</h1>
            <ul className="list-disc">
              {choosedScheme.feature.split(";").map((itm, ind) => (
                <li key={ind} className="text-[12px] m-0 p-0">
                  {itm}
                </li>
              ))}
            </ul>
            {/* <h3>{choosedScheme.feature}</h3> */}
          </div>
          <div className="w-[80%] mx-auto my-2">
            <h3 className="font-semibold text-[18px] text-[#444444]">
              Who can apply
            </h3>
            <ul className="list-disc">
              {choosedScheme.whoCanApply.map((apply, ind) => (
                <li className="text-[12px] tracking-[.10em]" key={ind}>
                  {apply}
                </li>
              ))}
            </ul>
          </div>

          <YouTube
            className="w-[80%] mx-auto"
            videoId={choosedScheme.videoId}
            opts={{ ...opts, width: "100%", height: "300px" }}
            onReady={_onReady}
          />
          <div className="w-[80%] mx-auto my-2">
            <h3 className="font-semibold text-[18px] text-[#444444]">
              Beneficiary Projects
            </h3>
            <ul className="list-disc">
              {choosedScheme.beneficiaryProjects.map((apply, ind) => (
                <li className="text-[12px] tracking-[.10em]" key={ind}>
                  {apply}
                </li>
              ))}
            </ul>
          </div>
          <div className="w-[80%] mx-auto my-2">
            <h3 className="font-semibold text-[18px] text-[#444444]">
              More Info About Scheme
            </h3>
            {choosedScheme.objectives.map((apply, ind) => (
              <div key={ind}>
                <strong>{apply.title}</strong>
                <div>
                  {apply.para.map((itm, index) => (
                    <p className="text-[12px] tracking-[.10em]" key={index}>
                      {itm}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleSubsidy;

import React, { useEffect, useState } from "react";
import { ContextAuthProvider } from "./appContext";
import { auth } from "../firebase-key";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { owneruid } from "../constant/services";
import passwordHash from "password-hash";

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [loader, setloader] = useState(true);
  const [uid, setuid] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [officeId, setofficeId] = useState('');
  const [userType, setuserType] = useState("");
  const [newsData, setnewsData] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (user.uid === owneruid) {
          setuid(user.uid);
          setcompanyName(user.email);
          setisAuthenticated(true);
          setloader(false);
        }
      } else {
        (async () => {
          const cookie = window.localStorage.getItem("userid");
          const cookiePass = window.localStorage.getItem("password");
          const cookieUserType = window.localStorage.getItem("userType");
          if (cookie && cookiePass) {
            const db = getFirestore();
            const docRef =
              cookieUserType === "user"
                ? doc(db, "Customer", cookie)
                : doc(db, "Team", cookie);

            // Get the document snapshot
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const teamMemberData =
                cookieUserType === "user" ? docSnap.data() : docSnap.data();
              var passwordMatch = passwordHash.verify(
                cookiePass,
                teamMemberData.password
              );
              if (passwordMatch) {
                setcompanyName(
                  teamMemberData.firstName + " " + teamMemberData.lastName
                );
                if(cookieUserType === "user") {
                  setofficeId(teamMemberData.officeIncharge);
                }
                setuserType(cookieUserType);
                setuid(teamMemberData.phoneNumber);
                setisAuthenticated(true);
              } else {
                window.localStorage.removeItem("userid");
                window.localStorage.removeItem("userType");
                window.localStorage.removeItem("password");
              }
            } else {
            }
          }
          setloader(false);
        })();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchNewsDocument();
  }, []);

  const fetchNewsDocument = async () => {
    const firestore = getFirestore();
    const newsDocRef = doc(firestore, "news", "Vocsw4EQgXuivxzgcfI2"); // Replace with the actual document ID

    try {
      const newsDocSnap = await getDoc(newsDocRef);
      if (newsDocSnap.exists()) {
        setnewsData(newsDocSnap.data());
      } else {
      }
    } catch (error) {
      console.error("Error fetching news document:", error);
    }

    setloader(false);
  };

  if (loader) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
          <svg
            fill="none"
            className="w-6 h-6 animate-spin"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>

          <div>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <ContextAuthProvider.Provider
      value={{
        newsData: newsData ?? {
          schemes: [],
          clientCorner: [],
          singleLine: "",
          ourProjects: [],
          updates: [],
        },
        officeId,
        setLoader: setloader,
        setLoggedIn: setisAuthenticated,
        setName: setcompanyName,
        setUid: setuid,
        setUSERType: setuserType,
        loader: loader,
        userType: userType,
        uid: uid,
        loggedIn: isAuthenticated,
        name: companyName,
      }}
    >
      {children}
    </ContextAuthProvider.Provider>
  );
};
// Memoize the AuthContextProvider to prevent unnecessary re-renders
export default AuthContextProvider;

import React from "react";

const ShowingInfo = ({ value, label }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      <p className="text-lightSecondary text-[12px] text-center font-normal">
        {label}
      </p>
      <h3 className="text-bluePrimary text-xl font-semibold">{value}</h3>
    </div>
  );
};

export default ShowingInfo;

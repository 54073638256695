import {
  collection,
  getFirestore,
  updateDoc,
  deleteDoc,
  setDoc,
  doc,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  getDoc,
  where,
  Timestamp as FireTime,
} from "firebase/firestore";
import { toast } from "react-toastify";
import passwordHash from "password-hash";
import { auth, app } from "../firebase-key";
import { signInWithEmailAndPassword } from "firebase/auth";

const deleteUser = async (documentId, ind, settimeTodelete) => {
  try {
    settimeTodelete(ind);
    const db = getFirestore(app);
    const docRef = doc(db, "Team", documentId);
    await deleteDoc(docRef);
    toast.success("User deleted Successfully.");
    window.location.reload();
  } catch (error) {
    settimeTodelete(-1);
    toast.error("Error occured, try again later.");
  }
};

const updateUser = async (
  documentId,
  updatedData,
  index,
  settimetoBlockTeam
) => {
  try {
    settimetoBlockTeam(index);
    const db = getFirestore(app);
    const docRef = doc(db, "Team", documentId);
    await updateDoc(docRef, updatedData);
    toast.success("User Blocked Successfully.");
    window.location.reload();
  } catch (error) {
    settimetoBlockTeam(-1);
    toast.error("Error occured, try again later.");
  }
};

const fetchUserQuery = async (startAfterDoc, settimeTofetchQuery) => {
  settimeTofetchQuery(true);
  try {
    const db = getFirestore();
    const teamCollection = collection(db, "Contact");

    const q = query(
      teamCollection,
      orderBy("createdAt"), // Replace with your sorting field
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });
    return newTeamMembers;
  } catch (error) {
    console.error("Error fetching Query:", error);
    return [];
  } finally {
    settimeTofetchQuery(false);
  }
};

const fetchNextQuery = async (
  lastQueryDoc,
  settimeToFetchNextQueryDoc,
  lastDocument,
  setfetchQuery,
  setlastQueryDoc,
  setIsLoading,
  setLastDocument
) => {
  try {
    if (lastQueryDoc == null) return;
    settimeToFetchNextQueryDoc(true);
    const newTeamMembers = await fetchTeamMembers(
      lastDocument,
      setIsLoading,
      setLastDocument
    );
    if (newTeamMembers.length > 0) {
      setfetchQuery((prevTeamMembers) => [
        ...prevTeamMembers,
        ...newTeamMembers,
      ]);
      // Update lastDocument with the last document from the fetched data
      setlastQueryDoc(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      setlastQueryDoc(null);
    }
  } catch (error) {
    console.error("Error fetching Query:", error);
  } finally {
    settimeToFetchNextQueryDoc(false);
  }
};

const fetchTeamMembers = async (
  startAfterDoc,
  setIsLoading,
  setLastDocument
) => {
  setIsLoading(true);
  try {
    const db = getFirestore();
    const teamCollection = collection(db, "Team");

    const q = query(
      teamCollection,
      orderBy("dateOfJoined"), // Replace with your sorting field
      limit(25),
      startAfter(startAfterDoc)
    );

    const querySnapshot = await getDocs(q);
    const newTeamMembers = [];

    querySnapshot.forEach((doc) => {
      const teamMemberData = doc.data();
      newTeamMembers.push(teamMemberData);
    });

    if (querySnapshot.size < 25) {
      setLastDocument(null);
    }
    return newTeamMembers;
  } catch (error) {
    console.error("Error fetching team members:", error);
    return [];
  } finally {
    setIsLoading(false);
  }
};

const fetchNext = async (
  lastDocument,
  setloadingFormore,
  setTeamMembers,
  setLastDocument,
  setIsLoading
) => {
  try {
    if (lastDocument == null) return;
    setloadingFormore(true);
    const newTeamMembers = await fetchTeamMembers(
      lastDocument,
      setIsLoading,
      setLastDocument
    );

    if (newTeamMembers.length > 0) {
      setTeamMembers((prevTeamMembers) => [
        ...prevTeamMembers,
        ...newTeamMembers,
      ]);
      // Update lastDocument with the last document from the fetched data
      setLastDocument(newTeamMembers[newTeamMembers.length - 1]);
    } else {
      // If no new documents were fetched, there are no more items to load
      setLastDocument(null);
    }
  } catch (error) {
    console.error("Error fetching team members:", error);
  } finally {
    setloadingFormore(false);
  }
};
const handleSignIn = async (
  event,
  setloading,
  email,
  password,
  owneruid,
  closeModal
) => {
  event.preventDefault();
  try {
    setloading(true);
    const user = await signInWithEmailAndPassword(auth, email, password);

    if (user.user.uid === owneruid) {
      setloading(false);
      closeModal();
      toast.success("Welcome Back Owner");
    } else {
      toast.error("Invalid Credentials");
    }
  } catch (error) {
    toast.dismiss();

    switch (error.code) {
      case "auth/user-not-found":
        toast.error("User not found");
        break;
      case "auth/invalid-email":
        toast.error("Please Enter Email first.");
        break;
      case "auth/wrong-password":
        toast.error("Wrong password");
        break;
      default:
        toast.error("An error occurred. Please try again later.");
    }
    setloading(false);
  }
};
const initialTeam = {
  ind: 0,
  firmName: "",
  name: "",
  fatherName: "",
  email: "",
  phoneNumber: "",
  password: "",
  panNumber: "",
  gst: "",
  aadharNumber: "",
  address: "",
  bankName: "",
  ifscCode: "",
  AccountNo: "",
  profilePicture: "",
  backgroundPicture: "",
  totalAmtRecieved: 0,
  totalAmtPending: 0,
  district: "",
  documentsCount: 0,
  clientCounts: 0,
  dateOfJoined: FireTime.fromDate(new Date()),
  status: "active",
};

const handleFormSubmit = async (
  e,
  teamDoc,
  setloaderWhileAdding,
  setTeamMembers,
  closeModal,
  setteamDoc,
  setIsLoading,
  setLastDocument
) => {
  e.preventDefault();
  try {
    await createNewTeamMember(
      teamDoc,
      setloaderWhileAdding,
      setTeamMembers,
      closeModal,
      setteamDoc,
      setIsLoading,
      setLastDocument
    );
    // Handle success if needed
  } catch (error) {
    // Handle error, for example, show an error message to the user
    console.error(error.message);
  }
};

const createNewTeamMember = async (
  teamMember,
  setloaderWhileAdding,
  setTeamMembers,
  closeModal,
  setteamDoc,
  setIsLoading,
  setLastDocument
) => {
  setloaderWhileAdding(true);
  // Check phone number length
  if (teamMember.phoneNumber.length !== 10) {
    setloaderWhileAdding(false);
    toast.error(
      "Phone number must be 10 digits long, Use without country code."
    );
    return;
  }
  // var salt = bcrypt.genSaltSync(10);
  // var hash = bcrypt.hashSync(password, salt);
  var hash = passwordHash.generate(teamMember.password);
  // Create a new document in the "Team" collection
  const newTeamMemberData = {
    ...teamMember,
    password: hash,
  };

  try {
    const db = getFirestore(app);
    // Assuming you have a function to add data to your database, for example, using Firebase Firestore
    const teamCollection = collection(db, "Team");
    const customDocRef = doc(teamCollection, teamMember.phoneNumber);
    const response = await getDoc(customDocRef);

    if (response.exists()) {
      toast.error("This team member already exists, Try creating new");
      return;
    }
    await setDoc(customDocRef, newTeamMemberData);

    setloaderWhileAdding(false);
    toast.success("Successfully created new member");
    let userData = await fetchTeamMembers(null, setIsLoading, setLastDocument);
    setTeamMembers(userData);
    closeModal();
  } catch (error) {
    setloaderWhileAdding(false);
    console.error("Error adding team member:", error);
    toast.error("Error occured, try again later");
  } finally {
    setteamDoc(initialTeam);
  }
};

const getTeamMemberByPhoneNumber = async (
  phoneNumber,
  setloaderWhileFetchingFromPhone
) => {
  if (phoneNumber.length !== 10) return;
  setloaderWhileFetchingFromPhone(true);
  const db = getFirestore();
  const teamCollection = collection(db, "Team");

  const q = query(teamCollection, where("phoneNumber", "==", phoneNumber));

  try {
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      setloaderWhileFetchingFromPhone(false);
      // Handle case where no document is found for the given phoneNumber
      return null;
    }

    // Assuming there's only one document with the given phoneNumber, return it
    const teamMemberData = querySnapshot.docs[0].data();
    setloaderWhileFetchingFromPhone(false);
    return teamMemberData;
  } catch (error) {
    console.error("Error getting team member by phone number:", error);
    // Handle error
    setloaderWhileFetchingFromPhone(false);
    toast.error("Error occured, try again later.");
    return null;
    // throw error;
  }
};

export {
  getTeamMemberByPhoneNumber,
  handleFormSubmit,
  handleSignIn,
  initialTeam,
  deleteUser,
  updateUser,
  fetchUserQuery,
  fetchNext,
  fetchNextQuery,
  fetchTeamMembers,
};

import "./App.css";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Contact from "./pages/contact";
import Privacy from "./pages/privacy";
import SignIn from "./pages/signIn";
import Gallery from "./pages/gallery";
import SingleBanking from "./pages/singleBanking";
import SinglePageGallery from "./pages/singlePageGallery";
import PageNotFound from "./pages/pageNotFound";
import Banking from "./pages/banking";
import Startup from "./pages/startup";
import SingleStartup from "./pages/singleStartup";
import Subsidy from "./pages/subsidy";
import SingleSubsidy from "./pages/singleSubsidy";
import Taxation from "./pages/taxation";
import SingleTaxation from "./pages/singleTaxation";
import Owner from "./pages/owner";
import Office from "./pages/office";
import UserPage from "./pages/user";

function App() {
  const [showMobileMenu, setshowMobileMenu] = useState(false);

  return (
    <div className="App">
      <Navbar
        showMobileMenu={showMobileMenu}
        setshowMobileMenu={setshowMobileMenu}
      />
      <Routes>
        <Route path="/consultancy" element={<Taxation />} />
        <Route path="/startup" element={<Startup />} />
        <Route path="/banking/:service" element={<SingleBanking />} />
        <Route path="/consultancy/:service" element={<SingleTaxation />} />
        <Route path="/banking" element={<Banking />} />
        <Route path="/startup/:service" element={<SingleStartup />} />
        <Route path="/subsidy" element={<Subsidy />} />
        <Route path="/subsidy/:service" element={<SingleSubsidy />} />
        <Route path="/singlePageGallery" element={<SinglePageGallery />} />
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/owner" element={<Owner />} />
        <Route path="/owner/:district/:number" element={<Office />} />
        <Route path="/portal/office/:number" element={<Office />} />
        <Route path="/owner/:district/:number/:phone" element={<UserPage />} />
        <Route path="/portal/user/:phone/:number" element={<UserPage />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../common/spinner";
import React, { useState, useEffect } from "react";
import { app } from "../firebase-key";
import {
  collection,
  getFirestore,
  addDoc,
  Timestamp as FireTime,
} from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import {
  faClock,
  faLock,
  faMailBulk,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Contact = () => {
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [loading, setloading] = useState(false);
  const [hasFilledForm, setHasFilledForm] = useState(false);

  function checkFilledForm() {
    const item = localStorage.getItem("hasFilledForm");

    if (item) {
      const parsedItem = JSON.parse(item);

      // Check if the item has not expired
      if (new Date().getTime() < parsedItem.expiry) {
        return true; // Form has been filled and has not expired
      }
    }

    return false; // Form has not been filled or has expired
  }

  useEffect(() => {
    // Check local storage to see if the user has filled the form before
    const storedValue = checkFilledForm();
    if (storedValue) {
      setHasFilledForm(true);
    }
  }, []);

  async function submit(event) {
    event.preventDefault();
    if (hasFilledForm) {
      return;
    }
    if (!name || !phone || !message) {
      toast.dismiss();
      toast.error("Fill the details first.");
      return;
    }
    setloading(true);
    const db = getFirestore(app);
    const contactRef = collection(db, "Contact");

    try {
      const newContactDoc = await addDoc(contactRef, {
        name: name,
        phoneNumber: phone,
        message: message,
        ind: 1,
        createdAt: FireTime.now(),
      });
      toast.success("form submitted successfully");
      // Calculate expiry time (1 day in milliseconds)
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const expiryTime = new Date().getTime() + oneDayInMilliseconds;

      // Set local storage key to indicate user has filled the form with expiry time
      var object = { value: "true", expiry: expiryTime };
      localStorage.setItem("hasFilledForm", JSON.stringify(object));
      setHasFilledForm(true);
    } catch (error) {
      toast.error("failed to submit form");
      console.error("Error adding document: ", error);
    } finally {
      // Clear form fields after successful submission
      setloading(false);
      setphone("");
      setmessage("");
      setname("");
    }
  }

  return (
    <div>
      <ToastContainer position="bottom-center" />
      <div className="container my-24 mx-auto md:px-6">
        <section className="mb-32">
          <div className="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://mdbcdn.b-cdn.net/img/new/textures/full/171.jpg')]"></div>
          <div className="container px-6 md:px-12">
            <div className="block rounded-lg bg-[hsla(0,0%,100%,0.7)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
              <div className="mb-12 grid gap-x-6 md:grid-cols-2 lg:grid-cols-4">
                <a
                  href="http://wa.me/9269148541?text=Hello%20Sir"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-auto flex flex-col items-center justify-center cursor-pointer"
                >
                  <div className="bg-[#223a72] rounded-full w-[50px] h-[50px] p-3">
                    {/* <img alt="location" src="/whatsapp.svg" /> */}
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      color="white"
                      size="2xl"
                    />
                  </div>
                  <button
                    type="button"
                    className="text-white bg-blue-700 mt-4 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Send a Quick Message
                  </button>
                  {/* <h6 className="font-medium">Whatsapp</h6> */}
                </a>

                <a
                  href="mailto:subsidyscheme01@gmail.com"
                  className="mx-auto mb-12 text-center lg:mb-0 flex flex-col items-center justify-center"
                >
                  <div className="bg-[#223a72] rounded-full w-[50px] h-[50px] p-3">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="m-auto text-white text-primary"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg> */}
                    <FontAwesomeIcon
                      icon={faMailBulk}
                      size="xl"
                      color="white"
                    />
                  </div>
                  <button
                    type="button"
                    className="text-white bg-blue-700 mt-4 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Mail Us
                  </button>
                  {/* <h6 className="font-medium">Mail Us</h6> */}
                </a>
                <a
                  href="tel:+919269148541"
                  className="mx-auto mb-6 text-center md:mb-0 flex flex-col items-center justify-center"
                >
                  <div className="bg-[#223a72] rounded-full w-[50px] h-[50px] p-3">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="m-auto text-white text-primary"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg> */}
                    <FontAwesomeIcon icon={faMobile} color="white" size="xl" />
                  </div>
                  <button
                    type="button"
                    className="text-white bg-blue-700 mt-4 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Call Us
                  </button>
                  {/* <h6 className="font-medium">Call Us</h6> */}
                </a>

                <a
                  href="https://cal.com/jitendra-jain-jfsiyz/15min"
                  target="_blank"
                  rel="noreferrer"
                  className="mx-auto mb-6 text-center md:mb-0 flex items-center justify-center flex-col"
                >
                  <div className="bg-[#223a72] rounded-full w-[50px] h-[50px] p-3">
                    <FontAwesomeIcon icon={faClock} size="xl" color="white" />
                  </div>
                  <button
                    type="button"
                    className="text-white bg-blue-700 mt-4 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                  >
                    Book A Time
                  </button>
                </a>
              </div>
              <div className="mx-auto max-w-[700px]">
                <form onSubmit={submit}>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className="peer block min-h-[auto] w-full rounded border-2 border-solid py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                      id="exampleInput90"
                      placeholder="Name"
                    />
                    <label
                      className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                      htmlFor="exampleInput90"
                    >
                      Name
                    </label>
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <input
                      type="number"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      className="peer block min-h-[auto] w-full rounded border-2 border-solid py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                      id="exampleInput91"
                      placeholder="Phone Number"
                    />
                    <label
                      className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none"
                      htmlFor="exampleInput91"
                    >
                      Phone Number
                    </label>
                  </div>
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <textarea
                      className="peer block border-2 border-solid min-h-[auto] w-full rounded py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                      placeholder="Your message"
                    ></textarea>
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="pointer-events-none absolute top-0 left-3 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 transition-all duration-200 ease-out peer-focus:-translate-y-[0.9rem] peer-focus:scale-[0.8] peer-focus:text-primary peer-data-[te-input-state-active]:-translate-y-[0.9rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none dark:text-neutral-200 dark:peer-focus:text-primary"
                    >
                      Message
                    </label>
                  </div>
                  <button
                    type="submit"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    className="inline-block w-full bg-[#223a72] rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] lg:mb-0"
                  >
                    {loading ? (
                      <Spinner />
                    ) : hasFilledForm ? (
                      <div className="flex items-center justify-center gap-3">
                        <span>You have filled form</span>
                        <FontAwesomeIcon icon={faLock} />
                      </div>
                    ) : (
                      "Send"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Gallery = () => {
  // Define an array of image URLs

  const route = useNavigate();

  return (
    <div className="w-full h-full p-6 mt-28">
      <h1 className="text-center font-bold text-[20px]">Work Gallery</h1>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0 },
        }}
        className="grid grid-cols-2 md:grid-cols-3"
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((imageUrl, imgIndex) => (
          <div
            key={imgIndex}
            className="m-2"
            // onClick={() => {
            //   route("/singlePageGallery");
            // }}
          >
            <img
              alt={`Imagfdse ${imgIndex}`}
              className="h-[250px] w-full rounded-lg"
              src={`/industry${imageUrl}.jpeg`}
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Gallery;

import React from "react";

const EditComponent = ({ placeholder, onChange, label }) => {
  return (
    <div>
      <label
        htmlFor="small-firm"
        className="block mb-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
      <input
        type={label === "Password" ? "password" : "text"}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e);
        }}
        id="small-firm"
        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 "
      />
    </div>
  );
};

export default EditComponent;

import React from "react";

const WhatIsHappening = () => {
  const subsidySchemes = [
    "Interest Subvention (3%) Subsidy Scheme of Central Government - September 25, 2022",
    "Central Govt. Scheme for “Processed Food Industry” - June 11, 2018",
    "Tax Rebate for Tourism Sector in Rajasthan - May 26, 2018",
    "Capital Investment Subsidy on Textile Sector - May 10, 2018",
    "Interest Subvention on Agro-Processing Sector - May 8, 2018",
  ];

  return (
    <section
      id="aboutus"
      className="border-t-solid border-t-black border-b-2 border-b-black border-b-solid border-t-2 border-solid border-black flex md:flex-row flex-col items-start justify-center"
    >
      <div className="w-[95%] md:w-[60%] lg:w-[75%] mx-auto md:p-6 p-3">
        <div className="flex lg:flex-row flex-col gap-2">
          <div className="flex flex-col items-start justify-center gap-2 mx-auto">
            <img
              alt="motivational"
              className="max-w-[250px]"
              src="/quote.png"
            />
          </div>
          <div>
            <h1 className="font-bold text-[18px] my-4">About Us</h1>
            <hr className="bg-black w-full" />
            <p>
              At subsidyscheme, we are dedicated to being your reliable source
              for a wide range of financial services. Our expertise spans
              finance, taxes, business growth, and more. With a deep
              understanding of the complexities in the financial landscape, we
              are here to simplify these intricacies for individuals and
              businesses alike. Our mission is to provide tailored solutions
              that empower you to navigate the world of money with confidence
              and ease.
            </p>
          </div>
        </div>
        <div>
          <h1 className="font-bold text-[18px] my-4">Vision & Mission</h1>
          <hr className="bg-black w-full" />
          <p>
            Our vision is clear—to be the driving force behind financial
            empowerment and growth for all. We envision a future where financial
            complexities are demystified, opportunities are maximized, and
            dreams find fertile ground to flourish. Our mission aligns with this
            vision. We are committed to delivering unparalleled consultancy,
            strategic planning, and pragmatic advice. By doing so, we strive to
            become an integral part of our clients' financial accomplishments.
            Our journey is guided by unwavering principles of integrity,
            transparency, and professionalism, aiming to be a catalyst in your
            pursuit of financial success.
          </p>
        </div>
      </div>

      <div className="w-[95%] borderl-2 border-s  md:py-6 py-3 border-l-black md:m-0 my-12 mx-auto md:w-[35%] lg:w-[20%]">
        <h1 className="font-bold text-[20px] px-2">Recent Posts</h1>
        <div>
          {subsidySchemes.map((itm, ind) => (
            <div
              className="px-2 border-b-[1px] py-4 border-b-s border-b-black"
              key={ind}
            >
              {itm}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatIsHappening;

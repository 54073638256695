import BannerCarousel from "../components/bannerCarousel";
import WhatIsHappening from "../components/whatIsHappening";
import Activity from "../components/activity";
import OtherCarousel from "../components/otherCarousel";
import Testimonial from "../components/testimonial";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";

const Home = ({ showMobileMenu }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main
      id="background"
      className={`w-full bg-transparent ${
        showMobileMenu ? "hidden" : "min-h-screen"
      }`}
    >
      <div className="relative flex lg:mt-0 mt-20 flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src="https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
              Government Schemes
            </p>
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Empowering Businesses
              <br className="hidden md:block" />
              with Expert Financial Guidance
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
              Welcome to our platform dedicated to businesses. We specialize in
              providing comprehensive financial solutions tailored to government
              schemes.
            </p>
          </div>
        </div>
      </div>
      <Activity />
      <BannerCarousel />
      <WhatIsHappening />
      <section className="border-b-2 border-b-black border-b-solid ">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">
            why <br /> work with us?
          </h1>

          <div className="mt-2">
            <span className="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
            <span className="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
            <span className="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
          </div>

          <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
            <div className="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
              <div className="space-y-3">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">
                  Expert Financial Advisory
                </h1>

                <p className="text-gray-500 ">
                  Our team of experienced Chartered Accountants provides expert
                  financial advisory services. We assist businesses in
                  understanding intricate government schemes, ensuring
                  compliance, and optimizing financial strategies for long-term
                  success.
                </p>
              </div>

              <div className="space-y-3">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
                    />
                  </svg>
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize ">
                  Customized Solutions
                </h1>

                <p className="text-gray-500">
                  We analyze your business requirements and customize government
                  scheme solutions to meet your specific needs. From tax
                  incentives to funding programs, our tailored approach ensures
                  you make the most of available opportunities, maximizing your
                  benefits.
                </p>
              </div>

              <div className="space-y-3">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                    />
                  </svg>
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize">
                  Hassle-Free Compliance
                </h1>

                <p className="text-gray-500 ">
                  Navigating through government regulations and compliance
                  requirements can be daunting. We offer seamless assistance,
                  helping businesses adhere to legal frameworks effortlessly.
                  Stay up-to-date with changing regulations, leaving compliance
                  worries to us.
                </p>
              </div>

              <div className="space-y-3">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                    />
                  </svg>
                </span>

                <h1 className="text-xl font-semibold text-gray-700 capitalize ">
                  Strategic Planning
                </h1>

                <p className="text-gray-500 ">
                  Effective financial planning is at the core of every
                  successful business. Our team collaborates with you to develop
                  strategic financial plans aligned with government schemes.
                  From budgeting to investment strategies, we empower your
                  business to thrive in the competitive landscape.
                </p>
              </div>
            </div>

            <div className="hidden lg:flex lg:w-1/2 lg:justify-center">
              <img
                className="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem] rounded-full"
                src="https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
      <OtherCarousel title="startup" />
      <OtherCarousel title="consultancy" />
      <OtherCarousel title="banking" />
      <div data-dial-init className="fixed right-6 bottom-6 group">
        <div
          id="speed-dial-menu-default"
          className="group-hover:flex hidden flex-col items-center mb-4 space-y-2"
        ></div>
        <a
          rel="noreferrer"
          href="http://wa.me/9269148541?text=Hello%20Sir"
          target="_blank"
          className="flex items-center justify-center gap-2"
        >
          <p className="bg-gray-400 text-[12px] p-2 rounded-md">
            Need help? chat with us
          </p>
          <button
            type="button"
            data-dial-toggle="speed-dial-menu-default"
            aria-controls="speed-dial-menu-default"
            aria-expanded="false"
            className="flex items-center justify-center text-white bg-[#128C7E] rounded-full w-14 h-14 focus:ring-4 focus:ring-blue-300 focus:outline-none"
          >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            <span className="sr-only">Open actions menu</span>
          </button>
        </a>
      </div>
    </main>
  );
};

export default Home;

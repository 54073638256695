export const documentsFormsDatabase = {
  "PAN Card":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2Fpan_form.pdf?alt=media&token=c7c17435-6627-4c7e-855e-40ec370079dd",
  "PAN Correction Form":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2Fpan_correction.pdf?alt=media&token=182c26f8-1390-48c0-ad9c-e9e7fc941455",
  "Partnership Deed":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2Fpartnership-deed-format.doc?alt=media&token=ccf98c7f-033b-43fc-83c3-c818a0c7cc5f",
  "Rent Agreement":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2FRent-Agreement-Format-MakaanIQ.doc?alt=media&token=d2b0de5c-6e68-4ae4-a0d9-71f1e81c3b57",
  "GST Return Form":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2FGST%20RETURN%20123.xlsx?alt=media&token=d3444b08-7e62-428f-a940-a527e8161c90",
  "Power Of Attorney":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2FGeneral%20Power%20Of%20Attorney.docx?alt=media&token=f252b4e9-9c12-419a-a70b-e0a64a85896d",
};
export const headingImages = {
  Propriotiership:
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fpropriotiership.jpeg?alt=media&token=beeb19bd-ed7d-4194-800b-6a447a0643b7",
  Partnership:
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fpartnership.png?alt=media&token=0dfe27ea-af2d-48fa-8948-f37cff4ab408",
  "Limited Liability Parternship":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fllp.jpg?alt=media&token=aaca91da-b1c3-4f37-ac15-7c8bc1b4a163",
  "Company Incorporation":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fincorporation.jpeg?alt=media&token=ab95adc4-64aa-4fe7-9fd9-d60a801cc49b",
  "One Person Company":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fopc.jpg?alt=media&token=c164516c-b8ee-4002-b7c7-d25129e17d0b",
  "Startup India Registration":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fstartup.jpg?alt=media&token=62e22acd-28c5-45ff-b0f7-5b721123c51a",
  "Other Startup Services":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fstartup.jpeg?alt=media&token=17f6219d-7653-43ec-a578-aa573e70e8a1",
  "Opening Current Ac":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fbanking.jpeg?alt=media&token=5bec2e96-6073-4f14-a87d-28a58fc946ab",
  "Income Tax":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Ftaxation.jpeg?alt=media&token=a8933ffc-2aa9-47a6-846f-0720a86c3587",
  GST: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fgst.jpeg?alt=media&token=56921afe-bbad-4d3b-9161-f0cdebc7d81f",
  Trademark:
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Ftrademark.png?alt=media&token=979c7c7e-4f46-4336-b55c-7cc9e9899acf",
  ISO: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fiso.png?alt=media&token=de42f20a-3c24-41cf-995b-c271c9c501cb",
  Design:
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fdesign.png?alt=media&token=ac4ad72d-cbe5-49ad-96db-702b045e62a8",
  "Other IPR Services":
    "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/headingImages%2Fipr.jpeg?alt=media&token=1108c5fb-7f2d-4f09-bb47-d27d87a44af5",
};

export const subsidySchemeData = {
  "Agri Infra Fund": {
    videoId: "Q3SO8R1aNo8",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg1.jpg?alt=media&token=9614d8b0-e866-440f-99cf-01c3f7f12b68",
    title: "National Agriculture Infra Financing Facility",
    feature:
      "Interest Subvention of 3%; No CGTMSE Fee upto 2 Cr.; No Collateral Required upto 2 Cr.",
    beneficiaryProjects: [
      "Organic inputs production",
      "Bio stimulant production units",
      "Nursery",
      "Tissue culture",
      "Seed Processing",
      "Custom Hiring Center",
      "Infrastructure for smart and precision agriculture",
      "Farm/Harvest Automation",
      "Purchase of drones, putting up specialized sensors on field, Blockchain and AI in agriculture etc.",
      "Remote sensing and Internet of Things (IOT) such as automatic weather station, Farm advisory services through GIS applications.",
      "Logistics facilities - Reefer Van& Insulated vehicles",
      "Assaying Units",
      "Supply chain services including e-marketing platforms",
      "Warehouse & Silos",
      "Cold Stores and Cold Chain",
      "Packaging Units",
      "Primary Processing activities",
    ],
    whoCanApply: [
      "Agricultural Produce Market Committee",
      "Agri-Entrepreneur",
      "Central sponsored Public-Private Partnership Project",
      "Farmer",
      "Farmer Producers Organization",
      "Federation of Farmer Produce Organisations",
      "Joint Liability Groups",
      "Local Body sponsored Public-Private Partnership Project",
      "Marketing Cooperative Society",
      "Multipurpose Cooperative Society",
      "National Federations of Cooperatives",
      "Primary Agricultural Credit Society",
      "Self Help Group",
      "Federations of Self Help Groups",
      "Start-Up",
      "State Agencies",
      "State Federations of Cooperatives",
      "State sponsored Public-Private Partnership Project",
    ],
    objectives: [
      {
        title:
          "A. Farmers (including FPOs, PACS, Marketing Cooperative Societies, Multipurpose cooperative societies)",
        para: [
          "Improved marketing infrastructure to allow farmers to sell directly to a larger base of consumers and hence, increase value realization for the farmers. This will improve the overall income of farmers.",
          "With investments in logistics infrastructure, farmers will be able to sell in the market with reduced post-harvest losses and a smaller number of intermediaries. This further will make farmers independent and improve access to the market.",
          "With modern packaging and cold storage system access, farmers will be able to further decide when to sell in the market and improve realization.",
          "Community farming assets for improved productivity and optimization of inputs will result in substantial savings to farmers.",
        ],
      },
      {
        title: "B. Government",
        para: [
          "Government will be able to direct priority sector lending in the currently unviable projects by supporting through interest subvention, incentive, and credit guarantee. This will initiate the cycle of innovation and private sector investment in agriculture.",
          "Due to improvements in post-harvest infrastructure, the government will further be able to reduce the national food wastage percentage, thereby enabling the agriculture sector to become competitive with current global levels.",
          "Central/State Government Agencies or local bodies will be able to structure viable PPP projects for attracting investment in agriculture infrastructure.",
        ],
      },
      {
        title: "C. Agri entrepreneurs and startups",
        para: [
          "With a dedicated source of funding, entrepreneurs will push for innovation in the agriculture sector by leveraging new-age technologies including IoT, AI.",
          "It will also connect the players in the ecosystem and hence, improve avenues for collaboration between entrepreneurs and farmers.",
        ],
      },
      {
        title: "D. Banking ecosystem",
        para: [
          "With Credit Guarantee, incentive, and interest subvention lending institutions will be able to lend with lower risk. This scheme will help enlarge their customer base and diversification of the portfolio.",
          "Refinance facility will enable a larger role for cooperative banks and RRBs.",
        ],
      },
      {
        title: "E. Consumers",
        para: [
          "With reduced inefficiencies in the post-harvest ecosystem, the key benefit for consumers will be a larger share of produce reaching the market and hence, better quality and prices. Overall, the investment via the financing facility in agriculture infrastructure will benefit all the eco-system players.",
        ],
      },
    ],
  },

  PMEGP: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg3.jpg?alt=media&token=06112a20-bf6a-403d-a3dd-e81cc1456e9e",
    videoId: "ERGSrzbQZhg",
    title: "Prime Minister Employment Generation Programme",
    feature:
      "Captial Subsidy upto 35%; Interest Subvention upto 3%; Project cost upto 50 Lakhs",
    objectives: [
      {
        title: "Sector-specific Financial Assistance",
        para: [
          "Manufacturing sectors can get a project cost of a maximum of ₹25 lakh.",
          "Business/service sectors can get a project cost of a maximum of ₹10 lakh.",
        ],
      },
      {
        title: "Area-specific Financial Assistance",
        para: [
          "Individuals belonging to the general category residing in an urban area can get 15% of the total project cost.",
          "Individuals belonging to the general caste residing in a rural area can receive 25% of the total project cost.",
          "Individuals belonging to special categories (OBC/Minorities/Women, Physically handicapped, Ex-servicemen, NER, or residing in Hill and Border areas, etc.) can receive 25% (urban) and 35% (rural).",
        ],
      },
    ],
    beneficiaryProjects: [
      "Offer sustainable and continuous self-employment opportunities in both rural and urban areas of the country.",
      "Encourage financial institutions to increase credit flow to the micro sector.",
      "Provide employment opportunities to traditional artisans and unemployed youth to reduce migration rates from rural to urban areas.",
    ],
    whoCanApply: [
      "Any individual, above 18 years of age",
      "There will be no income ceiling for assistance for setting up projects under PMEGP.",
      "For setting up a project costing above Rs.10 lakh in the Manufacturing sector and above Rs. 5 lakhs in the Service/ Business sector, the beneficiaries should possess at least VIII standard pass educational qualification.",
      "Assistance under the scheme is available only for new viable projects sanctioned specifically under the PMEGP.",
      "Existing Units and the units that have already availed any Government Subsidy (under PMRY, REGP, PMEGP, CMEGP, or any other scheme of Government of India or State Government) are not eligible.",
      "Projects without Capital Expenditure (Term Loan) are not eligible.",
      "Cost of Land cannot be covered under the project cost.",
      "All Implementing Agencies (KVIC, KVIB, DIC and Coir Board) can process applications in both rural as well as urban areas.",
      "Applicant should possess a valid Aadhaar Number.",
      "Applicant shall give his/her consent to authenticate demographic details such as Aadhaar number, Name, Gender, Date of Birth, and Mobile number from UIDAI server.",
    ],
  },
  PMFME: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg4.jpg?alt=media&token=9c15ac01-6938-4e46-8c87-9675e0740930",
    videoId: "C18_Asg7QX4",
    title:
      "Pradhan Mantri Formalisation of Micro Food Processing Enterprises Scheme",
    feature:
      "Interest Subvention 3%; Subsidy 35% upto 10 Lakhs on Plants & Machinery; Project Cost Rs. 30 Lakh",
    objectives: [
      {
        title: "One District-One Product (ODOP) Approach",
        para: [
          "The PMFME Scheme has adopted the ODOP approach from the ODOP programme launched by the Uttar Pradesh (UP) government in 2018.",
          "Under this approach, the state identifies and selects a food product for the district, which could be a perishable agricultural crop or a food product largely produced in the district.",
          "Products covered by the ODOP include tomato, mango, potato, litchi, millet-based goods, fishery, poultry, meat, animal feeds, honey, minor forest products, and herbal items like turmeric.",
          "The PMFME scheme provides assistance in the form of new micro food processing units and marketing & branding support to micro food processing enterprises following the ODOP approach.",
        ],
      },
      {
        title: "Components of the PMFME Scheme",
        para: [
          "The scheme has four primary components:",
          "1. Support for food processing units: Unorganised micro food processing units are eligible for a credit-linked capital grant of 35% of the project cost (up to Rs. 10 lakhs per unit) for upgradation. Members also receive a seed funding of Rs. 40,000 for working capital and purchase of small tools.",
          "2. Marketing and branding support: Provided to FPOs, co-operatives, SHGs, and regional or state-level Special Purpose Vehicles (SPVs) of micro food processing enterprises. It covers areas like packaging & branding, quality control, standardization, and food safety adherence for consumer retail sales.",
          "3. Support for common infrastructure development: Enterprises receive support to build common infrastructure, including incubation centers, processing facilities, warehouses, laboratories, and cold storages. Credit-linked grants of up to 35% of the total project cost are available.",
          "4. Capacity building and research: Training is a key component for transforming unorganized micro food processing units into organized units. Individuals and institutions receiving grants attend training to improve their skills, and other ODOP producers and units also receive training.",
        ],
      },
    ],
    beneficiaryProjects: [
      "Individual micro food processing units looking to upgrade can receive a credit-linked capital subsidy @35% of the project cost with a maximum cap of INR 10 lakh per unit. The beneficiary contribution should be a minimum of 10% and the remaining should be a bank loan.",
    ],
    whoCanApply: [
      "Any existing micro food processing enterprises",
      "Farmer producer organizations(FPO)",
      "Self Help Groups(SHGs)",
      "Producers Cooperatives",
    ],
  },
  NABARD: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg7.jpg?alt=media&token=d88b8161-2895-44f7-8066-57ed79332660",
    videoId: "0vw6v_102sU",
    title: "National Bank for Agriculture and RuralDevelopment",
    feature:
      "Capital Subsidy 33.33% upto 50 Lakhs; Interest Subvention 3%; Project Cost upto 1.5 Cr.",
    beneficiaryProjects: [
      "Rendering capital investment support in agriculture, fishery, poultry, horticulture, etc. activities",
      "Creating credit flow in the activities incorporated by NABARD and the government",
      "Identification and fulfillment of self-help groups (SHGs) and joint-liability groups (JLGs) credit requirements",
      "Promotion of non-agriculture employment opportunities by encouraging the semi-rural and rural people to explore and opt for alternate professions and job options",
      "Extending support and assistance for mitigation and climate adaptation projects",
      "Refinancing the credit linked with subsidy on capital investments by the Indian government under the authority of the NABARD subsidy",
    ],
    whoCanApply: [
      "Farmers",
      "Individual Entrepreneurs",
      "NGOs",
      "Companies",
      "Groups of unorganized and organized sector etc.",
      "Groups of the organized sector include Self Help Groups, Dairy Cooperative Societies, Milk Unions, Milk Federations, etc.",
    ],
    objectives: [
      {
        title: "Short Term Loans",
        para: [
          "These are crop-oriented NABARD loans offered by various financial institutions to farmers to refinance crop production.",
          "This loan offers farmers and their surrounding rural communities the assurance of food security.",
          "When Agro-operations are seasonal, as of FY17–18, the NABARD scheme has sanctioned ₹55,000 Crores to a host of financial institutions as the short-term credit loan amount.",
        ],
      },
      {
        title: "Long Term Loans",
        para: [
          "These loans are offered by multiple financial institutions for either farm or non-farm activities.",
          "Their tenure is much longer than short term loans and ranges from 18 months to a maximum of 5 years.",
          "As of FY17–18, NABARD refinanced close to ₹65,240 Crores to financial institutions, covering any concessional refinancing of ₹15,000 Crores to Indian Regional Rural Banks (RRBs) and Cooperative Banks.",
        ],
      },
      {
        title: "RIDF or ‘Rural Infrastructure Development Fund.’",
        para: [
          "RBI introduced the RIDF as part of the NABARD scheme as they noticed a shortfall in lending to priority sectors that need support for their rural development.",
          "With the main focus being rural infrastructure development, a total loan amount of ₹24,993 Crores was disbursed in FY17–18.",
        ],
      },
      {
        title: "LTIF or ‘Long-Term Irrigation Fund.’",
        para: [
          "This was introduced as part of the NABARD loans to provide funding for a total of 99 irrigation projects with the disbursal of a loan amount of Rs. 20,000 Crores.",
        ],
      },
      {
        title: "PMAY-G or ‘Pradhan Mantri Awaas Yojana - Gramin’",
        para: [
          "Under this financial scheme, NRIDA or the ‘National Rural Infrastructure Development Agency’ was given a loan amount of ₹9000 Crores to carry out its project of building pukka houses with all essential amenities to needy households 2022.",
        ],
      },
      {
        title: "NIDA or ‘NABARD Infrastructure Development Assistance.’",
        para: [
          "NIDA is a sub-program under the NABARD scheme, and it specializes in providing credit to any financially well-to-do institutions or corporations that are state-owned.",
          "Hence, NABARD also refinances non-private schemes with the help of this program.",
        ],
      },
      {
        title: "Warehouse Infrastructure Fund",
        para: [
          "Warehouse Infrastructure Fund provides scientific warehousing infrastructure for agricultural commodities.",
          "Initial loan of the amount Rs. 5000 was provided by NABARD in FY 2013–14.",
          "As of 31st March 2018, the amount disbursed is Rs. 4778 crores.",
        ],
      },
      {
        title: "Food Processing Fund",
        para: [
          "Under the food processing fund of NABARD, the Indian government has a loan commitment of Rs. 541 Crores to be disbursed to 11 large-scale food park projects, 1 integrated food park project, and 3 rural food processing units in India.",
        ],
      },
      {
        title: "Direct Lending",
        para: [
          "The NABARD scheme has specially sanctioned a loan amount of ₹4849 Crores for cooperative banks, which will assist four state-owned cooperative banks and 58 Co-operative Commercial Banks (CCBs) spread across the country.",
        ],
      },
      {
        title: "CFF or ‘Credit Facility to Marketing Federations’",
        para: [
          "This category of NABARD loans promotes the marketing of farm activities by financially strengthening marketing federations.",
          "The amount disbursed to such federations as of 2018 was Rs. 25,436 Crores in total.",
        ],
      },
      {
        title: "PACS or ‘Primary Agriculture Credit Societies’",
        para: [
          "NABARD has also launched a unique ‘Producer Organizations Development Fund’ or PODF for short.",
          "The goal is to provide financial support to PACS that mainly operate as ‘Multi Service’",
        ],
      },
    ],
  },

  "Standup India": {
    videoId: "",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg8.jpg?alt=media&token=3e0d0002-4132-4b7d-a223-6fffb959a813",
    title: "Standup India",
    feature: "Bank Finance Facility between Rs. 10 Lakh to Rs. 1 Cr.",
    whoCanApply: ["SC/ST and/or woman entrepreneurs, above 18 years of age"],
    beneficiaryProjects: [
      "The barest minimum low-interest rate that the bank can offer.",
      "Reimbursement for amounts up to 3/4th of the total project costs.",
      "Long loan repayment period with especially long moratorium period.",
      "Minimal and very basic security.",
    ],
    objectives: [
      {
        title: "1. What is the objective of “Stand-Up India” Scheme?",
        para: [
          "The objective of the Stand-Up India scheme is to facilitate bank loans between 10 lakh and 1 Crore to at least one Scheduled Caste (SC) or Scheduled Tribe (ST) borrower and at least one woman borrower per bank branch for setting up a greenfield enterprise.",
          "This enterprise may be in manufacturing, services or the trading sector. In case of nonindividual enterprises at least 51% of the shareholding and controlling stake should be held by either an SC/ST or Woman entrepreneur.",
        ],
      },
      {
        title:
          "2. What is the purpose of the loan under “Stand-Up India” Scheme?",
        para: [
          "The scheme is for setting up a new enterprise in manufacturing, trading or services sector by SC/ST/Women entrepreneur.",
        ],
      },
      {
        title: "3. What are the schemes for loan requirement upto 10 lakh?",
        para: [
          "For loans below 10 lakh, banks are already lending under their existing schemes. Further, MUDRA Ltd. also operates 3 schemes namely Shishu/Kishore/Tarun through banks for loans upto 10 lakh.",
          "Visit www.mudra.org.in for further details.",
        ],
      },
      {
        title:
          "4. Who are the target clients under Stand-Up India Scheme / what kind of borrowers are eligible for loans?",
        para: [
          "SC/ST and/or Women entrepreneurs setting up new enterprises are eligible for availing loans under Stand-Up India Scheme. Typically projects in the manufacturing, trading and service sector would be eligible for coverage under the scheme.",
        ],
      },
      {
        title:
          "5. What will be the nature of loan under the Stand-Up India Scheme?",
        para: [
          "Composite loan (inclusive of term loan and working capital) between 10 lakh and upto 100 lakh representing upto 75% of the project cost would be eligible.",
        ],
      },
      {
        title:
          "6. What is the size of the loan under the Stand-Up India Scheme?",
        para: [
          "Composite loan (inclusive of term loan and working capital) between 10 lakh and upto 100 lakh would be eligible.",
        ],
      },
      {
        title:
          "7. What is the rate of interest charged under the Stand-Up India Scheme?",
        para: [
          "The rate of interest would be lowest applicable rate of the bank for that category (rating category) not to exceed (base rate (MCLR) + 3%+ tenor premium).",
        ],
      },
      {
        title:
          "8. What will be the security requirement under the Stand-Up India Scheme?",
        para: [
          "In addition to mortgage/hypothecation of Primary Asset acquired out of loan, the loan may also be secured by collateral security or guarantee of Credit Guarantee Scheme for Stand-Up India Loans (CGSSI) as decided by the banks.",
        ],
      },
      {
        title:
          "9. Who are the eligible lending institutions for extending loans under the Scheme?",
        para: [
          "All branches of Scheduled Commercial Banks located across the country.",
        ],
      },
      {
        title: "10. What is the repayment tenure under the Scheme?",
        para: [
          "The repayment period of the composite loan is to be fixed depending upon the nature of activity and useful life of assets purchased with bank loan but not to exceed 7 years with a maximum moratorium period of 18 months.",
        ],
      },
      {
        title:
          "11. What are the Salient Features of the Credit Guarantee Fund Scheme for Stand-Up India Loans (CGFSIL)?",
        para: [
          "Eligibility – Stand-Up India Loans of over 10 lakh and upto 100 lakh extended by eligible Lending Institution(s) to an eligible borrower on or after entering into an agreement with National Credit Guarantee Trustee Company (NCGTC).",
          "Guarantee Fee – Annual Guarantee Fee (AGF) of 0.85% p.a. on the credit facility sanctioned (comprising term loan and / or working capital facility).",
          "Guarantee Fee shall be paid upfront to the Trust by the eligible institution availing of the guarantee and to be shared equally between bank and the borrower.",
        ],
      },
      {
        title:
          "12. What is the Difference between Stand-Up India Scheme and SMILE Scheme?",
        para: [
          "SMILE Scheme is operated only through SIDBI for investment in projects coming up in 25 identified sectors under the Make in India programme for existing and new units. The support is in the nature of quasi equity and term loan on relatively soft terms, with the minimum term loan size for new units at 25 lakh.",
          "Stand-Up India scheme is proposed to be operated through 1.25 lakh bank branches in the country.",
          "The loans would be above 10 lakh and upto 100 lakh specifically for SC/ST/Women entrepreneurs setting up green field projects.",
        ],
      },
    ],
  },
  CGTMSE: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg12.jpg?alt=media&token=99f2dffe-f083-4a76-bb43-ff991b5fc45b",
    videoId: "u-F9J8tkyVY",
    title: "Credit Guarantee fund Trust for Micro and Small Enterprises",
    feature:
      "Collateral Free Loan upto Rs. 5 Cr.; No Fees under AIF Scheme upto Rs. 2 Cr.",
    beneficiaryProjects: [
      "The CGTMSE scheme not only grants credit up to ₹1 crore, but it does so without asking for a third-party guarantee or collateral security. Furthermore, the CGTMSE scheme provides a credit guarantee, liberating entrepreneurs from the burden of repaying the loan, if by any chance their business fails.",
    ],
    whoCanApply: [
      "Individuals",
      "Proprietorship",
      "LLP",
      "Partnership",
      "Private Limited Company or registered company",
    ],
    objectives: [
      {
        title:
          "What is Distressed Asset Fund - Subordinated Debt for Stressed MSMEs?",
        para: [
          "The “Distressed Asset Fund - Subordinated Debt for Stressed MSMEs” is a scheme framed by Ministry of MSME under which credit facility would be provided to the Promoters of the stressed MSMEs to all the member lending Instutution(MLIs) registred under CGS-I of the Credit Gurantee Scheme for Micro and Small Enterprises.",
        ],
      },
      {
        title:
          "What is the Credit Guarantee Scheme for Subordinate Debt (CGSSD)?",
        para: [
          "Credit Guarantee Scheme for Subordinate Debt (CGSSD) (hereinafter referred to as “Scheme”) being operated by Credit Guarantee Fund Trust for Micro and Small Enterprises (CGTMSE) provides guarantee coverage to Member Lending Institutions (MLIs) for the credit facility extended to the eligible MSMEs under the above mentioned scheme.",
        ],
      },
      {
        title: "What is the objective of the Scheme (CGSSD)?",
        para: [
          "The objective of the scheme is to provide credit facility through lending institutions to the promoters of stressed MSMEs viz. SMA-2 and NPA accounts who are eligible for restructuring as per RBI guidelines on the books of the Lending institutions. The promoter would infuse the credit in the MSME as quasi equity or sub-debt.",
        ],
      },
      {
        title: "How do we define MSME?",
        para: [
          "Micro, Small and Medium Enterprises defined under the MSMED Act from time to time.",
        ],
      },
      {
        title: "Which are the MSME’s constitution included under the Scheme?",
        para: [
          "MSME units such as Individuals / Proprietorship, LLP, Partnership, Private Limited Company or registered company are eligible to be covered under the scheme.",
        ],
      },
      {
        title:
          "Who are the eligible Member Lending Institutions (MLIs) under the Scheme?",
        para: [
          "All the Member Lending Institutions (MLIs) registered under CGS-I of the Credit Guarantee Scheme for Micro and Small Enterprises.",
        ],
      },
      {
        title: "What is the maximum sub-debt amount eligible under the Scheme?",
        para: [
          "Under the Scheme, Promoter(s) of the MSME unit will be given credit facility equal to 50% of his/her stake in the MSME entity (equity plus debt) or Rs 75 lakh whichever is lower as per last audited Balance Sheet.",
          "Illustration 1: If promoters have invested Rs.100 lakh as equity/debt in a company, then 50% of Rs.100 lakh, i.e. Rs.50lakh is eligible amount under subdebt.",
          "Illustration 2: If promoters have invested Rs.700 lakh as equity/debt in a company, then 50% of Rs.700 lakh is Rs.350 lakh. However, eligible amount under sub-debt would be Rs.75 lakh.",
        ],
      },
    ],
  },
  MSME: {
    videoId: "lVWSBjDl4zY",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg18.jpg?alt=media&token=f2afb910-579e-406a-8016-aacb6d1ad56b",
    title: "Ministry of Micro, Small & Medium Enterprises",
    beneficiaryProjects: [],
    whoCanApply: [
      "Proprietorship",
      "Partnership Firm",
      " company, trust or society with an investment below Rs.50 crore and annual turnover below Rs.250 crore",
    ],
    objectives: [],
    feature:
      "Collateral Free Credit upto Rs. 2 Cr.; Rebate in Interest Rate upto 1.5%",
  },
  "Biomas (Non-bagasse) cogeneration Project under MNRE": {
    videoId: "ZKUzm5vh3qc",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fbiomass.webp?alt=media&token=c7e4aec5-4abc-4113-83ff-8977b5dc201b",
    title: "Biomas (Non-bagasse) cogeneration Project under MNRE",
    feature: "Rs. 40 Lakh per MW; Maximum CFA of Rs. 5 Cr. per project",
    beneficiaryProjects: [],
    whoCanApply: [],
    objectives: [],
  },
  "Briquette Pellet Manufacturing Plant Under MNRE": {
    videoId: "9G5obSwrH3Q",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fbriqutte.jpg?alt=media&token=a45b0498-1d76-4d89-b73f-a3e14527d83e",
    feature:
      "Rs. 9 Lakhs. per MT per hour Manufacturing Capicity; Maximum CFA Rs. 45 Lakhs. per plant",
    title: "Briquette Pellet Manufacturing plant Under MNRE",
    beneficiaryProjects: [
      "Businesses can receive up to 15% subsidy on their investment in specific machinery under this scheme. It must be noted that the subsidy comes with an upper limit of Rs.1 crore.",
      "This subsidy is available to those enterprises which have put their money in machinery by availing a term loan from the approved list of financial institutions.",
      "Industries that are transitioning from small to medium scale by availing a loan under CLCSS are also entitled to benefit from this subsidy scheme.",
      "According to the revised CLSS scheme, an extra 10% subsidy is extended to entrepreneurs who belong to the SC/ST category and hail from the selected districts of the North-East or other hilly terrains.",
    ],
    whoCanApply: [
      "Small and micro-enterprises that belong to sub-sectors specified by the Ministry of MSME will be deemed eligible for this subsidy scheme.",
      "Both new and existing enterprises can avail subsidy under this scheme.",
      "Small and micro-enterprises based in semi-urban and rural areas with a valid UAM number are also eligible.",
      "Typically, the enterprises include – sole-proprietorship, private limited companies, tiny industries, khadi, coir units, partnerships, village industries, etc.",
    ],
    objectives: [],
  },
  RSAMB: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg10.jpg?alt=media&token=db32dcc6-ecc2-4496-93f4-b6e00625e55f",
    videoId: "FymBzoMq_Uk",
    title: "Rajasthan State Agricultural Marketing Board",
    feature:
      "Capital Subsidy upto 1.5 Cr. (50%-75%); Interest Subvention 5%-6%, Project Cost upto 3 Cr.",
    whoCanApply: [],
    beneficiaryProjects: [],
    objectives: [],
  },

  RIPS: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg6.jpg?alt=media&token=f1a23183-312b-47d8-8f88-c81141ebd001",
    videoId: "LURkf7y4sXs",
    title: "Rajasthan Investment Promotion Scheme",
    feature:
      "Interest Subvention upto 6%; SGST Subvention upto 75%; 100% Rebate of Electricity Duty, Land Tax, Mandi Tax, Land Conversion",
    objectives: [
      {
        title:
          "Which Scheme or Policy is available for investment promotion in Rajasthan?",
        para: ["Rajasthan Investment Promotion Scheme-2014."],
      },
      {
        title:
          "Which is the Nodal Agency for sanctioning benefit on the investment made in the State under RIPS-2014?",
        para: [
          "For investment upto Rs.10 crore: District Level Screening Committee of the concerned District.",
          "For investment above Rs.10 crore: State Level Screening Committee.",
        ],
      },
      {
        title: 'What is the meaning of "Investment"?',
        para: [
          '"Investment" means the investment made by the enterprise in fixed assets which constitutes the cost of land, cost of factory and building, cost of plant and machinery or equipment, other investment made in fixed assets which is essential for production and fee involving technical knowhow or drawing fee paid to service provider.',
        ],
      },
      {
        title: "Does this Scheme apply to new enterprises only?",
        para: [
          "The Scheme also applies to investment made for revival of sick enterprises and expansion by existing enterprises.",
        ],
      },
      {
        title: "How long shall this Scheme remain in force?",
        para: [
          "The operative period of the Scheme is from 8th October, 2014 to 31st March, 2019.",
        ],
      },
    ],
    whoCanApply: [
      "Enterprises made investment and generate employment in Rajasthan state",
    ],
    beneficiaryProjects: [
      "Investment Subsidy of 75% of State tax due and deposited, for seven years",
      "Employment Generation Subsidy in the form of reimbursement of 50% of employers' contribution towards employees' EPF and ESI, for seven years",
      "Exemption from payment of 100% of Electricity Duty for seven years",
      "Exemption from payment of 100% of Land Tax for seven years",
      "Exemption from payment of 100% of Market Fee (Mandi Fee) for seven years",
      "Exemption from payment of 75% of Stamp Duty",
      "Exemption from payment of 100% of conversion charges payable for change of land use and conversion of land",
    ],
  },
  MLUPY: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg2.jpg?alt=media&token=6c51ee98-d90a-4d91-97a3-b029b7ac5723",
    videoId: "fHd6HBz6278",
    title: "Mukhya Mantri Laghu Udyog Protsahan Yojana",
    feature:
      "Interest Subvention upto 8%; Project Cost for Traders upto 25 Lakhs; Project Cost for Manufacturer upto 10 Cr.",
    objectives: [
      {
        title: "Financial Help:",
        para: [
          "The MLUPY scheme offers financial aid to eligible small businesses to buy essential machinery,",
          "Equipment. Also, as well as the raw materials. These funds can help small businesses upgrade their facilities and improve their production processes, leading to increased competitiveness in the market.",
        ],
      },
      {
        title: "Technical Support:",
        para: [
          "The scheme provides technical support to small businesses in the form of training programs and workshops. These programs aim to enhance the skills and knowledge of small business owners and their employees. it also enables them to improve their operations and production processes. This support helps small businesses stay ahead in the market and remain competitive.",
        ],
      },
      {
        title: "Tax Benefits:",
        para: [
          "Under the MLUPY, eligible small businesses can avail of tax benefits such as reduced tax rates and exemptions from certain taxes. This can significantly lower the financial burden on small businesses and help them retain more of their profits for reinvestment.",
        ],
      },
      {
        title: "Easy Loan Access:",
        para: [
          "The MLUPY scheme makes it easier for small businesses to access loans at lower interest rates through banks and financial institutions. This can help small businesses overcome financial constraints. Also, it helps them to obtain the funding they need to grow and expand their operations.",
        ],
      },
      {
        title: "Increased Visibility:",
        para: [
          "The MLUPY helps small businesses increase their visibility in the market through promotional activities and exhibitions. This increased visibility can help small businesses reach new customers. it also helps them establish their brand as well as increase their sales. In turn, this can help small businesses grow and become more profitable.",
        ],
      },
    ],
    beneficiaryProjects: [
      "One of the primary goals of the MLUPY is to create employment opportunities and boost the state’s economy. By providing support to small businesses, the scheme aims to help them grow and expand their operations, thereby increasing the number of job opportunities in the state",
    ],
    whoCanApply: [
      "Company",
      "LLP",
      "Partnership Firm",
      "Self-help Group/ Society",
      "Individual",
    ],
  },
  MYUPY: {
    videoId: "fHd6HBz6278",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg5.jpg?alt=media&token=b5a26e98-d88d-4065-a835-df3e23f624a8",
    title: "Mukhyamantri Yuva Protsahan Yojna",
    feature:
      "Capital Subsidy upto 5Lac; Interest Subvention 6%-8%; Project cost upto 1Cr.",
    whoCanApply: [
      "Company",
      "LLP",
      "Partnership Firm",
      "Self-help Group/ Society",
      "Individual",
    ],
    beneficiaryProjects: [],
    objectives: [],
  },
  "Rajasthan Dalit Aadiwasi Udyam Protsahan Yojna": {
    title: "Rajasthan Dalit Aadiwasi Udyam Protsahan Yojna",
    videoId: "GHK9lThwCmw",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fambedkar.jpg?alt=media&token=38f1ab28-cbfc-4714-9c17-eaad719f2e29",
    feature:
      "Interest Subvention upto 6%-9%; Capital Subsidy 25% Max Rs. 25 Lakh; Project Cost upto Rs. 10 Cr.",
    whoCanApply: [],
    beneficiaryProjects: [],
    objectives: [],
  },
  "Minority Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg9.jpg?alt=media&token=06e82646-b4ab-4916-862d-449e99779a0e",
    videoId: "",
    title: "Minority Loan",
    feature: "",
    whoCanApply: [],
    beneficiaryProjects: [],
    objectives: [],
  },
  "Common Facility Centre": {
    videoId: "AptqttJYDEs",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg15.jpg?alt=media&token=56048ab4-dff7-4ae4-9b50-9ed85a6a7d40",
    feature: "",
    title: "Common Facility Centre",
    whoCanApply: [
      "It is necessary to form a Special Purpose Vehicle (SPV) with a minimum of 11 members required to apply for the CFC scheme.",
      "The Members of SPV should be Powerloom Weavers, Master Weavers, Co-operative Societies, Private Entrepreneurs, NGO’s working for Powerloom Sector.",
      "The SPV registered under Companies Act, Co-operative societies, a trust, Limited Liabilities Partnership (LLP) Act, State Government/State Government Agencies are eligible for establishment CFC under the Scheme.",
      "The SPV should possess their own Land/Building (or) Leased Land/Building registered in favour of SPV for a minimum period of 10 Years.",
    ],
    objectives: [
      {
        title: "WHAT IS A COMMON FACILITY CENTRE (CFC)?",
        para: [
          "A Common Facility Centre is a pool of high-end and capital-intensive, state-of-the-art machinery/equipment, offering various services at affordable rates, especially beneficial to the micro, small and medium enterprises (MSME) sector of the gem and jewellery industry.",
        ],
      },
      {
        title: "WHAT IS THE OBJECTIVE OF CFCS?",
        para: [
          "There has been much all-round development with regard to the manufacturing of diamonds and jewellery, especially on the technological front. However, machinery as well as inputs which go into a quality product are often out of reach for the entrepreneurs of the MSME segment, holding back their growth and performance.",
          "Small and medium diamond and jewellery manufacturing units located in small towns and interior villages are still operating with the old technology, as the cost to upgrade, acquire and install state-of-the-art technology/equipment is not affordable to them. This has resulted in low productivity and also affected the quality of the finished goods produced.",
          "Further, there also exists a need to organise skill upgradation training for artisans involved in diamond manufacturing activities to make them aware about the operation and benefits of using modern equipment.",
          "The establishment of CFCs in small towns and villages would greatly help to transfer the technological advancement benefit to these diamond and jewellery manufacturers. This would also provide an opportunity to re-skill them on the cutting-edge equipment widely used by the manufacturers globally.",
        ],
      },
    ],
    beneficiaryProjects: [
      "To support the growth of micro & small enterprises (MSMEs) through addressing their common issues such as improvement of technology, skills and quality, market access, etc.",
      "To promote setting up of Common Facility Centres for testing, training centre, raw material depot, etc. through formation of self-help groups, consortia, district industry associations.",
    ],
  },
  "Zero Defect and Zero Effect": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg17.jpg?alt=media&token=365a237b-dcab-420c-a930-92f0fefe0ac7",
    videoId: "-OUqIIQSuZI",
    title: "Zero Defect and Zero Effect",
    whoCanApply: [
      "All manufacturing sector MSMEs registered with the UDYAM registration portal (of the MoMSME)",
    ],
    objectives: [
      {
        title: "Q: How does the MSME scheme benefit the MSMEs?",
        para: [
          "Some of the ZED Scheme benefits to MSMEs are",
          "Higher revenues through improved processes: Adapting to the ZED process results in high-quality zero defect manufacture of products, services or processes, thereby reducing wastage, rejection, scrap, etc., and leading to higher profit revenues.",
          "Access to a reliable and credible vendor database: The ZED Scheme ensures that investors in companies with ZED ratings in India have access to a highly credible and ready-made database of reliable vendors to help procure their activities.",
          "Zero Effect on the environment: The ZED culture leads to environmental consciousness, greener solutions to pollution and waste disposal, and addresses the critical climate change issue.",
          "Rewards and Recognition: Consistent champions of the ZED culture and high performing ZED rated units are nationally rewarded and recognised as responsible manufacturers or service providers by QCI and the MSME Ministry.",
          "Adapting the best practices: MSMEs with a ZED rating use best practices and benchmark their systems.",
          "Global market competitive industries.",
          "Brand recognition and visibility",
        ],
      },
      {
        title:
          "Q: How much does the Defence Rating assessment cost and the offset under the MSME scheme?",
        para: [
          "There are 25 additional parameters under the Defence Rating scheme that ordinarily costs ₹40,000/- per enterprise. The MSME Ministry subsidises an average of 70% of the defence rating certification fee. It offsets 60% for Small enterprises, 80% for micro-enterprises, and 50% for Medium Enterprises in the defence rating certification fees.",
        ],
      },
      {
        title:
          "Q: To what extent are the certification fees offset under the MSME scheme?",
        para: [
          "The MSME Ministry subsidises an average of 70% of the certification fee and is 60% of Small enterprises, 80% of micro-enterprises, and 50% of Medium Enterprises certification fees. The actual assessment fee is ₹10,000/- for assessment by the desktop analysis method and ₹80,000/- per organisation for a complete assessment of ZED rating.",
        ],
      },
      {
        title:
          "Q: What activities can be undertaken under the ZED Certification scheme?",
        para: [
          "Some of the activities under this Certification Scheme are:",
          "Conduct workshops, industry-awareness programs, and training of implementing agencies officials.",
          "Benchmarking the international standards and adapting the ‘Best ZED Practices’.",
          "Training of Consultants, Assessors & Master Trainers.",
          "Conduct ZED International Trainings on QTT/QMS, production management, and delegate foreign travel to PAC/IAF.",
          "Onsite Capacity-Building Enterprise Training for I&K, NER, remote areas and industrially backward regions.",
          "Rating, assessment, re-rating and certification using the Desktop analysis method or the MSME Assistance programmes for Gap Analysis, Consultancy, and Handholding by the agencies responsible.",
          "Calibrating online MIS systems, e- awareness and training content and e-assessment platforms and much more.",
        ],
      },
      {
        title: "Q: What other schemes are there for the MSMEs?",
        para: [
          "Under the PMEGP or the PM’s Employment Generation Programme are several initiatives like the",
          "SFURTI or Scheme for Funds for Traditional Industries Regeneration",
          "The CHAMPIONS portal advertising",
          "ASPIRE or Scheme for Promotion of Innovation, Entrepreneurship and Rural Industry",
          "CGS or Credit Guarantee Scheme for Small and Micro Enterprise",
          "Scheme for Interest Subvention of MSMEs Incremental Credits.",
        ],
      },
      {
        title: "Q: What is meant by ZED culture or effect?",
        para: [
          "The ‘Make in India’ program’s slogan is “Zero Defect Zero Effect”, also known as ‘ZED Culture’. This concept was advocated by PM Shri Narendra Modi and signifies,",
          "Zero defects or high quality made in India products, processes and services",
          "The manufacture of these ZED culture products leads to zero detrimental effects on the environment and ecology.",
        ],
      },
    ],
    beneficiaryProjects: [
      "Zero defect zero effect manufacturing (ZED) can lead MSMEs to assure customers, reduce wastages substantially, increase productivity, expand their markets, apart from saving on energy",
    ],
    feature: "Assistance upto 50%; Maximum Rs. 1 Lakh",
  },
  "Amended Technology Upgradation Fund Scheme": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg14.jpg?alt=media&token=560c2f21-c4f0-4fc0-94fb-2a4d33077bf5",
    videoId: "f3avOvlxDJs",
    whoCanApply: [
      "Handloom sector",
      "Silk sector",
      "Jute sector",
      "Technical textiles",
      "Madeup/garment manufacturing",
      "Weaving preparatory and knitting",
    ],
    objectives: [
      {
        title: "1. What are the ATUFS benchmarked machineries?",
        para: [
          "TUFS benefit is available for TUFS benchmarked machinery covering the following activities:-",
          "a) Cotton ginning and pressing.",
          "b) Silk reeling and twisting.",
          "c) Wool scouring, combing and carpet industry.",
          "d) Synthetic filament yarn texturising, crimping and twisting.",
          "e) Spinning.",
          "f) Viscose Staple Fibre (VSF) and Viscose Filament Yarn (VFY).",
          "g) Weaving, knitting and fabric embroidery.",
          "h) Technical textiles including non-wovens.",
          "i) Garment/design studio/made-up manufacturing.",
          "j) Processing of fibres, yarns, fabrics, garments and made-ups.",
          "k) Production activities of Jute Industry.",
        ],
      },
      {
        title:
          "2. What are the subsidies under Amended Technology Upgradation Fund Scheme (ATUFS)?",
        para: [
          "Capital Investment Subsidy is available under ATUFS under this scheme for eligible segments @10%/15% with an upper limit on investment amount. For more information, click here.",
        ],
      },
      {
        title:
          "3. What is certificate of exemption and endorsement of GSP in Export Promotion & Quality Assurance?",
        para: [
          "An exemption certificate is issued to enable quota/duty free entry of the eligible items of Handloom origin at the importing end.",
          "GSP certificates (Form A) is issued for the eligible items for the following tariff preference giving (donor) countries: Australia, Canada, Japan, New Zealand, Norway, Switzerland, Turkey, United States of America (USA), Republic of Belarus, Russian Federation, and European Union.",
          "The European Union includes 28 countries Viz. Austria, Belgium, Czech Republic, Croatia, Cyprus, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands (Holland), Republic of Bulgaria, Romania, Poland, Portugal, Slovakia, Slovenia, Spain, Sweden and United Kingdom (UK).",
          "Note:",
          "1) For Australia, the main requirement is exporter’s declaration on the normal commercial invoice. Form A accompanied by the normal commercial invoice is an acceptable alternative, but official certification is not required.",
          "2) In case of Canada and New Zealand, Official Certification is not required.",
          "3) The United States does not require GSP Form A. A declaration setting forth all pertinent detailed information concerning the production or manufacture of the merchandise is considered sufficient only if requested by the district collector of the Customs.",
        ],
      },
      {
        title:
          "4. What are the government sponsored schemes in textile industry?",
        para: [
          "The Ministry of Textiles has rolled out various schemes like the Production Linked Incentive scheme, PM-MITRA scheme, ATUFFS scheme, National Technical Textiles Mission Scheme, SAMARTH Scheme, Silk Samagra Scheme, Integrated Wool Development Programme. The Ministry of Textiles through the Textile Committee provides information on the various schemes available for the textile sector. The schemes are aimed at providing wholistic benefits and growth opportunities to this sector.",
        ],
      },
      {
        title:
          "5. What is Export Promotion & Quality Assurance under Ministry of Textile?",
        para: [
          "The Export Promotion & Quality Assurance Division carries out functions under various Sections of The Textiles Committee Act, such as conducting technical studies in the textile industry, Promotion of textile exports, Establishing, adopting and recognizing standard specifications for textiles and packing materials, Specifying the type of quality control or inspection needs to be applied to textiles, providing training on the techniques of quality control to be applied to textiles etc.",
        ],
      },
    ],
    feature: "",
    beneficiaryProjects: [
      "This scheme aims to facilitate investment, employment, productivity, quality and import and export substitution in the textile industry",
    ],
    title: "Amended Technology Upgradation Fund Scheme",
  },
  ASPIRE: {
    videoId: "d-b6O-kmlls",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/schemeimages%2Fimg19.jpg?alt=media&token=f2e992e4-8e7c-4fcb-b94e-9664c160d241",
    feature:
      "Automation of agricultural techniques and operations for efficiency",
    title:
      "A Scheme for Promotion of Innovation, Rural Industries and Entrepreneurship",
    whoCanApply: [
      "Entrepreneur(s) who are looking to fund or develop their start-up.",
      "Any research, technical, or educational institutions that are focused on rural and agro industries.",
      "All MSMEs with Entrepreneurs Memorandum Registration.",
      "Any institution and/or agencies under any state government or the Government of India. These institutions must be engaged in the field of rural development, business management, technology, and entrepreneurial development. This can also include any body corporate under the Public-Private Partnership (PPP) mode that can set up Livelihood Business Incubation (LBI).",
      "Existing business incubation centers that are currently operating under different Ministries and Departments of the Government of India and institutions. These centers can include national or regional-level institutions that are dedicated to enterprise development and incubation in the field of agricultural and rural development.",
      "New incubation centers that are to be set up by certain eligible private institutions. This can include industry universities, associations, government entities, R&D laboratories, academic institutions, and technology parks.",
    ],
    beneficiaryProjects: [
      "The ASPIRE scheme helps in value addition in the agricultural and produce sector.",
      "The ASPIRE scheme helps in developing new and creative business models for value addition and aggregation in the rural areas.",
      "The ASPIRE scheme helps in empowering new-age entrepreneurs, particularly in rural areas.",
      "The ASPIRE scheme helps rural and agro-based industries adopt new technology and infrastructure to maximize productivity.",
      "The ASPIRE scheme helps create new employment opportunities, which in turn can empower small and medium-sized entrepreneurs.",
    ],
    objectives: [
      {
        title: "Livelihood Business Incubation (LBI)",
        para: [
          "The main objective of Livelihood Business Incubation is setting up business incubators to incubate, provide skill development training to youth, impart entrepreneurship and facilitate funding for empowering the entrepreneurs to set up their business enterprises.",
          "The main focus of these incubators is creating jobs at the local level and reducing unemployment.",
          "These incubators create a favorable ecosystem for the development of entrepreneurship in the country.",
          "The LBI’s create enterprises on a large scale by taking up those commercial activities which are already established.",
        ],
      },
      {
        title: "Technology Business Incubation (TBI)",
        para: [
          "Technology Business Incubators are an effective economic development tool.",
          "TBI promotes the growth of an enterprise through the application of technology, innovation and supporting economic development strategies for small business development.",
          "They also encourage growth in local economies and provide mechanisms for technology transfer.",
          "The TBI’s mainly focus on those technologies that require support for commercialization and future proliferation.",
          "The programs under TBI include support and setting up incubation centers, incubation of ideas, creation of business enterprises out of innovative ideas and accelerator programs for incubators.",
        ],
      },
      {
        title:
          "Start-up Promotion through Small Industries Development Bank of India (SIDBI)",
        para: [
          "The Small Industries Development Bank of India (SIDBI) enables ideas/innovations that have creativity and scalability to come ahead and convert these into commercially viable enterprises within a specified period with specific outcomes through innovative means of finance.",
          "The innovative means of finance of SIDBI include equity, quasi-equity, venture capital fund, angel fund, challenge fund, impact fund, etc.",
          "A fund of funds is also created under SIDBI for achieving the purpose of converting ideas/innovations into commercially viable enterprises.",
          "This component targets knowledge initiatives which require support and nurture to succeed in the development of technology.",
          "It also targets the business enterprises in the areas of innovation, accelerator support in Agro-based Industry vertices, entrepreneurship and forward-backwards linkage with multiple value chains of manufacturing and service delivery.",
        ],
      },
    ],
  },
};

export const propriotiership = {
  "MSME Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fmsme.jpeg?alt=media&token=fc2b4dc8-5d7b-41c4-9b90-3dec6f4e8613",
    head: "MSME Registration",
    documentsRequired: ["PAN Card", "Aadhar Card"],
    description: [
      {
        title: "What is MSME classification?",
        para: [
          "Initially, when the government introduced the MSME registration in 2006, the MSME classification was based on the investment criteria in plant and machinery or equipment. The government revised the MSME classification by inserting composite investment and annual turnover criteria. Also, the distinction between the manufacturing and the services sectors under the MSME definition was removed.",
          "The following is the current revised MSME classification, where the investment and annual turnover are to be considered for deciding if an entity is considered as an MSME:",
        ],
      },
      {
        title: "Documents Required for MSME Registration",
        para: [
          "The MSME registration documents are as follows:",
          "- Aadhaar card",
          "- PAN card",
          "There are no MSME registration fees and it does not require proof of documents. PAN and GST-linked details on investment and turnover of enterprises will be taken automatically by the Udyam Registration Portal from the Government databases since the portal is integrated with Income Tax and GSTIN systems.",
          "GST registration is not compulsory for enterprises that do not require a GST registration under the GST law. However, enterprises that mandatorily need to obtain GST registration under the GST law, must enter their GSTIN for obtaining the MSME Registration.",
        ],
      },
      {
        title:
          "How to Apply for MSME Registration on Udyam Registration Portal?",
        para: [
          "The MSME registration process is entirely online. MSME online registration is to be done on the government portal of udyamregistration.gov.in. There is no MSME registration fee charged for registration. MSME registration online can be done under the following two categories in the portal –",
          "For New Entrepreneurs who are not Registered yet as MSME or those with EM-II and",
          "For those having registration as UAM and For those already having registration as UAM through Assisted filing",
        ],
      },
      {
        title:
          "For New Entrepreneurs who are not Registered yet as MSME or those with EM-II",
        para: [
          "Step 1: New entrepreneurs and entrepreneurs having EM-II registration need to click the button “For New Entrepreneurs who are not Registered yet as MSME or those with EM-II” shown on the home page of Udyam Registration Portal.",
          "Step 2: On the next page, enter the Aadhaar number and the name of the entrepreneur and click on the “Validate and Generate OTP Button”. Once this button is clicked and OTP is received and entered, the PAN Verification page opens.",
          "Step 3: The entrepreneur must enter the “Type of Organisation” and the PAN Number and click on the “Validate PAN” button. The portal gets the PAN details from the government databases and validates the PAN number of the entrepreneur.",
          "Step 4: After verification of PAN, the Udyam Registration form will appear, and the entrepreneurs need to fill in their personal details and details of their enterprise.",
          "Step 5: Enter the investment and turnover details, select the declaration, and click on the “Submit and Get Final OTP” button. The OTP is sent, and after entering the OTP and submitting the form, the Udyam Registration Certificate will be sent through email. Entrepreneurs can also find out the MSME registration status from the Udyam Registration Portal.",
        ],
      },
      {
        title: "Registration For Entrepreneurs Already Having UAM",
        para: [
          "For those already having UAM registration, they need to click the button “For those having registration as UAM” or “For those already having registration as UAM through Assisted filing” shown on the home page of the government portal. This will open a page where Udyog Aadhaar Number is to be entered, and an OTP option should be selected.",
          "The options provided are to obtain OTP on mobile as filled in UAM or obtain OTP on email as filled UAM. After choosing the OTP Options, “Validate and Generate OTP” is to be clicked. After entering OTP, registration details are to be filled on the MSME registration form, and Udyam registration will be complete.",
        ],
      },
      {
        title: "Benefits of MSME Registration",
        para: [
          "Below listed are the MSME registration benefits:",
          "- Due to MSME Registration India, bank loans become cheaper as the interest rate is very low, around 1-1.5%, much lower than interest on regular loans",
          "- It also allowed credit for minimum alternate tax (MAT) to be carried forward for up to 15 years instead of 10 years",
          "- Once registered the cost of getting a patent done, or the cost of setting up the industry reduces as many rebates and concessions are available",
          "- MSME registration helps to acquire government tenders easily as Udyam Registration Portal is integrated with Government e-Marketplace and various other State Government portals which give easy access to their marketplace and e-tenders",
          "- There is a One Time Settlement Fee for non-paid amounts of MSME",
          "- The MSME registration will help the MSMEs in availing the benefits of government schemes such as the Credit Guarantee Scheme, Credit Linked Capital Subsidy Scheme, Public Procurement Policy, Protection against delayed payments, etc.",
          "- MSMEs are eligible for priority sector lending from banks",
          "- MSMEs get the benefit of a government security deposit waiver that is helpful while participating in e-tenders",
          "- Any number of activities, including service or manufacturing or both, may be added or specified in one MSME registration",
          "- Barcode registration subsidy",
          "- Exemption scheme from direct taxes",
          "- ISO certification fees reimbursement",
          "- Electricity bills concession",
          "- Special consideration in International trade fairs",
        ],
      },
    ],
  },
  "Business Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fbusiness_registration.jpg?alt=media&token=7f7c85cc-8552-40b3-87d8-58877ebc27d9",
    head: "Business Registration",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "Passport",
      "Voter ID Card",
      "Ration Card",
      "Driving License",
      "Electricity Bill",
      "Telephone Bill",
    ],
    description: [
      {
        title: "Steps to Register a Business",
        para: [
          "Registering your business isn’t complicated, but it does require you to take the right steps. The procedure to register as a legal entity involves:",
          "1. Naming Your Business",
          "Every ecommerce entrepreneur will need to take the first step towards registering their business by deciding on a name for their online store.",
          "It’s essential that you come up with a memorable name for your online store– something which is going to catch the eyes of your audience and leave a lasting impression. Make sure that the name is related to your niche, and something which you’ll be able to build a marketing strategy around.",
          "Coming up with a business name that will stand the test of time can be tricky. Check out some of your competitors and analyze the types of names which they use. Make a note of the ecommerce business names which you like and then think about how your idea compares with it. Does your idea stand out from the crowd? If not, what can you do to make it unique? You can also use a business name generator to get ideas for your company name.",
        ],
      },
      {
        title: "2. Registering a Business Name",
        para: [
          "Once you’ve come up with an amazing name for your ecommerce business you’ve already overcome one of the most difficult parts of registering a business. It’s critical to make sure that your business name isn’t already registered with the United States Patent and Trademark Office (USPTO). The USPTO will automatically reject any registration for a business name that’s already registered in their system. You’ll also lose any processing fees from the application.",
          "To avoid any of these problems, research your local business directories to verify if the business name you’d like to use is available. You can also use USPTO’s free tool to check if your business name is available.",
          "Future-Proofing Your Business Name",
          "When you’ve found a memorable business name that is available, take some time to think about the long-term goals of your business. Do you think that the name of your business is future-proof? Will it stand the test of time?",
          "Try to avoid choosing a business name which stems from a current trend. If you were to name your ecommerce business ‘Selfie Stick Corporation’ you may see success initially, but how popular will your business be in the next few years?",
          "It’s important to consider the staying-power of a name before you register your business, as it’ll be inconvenient to change your name later on. Changing your name once you’ve created an established ecommerce business is a hassle legally, and can also lead to confusion for your existing customers.",
        ],
      },
      {
        title: "3. Picking Your Business Entity",
        para: [
          "After you’ve decided on a name for your ecommerce business, you’ll need to choose the type of business entity which best fits your online store. There are two main options; registering your business as an LLC, or incorporation. There are distinct differences between these two options, so we’ve broken down the key points which you’ll need to know so you can make the best decision for your ecommerce business.",
          "LLC",
          "Setting up your business as an LLC will require less paperwork than it would if you were to incorporate, which means that you’ll have more time to focus on getting your store off the ground.",
          "When you’ve registered your business as an LLC, you won’t be personally liable for any debts which your company accumulates. This means that your personal assets like your savings, car, and house will be completely safe if anything were to happen to your company.",
          "Another benefit of choosing to register as an LLC is that you’ll be able to rent your personal assets to your business. For example, if you run your ecommerce business from a home office, you’re able to claim that space as a business expense. You’ll save some extra money by doing this which you’ll be able to put into other aspects of your business.",
          "Corporation",
          "When most think of corporations they think of multi-million dollar companies with hundreds of employees, but incorporation merely means that you’ll develop from a sole proprietorship into a company which is formally recognised as a legal business entity.",
          "There are two main options which businesses can choose when they are incorporating; registering as an ‘S’ corporation, or a ‘C’ corporation. There are no prerequisites to registering as either, it’s all down to which option is better for your ecommerce business.",
          "Registering your ecommerce business as an ‘S’ corporation will give you the option of ‘pass-through taxation’, which means that your ecommerce business will not be taxed on a business level. Instead, you’ll pay income tax on the revenue that your ecommerce business generates, but not the actual business itself.",
          "On the other hand, ‘C’ corporations are considered as a separate entity when it comes to taxation. This means that your ecommerce business will be subject to ‘double-taxation’, which means that you’ll be taxed on your business’ profits and you’ll still be taxed on your personal income.",
          "Whichever structure you choose for your business entity, bare in mind that it isn’t set in stone. As your business matures you’ll be able to move from one structure to another depending on the needs of your ecommerce business.",
        ],
      },
      {
        title: "4. Choosing the State You’ll Register In",
        para: [
          "Take some time to consider in which state you’ll register your ecommerce business. The tax rates vary from state to state. However, you aren’t required to register in the state where you operate your small business.",
          "Registering a new business in a state which has low, or non-existent sales tax rates makes a lot of sense as it’ll save you money down the road. Amazon, one of the biggest online retailers in the world, takes advantage of this option.",
          "Have a look through each state’s tax rates and decide which state is best to register in for your business’ goals and immediate needs. Some states have no sales tax, so they don’t have a registration procedure. Examples include:",
          "- Delaware",
          "- Montana",
          "- Oregon",
          "- Alaska",
          "- New Hampshire",
          "For other states, check with their local office to find out what the registration process comprises.",
        ],
      },
      {
        title: "5. Obtaining Your Business’ Federal Tax Number",
        para: [
          "You also need to register your business on a federal level. To do this, you’ll need to obtain a federal tax number for your ecommerce business. Your federal tax number is also known as an employer identification number (EIN), and it’s the equivalent of a social security number for your business.",
          "As you’ll be filing tax returns and submitting reports on your business activity, it’s essential that you get a EIN number for your business. Without this number, you won’t be able to submit your tax forms to either the federal or the state government.",
          "You can apply online for your ecommerce business’ EIN number. It’s a simple application form and you’ll be able to get your EIN number on the same day that you submit the online form.",
        ],
      },
      {
        title: "6. Opening a Business Bank Account",
        para: [
          "Banks in the US require documents of small business registration before they will allow you to open up a business bank account. It’s essential that ecommerce entrepreneurs know how to register an online business if they are looking to open a business bank account.",
          "As an entrepreneur, you’ll find that opening a business account is a great way to separate your personal finances from those which you’ll be using to fund your ecommerce business. Having a business account is also clever for tax reasons– you’ll be able to invoice certain payments and claim them as business expenses.",
          "Opening a business account will give you a clear idea of the amount of money which your business is costing each month, and you’ll know the exact amount of money which your ecommerce business is making.",
          "It’ll also give your ecommerce business more professional credibility. Customers will make payments for orders to your business account, instead of your name. This distinction instills confidence in your customer that your business is legitimate.",
        ],
      },
    ],
  },
  "Firm Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2Ffirm_registration.jpg?alt=media&token=49e8c9c1-f108-488f-bd88-83c08d5ceda9",
    head: "Firm Registration",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "Passport",
      "Voter ID Card",
      "Ration Card",
      "Driving License",
      "Electricity Bill",
      "Telephone Bill",
    ],
    description: [
      {
        title: "What is a Firm?",
        para: [
          "An organisation that sells or buys services or products to consumers in order to make profit is called a firm. For example corporation, limited liability company (LLC), or partnership.",
          "Most of the firms just have one location. However there are many firms that have more than one location.",
          "The word “firm” is usually associated with enterprises that provide accounting and law services.",
          "Nevertheless, the term is applicable for a wide range of enterprises such as finance, marketing, consulting etc.",
        ],
      },
      {
        title: "What is the difference between a firm and company?",
        para: [
          "Both terms are used interchangeably and often appear as synonymous, however there is a difference between a firm and a company.",
          "A company can be any business or trade that sells services or goods to generate profit.",
          "Furthermore, it includes all types of business structure, for example sole proprietorship, corporations and partnerships.",
          "Contrary, a firm often refers to a for- profit business controlled by two or more partners providing professional services, such as a legal firm, and normally excludes the sole proprietorship business.In some scenarios, a firm can be a corporation.",
        ],
      },
      {
        title: "What are the different types of firms?",
        para: [
          "The name of the firm is often used for business purposes, but the level of legal protection for owners or employees depends on the ownership structure that was used to establish the company.",
          "Corporations, for example, offer a higher level of legal protection than other types of organisation.",
          "Firms can presume numerous types based on their ownership structures:",
          "1. SOLE PROPRIETORSHIP OR SOLE TRADER: An individual owns a sole proprietorship or sole trader, and that person is also responsible for all liabilities and financial commitments. There are certain sole proprietorship enterprises that function like firms, however that is very rare.",
          "2. PARTNERSHIP: A partnership is an enterprise that is owned by two or people; however there are no limitations to the number of partners that can avail stake in ownership. Each and every partner is liable for all business obligations, and jointly owns everything that belongs to the business.",
          "3. CORPORATIONS - In a corporation, the financials of the business and the owners are kept apart. A corporation’s owners are not responsible for any expenses,legal fees, or other debts incurred by the business. Governments or individuals may own corporations.The functions of corporations can be similar to those of individuals, even though they are business entities. For instance, enter into contract agreements, they may take out loans and pay taxes.Any firm that has many owners is called a company.",
          "4. FINANCIAL COOPERATIVE - A financial cooperative is very much like a corporation as its owners have limited liability, however the difference is that in financial cooperative investors have a say in the operations of the company.",
        ],
      },
      {
        title: "What is the Indian Partnership Act 1932?",
        para: [
          "A partnership firm can be created when two or more people join forces as partners.",
          "The provisions of Indian Partnership Act, 1932 apply to this partnership firm. In situations and circumstances where the partnership act, 1932 is silent, the Indian Contract Act also applies to the partnership.",
          "The partners have the sole authority to decide whether to register their partnership. The Partnership Act does not make registration a requirement that must be followed.",
          "Nonetheless, it is always better to register the firm under the act because it grants the firm numerous benefits that imply the act favours registered firms over unregistered firms. The firms registered under this act are only considered existing legally.",
          "The registration of the firm can be done at any moment before or after the partnership’s continuation.",
          "Another situation in which registration of the firm is necessary is when the firm wants to more readily adopt a new organisational structure, such as an LLP or company more easily.",
        ],
      },
      {
        title: "Describe the meaning of “Partnership Deed”?",
        para: [
          "A partnership deed regulates both the relationship between the partners and the partnership firm under the contract between the partners. Partnerships are based on partnership agreements.",
          "A partnership agreement establishes the legal relationship between the partners for the purpose of carrying out the firm's business.",
          "Written contracts are known as partnership deeds, but oral agreements are known as partnership agreements.",
          "The details included in a partnership deed are :",
          "- Firm name and address, as well as business address",
          "- Partners' names and addresses",
          "- Partners' rights, duties, and obligations",
          "- Ratio of sharing profits and losses",
          "- contribution of capital by each partner",
          "- Rate of interest on loan, drawings and capital",
          "- Account reconciliation in the event of a firm's breakup",
          "- Mode of settlement in the event of conflicts among partners",
          "- Payable to partners are salaries and commissions.",
          "- The rules to follow when a new partner is admitted, or when an existing partner retires or dies",
          "- Any other provisions impacting the interests of the partners",
        ],
      },
      {
        title: "What are the Advantages/Benefits of firm registration?",
        para: [
          "POSSIBILITY OF SUING THE FIRM OR THE OTHER PARTNERS: If there is a dispute that takes place between the partners, and firm itself provided the dispute so arising is out of the terms stipulated in the partnership deed or the dispute is regarding the rights bestowed on the partner by virtue of Partnership Act,",
          "then a partner belonging to the firm in which the partnership is registered can always bring a claim against the firm.",
          "A partner in an unregistered firm is not granted this right, albeit he may bring a criminal case against the wronged partner(s).",
          "THE FIRM'S ABILITY TO SUE THIRD PARTIES: A registered firm is capable of bringing legal action against the other parties.",
          "In a registered firm, any partner may sue when one of their contracts is broken. This leniency is not extended to the partners of an unregistered firm.",
          "RIGHT TO APPLY THE SET- OFF PRINCIPAL : If a partnership firm receives a summons from another party to recover a debt owed to that party, the firm may utilise the set-off principal against the other party as long as the latter owes a debt to the partnership firm as well. The amount the registered partnership owes to the third party can simply be offset. If the partnership firm is not registered, this arrangement is not possible",
          "BETTER CREDIBILITY: A registered partnership firm has a greater level of trustworthiness in the eyes of potential clients, even if the partnership Act makes both registered and unregistered firms legitimate.",
          "CONVERTIBILITY INTO AN ENTITY: A registered partnership firm is always capable of easily transforming into a Limited Liability Partnership (LLP) or a private company. An unregistered firm is not granted this ease of conversion.",
        ],
      },
      {
        title: "What Is The Procedure Of Firm Registration?",
        para: [
          "STEP 1 CHOOSE THE FIRMS NAME",
          "Pick a name that is unique, different and easy to remember. It is recommended to check first for any existing trademark applications in order to avoid overlapping.",
          "The following should be kept in mind before finalising the name of the firm:",
          "- The firm name should not be similar or identical to any of the existing firms.",
          "- The names of the members listed in the member register may appear in the partnership firm's name.",
          "- The name of the partnership firm should be in  accordance with the rules and regulations of The Names and Emblems (Prevention of Improper Use) Act, 1950.",
          "- The firm name should end with suffixes such as “and associates”, and company”  and Co.",
          "STEP 2 DRAFTING OF THE PARTNERSHIP DEED",
          "The next step is to draft a partnership deed. It is the most important document of the partnership.Therefore there is an immense need for drafting it.",
          "The following are the important components of partnership deed:",
          "- Name of the firm",
          "- Partners details along with name and address",
          "- The date  of establishment",
          "- Interest on investment",
          "- The partners' respective capital contributions.",
          "- Payable salary",
          "- Settlement of unpaid debts with estate executors.",
          "- Method of calculating the goodwill",
          "- The steps involved in choosing a partner and retiring a partner.",
          "In case if there is no partnership deed then the following regulations shall apply irrespective of the approval or rejection by partners.",
          "- Distribution of profit and loss equally.",
          "- None of the partner shall be provided with the salary",
          "- No interest on capital paid",
          "- The firm will, upon mutual agreement, pay a 6% annual interest rate to the partner who has an advanced loan.",
          "- There are, however, a number of additional crucial provisions that help the partnership deed be legally binding and prevent future disputes.",
          "STEP 3 THE DEED NEEDS TO BE NOTARIZED AND EXECUTED",
          "To avoid any technical errors, the deed must be checked by the partners and, if necessary, by professionals after it has been completed. Depending on the value of the properties included in the deed, the final Deed must be printed on non-judicial stamp paper with a value of 100 or more. The state stamp duty statute of the state in which it is registered is therefore demanded by the parties.",
          "To eliminate the discomfort of physically stamping, the process of E-Stamping must be completed. The new recording agent for India's electronic stamping process is Stock Holding Corporation of India Limited.",
          "The deed may also be carried out by franking. It is a procedure that stamps the document and produces the same results as stamping. The required sum must be paid at the closest bank before the parties sign the deed. The deed must bear a stamp from the bank attesting to the payment of the stamp duty.",
          "The deed must be signed by all of the partners or their authorised representatives in the presence of the other partners and the witness. Every partner usually keeps a copy of the original, duly executed deed for their records. The witness's signature is then acquired.",
          "Although the notarization of the deed is not required under the partnership act, it is recommended because it gives the deed more credibility. Simply by notarizing the deed, disputes over its authenticity will be averted. It's just a standard procedure in our profession.",
          "STEP 4 PAN APPLICATION",
          "The partnership firm is not a separate legal entity from the owners or shareholders like a joint stock company. The partnership firm must still obtain a distinct PAN in order to comply with the law, nevertheless.",
          "PAN acquisition is possible both before and after firm registration. But in other states, getting a PAN is a must before completing the registration process. Even if the business is not registered, it must obtain a PAN.",
          "STEP 5 PARTNERSHIP DEED NEEDS TO BE REGISTERED",
          "A Form must be completed by the partners in accordance with Section 58 and Rule 3 of the Indian Partnership Act of 1932. The following information needs to be filled in the form: Firms full Name ,Address, Full name and details of the firm,The firm's duration,The firm's location,Other locations where the business transaction takes place.",
          "STEP 6 DOCUMENTS REQUIRED FOR REGISTRATION",
          "THE FOLLOWING DOCUMENTS ARE REQUIRED FOR THE FIRM REGISTRATION:-",
          "PARTNERSHIP DEED",
          "A partnership deed can be unwritten, however it is better written in order to avoid any kind of dispute. The partnership deed is made on a judicial stamp paper acquired from the respective state Registrar Office and it needs to be signed by all the partners. It consists of rights and duties of the firm and its partners.",
          "PAN CARD OF THE FIRM",
          "A PAN card of a firm is required. For that the partners need to apply. They have to fill form 49A. It can be applied offline or online. In the process of online filling of the form. The partners sign the application using a digital signature certificate. In the offline process the application and the required documents are sent to the nearest processing centres",
          "FIRMS ADDRESS PROOF",
          "If the registered place happens to be rented then these two documents needs to be submitted",
          "- One  Utility bill like water bill, gas receipt, electricity bill, property tax bill etc.",
          "- Rent agreement",
          "And, NOC from the landlords needs to be submitted as well.",
          "If the registered office happens to be of the owner, a utility bill that mentions the name of the owner along with the NOC  from the owner has to be submitted.",
          "AADHAR CARD OF THE PARTNERS",
          "It is mandatory for the partners to submit their Aadhar card.",
          "GST REGISTRATION",
          "The firm also needs to apply for GST registration, For attainment of GST registration, a firm needs to submit the following documents, PAN number, address proof and identity (aadhar card) along with address proofs of the partners. The authorised signatory will put a signature to the application using either a digital signature certificate or E- Aadhar verification.",
          "CURRENT BANK ACCOUNT",
          "The firm also needs to open a current bank account. For that the firm is required to submit the following documents Partnership deed,Partnership firm PAN card,Address Proof of the partnership firm,Identity proofs of all the partners,Partnership registration certificate,Any registration document issued by central or state government (usually GST certificate is submitted),Copy of electricity bill, telephone bill or water bill (it should not be more than 3 months old),Authorization letter on the letterhead of the firm authorizing a partner as an authorised signatory for the bank account.",
          "AFFIDAVIT",
          "An affidavit is also necessary to be submitted that certifies that the details mentioned in documents and partnership deed are correct.",
        ],
      },
    ],
  },
  "One Person Company": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fopc.jpg?alt=media&token=d58eb3bb-7d1b-4fa4-bb49-4fad3933e95f",
    head: "One Person Company",
    documentsRequired: [
      "PAN Card",
      "Passport",
      "Voters Identity Card",
      "Ration Card",
      "Driving License",
      "Electricity Bill",
      "Telephone Bill",
      "Aadhaar Card",
      "Bank Statement",
      "Sale Deed Copy",
      "Lease / Rent Agreement",
      "MOA Subscriber Sheet",
      "AOA Subscriber Sheet",
      "Passport Size Photo",
      "Recent Utility Bill",
    ],
    description: [
      {
        title: "One Person Company",
        para: [
          "Prior to the implementation of the Companies Act of 2013, only two people could form a company.",
          "The Companies Act of 2013 supports the formation of One Person Company (OPC) in India, governing its registration and functioning.",
          "Unlike private companies, OPCs can be formed with just one director and one member, making it easier for solo entrepreneurs.",
          "The registration of OPCs in India is legal under Section 262 of the Companies Act of 2013, and they come with fewer compliance requirements compared to private corporations.",
        ],
      },
      {
        title: "Steps for Registration of OPC",
        para: [
          "Check eligibility and documentation.",
          "Request Digital Signature Certificates (DSCs) and Director Identification Numbers (DINs) for each director.",
          "Submit a name reservation request using Form Spice+ for company incorporation.",
          "Apply for PAN and TAN for your new business.",
          "Receive an incorporation certificate with a PAN and TAN from the Registrar of Companies (RoC).",
          "Open a bank account and start your business.",
          "The entire process for registering an OPC can be completed in as little as 20 days.",
        ],
      },
      {
        title: "Documents Required for OPC Company Registration",
        para: [
          "Scanned copy of a current bank statement.",
          "Utility bills such as electricity, gas, phone, and mobile bills.",
          "Rental agreement in English in digital format.",
          "Landowner's no-objection certificate in digital format.",
          "Property or sale deeds scanned copy in English (if the property is owned).",
        ],
      },
      {
        title: "Checklist for One Person Company Registration",
        para: [
          "Meet maximum and minimum membership requirements.",
          "Nominate a person before incorporation.",
          "Request the nominee's approval using Form INC-3.",
          "Select a unique name for the OPC.",
          "Maintain a minimum authorized capital of ₹1 Lakh.",
          "Obtain the Digital Signature Certificate (DSC) of the potential director.",
          "Provide evidence of the OPC's registered office.",
        ],
      },
      {
        title: "Features of One Person Company",
        para: [
          "Easy succession: OPCs allow for easy succession, with the nominee taking over in case of the member's demise.",
          "Limited liability: Members in OPCs have limited liability, protecting them from the company's debts.",
          "Sole directorship and shareholder: A single member acts as both director and shareholder in an OPC.",
          "Ownership in property: OPCs can hold property and assets in their name as a separate legal entity.",
        ],
      },
      {
        title: "Compliances for a One Person Company",
        para: [
          "Certain compliances are outlined in the Companies Act of 2013 that must be met by specified deadlines.",
          "These compliances ensure transparency, governance, and safeguard the interests of all parties involved.",
          "They can be divided into annual, recurring, one-time, and event-dependent compliances.",
        ],
      },
      {
        title: "Advantages Of OPC Company in India",
        para: [
          "Legal standing: OPCs have a separate legal entity status, protecting the member from personal liability for the company's losses.",
          "Easy access to funding: OPCs can easily raise funds through venture capital, angel investors, and other sources.",
          "Less conformity: OPCs have exemptions from some compliance requirements, such as not being required to prepare cash flow statements.",
          "Easy integration: OPCs can be easily integrated without significant legal hassles.",
          "Easy to manage: OPCs are straightforward to manage, as decisions can be made quickly with fewer disputes and delays.",
          "Constant succession: OPCs have perpetual succession, with a nominee taking over in case of the member's demise.",
        ],
      },
      {
        title: "Restrictions on One Person Company",
        para: [
          "Not suitable for scalability: OPCs are ideal for small businesses but not suitable for scaling up with more members.",
          "Higher restrictions on business activities: OPCs are restricted from conducting non-banking financial investment activities.",
          "No clear distinction between ownership and management: In OPCs, there is no clear separation between the director and management roles.",
        ],
      },
    ],
  },
  "Private Limited": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fpvt.png?alt=media&token=b822ea27-57c2-4e6e-b1b5-02541f12e435",
    head: "Private Limited",
    documentsRequired: [
      "PAN Card",
      "Passport (Foreign Nationals Only)",
      "Aadhaar Card",
      "Foreign Government - Address Proof",
      "Bank Statement",
      "Latest Electricity Bill",
      "Latest Telephone Bill",
      "Latest Mobile Bill",
      "Board Resolution Authorising Investment",
      "Investing Company Address Proof",
      "Passport Size Photo",
      "Recent Utility Bill",
    ],
    description: [
      {
        title: "What is a Private Limited Company?",
        para: [
          "A private limited company is a privately held business entity held by private stakeholders.",
          "The liability arrangement, in this case, is that of a limited partnership, wherein the liability of a shareholder extends only up to the number of shares held by them.",
        ],
      },
      {
        title: "Characteristics of a Private Limited Company",
        para: [
          "Membership: Requires a minimum of two shareholders and a maximum cap of 200 members.",
          "Limited liability structure: Shareholders' liability is limited, protecting their personal assets.",
          "Separate legal entity: Exists in perpetual succession, unaffected by the lives of its shareholders.",
          "Minimum paid-up capital: Requires a minimum paid-up capital of Rs. 1 lakh.",
        ],
      },

      {
        title: "Requirements to start a Private Limited Company",
        para: [
          "Members and directors: Minimum of two shareholders and directors with DIN, one must be an Indian resident.",
          "Name of the company: Must follow naming conventions and have 'Private Limited Company' at the end.",
          "Registered office address: Must be provided after registration.",
          "Obtaining other documents: Requires a digital signature certificate and certifications by professionals.",
        ],
      },

      {
        title: "Advantages of Private Limited Companies",
        para: [
          "Limited liability: Shareholders are not at risk of losing their private assets.",
          "Less number of shareholders: Can be started with just two shareholders.",
          "Ownership: Owners can transfer and sell their shares.",
          "Uninterrupted existence: Continues even after the departure of a member.",
        ],
      },
      {
        title: "Disadvantages of Private Limited Companies",
        para: [
          "Compliance formalities for shutting down can be complicated and time-consuming.",
        ],
      },
      {
        title: "List of Documents Required for Private Limited Company",
        para: [
          "ID proof: PAN card and passport of Indian and foreign directors.",
          "Address proofs: Ration card, Aadhar card, driver’s license, or voter ID.",
          "Residence proofs: Bank statement or electricity bill of the premise.",
          "Notarized rental agreement.",
          "NOC from the property owner.",
          "A copy of the sale deed or property deed (for an owned property).",
        ],
      },
      {
        title: "How to register a Private Limited Company?",
        para: [
          "Apply for DSC (Digital Signature Certificate).",
          "Apply for the DIN (Director Identification Number).",
          "Apply for the name availability.",
          "File the EMoa and EAOA to register the private limited company.",
          "Apply for the PAN and TAN of the company.",
          "Certificate of incorporation will be issued by RoC with PAN and TAN.",
          "Open a current bank account in the company's name.",
        ],
      },
    ],
  },
  "Limited Liability Partnership": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fllp.jpg?alt=media&token=98a14670-ec24-47ff-a2b0-78d29b221ad7",
    head: "Private Limited",
    documentsRequired: [
      "PAN Card",
      "Passport (Foreign Nationals Only)",
      "Voters Identity Card",
      "Ration Card",
      "Driving License",
      "Electricity Bill",
      "Telephone Bill",
      "Aadhaar Card",
      "Bank Statement",
      "Passport Size Photo",
      "Recent Utility Bill",
    ],
    description: [
      {
        title: "What is LLP?",
        para: [
          "LLP or Limited Liability Partnership is an alternate form of business where the founders’ liability is limited to the extent of their capital contribution.",
          "Here’s an explainer blog on how Limited Liability Partnerships work, the benefits of an LLP, how you can register your own partnership and more.",
        ],
      },
      {
        title: "How does an LLP Work?",
        para: [
          "To understand, let’s break Limited Liability Partnerships down: limited liability and partnership.",
          "What is Limited Liability?",
          "Limited Liability means that each founder is only liable to the extent of the capital contribution. In a Limited Liability Partnership, partners only have to bear losses to the extent of their actual contribution to the business, and nothing beyond that.",
          "This means that in the case of business losses or bankruptcy, the partners’ personal assets are protected. In a general partnership, liability is not limited, and every partner is personally liable to bear all the losses.",
          "What is a Partnership?",
          "A partnership, in business terms, is when two or more people come together to do business. Each partner contributes resources to the business: capital, knowledge or even a good network.",
          "There are many different kinds of partnerships: based on liability, contributions, duration and more.",
        ],
      },
      {
        title: "Features of LLP",
        para: [
          "An LLP’s existence is perpetual, meaning it continues to exist even if one or more of the partners drop out of the partnership.",
          "An LLP exists as a legal entity separate from its partners.",
          "It is incorporated on the basis of an agreement that details important details like profit sharing ratio and capital contribution ratio.",
        ],
      },
      {
        title: "Benefits of LLP",
        para: [
          "It protects the personal liability of its founders in case of litigation and losses.",
          "An LLP also gives the benefit of perpetual succession – this means that the partnership continues to exist even if a partner drops out of the business.",
          "Partners can add new partners who can bring more expertise or resources to the business.",
          "Limited Liability Partnerships are ideal forms of business for professionals who want to start their own practice. For example, many law firms choose to register as LLPs.",
        ],
      },

      {
        title: "LLP vs LLC – Which one should you choose?",
        para: [
          "Another form of business that gives owners the benefit of limited liability is an LLC or a Limited Liability Company.",
          "The only similarity between a Limited Liability Company and a Limited Liability Partnership is that both types of businesses protect owners’ personal interests.",
          "LLP",
          "Incorporation: Needs at least two partners",
          "Compliances: Annual disclosure of accounts & solvency and annual report. Many expensive and complicated compliance requirements",
          "Personal Liability: If a partner is found personally responsible for any wrongdoing, he can be directly sued. The other partners remain protected.",
          "LLC",
          "Incorporation: Can be formed by one person",
          "Compliances: Lawsuits can only be filed against the entire company, and never against any individual director.",
        ],
      },
      {
        title: "Limited Liability Partnership vs Limited Partnership",
        para: [
          "A limited partnership is also made up of two or more people who come together for the purpose of doing business.",
          "However, a limited partnership constitutes a general member who oversees and manages the business and limited partners, who contribute resources and do not oversee the business.",
          "The general partner has unlimited liability while the limited partners are only liable up to their contribution to the business.",
        ],
      },

      {
        title: "How to Register an LLP in India?",
        para: [
          "Obtain Digital Signature Certificate (DSC)",
          "Apply for Director Identification Number (DIN)",
          "Name Approval to be processed by Central Registration Certificate",
          "Incorporate your Partnership",
          "File for an LLP Agreement",
          "Documents Required for LLP Registration In India",
          "To register your limited liability partnership in India, the following documents are typically required:",
          "Identity Proof",
          "PAN Card",
          "Passport",
          "Aadhar Card",
          "Address Proof",
          "Passport",
          "Driving License",
          "Aadhaar Card",
          "Voter ID Card",
          "Bank Statements or Utility Bills",
          "Registered Office Proof",
          "Rental Agreement or Lease Deed (in case the office is rented)",
          "Sale Deed or Property Deed (in case the office is owned)",
          "No Objection Certificate (NOC) from the property owner",
          "Utility Bill (not older than 2 months)",
          "Partnership Agreement Including the following details:",
          "Details of partner contributions",
          "Profit sharing ratio",
          "Partner rights",
          "Partner obligations",
          "Director Identification Number (DIN) This can be obtained from the Ministry of Corporate Affairs",
          "Digital Signature Certificate DSC is required for online filing of these documents",
        ],
      },
    ],
  },
  "Startup India Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fstartup.jpg?alt=media&token=8709bd60-b527-448f-9f90-d313bf8b4e37",
    head: "Startup India Registration",
    documentsRequired: [
      "Letter of Authorisation",
      "Copy Of Aadhar",
      "Contact Details",
      "Proof Of Registration",
      "USP",
      "Business Plan",
      "Type Of Innovation",
      "a Of Website",
      "Pitch Deck",
      "Letter Of Recommendation from Investor(not mandatory)",
      "Letter Of Funding From Angel Investor(If Applicable)",
    ],
    description: [
      {
        title: "What is Startup India Hub?",
        para: [
          "Startup India Hub is a one-stop platform for all stakeholders in the Startup ecosystem to interact amongst each other, exchange knowledge and form successful partnerships in a highly dynamic environment.",
        ],
      },
      {
        title: "How do Investors add value to Startups?",
        para: [
          "Investors, particularly venture capitalists (VCs), add value to startups in a lot of ways:",
          "Stakeholder Management: Investors manage the company board and leadership to facilitate smooth operations of the startup. In addition, their functional experience and domain knowledge of working and investing with startups imparts vision and direction to the company.",
          "Raising Funds: Investors are the best guides for the startup to raise subsequent rounds of funding on the basis of stage, maturity, sector focus etc. and aid in networking and connection for the founders to pitch their business to other investors.",
          "Recruiting Talent: Sourcing high-quality and best-fit human capital is critical for startups, especially when it comes to recruiting senior executives to manage and drive business goals. VCs, with their extensive network, can help bridge the talent gap by recruiting the right set of people at the right time.",
          "Marketing: VCs assist with marketing strategy for your product/service.",
          "M&A Activity: VCs have their eyes and ears open to merger and acquisition opportunities in the local entrepreneurial ecosystem to enable greater value addition to the business through inorganic growth.",
          "Organizational Restructuring: As a young startup matures to an established company, VCs help with the right organizational structuring and introduce processes to increase capital efficiency, lower costs, and scale efficiently.",
        ],
      },
      {
        title: "Why do Investors invest in Startups?",
        para: [
          "Investing in startups is a risky proposition, but the low requirement for overhead capital combined with high upside potential makes it lucrative for investors to put their bets on startups.",
          "The Thomson Reuters Venture Capital Research Index replicated the performance of the venture capital industry in 2012 and found that overall venture capital has returned at an annual rate of 20% since 1996 – far outperforming modest returns of 7.5% and 5.9% from public equities and bonds respectively.",
        ],
      },
      {
        title: "How can I register a profile on the hub?",
        para: [
          "Registering a profile on the hub is a fairly simple process.",
          "On clicking the “Register” tab on the top right-hand corner of the page, you will be directed to our “mygov” platform for authentication where you will be asked to fill details such as your name, email address, etc. This will give you a one-time password for verification as well as a link to set a new password.",
          "Sign in using the login credentials you created in step 1. This will direct you to the Hub where you can select and create the profile of a stakeholder which best defines your role.",
        ],
      },
      {
        title: "How do we connect to enablers after creating a profile?",
        para: [
          "The system is built to connect you to your relevant stakeholders based on your industry and preferred stage. Under the profile of every enabler, there will be an option to “connect/apply”. Upon clicking, a request will be sent to the respective profile for acceptance. Once accepted, you will be able to see the enabler as a new connection.",
          "Please note that you can connect with up to 3 users per week.",
        ],
      },
      {
        title: "Can a foreign company register under Startup India hub?",
        para: [
          "Any entity having at least one registered office in India is welcome to register on the hub as location preferences, for the time being, are only created for Indian states. However, we are working on international relations and will soon be able to enable registration for stakeholders from the global ecosystem.",
        ],
      },
    ],
  },
  "Section 8 Company": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fsection_8.jpg?alt=media&token=149fd17a-dca3-418f-afc9-e227d90ccd36",
    head: "Section 8 Company",
    documentsRequired: ["Aadhar Card", "Recent Utility Bill"],
    description: [
      {
        title: "How to Register as NGO – Section 8 Company in India",
        para: [
          "NGO (Non-Government Organisation) is an organization that works for non-profit/ charitable purposes. An NGO established as Section 8 company under the Companies Act, 2013 ('Act') is governed by the Ministry of Corporate Affairs ('MCA') whereas the NGO registered as a trust or society is governed by the registrar of state under the State Government.",
          "Section 8 company registration has more benefits in comparison to trust and society. This type of company has more credibility among government departments, donors, and other stakeholders. In this article, we will explain how to register as an NGO in the form of Section 8 Company, under the Companies Act, 2013.",
        ],
      },

      {
        title: "Laws in India applicable to an NGO",
        para: [
          "NGOs can be registered in India under any of the following laws:",
          "1. Trust under Indian Trusts Act, 1882",
          "2. Society under Societies Registration Act, 1860",
          "3. Section 8 of Companies Act, 2013",
        ],
      },
      {
        title: "Purpose of NGO – Section 8 Company Meaning",
        para: [
          "The main purpose of establishing a company as a Section 8 company is to promote non-profit objectives such as the following:",
          "- Commerce",
          "- Art",
          "- Science",
          "- Sports",
          "- Education",
          "- Research",
          "- Social welfare",
          "- Religion",
          "- Charity",
          "- Protection of the environment",
          "- Any such other object related to the above objects",
          "When the Central Government is satisfied that a person or association of persons propose to establish a limited company having the above-mentioned objectives, it shall grant a license to register as a Section 8 company under the Companies Act, 2013 ('Act').",
          "The profits/incomes of the Section 8 company, if any, are applied towards promoting the objectives of the company and are not distributed as dividends to its shareholders.",
        ],
      },
      {
        title: "Section 8 Company Benefits",
        para: [
          "There are many advantages of registering an NGO under Section 8 of the Companies Act, 2013, which are as follows:",
          "- No minimum capital: There is no minimum capital requirement for a Section 8 company incorporation, and the capital structure of Section 8 can be altered at any time as per the growth requirement of the company. Thus, the funds required for carrying the business operations can be brought in later, through donations and subscriptions from members and the general public.",
          "- Tax Benefits: The Company Auditor’s Report Order (CARO) does not apply to the Section 8 company. A Section 8 company enjoys tax benefits under 80G of the Income Tax Act, 1961.",
          "- No Stamp Duty: There is no stamp duty imposed for Section 8 company incorporation in India. The Section 8 company need not pay the stamp duty imposed on the Memorandum of Association (MOA) or Articles of Association (AOA) of a private or public limited company.",
          "- Separate Legal Identity: Section 8 Company registration acquires a distinct legal identity from its members. A registered partnership firm can also become a member in its individual capacity and obtain Directorship of Section 8 company. It has perpetual existence and thus, the entry or exit of any member will not affect the operation of the Section 8 company.",
          "- Limited liability: The members of the Section 8 company have limited liability as per their share subscribed. They are not personally liable for the losses of the company.",
          "- Credibility: Section 8 companies are more credible and reliable than any other form of a charitable organization. It is regulated under the provisions of the Act, thus they need to have mandatory audits every year and the Memorandum of Association cannot be altered relating to the non-profit objectives of the company.",
          "- Exemption to the donors: The tax exemption is granted to the donations received by the section 8 company under Section 12A and 80G of the Income Tax Act, 1961.",
        ],
      },

      {
        title: "Section 8 Company Registration Eligibility",
        para: [
          "An Individual, HUF is eligible to start a Section 8 company in India.",
          "Two or more persons who will act as Directors or shareholders should fulfil all the compliances and requirements of the Section 8 company incorporation under the Act.",
          "There must be at least one director who should be a resident of India in the Section 8 company.",
          "The objective must be one or more of the following – promotion of sports, social welfare, the advancement of science and art, education and financial assistance to lower-income groups.",
          "Founders, directors, members directors of the company cannot draw any remuneration in any form of cash or kind.",
          "No profit should be distributed among the members and directors of the company directly or indirectly.",
        ],
      },

      {
        title: "Section 8 Company Incorporation Requirement",
        para: [
          "Directors",
          "A minimum of two directors is required if the Section 8 company is to be incorporated as a private limited company, and a minimum of three directors in case of incorporation as a public limited company. The maximum number of members is 200 in the case of a private limited company, whereas for a public limited company, there is no such limit.",
          "Capital Requirement and Name",
          "There is no requirement of minimum paid-up capital in the case of a Section 8 company incorporation. NGOs established as a Section 8 company need not use the words ‘Limited’ or ‘Private Limited’ in their name.",
          "Charitable Objects",
          "Section 8 companies are incorporated with non-profit objectives. The MOA and AOA must mention the non-profit objective or purpose for which it is established. Any profits earned by the section 8 company is utilized for the furtherance of its main objectives, i.e. charitable purposes or reinvested in the company. The profits will not be distributed among its members.",
          "Management",
          "Section 8 company is managed by the Board of Directors as per the MOA and AOA of the company, unlike other trusts that are managed by the Trustees as per the Trust Deed.",
          "Regulation under Various Acts",
          "A Section 8 company needs to follow the rules and regulations prescribed under the Companies Act, 2013. It needs to maintain books of account, and file returns with the Registrar of Companies. Section 8 company cannot make any changes to the provisions of MoA and AoA without the prior approval of the Central Government. It also needs to follow the provisions of the Income Tax Act and GST Law.",
        ],
      },
      {
        title: "Obtain DSC  (Digital Signature Certificate)",
        para: [
          "Digital signatures of the proposed directors of the company are required as the forms for the registration process are filed online and should be digitally signed. Digital signature certificates (DSC) are issued by government-recognized certifying agencies. The list of such certified agencies can be accessed here. The cost of obtaining a DSC varies depending upon the certifying agency. You must obtain a Class 3 category  DSC.",
        ],
      },
      {
        title: "Apply for Director Identification Number (DIN)",
        para: [
          "You have to apply for a  DIN for the proposed directors of the company. The application for allotment of DIN has to be made in Form DIR-3 or along with the SPICe+ form for registration. You have to attach the scanned copy of the necessary documents such as a self-attested copy of PAN, Identity and Address proof of directors along with the form and submit it online on the MCA Portal. The form must be attested by a practicing professional who can be a chartered accountant, a company secretary, or a cost accountant.",
        ],
      },
      {
        title: "Forms Required for Section 8 Company Registration",
        para: [
          "Name of the form	Purpose of the Form",
          "SPICE+ 	Application for Incorporation of Company",
          "INC 12	Application for License",
          "INC 13	Memorandum of Association",
          "INC 14	Declaration from a practising Chartered Accountant",
          "INC 15	Declaration from each person making the application",
          "INC 16	License to incorporate as Section 8 company",
          "INC 22	Situation of Registered Office",
          "DIR 2	Consent of Directors",
          "DIR 3	Application to ROC to get DIN",
          "DIR 12	Appointment of Directors",
        ],
      },
      {
        title: "Purpose of the Section 8 Company Forms",
        para: [
          "Form INC-12",
          "Form INC-12 is filed for the grant of the license to operate as a Section 8 company. Along with this form, applicants are required to attach a draft copy of the Memorandum of Association (MOA) and Articles of Association (AOA).",
          "There is a prescribed format for Memorandum of Association of a Section 8 company in Form INC 13. The subscription pages of the MOA and AOA shall be signed by each subscriber along with their name, address, description, and occupation, in the presence of at least one witness.",
        ],
      },
      {
        title:
          "Form INC 12 is to be submitted along with the following attachments :",
        para: [
          "INC-13 – Memorandum of Association",
          "Draft Articles of Association",
          "INC-15 for the declaration by each subscriber to MOA that the draft memorandum and articles of association have been drawn up in conformity with the provisions of Section 8",
          "Estimated statement of Income & Expenditure for the next three years",
          "List of proposed Promoters and Directors of the Company",
          "The following forms are required to be filed after the issuance of the license to the company",
        ],
      },
      {
        title: "Form SPICe+",
        para: [
          "The SPICe+ form is the application for the incorporation of the company. The company can be reserved in the first part of the SPICe+ plus form. However, only one name can be declared for approval in this form. Thus, it is recommended that applicants access the free name search facility of existing companies available on the MCA portal before choosing the name. The system will provide a list of closely resembling names of the existing companies based on the search criteria. This will help you avoid choosing a name similar to that of an existing company.",
          "The following documents must be attached along with SPICe+ Form:",
          "Memorandum and articles of the company duly signed by all the subscribers",
          "Declaration from each of the subscribers and first directors that they are not guilty of any offence or misfeasance",
          "Declaration in Form INC 14 and Form INC 15 from a Chartered Accountant and each person making the application respectively",
          "Address of correspondence till the registered office is official",
          "Address and Identity proofs of all the subscribers to MOA and first directors of the company",
          "Passport size photograph of all the directors and shareholders",
          "Copy of the rental agreement, in case the company registered office, is on a rented property",
        ],
      },

      {
        title: "Form INC 22",
        para: [
          "Form INC 22 is filed for providing notice of the address of the company’s registered office. It needs to be filed within 30 days of the incorporation of the company.",
        ],
      },
      {
        title: "Form DIR 12",
        para: [
          "Form DIR 12 is filed for the appointment of the directors of the company. It must be filed within 30 days from the date of the appointment of the directors.",
        ],
      },
      {
        title: "Procedure for incorporation of a Section 8 company",
        para: [
          "Step 1 –  Obtain a DSC of the proposed Directors of the Section 8 Company. Once a DSC is received, file Form DIR-3 with the ROC for getting a DIN. The Proof of Identity and Address Proof needs to be attached for obtaining DSC.",
          "Step 2 – Once the DIR-3 is approved, the ROC will allot a DIN to the proposed directors.",
          "Step 3 –  File Form INC-12 with the ROC to apply for a licence for the Section 8 company along with the attachment of the required documents as mentioned above.",
          "Step 4 – Once the form is approved, a license under section 8 will be issued in Form INC-16.",
          "Step 5 – After obtaining the license, file the SPICe+ Form with the ROC for incorporation along with the required attachments as mentioned above.",
          "If the ROC is satisfied with the forms submitted, he issues a Certificate of Incorporation along with a unique Company Identification Number (CIN).",
        ],
      },
      {
        title: "Penalty for Non-Compliance under Companies Act",
        para: [
          "The Central Government will revoke the Section 8 company license if it fails to comply with the legal provisions of the Act. If the objectives of the company are conducted fraudulently or in violation of the objectives for which it is established, the license can be revoked.",
          "If a company defaults in complying with the provisions of the Act, the company will be punishable with a fine not less than Rs.10 lakh and may extend to Rs.1 crore. The Directors and the officers of the company in default of the provisions of the Act will be punishable with a fine not less than Rs.25,000 which may extend to Rs.25 lakh or both.",
        ],
      },

      {
        title: "Annual Compliances of Section 8 Company",
        para: [
          "The annual compliances of section 8 company are just like the other companies.",
          "- Conduct a minimum of two board meetings in a year",
          "- Maintenance of Books of Accounts",
          "- Preparation of financial statements",
          "- Mandatory audit report",
          "- Income tax return filing",
          "- Filing of financial statements in form AOC 4",
          "- An annual return is to be filed every year with other e-filing forms like MGT 7",
          "- Additional compliances to fulfill the registration like 12AA, 80G, etc.",
        ],
      },
    ],
  },
  "PF Registraion": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fpf.png?alt=media&token=d1375d56-dafa-486c-9218-085998a50f9f",
    head: "PF Registraion",
    documentsRequired: [
      "Copy of PAN/COI",
      "Address Proof of the Premises",
      "Identity & Address Proof of the Directors",
      "Details of the Resident and All Members",
      "Partnership Deed/LLP",
      "Agreement/MOA & AOA",
      "Details of Employees, Nominees, Salary",
      "Cancelled Bank Account Cheque",
      "Passport",
      "Aadhar Card",
      "Voter ID",
      "Driving License",
    ],
    description: [
      {
        title: "PF Registration",
        para: [
          "PF Registration is applicable for all organization which employs 20 or more employees.",
          "An important tool for aiding the savings of the workforce is EPF or Employees Provident Fund. EPFO or Employees Provident Fund Organisation of India manages the Provident Funds of the employees under Employees’ Provident Funds and Miscellaneous Provisions Act, 1952. Once an establishment employs 20 or more people, it becomes mandatory for the organization to obtain a PF Registration. The total contribution to the PF fund is 12% of basic salary plus DA plus retaining allowance by employee and employer both.",
          "eStartIndia is the best online legal services platform in PF Registration in India, offering a variety of registration services like GST registration, FSSAI Registration, Professional Tax registration, TAN registration, MSME registration, ESI registration, IEC Code, Digital Signature, Trade License to name a few.",
          "eStartIndia will help you to register PF from the comfort of your home, offering you services that are very technical and as per individual needs.",
          "Get a free consultation for PF Registration with Our Top Rated Experts with simple Registration.",
        ],
      },
      {
        title: "Advantages of PF Registration",
        para: [
          "Statutory Guidelines adherence – PF Registration helps businesses adhere to statutory guidelines since it is mandatory for businesses with over 20 employees.",
          "Employee savings – It aids savings for employees by maintaining a regular fund contribution from both employer and employees.",
          "Fund availability – Contributions to Provident fund can be withdrawn by employees in their time of need such as medical emergency, marriage expenses, education, etc.",
          "Tax benefits – The interest earned on the Provident fund savings are tax-free and the contributions to the fund are deductible from salary.",
          "High safety – The PF amount is safe and secure from any market variations and employees are assured a return on their principal amount.",
        ],
      },
      {
        title: "Documents required for PF Registration",
        para: [
          "Copy of PAN/ COI.",
          "Address Proof of the Premises.",
          "Identity and address Proof of the Directors/Partners/Proprietor.",
          "Details of the resident and all members with address and contact details in case of society/Trust.",
          "Partnership Deed/LLP Agreement/MOA & AOA.",
          "Details of employees, details of nominees, details of salary.",
          "Cancelled Bank Account cheque.",
          "For Individual Identity proof:",
          "  - Passport; or",
          "  - Aadhar card; or",
          "  - Voter ID; or",
          "  - Driving license",
          "For Individual address proof:",
          "  - Self-attested copy of Telephone/Mobile or Electricity bill; or",
          "  - Bank Account Statement (Less than 2 months old)",
          "For Proprietorship/Partnership/LLP/ Company Address proof:",
          "  - Telephone/Mobile/Electricity/Gas bill (Less than 2 months old); AND",
          "  - In case of rented premises a notarized copy of Rent Agreement with a No-Objection Certificate (NOC) for conducting business; AND",
          "  - In the case of self-owned premises registered title document.",
        ],
      },
    ],
  },
  "ESI Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fesi.png?alt=media&token=1603df45-849a-4888-9d32-8fda5f9d846d",
    head: "ESI Registration",
    documentsRequired: [
      "Registration Certificate",
      "Certificate of incorporation",
      "Utility Bills",
      "Rent Agreement",
      "PAN Card",
      "Property Tax Reciept",
      "Cancel Cheque Of Company's Bank A/c",
      "List Of Director Of Company",
      "List Of Shareholder Of Company",
    ],
    description: [
      {
        title: "What is ESI?",
        para: [
          "The term “ESI” refers to Employee State Insurance, administered by the Employee State Insurance Corporation (ESIC), an independent organization founded by legislation and housed inside the Ministry of Labour and Employment of the Government of India.",
          "In other words, India’s Employee State Insurance (ESI) program is a comprehensive welfare program for those employed in the organized sector. To provide workers with socioeconomic protection in the case of hardship or disaster-related to their finances and health, the government implemented this program in 1952.",
          "In addition to offering financial support, the ESI system provides health insurance to its beneficiaries. Aside from them, their family is also covered by insurance. The money for the program is mainly offered by the company’s employer and the employee’s contributions.",
          "This article will teach you all you need to know about ESI Applications.",
        ],
      },
      {
        title: "How To Know If You Are Eligible For ESI Application?",
        para: [
          "Any company or enterprise that is not seasonal has more than 20 employees and pays a basic income of Rs. 21,000/- is obligated to register for ESI with the ESIC 15 days from the time it becomes applicable.",
          "However, if you are a small business and do not have employees, you can register yourself voluntarily.",
        ],
      },
      {
        title: "Why Should You Register For ESI?",
        para: [
          "There are many benefits you might gain by registering for ESI. Some of these are explained below:",
          "- Health insurance for the employee as well as their family.",
          "- Healthcare expenses for the elderly.",
          "- Pregnancy benefits for women during pregnancy, along with paid leaves.",
          "- If an employee passes away while at the job, their family will be awarded a monthly payment equal to 90% of their total income.",
        ],
      },
      {
        title: "What Are the Required Documents for ESI Application?",
        para: [
          "Bank statement copy",
          "A copy of each license issued in the establishment’s name.",
          "A canceled check",
          "A copy of the company’s financial statement",
          "A duplicate of the certificate of company incorporation or registration",
          "A list of each person who the contractor or the company directly employs.",
          "A copy of the lease or rent contract.",
          "A copy of each director or partner’s PAN and voter ID",
          "A copy of the board’s decision",
          "A power bill copy",
          "Along with the documents mentioned previously, the following knowledge is also necessary.",
          "If an employee is already ESIC-registered:",
          "- Employee Insurance Information",
          "- Appointment date",
          "If the employee isn’t ESIC registered:",
          "- Name of Employee",
          "- Address and Birthdate",
          "- Employee’s father/husband’s name",
          "- Information about the nominees (name, mobile no, address, email id, etc.)",
          "- Bank Details (bank name, account number, IFSC code, Branch name, etc.)",
          "- Information about the former employer",
          "- Employer Insurance No.",
          "- Employer ID number",
          "- Name of the company",
          "- Employer’s address and phone number",
        ],
      },
      {
        title: "ESI Application Requirement",
        para: [
          "Section 2(12) of the Employees’ State Insurance Act states that all factories employing ten or more workers must get obligatory registration under the ESI act. To put it another way, the ESI program applies to establishments with ten or more staff workers.",
          "In India, however, the number of employees required for an organization to be eligible for registration under the ESI plan ranges from 10 to 20, depending on the location. Additionally, such firms and enterprises are required to register themselves with the ESI within the first 15 days after the date on which the ESI Scheme would begin to apply to them.",
          "In light of this, the following types of businesses, in addition to factories, qualify for coverage under the ESI plan since they employ ten people or more:",
          "- Shops and Establishment",
          "- Hotels and restaurants are not involved in any manufacturing activity; instead, they are primarily in the business of selling their products.",
          "- Theatres, cinemas, and preview theatres all rolled into one",
          "- Infrastructure for vehicular and automobile travel on roads",
          "- Establishments that publish newspapers (which are not considered factories according to section 2(12) of the ESI act)",
          "- Educational establishments that are not publicly funded (those run by individuals, trustees, societies, or other organizations) & Hospitals and Other Medical Facilities (including Corporate, Joint Sector, trust registration, charitable, and private ownership hospitals, nursing homes, diagnostic centers, pathological labs)",
        ],
      },
      {
        title: "Consequence of Delaying an Employee’s Contribution Deduction",
        para: [
          "When an employer registered with the ESIC fails to pay the required contribution to an employee’s ESIC, the employer will be liable to pay simple interest at the rate of 12 percent per year for each day the donation is late or not paid. In addition, the employer will be liable to pay the penalty for each day the contribution is late or not paid.",
        ],
      },
      {
        title: "ESI Code’s Numerical Designation",
        para: [
          "The ESI Code number is a one-of-a-kind identifying code of 17 digits and is given to every worker employed at an ESIC-Registered factory or facility. Several 17 digits are generated after the employer has submitted the information about their employee to the ESIC website.",
        ],
      },
      {
        title: "Requirements For Eligibility To Receive ESI Benefits",
        para: [
          "For an employee or worker to be eligible for the Employees’ State Insurance (ESI Scheme), their monthly income cannot be more than INR 21,000 (for non-disabled adults) or INR 25,000 (for disabled individuals)",
        ],
      },
    ],
  },
  "Udyam Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fudhyam.jpg?alt=media&token=6d7c84ef-8a6f-4b4b-89c8-a3cb8a27e7ea",
    head: "Udyam Registration",
    documentsRequired: ["Aadhar Card", "PAN Card", "GST"],
    description: [
      {
        title: "Udyam Registration – Get Udyam Registration Online",
        para: [
          "The government launched the Udyam portal on 1 July 2020 to boost Micro, Small and Medium Enterprise (MSME) registrations. The MSMEs must apply for MSME registration, also known as the Udyam registration, through the official Udyam portal. Udyam registration is required for MSMEs to obtain the benefits the government provides to the MSME sector.",
        ],
      },
      {
        title: "What is Udyam Registration?",
        para: [
          "Udyam registration is an e-certificate the government provides to the MSMEs in India. The Udyam registration replaced the Udyog Aadhaar Memorandum (UAM) registration. Though the Udyam registration is not mandatory, it is beneficial for the MSMEs to apply for this registration as it is required to avail of benefits that fall under the MSME category. These benefits help MSMEs to grow and boost their business across India.",
          "The government provides the MSMEs with a unique identification number and an MSME recognition certificate when they obtain the Udyam registration. The Udyam registration certificate certifies that the enterprise is eligible to get government benefits provided to MSMEs. The validity of the Udyam registration is till the existence of the enterprise. The government is facilitating ease of doing business to MSMEs by giving a permanent registration, i.e. Udyam registration.",
        ],
      },
      {
        title: "Eligibility for Udyam Registration",
        para: [
          "Enterprises with the below threshold limits, including trading, manufacturing and service enterprises, are eligible to apply for Udyam registration:",
          "- Micro enterprises: Enterprises whose investment in machinery, plant or equipment is below Rs.1 crore and whose turnover is below Rs.5 crore.",
          "- Small enterprises: Enterprises whose investment in machinery, plant or equipment is below Rs.10 crore and whose turnover is below Rs.50 crore.",
          "- Medium enterprises: Enterprises whose investment in machinery, plant or equipment is below Rs.50 crore and whose turnover is below Rs.250 crore.",
        ],
      },
      {
        title: "Benefits of Udyam Registration",
        para: [
          "Udyam registration is a prerequisite to get the benefits of government schemes or programmes of the Ministry of MSME, such as the Credit Linked Capital Subsidy Scheme, Credit Guarantee Scheme, Public Procurement Policy, etc.",
          "Udyam portal is integrated with the income tax portal, GST identification systems and government e-marketplace.",
          "MSMEs having Udyam registration are eligible for priority sector lending from banks.",
          "MSMEs are allowed Minimum Alternate Tax (MAT) credit to be carried forward for 15 years instead of 10 years.",
          "Udyam registration helps to obtain cheaper bank loans as the interest rate is low, i.e. around 1 to 1.5%.",
        ],
      },
    ],
  },
  "Import Export Code": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fiec.jpg?alt=media&token=05a46016-ac87-43cf-9758-f758f65dff50",
    head: "Import Export Code",
    documentsRequired: [
      "Aadhar Card",
      "PAN Card",
      "Cancel Cheque",
      "Electricity Bill",
      "self-addressed envelope",
    ],
    description: [
      {
        title: "What Is an Import-Export Code?",
        para: [
          "India has emerged as the trading hub in south-Asia. With globalization and the coming of the free market, there has been immense potential for cross-border trade in India. More and more companies today, in search of cheaper alternatives are looking beyond the border and engaging in the import-export business.",
          "In order to facilitate the import-export business, the government has come up with the concept of an import-export code or IEC, as it is generally known. IEC is allotted to all companies engaged in the business of import and export in order to have a record of all their activities using one 10-digit identification number.",
        ],
      },
      {
        title: "Eligibility for Obtaining the IEC Number",
        para: [
          "The entire functioning of import and export in India is governed by the provisions of the Foreign Trade (Regulation) Rules, 1993. Therefore, IEC is also regulated by the same statute. In case the import/export is of services or technology, IEC is required in only limited circumstances, when import/export is in ‘specified services’ or ‘specified technologies’, i.e. services or technologies in which international trade is restricted by the Government of India as they pertain to national security, such as dealing in nuclear weapons, automatic guns, etc. IEC is not required under the following circumstances:",
          "Import/export of goods for personal use, which is not connected with trade, manufacture, or agriculture.",
          "Import/export by government ministries and departments, and certain notified charitable organizations.",
        ],
      },
      {
        title: "What Is the Procedure for Obtaining the IEC Number?",
        para: [
          "The applicant’s Registered/Head Office will make the IEC application with the nearest Regional Authority of Directorate General Foreign Trade (DGFT), in the case of a company it is the Registered office and for others, it is Head Office.",
          "The applicant must submit the required forms and duly fill in ‘Aayaat Niryaat Form – ANF2A.",
          "Only one IEC will be issued against a single PAN number. If there is more than one IEC allotted to a proprietor, the same may be surrendered to the Regional Office for cancellation.",
          "It should be noted that as per the provisions only one IEC can be issued against a single PAN number. In case there are more than one IECs allotted to a proprietor, the same may be surrendered to the Regional Office for cancellation.",
        ],
      },
    ],
  },
  "APEDA Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2F2.3-APEDA-Registration.png?alt=media&token=ebc6b638-0d5f-430d-8473-877ba61cbbb5",
    head: "APEDA Registration",
    documentsRequired: [
      "Application Form for the APEDA Registration",
      "Copy Of Import Export Code",
      "Cancel Cheque",
      "Bank Certificate",
      "Account Statement",
    ],
    description: [
      {
        title: "APEDA Registration",
        para: [
          "Agricultural & Processed Food Products Export Development Authority (APEDA) is a government organisation established in 1985 through an act for the development and promotion of export of scheduled products. It provides financial assistance, information, guidelines towards the development of scheduled products. The products specified under the APEDA ACT are called schedule products and exporters of such scheduled products are required register under APEDA.",
        ],
      },
      {
        title: "Objective of APEDA",
        para: [
          "The objective of APEDA is to promote schedule products export and to achieve this various functions has been undertaken by this body under the regulation of central government. Central government lays down the rules and regulation and implements through this body for the efficient administration of APEDA Act.",
        ],
      },
      {
        title: "Functions of APEDA Authorities",
        para: [
          "Authorities are assigned with functions such as:",
          "Promotion of export-oriented production and development of the Scheduled products",
          "Setting the standards and specifications for the scheduled products",
          "Registration of exporters of the scheduled products on payment of required fees",
          "Improving packaging and marketing of the Scheduled products",
          "Carrying out an inspection of products for the purpose of ensuring the quality of such products",
          "Training in various aspects of the industries connected with the scheduled products",
          "Development of industries relating to the scheduled products and undertaking surveys, feasibility studies etc",
          "Collection of statistics from the owners of factories or establishments and publication of such statistics",
          "APEDA is mandated with the responsibility of export promotion and development of scheduled products such as Fruits, Vegetable, Meat, Poultry Products. Dairy Products, Confectionery, Biscuits, Bakery Products, Honey, Jaggery and Sugar Products, Cocoa products, chocolates, floriculture Products. Pickles, Papads and Chutneys etc",
        ],
      },
      {
        title: "Registration Process",
        para: [
          "For registering under APEDA, the applicant should submit the application form within 1 month from the date of undertaking the business. If the exporter of scheduled products fails to register within that time limit due to sufficient cause, such date can be extended only by the Authority. Once the application duly filed and paid by the applicant, the authority will issue Registration -Cum- Membership- Certificate (RCMC). This registration is one-time registration and all the registered members are governed by the rules & regulations under APEDA Act",
          "Documents required for registration:",
          "Duly signed an application form",
          "Copy of Import-Export code issued by D.G.F.T.",
          "Bank Certificate duly signed by the authorities",
          "Bank A/c. statement of the bank account of the firm (for latest 2 months)",
          "Canceled cheque",
        ],
      },
      {
        title: "The Advantage of APEDA Registration",
        para: [
          "With respect to the export of scheduled products, APEDA registration is mandatory.",
          "Exporters can avail the various financial assistance schemes of APEDA.",
          "It helps the exporters in brand publicity through advertisement, packaging development, database up-gradation and surveys etc",
          "Provides guidelines to exporters about the various  products and countries for export",
          "Registered members can participate in training programmes organized by APEDA for various scheduled products and thereby improve their business.",
        ],
      },
      {
        title: "Online Registration Process",
        para: [
          "With the objective of simplification of procedures and ease of doing business with effect from 1.8.2015, the issuance of RCMC has been made online using digital signatures. Exporters can submit an application online and the steps are listed below:",
          "Login to the APEDA Website and click on “Register as Member” tab",
          "Enter the basic detail – IE CODE, Email ID & Mobile number and then submit",
          "One Time Password for confirming the details will be sent on E-mail and mobile number and the same has to be entered on the verification screen and click on Submit to proceed.",
          "Once verification is complete, fill in the online application and upload the required documents. The documents should be in the JPEG, PDF or PNG formats only.",
          "On-line application can be completed in one or more sessions by revisiting the website using the assigned OTP. Once the information is entered in the respective fields, save the data. The data entered can be edited until online payment is not made.",
          "Fee for the registration is Rs. 5000/- excluding taxes and can be made through any of the following modes: a) Credit Card(MasterCard and Visa), b) Debit Card (MasterCard and Visa), c) Demand draft in favor of “APEDA” payable with respective cities",
          "After completion of Payment process, an application number will be generated. This is application number and required for future reference.",
          "On issuance of RCMC, Login detail will be sent to the registered email of the exporter. The Exporter may login into their account through “Exporter Login” link given at APEDA website.",
          "Status of the RCMC application can be viewed by clicking the “Track Application” link to view the status of the application by submitting the IE Code and Application number until it is issued.",
          "If there is any shortcoming in the application the exporter will have to monitor and resubmit the document online.",
          "The final procedure is the approval of RCMC from APEDA officials and once the approval is granted the Certificate can be taken using APEDA Login under the heading of “View RCMC Certificate” under RCMC Menu. Further details are available on APEDA’s website",
        ],
      },
    ],
  },
  "FSSAI Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Ffssai.png?alt=media&token=8d18c700-1b0f-4604-8a24-fb4fe1a8cb68",
    head: "FSSAI Registration",
    documentsRequired: [
      "Photo identity proof",
      "Business constitution certificate",
      "Rental Agreement",
      "Food safety management system plan",
      "List of food products manufactured",
      "Bank account information",
      "NOC by Municipality or Panchayat",
    ],
    description: [
      {
        title: "What is FSSAI?",
        para: [
          "The Food Safety and Standards Authority of India (FSSAI) is an independent organisation that reports to the Indian government’s Ministry of Health & Family Welfare. According to the Food Safety and Standards Act, FSSAI was founded in 2006 (FSSA). The FSSAI is a vast umbrella organisation under which numerous prior laws, rules, and guidelines pertaining to food safety are integrated.",
          "The act consolidates the following 7 older laws:",
          "1947-Vegetable Oil Products (Control) Order,",
          "1954-Prevention of Food Adulteration Act",
          "1955-Fruit Products Order",
          "1967-Solvent Extracted Oil, De-Oiled Meal, and Edible Flour (Control) Order",
          "1973-Meat Food Products Order",
          "1988-Edible Oils Packaging (Regulation) Order",
          "1992-Milk and Milk Products Order",
        ],
      },
      {
        title: "Main Purpose of FSSAI",
        para: [
          "The primary goal of FSSAI is to protect and advance public health by establishing and enforcing food safety regulations.",
          "It establishes food safety standards and dispels misconceptions about food and food-related business from the minds of producers, traders, consumers, and anyone else either directly or indirectly involved in the food industry.",
          "Simply put, the FSSAI’s job is to make sure that the food you consume is wholesome and safe.",
          "For this reason, they keep an eye on the ingredients and everyone involved in the production of food, from growing it to serving it.",
          "They also establish objectives and plans. For instance, they recently established a goal to outlaw the use of synthetic trans fats by the year 2022.",
        ],
      },
      {
        title: "Types of Food Licenses issued by FSSAI",
        para: [
          "FSSAI in India issues the following three types of license:",
          "Central License",
          "State License",
          "Basic Registration",
        ],
      },
      {
        title: "FSSAI Registration Central License",
        para: [
          "Companies operating as importers in the food business or export-oriented business, large manufacturers, operators in the Central Government Agencies, ports or airports, etc. need to have central licenses issued by FoSCos FSSAI.",
          "This license is issued by the Central Government.",
          "A company in a food business needs to have a license for the head office in case it is operational in different states or cities.",
          "This license is for the FBOs having an annual turnover of ₹20 crore.",
          "It is issued for 5 years and at least for 1 year.",
        ],
      },
      {
        title: "FSSAI Registration State License",
        para: [
          "This license is for SMEs like transport, storage houses, transport, distributors, marketers, etc.",
          "This license is issued by the State Government.",
          "The company must have its operations in only 1 state.",
          "The business must have an annual turnover of ₹2 lakh.",
          "The license is issued for 5 years and a minimum of 1 year.",
        ],
      },
      {
        title: "FSSAI Basic Registration Licence",
        para: [
          "This license is issued by the State Government and is given to small manufacturers, storage units, transporters, marketers, and distributors.",
          "The company has an annual turnover of ₹12 lakh.",
          "The license issuance for a period of 5 years and a minimum of 1 year.",
        ],
      },
      {
        title: "Steps of FSSAI Registration",
        para: [
          "There are certain formalities that one needs to fulfill before going ahead for a food license in India. We have divided this section into the following:",
          "Steps to Follow",
          "FBOs need to submit Form A (basic registration and FBO has a turnover of less than ₹12 lakh) and Form B for FBOs having a turnover of less than ₹20 crore.",
          "Moreover, it takes seven days for the validation and verification of information.",
          "Additionally, once the application is accepted, the applicant will get the registration certificate, which will have the registration number and the photo of the applicant.",
          "This certificate has information like place of business and operation time.",
          "Documents for FSSAI license registration:",
          "Address Proof",
          "Identity proof",
          "List of food categories",
          "Layout plan",
          "Details of all the equipment",
          "Passport photograph",
          "NOC from municipality",
          "MoA and AoA",
          "Import Export Code",
          "Water Test Report",
          "Besides, you would need the following documents:",
          "Signed and filled out Form B",
          "Additionally, a complete plan of the processing unit",
          "List of all the directors and members of the company along with their contact details address proof and photo ID",
          "List of food category that is manufactured by the company",
          "Authority letter from the manufacturer",
          "A complete report of the water that is in manufacturing the food",
          "Source of raw materials like meat, milk, etc.",
          "Additionally, the Ministry of Commerce Certificate",
          "NOC",
          "IE code document issued by DGFT",
          "Moreover, Proof of ownership of the premises",
          "NOC and copy of the license from the manufacturer",
          "Form IX",
          "Form IX",
          "Certificate from the Ministry of Tourism",
          "Partnership Deed",
          "Further, the declaration from",
          "Certificate of Food safety management system",
          "The documentation for central, state, and basic registration are almost similar.",
        ],
      },
      {
        title: "Benefits of FSSAI License",
        para: [
          "It will provide legal benefits",
          "Helps in building goodwill",
          "Ensures food safety",
          "It will help in regulating storage, sale, or import of food, manufacturer, etc.",
        ],
      },
      {
        title: "Principal goals of FSSAI",
        para: [
          "Creation of scientifically based food standards",
          "Regulation of food production, distribution, storage, sales, and imports",
          "Assistance with food safety",
        ],
      },
    ],
  },
  "Shop Act Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fshop-act-certificate-more-than-10-employees-gumasta--500x500.webp?alt=media&token=12b8ff67-939d-41b9-a2de-9a6837fb6d21",
    head: "Shop Act Registration",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "Photo of Owner",
      "Rent Agreement",
      "Photo of Shop",
      "Electricity Bill",
    ],
    description: [
      {
        title: "Shop and Establishment Act Registration",
        para: [
          "The Shop and Establishment Act regulates the shops and commercial establishments operating within the state. Every state has its own Shop and Establishment Act (“Act”). However, the general provisions of the Act are the same in all states. The Labour Department of the respective states implements the Shop and Establishment Act.",
          "Shops are generally defined under the Act as the premises where the selling of goods take place either by retail or wholesale or where services are rendered to customers. It includes offices, godowns, storerooms and warehouses used in connection with the trade or business.",
          "Commercial establishments are generally defined as a commercial, banking, trading or insurance establishment or administrative service in which persons are employed for office work. It includes a hotel, boarding or eating house, restaurant, cafe, theatre, or other public entertainment or amusement places. However, factories and industries are not covered by the Act and are regulated by the Factories Act, 1948 and Industries (Development and Regulation) Act 1951.",
        ],
      },
      {
        title: "Registration Under The Shop and Establishment Act",
        para: [
          "The shops and commercial establishments covered under the Act must mandatorily apply for registration under the respective state Act. All establishments and business, including the people working and maintaining a business from home, must obtain a Shop and Establishment Registration Certificate or Shop License (“Certificate”) under the Act.",
          "The proprietors who run a business from home without having any physical store or premises are also required to obtain this Certificate. The proprietors of e-commerce business or online business, or online stores and establishment must register under this Act and obtain the Certificate. Every shop and commercial establishment should register itself under the Act within 30 days of commencement of business.",
          "The Certificate or the Shop License acts as a basic registration/license for the business. This Certificate is produced for obtaining many other business licenses and registrations. It serves as proof of the incorporation of commercial establishment or shops. It is also useful when the proprietor of the business wants to obtain a loan or create a current bank account for the business. Most banks will ask for this Certificate for opening a current bank account.",
        ],
      },
      {
        title: "Regulations Under The Shop and Establishment Act",
        para: [
          "The Act, among other things, regulates the following matters-",
          "Hours of work, annual leave, weekly holidays.",
          "Payment of wages and compensation.",
          "Prohibition of employment of children.",
          "Prohibition of employing women and young persons in the night shift.",
          "Enforcement and Inspection.",
          "Interval for rest.",
          "Opening and closing hours.",
          "Record keeping by the employers.",
          "Dismissal provisions.",
        ],
      },
      {
        title: "Process For Obtaining Shop and Establishment Registration",
        para: [
          "The procedure for obtaining the Shop and Establishment Registration Certificate differs from state to state. It can be obtained online or offline.",
          "For obtaining the registration certificate online, the proprietor or owner of the shop or business must log into the respective State Labour Department website. The proprietor or owner must fill the application form for the registration under the Shop and Establishment Act, upload the documents and pay the prescribed fees. The prescribed fees differ from state to state. Once the registration form is approved, the registration certificate will be issued online to the proprietor or owner of the business.",
          "For obtaining the registration certificate offline, the registration application is to be filled and submitted to the Chief Inspector of the concerned area along with the prescribed fees. The Chief Inspector will issue the registration certificate to the owner or proprietor after being satisfied with the correctness of the application.",
          "The registration application form contains the details relating to the name of the employer and establishment, address and category of the establishment, number of employees and other relevant details as required. The registration application needs to be renewed before the expiry of the period of registration. The validity of the Shop and Establishment Certificate differs from state to state. Some states provide the Certificate valid for a lifetime, while other states provide the Certificate valid for one to five years.",
        ],
      },
      {
        title: "Documents Required For Shop And Establishment Registration",
        para: [
          "The documents required for obtaining the shop and establishment registration certificate are-",
          "Shop or Business Establishment address proof.",
          "ID proof of the proprietor.",
          "PAN Card of the proprietor.",
          "Details of the employees.",
          "Payment challan.",
          "Additional business licenses necessary for starting the business, if any.",
        ],
      },
    ],
  },
  "Societ, NGO, Trust Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/propriotiership%2Fngo.jpg?alt=media&token=5c21c083-befb-4fe7-b8a7-c7e5b575eb22",
    head: "Society/NGO/Trust Registration",
    documentsRequired: [
      "Identity Proof",
      "Passport",
      "Proof of residence",
      "Registered Office Address Proof",
      "At least two shareholders",
      "Minimum of two directors",
    ],
    description: [
      {
        title: "NGO Registration",
        para: [
          "An NGO is a non-governmental organisation that works to improve society at large through philanthropic endeavours. Depending on the activity you want to pursue, you can start it as a Trust, a Society, or a Non-Profit Company [Section 8 Company].",
          "All non-profit organisations, including Trust, Societies, and Section 8 Companies, are collectively referred to as NGOs in India. Such non-profit organisations are also known as 'Sangathan', 'Sangh', and 'Sangam.' All non-profit NGOs are eligible for an income tax exemption.",
          "These are occasionally mistaken with non-profitable businesses, which denotes a conventional company that is not turning a profit. Vakilsearch will assist you in making the best decision and will walk you through the full NGO company registration procedure.",
        ],
      },
      {
        title: "What are the Types of NGO Registration in India?",
        para: [
          "There are three types of NGOs widely registered in India.",
          "Trust registration refers to the process of legally establishing a trust in India. A trust is a form of non-profit organisation (NPO) that is created to provide assistance and support to specific causes, such as education, health care, and community development. The trust registration process is governed by the Indian Trusts Act, 1882, and is typically handled by a team of legal experts and professionals.",
          "Society registration refers to the process of forming a society under the Societies Registration Act, 1860. A society is a group of individuals who come together to achieve a common goal or objective. Societies are formed to promote charitable, religious, educational, scientific, literary, or social causes.",
          "Section 8 Company is a type of non-profit organisation that is registered under Section 8 of the Companies Act, 2013. It is registered with the sole purpose of promoting commerce, art, science, religion, charity or any other useful object, and not for the purpose of making a profit. This type of company is also known as a Non-Profit Organisation or Non-Governmental Organisation (NGO).",
        ],
      },
      {
        title: "What Is the Classification of NGOs in India?",
        para: [
          "There are various classifications of NGOs in India, here is the list:",
          "By the Level of Orientation",
          "- Charitable Orientation",
          "- Service Orientation",
          "- Participatory Orientation",
          "- Empowering Orientation.",
          "By the Level of Operation",
          "- Community-Based Organisation",
          "- City Wide Organisation",
          "- National NGOs",
          "- International NGOs.",
        ],
      },
      {
        title: "Why Is NGO Registration Required?",
        para: [
          "The organisation gains credibility, and those who contribute money will be more likely to affiliate themselves with those NGOs that have registration because it lends an air of authenticity.",
          "A local NGOs operations may be able to develop with the aid of NGO company registration, which guarantees greater exposure for the organisation.",
          "The range of both financial and human resources is expanded. The NGO will benefit because more volunteers will step forward.",
          "Register your business as an NGO in India in accordance with the 2013 Companies Act to receive a number of benefits for the company's directors. NGOs are exempt from a number of taxes in India, which enables the business to save taxes and invest the money in further projects.",
          "Companies that have been recognised by the ITR filing return act of 1961 as NGOs are not prohibited from selling their assets or interest-earning claims.",
          "All businesses organised as NGOs under the ITR Act of 2013 are exempt from stamp duty, providing the business with additional opportunities to reduce its tax burden. The entire tax savings from the stamp duty are then used to promote the company's philosophy.",
        ],
      },
      {
        title: "Benefits of NGO Registration",
        para: [
          "A registered NGO gains the legal status and becomes accountable for the funds received. For instance, when an individual donates funds to a charitable trust, it is received under the name of the organization and used for the trust’s activities. In an unregistered firm, the assets can be received under anyone’s name and may be used for their own profit.",
          "An organization that is registered as an NGO reinforces the ethical, social and legal norms of our society.",
          "The basic requirement for running an NGO is to have a bank account under its name. In order to open an account, it is mandatory to be registered as a Trust, Society or Section 8 Company.",
          "The registration of an NGO is necessary to seek tax exemption from the Income Tax Authority.",
          "Some of the advantages of NGO registration are discussed below:",
          "- Avails Tax Exemption: NGOs are exempt from a number of taxes, thus registering the company as an NGO under the Companies Act, 2013 enables it to take advantage of these tax benefits and save money on taxes that it may then invest in more initiatives.",
          "- Ability to Purchase Assets: After obtaining legal registration, your company will be allowed to make real estate purchases, retain fixed assets, and/or incur liabilities. The legislation forbids unregistered organisations from buying, retaining, or selling land.",
          "- Minimum Share Capital Is not Required: To operate autonomously, no minimum share capital is required. Donations made to NGOs may be used to directly finance them. This shows that NGOs don't necessarily need more funds to function freely.",
          "- Defence Against Personal Liability: You can acquire assets and stakes under your NGO's name and register them. This is a really elegant method of protecting oneself from any consequences for unlucky occurrences like divorce, foreclosure, or bankruptcy.",
          "- Changing Ownership: It is not illegal for NGOs established under the Companies Act of 2013 to transfer their ownership or rights to interest accrued under the Income Tax Act of 1961.",
          "- Corporate Entity: Your company's interactions and transactions with the community will improve as a corporate body. The NGO may also bring legal action to protect its interests or may be sued directly by registered trustees.",
          "- Exemption on Stamp Duty: Section 8 corporations are free from stamp duty under the Income Tax Act since they are non-profit organisations, which enables the company to employ further tax-saving techniques. The corporation then uses all of the taxes saved by avoiding stamp duty to further its objectives. It also helps to protect the business's financial resources, which promotes productivity and allows for a more effective operation.",
          "- Structured Financial Plan: For actions you carry out on behalf of the registered NGO, an NGO may offer a tax-free framework. NGOs are considered non-profit organisations and are exempt from paying taxes. You may design a sound financial plan that enables the business to run tax-free.",
          "- Stability of Entity: Your organisation's registration may indicate that it has capable and accountable leadership. Similar will be seen by the public as more stable than an unregistered organisation. Political parties, the government, donor organisations, financial institutions, charity groups, and other NGOs will want to work with a recognised entity to achieve common objectives.",
          "- Perpetual Succession: An NGO can therefore exist forever and carry on its work even when its founders or trustees pass away or resign. The organisation won't cease to exist until a court ruling in India officially declares it to be closed. Along with other benefits, this might make permanent succession possible.",
          "- Admission to Credit: An NGO can obtain loans from lenders and financial institutions by registering. You can finance a mortgage, buy land or fixed assets with the help of a lending facility. Additionally, you can use it to advertise the company's operations. Banks will want registration papers before approving a loan.",
          "- Name Preservation: No one in India may use the same name or a name that is confusingly similar to your organisation's once it has been registered. The benefit of this is that it prevents unauthorised use of your company's brand and reputation.",
          "- Opening Bank Account: The NGO may convey its transparency by opening a corporate account with a bank. Some individuals, the government, donor organisations, and other NGOs won't feel comfortable signing a cheque in your personal name for your organisation. The existence of a bank account for the NGO would be evidence that it is a legal entity and is prepared to accept donations. To open an account with a bank, you must present evidence of your organisation's registration.",
        ],
      },
      {
        title: "Eligibility to Start an NGO",
        para: [
          "If an NGO is to be incorporated as a private limited company, there must be a minimum of two directors.",
          "In the case of incorporation as a public limited company, a minimum of three directors are necessary.",
          "200 members is the maximum allowed for a private limited business.",
          "For a public limited company, there is no member limit.",
          "If registering as an NGO, there is no fee.",
        ],
      },
      {
        title: "Things to Do Before Applying for NGO Registration Online",
        para: [
          "Obtain a Certificate for a Digital Signature (DSC)",
          "The registration forms must be digitally signed before being submitted online, therefore proposed directors must offer digital signatures. India's government certifies organisations that issue digital signature registration (DSC). Candidates must earn a DSC in either the Class 2 or Class 3 category. The cost to receive DSC varies and is determined by the certifying organisation.",
          "Get a Director Identification Number by Applying (DIN)",
          "Applications must include DIN requests for any potential company directors. The allocation of a DIN is aided by the completion of application Form DIR-3. The application form must be provided with scanned copies of director identification and address proof, self-attested copies of PAN, and other supporting documentation. Online submission of the application form is possible.",
          "Online NGO Registration Forms",
          "Here are the ngo registration forms required:",
          "- DIR 12 Appointments of Directors",
          "- DIR 2 Consent of Directors",
          "- DIR 3 Application to ROC to get DIN",
          "- INC 1 Business name approval",
          "- INC 12 Applications for License",
          "- INC 13 Memorandum of Association",
          "- INC 14 Declaration from a practising CA",
          "- INC 15 Declaration from each person making the application",
          "- INC 16 License to incorporate as NGO",
          "- INC 22 Situation of Registered Office",
          "- INC 7 Applications for Company’s Incorporation",
          "- INC 8 Declarations",
          "- INC 9 Affidavit from each director and subscribe",
        ],
      },
      {
        title: "Documents Required for NGO Registration",
        para: [
          "Before a registered deed is delivered to an NGO, submission of specific documents is required.",
          "Trust Registration",
          "For the trust registration, the following papers are mandatory:",
          "- A bill of electricity or water stating the address that needs to be registered.",
          "- The identity proof of at least two members of the company. The proof can be:",
          "  - Voter ID",
          "  - Driving License",
          "  - Passport",
          "  - Aadhaar Card",
          "Once the payment for the registration is done, it takes about 8 to 10 days for online registration to be completed under the Indian Trust Act – 1882. Before the deed becomes valid throughout the country, the settler has to deliver a presentation at the registrar’s office.",
          "Note: On the scheduled date for registration, the Author of the Trust shall be present in the Register Office for registration",
          "Society Registration",
          "For a society registration, the following papers are essential:",
          "- The name of the society.",
          "- Address proof of the office.",
          "- Identity proof of all the nine members which can be:",
          "  - Driving License",
          "  - Copy of Passport",
          "  - Voter ID",
          "  - Aadhaar card",
          "- Two copies of the Memorandum of Association and By-laws of the society.",
          "Note: Once the payment for the registration is done, it takes about 8 to 10 days for drafting the MoA and By-laws of the Society. Thereafter it takes 21 to 30 days for the Society to be registered.",
          "Section 8 Company:",
          "For a Section 8 company registration, the following papers are essential:",
          "- The name of the Company for approval.",
          "- Address proof of the office. It can be electricity or water bill or house tax receipt.",
          "- Identity proof of all the Directors which can be:",
          "  - Driving License",
          "  - Copy of Passport",
          "  - Voter ID",
          "  - Aadhaar card",
          "- The Memorandum of Association and Articles of Association of the Company.",
          "Note: Once the payment for the registration is done, it takes about 8 to 10 days for drafting the MoA and AoA of the Society. Thereafter it takes about 2 months for the entire Company registration to be completed.",
        ],
      },
      {
        title: "NGO Registration Procedure",
        para: [
          "Step 1: Determine the Type of NGO: Choose the appropriate type of NGO structure, such as a trust, society, or section 8 company, based on the organization's objectives and activities.",
          "Step 2: Select a Unique Name: Select a distinctive name for the NGO that represents its mission and purpose and is not similar to any existing registered entities.",
          "Step 3: Prepare the Memorandum of Association (MoA) and Articles of Association (AoA): Draft the MoA and AoA, which outline the objectives, rules, and regulations governing the NGO's operations and management.",
          "Step 4: Formulate the Governing Body: Establish a governing body or managing committee comprising individuals who will oversee the NGO's functioning and decision-making processes.",
          "Step 5: Provide a Registered Office Address: Designate a registered office address for the NGO, where official communications and legal documents can be sent.",
          "Step 6: Prepare the Required Documentation: Gather the necessary documents, including identity proofs, address proofs, and photographs of the governing body members.",
          "Step 7: File the Registration Application: Submit the registration application, along with the required documents, to the appropriate authority such as the Registrar of Societies, Registrar of Trusts, or Registrar of Companies, depending on the chosen NGO structure.",
          "Step 8: Review and Approval: The registration authority will review the application and supporting documents. They may seek additional information or clarification, if necessary.",
          "Step 9: Obtain the Registration Certificate: Upon successful review and approval, the registration authority will issue a registration certificate, confirming the legal status of the NGO.",
          "Step 10: Apply for Tax Exemptions: After obtaining the registration certificate, apply for tax exemptions under the Income Tax Act by obtaining certifications such as 12A and 80G.",
          "Step 11: Ensure Compliance and Reporting: Adhere to ongoing statutory requirements, including maintaining proper accounts, filing annual returns, and submitting audited financial statements.",
        ],
      },
      {
        title: "Process of NGO Registration in India",
        para: [
          "In India, for NGO registration process anybody is free to do social activities without forming an association or organization. But when an individual wants to create a group that involves volunteers, activities, and resources, it becomes important to have proper management in place. To run such companies, trusts and Societies in the correct manner, a certain set of rules need to be followed.",
          "How long does it take to register an NGO?",
          "The process of section 8 company is 10-14 days",
          "There is a process of trust incorporation that will take 20 days",
          "The society registration process will take another 25 days.",
        ],
      },
      {
        title: "How to Raise Funds for NGOs?",
        para: [
          "There are several ways to raise funds for an NGO, including:",
          "- Crowdfunding: This involves soliciting small donations from a large number of people through online platforms.",
          "- Grants: NGOs can apply for grants from various sources like government agencies, private foundations, and international organizations that fund social causes.",
          "- Corporate Partnerships: They can partner with companies that share their values to sponsor their programs and donate to their causes.",
          "- Fundraising Events: Organizing fundraising events like charity walks, concerts, and auctions, or online campaigns like Giving Tuesday can help attract donors and raise funds.",
          "- Individual Donations: NGOs can appeal to individuals to donate to their cause through online donation portals or by directly approaching potential donors.",
          "- Social Media Campaigns: NGOs can leverage social media platforms like Facebook, Instagram, Twitter, and LinkedIn to reach out to potential donors and create awareness about their cause.",
        ],
      },
      {
        title: "NGO Registration Fees",
        para: [
          "The NGO registration fee varies based on the country and the specific registration process. It depends on factors such as the type of NGO, the location, and the requirements set by the governing bodies. The registration fee may range from a nominal amount to a substantial sum, depending on the type of NGO and the country's laws and regulations. Additionally, there may be other expenses related to documentation, legal compliance, and other regulatory requirements that need to be taken into account.",
        ],
      },
      {
        title: "NGO Registration Procedure",
        para: [
          "Step 1: The first step is to choose the type of NGO that best suits the goal and activities of the organisation",
          "Step 2: Once the type of NGO has been selected, choose a unique name for the organisation",
          "Step 3: The Memorandum of Association (MOA) and Articles of Association (AOA) Should be drafted",
          "Step 4: The MOA and AOA documents must be notarized by a notary public",
          "Step 5: Once the documents have been notarized, the NGO can be registered with the appropriate authority. The registration process may vary depending on the type of NGO",
          "Step 6: After registration, the NGO should apply for a Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN)",
          "Step 7: Once the PAN and TAN have been obtained, the NGO can open a bank account in the name of the organisation",
          "Step 8: To obtain tax exemptions, the NGO should apply for registration under Section 12A and 80G of the Income Tax Act",
          "Step 9: If the NGO plans to receive foreign funds, it must apply for registration under the Foreign Contribution (Regulation) Act (FCRA)",
          "Step 10: The NGO should maintain proper books of accounts and comply with all regulatory requirements and reporting obligations",
          "Step 11: It is important for the NGO to regularly file its annual income tax returns and other necessary documents with the relevant authorities",
          "Step 12: The NGO should also conduct regular audits of its accounts by a qualified chartered accountant",
          "Step 13: The NGO can then carry out its activities and initiatives as per its objectives and mission while adhering to all legal and regulatory requirements.",
        ],
      },
      {
        title: "NGO Registration Online",
        para: [
          "NGO registration can also be done online in many countries. The specific steps and requirements for online registration may vary by country, but here are some common steps involved in online NGO registration:",
          "Step 1: Choose the Type of NGO: Determine the most suitable type of NGO for your mission and activities, whether it's a trust, society, or section 8 company.",
          "Step 2: Name Reservation: Check the availability of your chosen NGO name and reserve it if necessary. This can often be done online through the government's registration portal.",
          "Step 3: Prepare Documents: Gather and prepare all the necessary documents, including the Memorandum of Association (MoA), Articles of Association (AoA), and identity/address proofs of members and directors.",
          "Step 4: Online Application: Visit the government's official website for NGO registration and fill out the online application form. Upload the required documents and pay the registration fee online, if applicable.",
          "Step 5: Verification and Approval: The government authorities will review your application and documents. They may seek additional information or clarification during this process.",
          "Step 6: Issuance of Registration Certificate: Once your application is approved, you will receive a registration certificate or acknowledgment from the authorities, confirming your NGO's legal status.",
          "Step 7: Apply for Tax Exemptions: If your country offers tax exemptions to registered NGOs, you can apply for these benefits by obtaining certifications such as 12A and 80G under the Income Tax Act.",
          "Step 8: Compliance and Reporting: Adhere to ongoing statutory requirements, maintain proper accounts, file annual returns, and submit audited financial statements as required by law.",
        ],
      },
      {
        title: "Challenges in NGO Registration",
        para: [
          "While registering an NGO can be a rewarding process, it also comes with its challenges and complexities. Some common challenges in NGO registration include:",
          "- Bureaucratic Procedures: The registration process may involve dealing with bureaucratic red tape and a complex legal framework, which can be time-consuming and challenging for newcomers.",
          "- Documentation Requirements: Meeting the documentation requirements, including drafting the Memorandum of Association (MoA) and Articles of Association (AoA), and obtaining notarization and approvals, can be daunting.",
          "- Eligibility Criteria: Meeting the eligibility criteria, such as having a minimum number of members or directors, can be challenging, especially for smaller NGOs.",
          "- Tax Exemptions: Applying for and obtaining tax exemptions under the Income Tax Act may require meeting specific conditions and undergoing a separate process.",
          "- Compliance and Reporting: NGOs must adhere to ongoing compliance requirements, maintain proper accounts, conduct regular audits, and file annual returns, which can be demanding.",
          "- Foreign Funding: If an NGO plans to receive foreign funds, it must navigate the complex regulations of the Foreign Contribution (Regulation) Act (FCRA), which can be challenging to comply with.",
          "- Changing Regulations: NGO regulations and compliance requirements may change over time, requiring NGOs to stay updated and adapt to new legal frameworks.",
          "Despite these challenges, the benefits of NGO registration, including legal recognition, access to funding, and tax exemptions, often outweigh the difficulties, making it a worthwhile endeavor for those committed to social and charitable causes.",
        ],
      },
      {
        title: "Conclusion",
        para: [
          "NGO registration is a crucial step for organizations dedicated to social and charitable causes. It provides legal recognition, credibility, and access to various benefits, including tax exemptions and the ability to receive foreign funds. While the registration process may involve challenges and complexities, the positive impact an NGO can have on society makes it a worthwhile endeavor.",
          "By choosing the appropriate type of NGO, preparing the necessary documents, and adhering to regulatory requirements, NGOs can fulfill their missions and contribute to positive change in their communities and beyond.",
        ],
      },
    ],
  },
};

export const incomeTax = {
  "26 AS": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2F26as.png?alt=media&token=f703d66a-3444-4ab0-8f60-a7971e57f517",
    head: "26 AS",
    msme: "26 AS including Government fee for individuals and SMEs.",
    regular:
      "26 AS including Government fee for entities NOT having MSME registration.",
    documentsRequired: [],
    description: [
      {
        title: "What is Form 26AS?",
        para: [
          "Form 26AS is a statement that provides details of any amount deducted as TDS or TCS from various sources of income of a taxpayer. It also reflects details of advance tax/self-assessment tax paid, and high-value transactions entered into by the taxpayer.",
          "Your Tax Credit Statement or Form 26AS is an important document for tax filing. Gone are the days when one has to download Form 26AS for filing IT returns manually. The scope of the statement has now been expanded to include details of foreign remittances, mutual funds purchases, dividends, refund details, etc.",
          "Form 26AS is a statement that shows the below information:",
          "Tax deducted on your income by all the tax deductors",
          "Details of tax collected source by all the tax collectors",
          "Advance tax paid by the taxpayer",
          "Self-assessment tax payments",
          "Regular assessment tax deposited by the taxpayers (PAN holders)",
          "Details of income tax refund received by you during the financial year",
          "Details of the high-value transactions regarding shares, mutual funds, etc.",
          "Additional details like mutual fund purchase and dividend, interest on income tax refunds, off-market transactions, foreign remittances, salary break-up details, etc.",
          "Details of tax deducted on sale of immovable property",
          "Details of TDS defaults (after processing TDS return) made during the year",
          "Turnover details reported in GSTR-3B",
          "New AIS effective 1st June 2020 to also include information on specified financial transactions, pending and completed assessment proceedings, tax demands and refunds in addition to the existing data presented in the form.",
        ],
      },
      {
        title: "Structure and Parts of Form 26AS?",
        para: [
          "Part A: Details of Tax Deducted at Source",
          "Part A1: Details of Tax Deducted at Source for 15G/15H",
          "Part A2: Details of Tax Deducted at Source on the Sale of Immovable Property u/s194(IA)/TDS on Rent of Property u/s 194IB/TDS on Payment to Resident contractors and professionals u/s 194M (For Seller/Landlord of Property/ Payee of resident contractors and professionals)",
          "Part B: Details of Tax Collected at Source",
          "Part C: Details of Tax Paid (other than TDS or TCS)",
          "Part D: Details of Paid Refund",
          "Part E: Details of SFT Transaction",
          "Part F: Details of Tax Deducted at Source on the sale of Immovable Property u/s194(IA)/TDS on Rent of Property u/s 194IB/TDS on payment to resident contractors and professionals u/s 194M (For Buyer/Tenant of Property/Payer of resident contractors and professionals)",
          "Part G: TDS Defaults* (processing of Statements)",
          "Part H: Details of Turnover as per GSTR-3B",
          "Part A:",
          "Details of Tax Deducted at Source",
          "Part A of Form 26AS contains TDS details deducted on your salary, interest income, pension income, prize winnings, etc. It also includes the TAN of the deductor, the amount of TDS deducted and deposited to the government. This information is provided every quarter.",
          "Form 26AS",
          "Part A1:",
          "Details of Tax Deducted at Source for 15G/15H",
          "Details of income where no TDS has been deducted is given here since the taxpayer has submitted Form 15G or Form 15H. You can verify the status of TDS deduction if you have submitted Form 15G or Form 15H. If you have not submitted Form 15G or Form 15H, this section will display ‘No transactions present’.",
          "Part A2:",
          "Details of the following entries are mentioned here:",
          "TDS on sale of immovable property u/s194(IA) (for the seller of property)",
          "TDS on rent of property u/s 194IB (for the landlord of property)",
          "TDS on payment to resident contractors and professionals u/s 194M (for a payee of resident contractors and professionals)",
          "That is, it will show entries if you have sold the property/rented the property, received payments for contractual or professional service during the year, and TDS was deducted on the same.",
          "Part B:",
          "Details of Tax Collected at Source",
          "Part B shows the Tax Collected at Source (TCS) by the seller of goods. Entries in Form 26AS will show seller details who has collected tax from you.",
          "Part C:",
          "Details of Tax Paid (Other than TDS or TCS)",
          "If you have deposited any tax yourself, that information will appear here. Details of advance tax as well as self-assessment tax are present here. It also contains challan details (BSR code, date of deposit, CIN) through which the tax was deposited.",
          "Part D:",
          "Details of Paid Refund",
          "If any, information regarding your refund will be present in this section. Assessment year to which the refund pertains, along with the mode of payment, the amount paid and interest paid, and the date of payment are mentioned.",
          "Part E:",
          "Details of SFT Transaction",
          "Banks and other financial institutions must report high-value transactions to the tax authorities. High-value purchases of mutual funds, property purchases, corporate bonds are all reported here.",
          "Part F:",
          "Details of Tax Deducted at Source on the sale of Immovable Property u/s194(IA)/TDS on Rent of Property u/s 194IB/TDS on payment to resident contractors and professionals u/s 194M",
          "If you have bought a property/paid rent to the tenant/paid for contractual work or professional fees and deducted TDS on such payments. This section will show details of TDS deducted & deposited by you.",
          "Part G:",
          "TDS Defaults*(Processing of Statements)",
          "This part shows TDS defaults (after processing of TDS returns). However, they do not include demands raised by the assessing officer.",
          "Part H:",
          "Details of Turnover as per GSTR-3B",
          "This part shows turnover of the taxpayer as reported in the GSTR-3B return.",
        ],
      },
      {
        title: "How to view Form 26AS?",
        para: [
          "You can view Form 26AS on the TRACES portal and download it (see the step-by-step guide below on downloading Form 26AS).",
          "You can view Form 26AS via the net banking facility of your bank account. The facility to view Form 26AS is available to a PAN holder having a net banking account with any authorised bank.",
          "You can view your Form 26AS only if your PAN number is linked to that particular account. This facility is available for free.",
        ],
      },
    ],
  },
  "Tax Payment": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2Ftax_payment.webp?alt=media&token=fda81054-989b-4ebe-a754-0543f6bf071d",
    head: "Tax Payment",
    msme: "Tax Payment including Government fee for individuals and SMEs.",
    regular:
      "Tax Payment including Government fee for entities NOT having MSME registration.",
    documentsRequired: [],
    description: [
      {
        title: "Step-1",
        para: [
          'To pay taxes online, login to https://www.protean-tinpan.com/ > Services > e-payment : Pay Taxes Online or click here on the tab "e-pay taxes" provided on the said website. Provide proper link of e-payment',
        ],
      },
      {
        title: "Step-2",
        para: [
          "Select the relevant challan i.e. ITNS 280, ITNS 281, ITNS 282, ITNS 283, ITNS 284 or Form 26 QB demand payment (only for TDS on sale of property) as applicable.",
        ],
      },
      {
        title: "Step-3",
        para: [
          "Enter PAN / TAN (as applicable) and other mandatory challan details like accounting head under which payment is made, address of the tax payer and the bank through which payment is to be made etc.",
        ],
      },
      {
        title: "Step-4",
        para: [
          "On submission of data entered, a confirmation screen will be displayed. If PAN / TAN is valid as per the ITD PAN / TAN master, then the full name of the taxpayer as per the master will be displayed on the confirmation screen.",
        ],
      },
      {
        title: "Step-5",
        para: [
          "On confirmation of the data so entered, the taxpayer will be directed to the net-banking site of the bank.",
        ],
      },
      {
        title: "Step-6",
        para: [
          "The taxpayer has to login to the net-banking site with the user id / password provided by the bank for net-banking purpose and enter payment details at the bank site.",
        ],
      },
      {
        title: "Step-7",
        para: [
          "On successful payment a challan counterfoil will be displayed containing CIN, payment details and bank name through which e-payment has been made. This counterfoil is proof of payment being made.",
        ],
      },
    ],
  },
  "Personal Tax Filing": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2FincomeTax.jpg?alt=media&token=f4deb2fe-fa0c-4728-9310-6a1aa3e6f1ac",
    head: "Personal Tax Filing",
    msme: "Personal Tax Filing including Government fee for individuals and SMEs.",
    regular:
      "Personal Tax Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: ["Form 16", "Form 16A", "Form 26AS"],
    description: [
      {
        title: "Income Tax Return",
        para: [
          "The taxpayer shall file an income tax return every year via ITR forms prescribed by the income tax department. The government has prescribed seven ITR forms through which the taxpayer can file his income tax return. The taxpayer has to choose the appropriate ITR forms and file his income tax return.",
        ],
      },
      {
        title: "Income Tax Forms List",
        para: [
          "The seven ITR forms are:",
          "ITR-1: Individuals (residents) having income from salary, one house property, other sources, agricultural income less than Rs 5,000 and with a total income of up to Rs 50 lakh",
          "ITR-2: Individuals/HUFs not having any business or profession under any proprietorship",
          "ITR-3: Individuals/HUFs having income from a proprietary business or profession",
          "ITR-4: Individuals/HUFs having presumptive income from business or profession",
          "ITR-5: Partnership firms or LLPs",
          "ITR-6: Companies",
          "ITR-7: Trusts",
        ],
      },
      {
        title: "Documents Required for ITR Filing",
        para: [
          "Form 16, Form 26AS, Form 16A, proof of tax saving investments made, bank account details etc are some of the crucial details / documents that you need to be ready with before filing your return. Further the documents you are going to need to file your tax return are largely going to depend on your source of income. Here is our detailed article on documents you need for filing of your return of income.",
        ],
      },
      {
        title: "How can I calculate my income tax?",
        para: [
          "Individuals should calculate income tax depending on the nature of income. The salaried individual can take the eligible exemptions available for various allowances received. Individuals/HUF can take a deduction under Sections 80C to 80U, deduct it from the gross total income, and calculate the income tax liability. Also, the total income tax liability should be adjusted by the taxes paid, such as advance tax, TDS, etc. Also, the taxpayer should apply the effect of rebate under Section 87A and relief under Section 89, Section 90, and Section 91 to arrive at the net amount of income tax payable.",
          "Every income that your receive should form part of your income tax return. Of course, the law does provide for exemption of certain incomes eg. dividend income from an Indian company, LTCG on listed equity shares upto Rs 1 lakh in any financial year etc. Therefore, here is a quick guideline you can probably follow to compute taxes due on your income:",
          "List down all your income – be it salary, rental income, capital gains, interest income or profits from your business or profession",
          "Remove incomes that are exempt under law",
          "Claim all applicable deductions available under every source of income . eg claim standard deduction of Rs 50,000 from salary income, claim municipal taxes from rental income, claim business related expenses from your business turnover etc",
          "Claim all applicable exemptions under every head of income eg. amount reinvested in another house property can be claimed as exemption from capital gains income etc",
          "Claim applicable deductions from your total income eg the 80 deductions like 80C, 80D, 80TTA, 80TTB etc",
          "You will now arrive at your taxable income. Check the tax slab you fall under and accordingly arrive at your income tax payable.",
          "The government keeps introducing and altering tax slabs, schemes and tax benefits, so it’s a good idea to keep up with the Budget.",
        ],
      },
      {
        title: "What is computation of income?",
        para: [
          "The process of calculating taxable income after taking into account the income from all the five heads (salary, house property, capital gains, business or profession, and other sources), exemptions, deductions, rebate, set off of losses, etc., is called computation of income. After computation of income, the taxpayer can compute the income tax liability as per the Income Tax Act.",
        ],
      },
      {
        title: "Rebate u/s 87A",
        para: [
          "Rebate under Section 87A allows taxpayers reduce their income tax liability. If you are a resident individual and the amount of your total income after reducing Chapter VI-A deductions (Section 80C, 80D, 80U, etc) does not exceed Rs 5 lakh in a financial year, you can claim a tax rebate up to Rs 12,500. This means, if your total tax payable is less than Rs 12,500, then you will not have to pay any tax.",
          "In Budget 2023, a tax rebate on income of Rs 7 lakhs has been introduced under the new tax regime. Therefore, you do not have to pay tax if your taxable income is below 7 lakhs under the new tax regime.",
        ],
      },
      {
        title: "e-File Returns",
        para: [
          "The taxpayer shall electronically file the income tax return through the e-filing platform of the IT department. To file the income tax return, the taxpayer should first register himself at www.incometax.gov.in. Thereafter, the taxpayer can log in to the website and file his ITR. Also, there is no need to manually send the acknowledgement of the return to the income tax department. The income tax department now allows e-verification of the ITR in different ways, which completes the income tax return process.",
        ],
      },
      {
        title: "What is ITR –V?",
        para: [
          "Form ITR-V is an income tax return verification form generated after the taxpayer submits files income tax return and submits it to the income tax department. The ITR-V should be e-verified or must be sent to CPC Bangalore at “Income Tax Department – CPC, Post Box No – 1, Electronic City Post Office, Bangalore – 560100, Karnataka” for verification. The ITR processing takes plae only if its verification is completed.",
        ],
      },
    ],
  },
};

export const bankingDatabase = {
  "Mudra Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fmudra.jpg?alt=media&token=a7fe668d-c507-440f-9c63-109836c78c1e",
    head: "Mudra Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Valid photo identity proof",
      "Trade references",
      "Current address proof",
      "Ownership proof of residence/office",
      "Proof of continuity of business",
      "Loan application form",
      "Last 6 months Bank statement",
      "Proof of income - Latest ITR Financial Docs of Income",
    ],
    description: [
      {
        title: "What is MUDRA Loan?",
        para: [
          "Micro-Units Development and Refinance Agency (MUDRA) is a scheme offered under the Pradhan Mantri MUDRA Yojana (PMMY). Launched in 2015, the scheme allows you to avail of business loans of up to INR 10,00,000 based on the different categories of MUDRA Loan schemes.",
          "All public sector banks, regional rural banks, co-operative banks, private sector banks, micro finance institutions, non-banking finance companies (NBFC), and foreign banks can lend up to INR 10 Lakhs to small borrowers for non-farming businesses.",
        ],
      },
      {
        title: "Types of MUDRA Loans",
        para: [
          "The government has categorised the Mudra loans into three types based on the funding needs of the micro-unit or the entrepreneur. The loans are categorised as under:",
          "- MUDRA Shishu Loan: Up to INR 50,000 for non-farming ventures with features like a repayment tenure of up to 7 years, no minimum loan amount, no collateral, and zero processing charges.",
          "- MUDRA Kishor Loan: Ranging from INR 50,000 to INR 5,00,000, suitable for various businesses, including personal service providers like local grocers, salons, and more.",
          "- MUDRA Tarun Loan: Offering loan amounts from INR 5 Lakhs to INR 10 Lakhs, with a 3 to 5 year repayment period, catering to both aspiring and established businesses.",
        ],
      },
      {
        title: "List of Business Activities Covered Under MUDRA Loans",
        para: [
          "MUDRA Loans cover a wide range of business activities, including but not limited to:",
          "- Transport Vehicles: Purchase of vehicles for commercial use.",
          "- Community, Social and Personal Services: Loans for businesses like tailor shops, salons, courier services, etc.",
          "- Food Producing Sectors: Support for small-scale food production and related businesses.",
          "- Textile Production: Assistance for textile-related businesses.",
          "- Traders and Shopkeepers: Financial support for shop owners and traders.",
          "- Equipment Finance Scheme for Micro Units: Loans for purchasing machinery for micro-enterprises.",
          "- Agriculture-allied Activities: Funding for activities allied to agriculture, such as fish farming and beekeeping.",
        ],
      },
      {
        title: "Benefits of MUDRA Loan",
        para: [
          "MUDRA Loan offers several benefits including:",
          "- Accessibility: Available to individuals in both rural and urban areas.",
          "- No lower limit on the business size: Supports small and micro businesses, along with startups.",
          "- Higher Loan Amounts: Loans range up to INR 10,00,000, offering flexibility in loan amounts.",
          "- No Collateral Needed: No need for collateral to avail of the loan.",
          "- Credit Guarantee: The 'Credit Guarantee Fund for Micro Units' provides additional security to lending institutions.",
          "- Initial funding to start a new business: Supports low-cost businesses and startups.",
          "- Affordable Interest Rates: Comes with affordable interest rates for flexible repayment.",
          "- Extended Repayment Tenure: Allows for longer repayment tenures of up to 7 years.",
          "- Promote local Indian businesses: Supports 'Make In India' campaign and boosts start-ups.",
          "- MUDRA Card: Provides a Mudra Card for online and offline transactions.",
        ],
      },
      {
        title: "Eligibility Criteria & Documents Required",
        para: [
          "To apply for a MUDRA Loan, you need to meet the following eligibility criteria:",
          "- Indian citizen",
          "- Age between 18 and 65 years at the time of final EMI repayment",
          "- Planning to start a business in non-agricultural activities requiring a loan amount less than INR 10 Lakhs.",
          "Required documents for MUDRA Loan application:",
          "- Duly filled Mudra Loan application form.",
          "- Identity proof documents like Aadhaar/PAN/Driving License/Passport/Voter ID/Government-issued photo ID of all applicants (in case of joint loans).",
          "- Residence proof documents like the latest utility bill/Aadhaar/Voter ID/Passports/bank account statement of all applicants (in case of joint loans).",
          "- Business ID and Address proof documents (Licenses/Registration Certificates/Deed Copy, etc.).",
          "- Latest photographs of the applicant/s.",
          "- Proof of minority, if any.",
          "- Proof of loan requirement, i.e., equipment quotations, vendor details, etc.",
        ],
      },
      {
        title: "How to Apply for a Mudra Loan?",
        para: [
          "You can apply for a Mudra Loan both online and offline at nationalized and Private sector banks. Follow these steps:",
          "- Fill out the loan application form, available on the bank’s website or their nearest branch.",
          "- Provide details about the applicable Mudra Loan category, your business enterprise, and the funds required.",
          "- The bank will verify your loan application, and upon approval, credit the funds into your Mudra Loan account along with a Mudra Debit Card.",
        ],
      },
    ],
  },
  "MSME Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fmsme.jpg?alt=media&token=54b22629-8c36-4c4e-8b04-6d4dc3bc4b85",
    head: "MSME Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Application Form",
      "Passport Size Photo",
      "PAN Card",
      "Aadhar Card",
      "Voter ID Card",
      "Driving License",
      "Utility Bills",
      "Ration Card",
      "Trade License",
      "Lease Agreement",
      "Sales Tax Agreement",
    ],
    description: [
      {
        title: "What is a Business Loan for MSMEs?",
        para: [
          "A Business Loan for MSMEs is a loan obtainable by any MSME business to expand and purchase their business. It can be used for various purposes including purchasing machinery and equipment, meeting working capital requirements, investment in fixed assets, payment of rent, salaries, daily or monthly expenses, managing cash flows, and other financial provisions for operational purposes.",
          "These loans are offered at exceptionally competitive interest rates and flexible tenures. However, interest rates may vary from one bank to another. Eligible enterprises for this type of loan scheme include partnerships, sole proprietorships, manufacturing units, and service-based micro and small enterprises. Retail trader segments, training or educational institutions, agricultural, and self-help groups are not eligible for a Business Loan for MSME.",
        ],
      },
      {
        title: "MSME Definition Change (May 2020)",
        para: [
          "In May 2020, the Government of India altered the definition of MSME, making it uniform for both manufacturing and services enterprises. The criterion shifted from investment to investment and annual turnover. The limits for each segment were also increased, allowing more enterprises to avail the MSME Scheme.",
          "Manufacturing and Services Enterprises:",
          "- Micro: Investment value should not exceed Rs. 1 crore, and annual turnover should not exceed Rs. 5 crores.",
          "- Small: Investment amount should not exceed Rs. 10 crores, and annual turnover should not exceed Rs. 50 crore.",
          "- Medium: Investment value should not exceed Rs. 20 crore, and annual turnover should not exceed Rs. 100 crore.",
          "Depending on the category your business falls under, you can benefit from a Business Loan for MSME, which will be deposited into your business bank account or Current Account upon approval. Interest rates can start as low as 7.65%, and the loan sanction amount can begin as low as Rs. 50,000 with repayment tenures up to 15 years. Keep in mind that interest rates, tenures, and loan sanction amounts can vary between banks and financial institutions.",
        ],
      },
      {
        title: "Features and Benefits of Business Loans for MSME",
        para: [
          "A Business Loan for MSMEs offers several features and benefits:",
          "- Facilitates extensive credit flow in the MSME sector.",
          "- Allows businesses to upgrade their technology to meet small business sector requirements.",
          "- Offers comprehensive development competence for small-scale industries through skill development and training packages.",
          "- Available to all borrowers associated with small-scale industries such as Sole Proprietorship Firms, Partnership Firms, Private Limited Companies, and Public Limited Companies. Manufacturing or service-based micro and small enterprises are eligible, excluding retail traders, educational institutions, training institutions, agriculture, and Self Help Groups.",
          "- The tenure of a Business Loan for MSME is for a maximum period of 15 years.",
          "- Offered as secured and unsecured loans, with the choice depending on the borrower's repayment capacity and the terms and conditions of the bank.",
        ],
      },
      {
        title: "Usage of Business Loan for MSMEs",
        para: [
          "Business Loan for MSMEs can be used for a multitude of purposes, including:",
          "- Meeting working capital requirements",
          "- Enhancing the business",
          "- New and innovative business expansion",
          "- Management of business cash flow",
          "- Installation of new equipment and machinery",
          "- Procurement of business tools, vehicles, and other fixed assets",
          "- Development of inventory, such as raw materials or stock.",
        ],
      },
    ],
  },
  "CGTMSE Coverage Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fcgmtse.webp?alt=media&token=37ecc47d-b89d-4776-a23c-6843efe6cf74",
    head: "CGTMSE Coverage Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Compliance Document  - CGS II - NBFC",
      "Undertaking Format - CGS II - NBFC",
      "Forwarding Letter - CGS II - NBFC",
      "Application Form - CGS II - NBFC",
    ],
    description: [
      {
        title: "What is CGTMSE Coverage Loan",
        para: [
          'The transformative journey of CGTMSE has begun with a change of its logo. The new logo, a "colorful flying bird," depicts giving wings to the entrepreneurial zeal of millions of youth in this great country who have bankable business ideas but lack collateral security and/or third-party guarantees to access credit from formal sources. Here, CGTMSE extends a helping hand by providing a guarantee to enable them to access credit, leading to the establishment of viable micro and small enterprises. This transformation turns them from job seekers into job providers, contributing meaningfully to nation-building.',
        ],
      },
      {
        title: "Objective",
        para: [
          "The objective of CGTMSE is to make bank credit available without the hassles of collaterals or third-party guarantees. This support is crucial for first-generation entrepreneurs who aspire to set up their own Micro and Small Enterprises (MSEs). To achieve this objective, the Ministry of Micro, Small & Medium Enterprises (MSME), Government of India, launched the Credit Guarantee Scheme (CGS). The scheme aims to strengthen the credit delivery system and facilitate the flow of credit to the MSE sector. To operationalize the scheme, the Government of India and SIDBI (Small Industries Development Bank of India) established the Credit Guarantee Fund Trust for Micro and Small Enterprises (CGTMSE).",
        ],
      },
      {
        title: "Guarantee Commencement",
        para: [
          "The lender should cover the eligible credit facilities as soon as they are sanctioned. Guarantee will commence from the date of payment of the guarantee fee and shall run through the agreed tenure of the term credit in case of term loans / composite loans and for a period of 5 years where working capital facilities alone are extended to borrowers, or for such a period as may be specified by the Guarantee Trust in this behalf.",
        ],
      },
    ],
  },
  "Agri Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fagri.jpg?alt=media&token=d12c012b-65f7-49a8-9f17-fe18d6ed33cb",
    head: "Agri Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Address Proof",
      "Land ownership documents",
      "Income proof",
      "Crop details",
      "Identity proof",
    ],
    description: [
      {
        title: "Agriculture Loans: The Definition and Overview",
        para: [
          "Agriculture loans in India are offered to farmers for various purposes, like farming and irrigation equipment purchases, crops for cultivation, and other agriculture-associated activities. Besides farmers, these loans are also available to individuals engaged in other agri-related sectors like animal husbandry, horticulture, apiculture, aquaculture, silk farming, and floriculture.",
          "Agriculture loans are offered at lower interest rates compared to other loans available to individuals. The main objective of these loans is to provide a helping hand to farmers to meet their various cash needs.",
          "Here, in this guide, let’s take a closer look at the types of agriculture loans in India, how to apply, benefits, FAQs, and more.",
        ],
      },
      {
        title: "Benefits of Agriculture Loans",
        para: [
          "Competitive Interest Rates – One of the biggest benefits of agriculture loans is that the interest rates are low and start at just 8.80% per annum.",
          "Quick Processing – Most lenders process agriculture loan applications within a couple of working days. Once the loan is approved, the amount is disbursed to the borrower’s account within a few working days.",
          "Minimal Paperwork – Agriculture loans require minimum documentation. The borrower has to submit only a few primary documents like photo id proof, address proof, land ownership deed, etc.",
          "Flexible Repayment Tenure – Borrowers can repay the loan as per their convenience. There are both short-term and long-term loans for farmers.",
          "No Hidden Charges – Agriculture loans are highly transparent, and all the related fees are mentioned upfront. There are no other hidden charges.",
        ],
      },
      {
        title: "Kisan Credit Card (KCC) /Crop Loans",
        para: [
          "When it comes to agricultural activities, there are multiple short-term processes like the purchase of seeds, sowing, raising crops, weeding, etc. Generally, farmers don't have enough cash to meet these overheads. Hence, they approach banks frequently for short-term loans.",
          "To simplify the process, banks came up with an innovative lending scheme known as Kisan Credit Card or a crop loan. This scheme is used to finance seasonal and recurring short-term requirements.",
          "Notable Features of the KCC Scheme:",
          "All nationalised banks and some private banks offer the Kisan Credit Card.",
          "The loan can be availed by small farmers, sharecroppers, tenant farmers, SHGs, and others involved in agricultural activities.",
          "The amount of finance sanctioned for a farmer depends on several factors like – credit history of the farmer, farm assets, size of cultivated area, etc.",
          "The KCC card also offers personal accident insurance cover to the farmer and is valid for 5 years.",
          "Today, several banks offer the KCC card as a smart card that the farmer can also use to meet other expenses.",
          "The interest rate charged on the amount borrowed ranges from 3% to 7%. Farmers can enjoy lower interest rates when they repay the borrowed amount quickly.",
          "The documentation required for this scheme is minimal. It includes the duly-filled application form, ID proof, address proof, declaration of the crops raised, etc.",
          "No collateral is required for the KCC.",
        ],
      },
      {
        title: "Agriculture Term Loan",
        para: [
          "The term loan has a longer tenure than the crop loan. It's provided for larger agriculture-related expenditures like the purchase of machinery, pump-sets, upgrading the farm facilities, installation of solar power motors, etc.",
          "Notable Features of Agriculture Term Loans:",
          "The eligibility criteria vary from one bank to another. The RBI has notified banks to offer agriculture term loans to Rs. 1 lakh without any collateral. Term loans are provided to individual farmers, sharecroppers, groups of farmers, SHGs, etc.",
          "Banks have created various subcategories of agriculture term loans like horticulture loans, beekeeping loans, dairy farming loans, etc. to cater to niche sectors.",
          "The loan amount ranges from a few thousand rupees to tens of lakhs of rupees, depending on the lender, the creditworthiness and repayment capacity of the farmer.",
          "The tenure of agriculture term loans ranges from 3 to 15 years.",
          "The interest rates of agriculture term loans are competitive and less when compared to other loans in the market.",
        ],
      },
      {
        title: "Agriculture Working Capital Loans",
        para: [
          "As the name implies, these loans are offered to farmers and other individuals engaged in agri-related activities to meet their various working capital requirements. These loans are used to meet expenses that are not covered by the Kisan Credit Card scheme.",
          "Notable Features of Agriculture Working Capital Loans:",
          "Objective – To meet the working capital requirements of farmers.",
          "Loan Quantum – Higher than the amount offered in the KCC and lesser than term loans.",
          "Eligibility – Offered to farmers and others engaged in agri-related activities like seed sellers, fertiliser dealers, mechanised machinery sellers, etc.",
          "Note that some banks offer the facility to increase the amount provided in the KCC scheme, so that farmers can use it, instead of going for a working capital loan.",
        ],
      },
      {
        title: "Agriculture Gold Loans",
        para: [
          "Gold loans are one of the most availed loans in India, as Indian families own considerable gold ornaments, compared to other assets. Agriculture gold loans work the same way as regular gold loans. The only difference is that it’s offered only to farmers.",
          "Notable Features of Agriculture Gold Loans:",
          "Since it’s a secured loan, the interest rates are as low as 4% per annum.",
          "The loan tenure ranges from 1 month to 12/24 months, depending on the lender.",
          "Minimal documentation and hassle-free application process.",
          "Eligibility – Farmers and other individuals engaged in agriculture and agri-related activities.",
          "Banks offer loans up to 75 - 80% of the market value of the gold ornaments.",
        ],
      },
      {
        title: "Farm Mechanisation Loan",
        para: [
          "Farm mechanisation loans are offered to farmers to purchase/repair/upgrade farm machinery. Some banks provide general farm mechanisation loans, while others offer sub-category loans like drip irrigation loans, tractor loans, combine harvester loans, etc.",
          "Notable Features of Farm Mechanisation Loans:",
          "Eligibility – Offers to farmers who own land. Different banks have various eligibility clauses like Canara Bank offers tractor loans only to farmers who own at least 6 acres of perennially irrigated land or 12 acres of dry land.",
          "The loan amount ranges from Rs. 30,000 to Rs. 30 lakhs and varies from lender to lender.",
          "It can be either secured or unsecured, depending on the lender.",
          "The rate of interest starts from 10.5%.",
          "Besides these five major types of agriculture loans, there are several other categories like horticulture loans, forestry loans, etc.",
        ],
      },
      {
        title:
          "What are the eligibility criteria for agriculture loans in India?",
        para: [
          "Agriculture loans are offered to individual farmers, groups of farmers, tenant farmers, sharecroppers, oral lessees, and other individuals engaged in agri-related activities. The actual eligibility criteria depend on the type of loan and the lender.",
        ],
      },
      {
        title:
          "What are the documents required for agriculture loans in India?",
        para: [
          "The list of documentation required for agriculture loans is minimal. Most schemes need only the essential paperwork, to make the entire process hassle-free and convenient for farmers.",
          "The basic list of documentation include:",
          "Duly filled application form",
          "Photo identity proof",
          "Address proof",
          "Proof of land ownership, purchase receipts, etc",
        ],
      },
      {
        title: "Can agriculture loans be used to purchase land?",
        para: [
          "Yes. The purchased plot should be agricultural and located within a radius of 5 km of the farmer's house/village.",
        ],
      },
      {
        title: "Is collateral required for agricultural loans?",
        para: [
          "To reduce the burden of farmers, the government of India has relaxed the norms for agricultural loans. As per RBI norms, agrarian loans up to Rs. 1 lakh do not require any collateral or security. For loans above Rs. 1 lakh, the security requirements are set by individual lenders.",
        ],
      },
      {
        title: "Can women apply for agricultural loans?",
        para: [
          "Yes. The government of India encourages women to lead agricultural activities. As a result, some banks offer special discounts on interest rates and other benefits for women borrowers.",
        ],
      },
      {
        title: "How to apply for an agricultural loan in India?",
        para: [
          "Applying for an agricultural loan is quite easy, and the entire process is seamless and hassle-free. Lenders have a simple application method to make it easy for farmers so that they don't fall into the trap of informal moneylenders. Some banks even offer an online application process.",
          "The first step is to compare the features, eligibility requirements, and interest rates of different lenders, to pick the right one. Next, visit the nearest branch of your preferred lender. Make sure to carry the required documentation with you to quicken the application process.",
          "Once you submit the application form and the required documentation, the lender approves the loan. Once sanctioned, the loan amount is sent to the farmer's bank account or issued via a cheque.",
        ],
      },
      {
        title: "Can I pre-close an agriculture loan?",
        para: [
          "Yes, most lenders allow borrowers to foreclose the loan before the completion of the tenure. However, there may be pre-closure penalties. So, make sure to check the foreclosure terms and conditions before pre-closing the loan.",
        ],
      },
    ],
  },
  "Housing Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fhome_loan.avif?alt=media&token=17d21eb7-7ea7-4d55-8748-07fab77a7c70",
    head: "Housing Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Photo Id Proof",
      "Residence Proof",
      "Proof of Age",
      "ITR Verification report",
      "If any previous loan then loan a/c statement for last 1 year along with sanction letter",
      "Assets and Liabilities statement",
      "Proof of assets",
    ],
    description: [
      {
        title: "What is Home Loan in India?",
        para: [
          "As mentioned above, a Home Loan is a form of financial assistance extended by banks and financial institutions. Such banks or financial institutions can help increase your budget to purchase a house with the loan amount offered. You can avail of the loan by meeting certain Home Loan eligibility criteria for a specific tenure. You must return the loan amount borrowed over the course of the tenure along with interest according to predetermined interest rates. You repay the Home Loan in monthly instalments, just like you would repay any other loan. Today, most banks offer Home Loans that not only help you purchase ready-made homes but also facilitate the construction of a house from scratch. In addition, you can also seek Home Loans for renovation or repair purposes.",
        ],
      },
      {
        title: "What are the benefits of a Home Loan?",
        para: [
          "When you opt for a Home Loan, you can enjoy the following benefits:",
          "Taxation: A Home Loan allows you to claim income tax deductions on the interest and principal amount due. Under the Income Tax Act, 1961, as per Section 80C, you can claim up to INR 1.5 lakh on principal repayments, and up to INR 2 lakh on interest repayments under Section 24B. You can avail of other tax benefits via a Home Loan, as well.",
          "Interest Rate: When compared to the various kinds of loans available, the interest rate on a Home Loan is relatively lower. Plus, in case of a cash crunch, you can also apply for a Top-Up Loan in addition to the existing Home Loan.",
          "Due Diligence: When you apply for a Home Loan, banks check the property from a legal standpoint and ensure that the documents are valid and the title is clear. This step can prevent you from being scammed and passing this due diligence can validate your property.",
        ],
      },
      {
        title: "What are some important factors to consider for a Home Loan?",
        para: [
          "Apart from knowing what is a Home Loan, it is important to know how to opt for the correct option among the various types of Home Loans offered. To apply for a Home Loan that will work best for your requirements you must consider the following factors:",
          "Interest Rate: Make sure to understand the kind of Home Loan interest rate you are being offered. A variable interest rate can vary during the tenure as opposed to a fixed interest rate that remains the same.",
          "Tenure: The tenure is an essential variable and decides the EMI amounts payable each month on the due date. Thus, knowing the exact tenure can help you plan your finances accordingly.",
          "Application: It helps to choose a Home Loan that has a simple application process without excessive documentation. Ideally, you should pick a Home Loan that allows for online application and quick disbursals.",
        ],
      },
    ],
  },
  "Car Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fcar_loan.jpg?alt=media&token=cc7e4a86-a9d2-417b-b4d1-8dc360e4898a",
    head: "Car Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Identity Proof",
      "Address Proof",
      "Bank statement of the previous 6 months",
      "Proof of Income",
    ],
    description: [
      {
        title: "What is a car loan?",
        para: [
          "A car loan is a borrowing instrument that a lender such as a bank or NBFC (non-banking financial company) provides an individual allowing him/her to purchase a car. Car loans have gained popularity in recent times as instead of being a luxury item or a symbol of status, cars have grown to become an essential commodity for the continuously growing middle class of India. With the increased popularity of car loans, most banks and NBFCs currently offer this type of loan to individuals who have a good credit history. One point to keep in mind regarding a car loan is the fact that this loan invests in a depreciating asset i.e. the asset (car) bought using this loan is, in fact, going to cost less over time.",
        ],
      },
      {
        title: "Can I get a loan to purchase a used car?",
        para: [
          "Yes, a car loan is offered for pre-used cars. But, the interest rate for such a loan would differ from that of a new car. However, the loan would only cover the price of the car itself, other costs such as transfer of registration, etc. will have to be borne by you.",
        ],
      },
      {
        title: "Do I need collateral for a car loan?",
        para: [
          "Just as the name suggests, a car loan is a loan granted to an individual interested in buying a car. Therefore a car loan is a secured loan where the car you buy acts as collateral. Therefore, there is no additional collateral requirement for a car loan. However, you do have to get the RC (registration certificate) of the car endorsed with the bank. This endorsement is canceled after repayment of the loan is completed.",
        ],
      },
      {
        title:
          "What are the benefits of applying for a car loan with Paisabazaar?",
        para: [
          "When you input your basic details into the Paisabazaar.com car loan eligibility tool, we provide you with all available options based on the information you provide us. After you get the complete list of lenders who might suit your specific requirement you can compare the various loan options based on tenure, interest rate, maximum loan amount as well as other factors. You can then go ahead and zero in on the offer that is best suited to your specific requirement. Paisabazaar streamlines the loan selection process, allowing you to derive the maximum benefits from your car loan.",
        ],
      },
      {
        title: "What is the maximum amount of loan that I can avail?",
        para: [
          "The maximum loan amount approved may vary from one bank to the other. Usually, banks approve loan amounts that range from 80%–90% of the car’s on-road price. Few banks even lend 100% of the car’s ex-showroom price. In addition to these criteria, the percentage of financing offered depends on the price, type of car (standard/ premium) and whether you are applying for a new or pre-owned car.",
        ],
      },
      {
        title:
          "What documents do I need to submit when applying for a car loan?",
        para: [
          "Like any other loan that you apply for, a car loan application requires self-attested supporting documents such as income (last three pay slips/last acknowledged ITR), address and identity proof documents along with your PAN card. Other documentation requirements, if any, tend to differ from one lender to another.",
        ],
      },
      {
        title: "What is the typical tenure of a car loan?",
        para: [
          "The tenure of a car loan ranges from 1 year to 5 years. The shorter the loan tenure, the higher is the EMI payable, and the reverse is true for longer car loan tenures. Currently, a small number of lenders are engaged in providing car loans for longer durations of up to 7 years.",
        ],
      },
      {
        title: "Is there a minimum salary requirement to apply for a car loan?",
        para: [
          "Though most lenders do not specify a minimum salary requirement, your loan application may be rejected in case your salary does not exceed a predetermined threshold level as required by the internal requirement of the bank. In such cases, applying with a co-borrower might be a better idea to increase your chances of successfully applying for a car loan.",
        ],
      },
      {
        title: "Do I need a loan guarantor or co-borrower?",
        para: [
          "A loan guarantor or a co-borrower is only required if you are unable to meet the eligibility criteria stated by the lending institution such as monthly income, age, or credit score. Otherwise, you can apply for a car loan on your own.",
        ],
      },
      {
        title: "In which cases can my car loan application be rejected?",
        para: [
          "Your loan application may be rejected if you have a bad credit score, have defaulted on your repayments or applied for and been rejected for loans multiple times, etc. Also, you should meet the bank’s eligibility criteria such as minimum income level, age, previous relationship with the bank, etc. to get your loan approved.",
        ],
      },
      {
        title: "Are there any tax benefits of a car loan?",
        para: [
          "No. Unlike a home loan, a car loan has no tax savings benefits whatsoever. Therefore, car loan amounts should always be tied to your actual requirement, and splurging on a fancy car by taking a large loan amount is not a good idea.",
        ],
      },
      {
        title:
          "What is the difference between a fixed rate and a floating rate car loan?",
        para: [
          "In case of a fixed rate car loan, the rate of interest applicable on the car loan remains the same over a period of time. Therefore, fixed rate car loans have a specific EMI payout requirement that does not change due to market conditions. In case of a floating rate car loan, the applicable interest rate of the car loan varies periodically as per the decision of the lender. Thus the payable EMI on a floating rate loan may change from time to time.",
        ],
      },
      {
        title: "Can interest rates be negotiated?",
        para: [
          "Yes, you can negotiate the interest rate applicable to your car loan by leveraging your credit history and prior relationship with the prospective lender. The regular payment of EMIs for previous loan/credit cards you had/have with your prospective lender can fetch you a car loan at a preferred (lower) interest rate.",
        ],
      },
      {
        title: "How does the implementation of MCLR change the car loan EMI?",
        para: [
          "As per the RBI directive, from April 2016 onwards, banks are using MCLR (marginal cost of lending rate) to fix the interest rate on various loans including car loans. Currently, the MCLR-based car loan rates are slightly lower than the base rate method that banks used earlier. However, changes in CRR, margin, bank operating expenses, and deposit interest rates will change the MCLR and by extension the interest rates offered on car loans. Moreover, as per the new regulations, banks are required to revise their loan interest rates every 6 months or on a yearly basis.",
        ],
      },
      {
        title: "Can I get financing for insurance and registration?",
        para: [
          "Car loans do not cover the insurance or registration fees that you have to pay at the time of buying the vehicle. Car insurance, which is mandatory, needs to be purchased separately, and all vehicle registration-related costs also have to be borne by you as they are not covered by your car loan. However, there are few banks that cover these costs under special schemes.",
        ],
      },
      {
        title: "Can I pre-pay my car loan?",
        para: [
          "Pre-payment of car loans is allowed by many lenders, however, there are usually a few terms and conditions attached. For starters, the lenders only allow car loan repayment after you have completed a specified loan tenure and there is usually a pre-payment penalty as well. The prepayment penalty charges usually range from 1%-4% of the loan amount. It is recommended that you confirm all relevant charges with a bank before you make a pre-payment.",
        ],
      },
      {
        title:
          "Can I sell my car before repayment of the car loan is completed?",
        para: [
          "No. Lenders who provide car loans to individuals only allow the car to be sold to a new owner when the loan has been paid off in full. This is because, you need to get a NOC from the bank before you can sell your car, and the document is released only after you have paid off the car loan in full.",
        ],
      },
      {
        title: "How can I make my loan EMI payments?",
        para: [
          "Repayment of the loan can be done through post-dated cheques (PDC) provided by you to your lender when signing up for the car loan. The other option is an auto-debit facility where the EMI is automatically debited from your savings account after you have provided an ECS (Electronic Clearing Service) mandate to the lender.",
        ],
      },
      {
        title: "What if I don’t pay EMIs on time?",
        para: [
          "Generally, banks treat you as a defaulter if you fail to pay two or more EMIs in a timely manner. You will initially be charged a penalty fee and asked to regularize your payments. If you fail to regularize your payments after repeated notifications, the financial institution can legally repossess your vehicle. Subsequently, your credit score will also take a hit and in the future, you will face complications in future loan approvals.",
        ],
      },
      {
        title: "What should I do after paying the last EMI?",
        para: [
          "Once you have paid off your loan in full, the bank issues Form 35 & NOC (No Objection Certificate) to the RTO (Road Transport Office) and the lender’s name will get removed from the RC book endorsement.",
        ],
      },
      {
        title: "Should I take a loan from the bank where I have an account?",
        para: [
          "Instead of going to separate banks in order to find the best car loan deal, log on to Paisabazaar.com and input your basic information. Within minutes you will have a range of options at your fingertips and you can compare available options to zero in on the one that’s best for you. Moreover, it is not mandatory to have an account with the bank which you approach for a loan. You can quite certainly opt for a loan with the bank that offers you the lowest interest rate. But, availing a loan from a bank or a lender you have previous experience with may help you get hassle-free approval, faster processing, waiver of processing fees, etc.",
        ],
      },
      {
        title: "What is zero percent Financing?",
        para: [
          "Zero percent financing is an offer provided by a few automobile manufacturers in collaboration with the lender. Under this plan, the automobile manufacturer is responsible for paying back the interest on the car loan to the financier instead of the borrower.",
        ],
      },
      {
        title:
          "Why are zero percent financing car models more expensive than the other ones?",
        para: [
          "As the manufacturer is liable to pay the interest component of the car loan, the manufacturer would have to maintain a higher profit margin on the sale. That is the reason why the cost of zero financing car models is usually greater than the cost of car models that do not have this feature.",
        ],
      },
      {
        title:
          "Are there any special terms and conditions associated with the zero percent financing option?",
        para: [
          "There are usually quite a few terms and conditions in case of zero financing options that are not usually applicable in case of a standard car loan. For example, the usual tenure of a zero financing loan ranges from 12 months to 15 months hence your premium payments will be much higher than that in case of a standard car loan. Additionally, you often have to pay a much larger down payment amount as compared to the standard car loan, which is bound to stretch your monthly budget.",
        ],
      },
      {
        title: "What are some of the top car loan providers in India?",
        para: [
          "Car loans are provided by most traditional banks including HDFC Bank, SBI, ICICI Bank, Union Bank, Kotak Bank, Axis Bank and many others. You can easily apply for a car loan with these as well as many others banks through Paisabazaar.",
        ],
      },
      {
        title:
          "Can I take a car loan from lenders other than traditional banks?",
        para: [
          "Yes. Through Paisabazaar you can apply for a car loan with traditional banks as well as with NBFCs. You can take a car loan from a number of NBFCs (non-banking financial companies) that offer car loans to individuals. Some of the leading NBFCs that provide car loans to prospective car purchasers include Mahindra Finance, L&T, AU Financiers, Bajaj Allianz, Sundaram Finance, Reliance Consumer Finance and others. Car loan offers from NBFCs are often as competitive as those offered by traditional banks.",
        ],
      },
    ],
  },
  "Mortgage Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fmortgage.png?alt=media&token=e226f03c-d2e4-4877-9e91-55d67edd081c",
    head: "Mortgage Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Telephone Bill",
      "Voter's ID Card",
      "Electricity Bill",
      "Employer's Card",
      "Ration Card",
    ],
    description: [
      {
        title: "What is Mortgage Loan?",
        para: [
          "Mortgage Loans are loans granted to you against collaterals that are substantially valued – typically one’s property. These loans are secured in nature and offer benefits, such as sizeable loan sanctions, competitive interest rates, and extended repayment tenors. In the case of a mortgage loan, the property ownership remains with the lender throughout the duration of the loan. Once you have repaid the loan with interest, the ownership transfers to you. Mortgage loans can be a handy solution to many big-ticket expenses, such as buying a new house, bearing the costs of unforeseen medical bills, or even paying the tuition for your child’s overseas education.",
        ],
      },
      {
        title: "Meaning Of Mortgage Loan?",
        para: [
          "When you seek a mortgage loan, you effectively receive the loan amount against the security of a property you have mortgaged as collateral. You can pledge a commercial or residential property and receive a sizeable sanction based on your property’s value and financial profile. The mortgage loan sanction is repaid in the form of EMIs (equated monthly instalments) that have two components: 1. The Principal Amount: This amount is your original loan ask and what the lender disburses into your account. 2. The Loan Interest: This amount is the cost of borrowing the principal amount, usually charged per the compound interest format. However, you may also prepay parts of your loan ahead of time or even foreclose your loan before your repayment tenor ends. If you are an individual borrower with a floating interest loan, you enjoy these privileges at zero additional costs – boosting the savings you make on your mortgage loan.",
        ],
      },
      {
        title: "Types Of Mortgage Loan?",
        para: [
          "Listed below are the popular types of mortgage loans that are available to interested borrowers in India. 1. Home Loan: A Housing Loan is a secured loan through which you borrow a sum to purchase a property from a developer, individual, or an entity. You may even receive a sanction to facilitate the repurchase of an old property. For new constructions, loans are offered for ready for possession properties and even ones that are under construction. 2. Loan Against Commercial Property: A Loan Against Commercial Property is a secured loan that allows you to mortgage your commercial property for a sizeable sum. The sanction can be used to address a variety of expenses as the amount comes with no end-use limitations. 3. Loan Against Residential Property: Similarly, a Loan Against Residential Property allows you to mortgage your residential property for a sizeable loan sanction with no restrictions on its end-use. 4. Lease Rental Discounting: Lease Rental Discounting is a credit tool sanctioned against income-producing commercial property.",
        ],
      },
      {
        title: "Features Of Mortgage Loan?",
        para: [
          "Following are some of the features and benefits of a mortgage loan. - Reasonable Rate of Interest: Mortgage loan interest rates are typically competitive and reasonable. Lenders consider factors such as the borrower's credit score, income, and property value while determining the interest rate. Securing a mortgage loan with a favorable interest rate ensures manageable monthly repayments and overall cost savings. - High-Value Funding: Mortgage loans generally offer high-value funding, which means borrowers can finance a significant portion of the property's value through the loan. - Long Tenor: Mortgage loans typically come with long repayment tenors, often ranging from 15 to 30 years. The extended repayment period helps in reducing the burden of high monthly installments. - Easy Balance Transfer with Top-up: Mortgage loans often allow borrowers a Loan Against Property Balance Transfer facility to another lender offering better terms and interest rate.",
        ],
      },
      {
        title: "Mortgage Loan Eligibility for Salaried Applicants",
        para: [
          "Nationality: Resident of India with property in the locations we operate. Age: 28 to 58 years. Employment: Employed in private, public, or MNC.",
        ],
      },
      {
        title: "Mortgage Loan Eligibility for Self-Employed Applicants",
        para: [
          "Nationality: Resident of India with property in the locations we operate. Age: 25 to 70 years. Employment: Should demonstrate a steady source of income with required business vintage in the existing venture.",
        ],
      },
      {
        title: "How to Apply for Mortgage Loan?",
        para: [
          "Step 1: Fill the Application Form To begin the mortgage loan process, you need to complete an application form. Depending on the lender, this can be done either at a physical branch or online. Online options are typically more convenient. The application form will typically require you to provide the following information: 1. Personal details 2. Employment details 3. Income information 4. Loan requirements Step 2: Await Loan Processing After you have submitted the loan application form, the lender will review your eligibility for the mortgage loan. They may offer you terms based on your eligibility, or they might suggest adding a co-applicant to increase your eligibility. Step 3: Document Submission Once the initial loan processing is complete, you will need to submit the required documents. Here is a general list of documents typically needed for loan processing: 1. KYC (Know Your Customer) documents 2. Property documents 3. Income documents Step 4: Loan Verification Once you have submitted the documents, the lender will begin the verification process, which includes technical and legal checks. A property evaluation will be conducted to ensure the authenticity of the property's title. Based on these assessments, the lender will confirm loan approval. At the final stage, the lender will provide a sanction letter, which confirms the approved terms of the loan. After receiving the sanction letter, you will be authorized to proceed with the loan disbursal based on the approved terms.",
        ],
      },
    ],
  },
  "Personal Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fpersonal.jpg?alt=media&token=faae1b64-6f5e-40a1-a5e9-01aeb164debf",
    head: "Personal Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Proof of Identity",
      "Proof of Residence",
      "Latest 3 months Bank Statement",
      "2 Passport Size photographs",
      "Salary slips for last 3 months.",
    ],
    description: [
      {
        title: "What is a personal loan?",
        para: [
          "A personal loan is a loan that does not require collateral or security and is offered with minimal documentation.",
          "You can use the funds from this loan for any legitimate financial need. Like any other loan, you must repay it in accordance with the agreed terms with the bank. Normally, this can include a few months to a few years in easy equated monthly installments.",
        ],
      },
      {
        title: "What is a personal loan used for?",
        para: [
          "You are free to use the funds you get from a personal loan any way you wish – fund a holiday, buy a gadget, pay for medical treatment, use it for home renovation, spend on a wedding, finance your children’s education, etc.",
        ],
      },
      {
        title: "How do personal loans work?",
        para: [
          "A personal loan works pretty much the same way as most loans. You apply for a loan, submit the documents, the bank checks your creditworthiness and makes a loan offer. If you accept it, the funds are transferred to your bank account, and you can use them any way you like.",
          "You must repay the loan in equated monthly installments (EMI), which will depend on factors such as loan amount, tenure, and interest rate.",
        ],
      },
      {
        title: "How quickly can I get a personal loan?",
        para: [
          "You can get a personal loan very quickly. HDFC Bank offers pre-approved personal loans to its customers in 10 seconds*. Others can get funds in as little as 4 hours*.",
        ],
      },
      {
        title: "What about interest rates, tenure, etc.?",
        para: [
          "Interest rates on personal loans: Interest rates can range and will depend on your credit history, tenure, income, occupation, etc. The rates are fixed and not floating rate.",
          "How to get a personal loan: Getting a personal loan from HDFC Bank is easy, especially if your documents are in order and you have a good credit track record. It helps if you are an existing customer. You can apply for a loan via NetBanking, on the HDFC Bank website, at an ATM, or by visiting a branch near you.",
          "Tenure and repayment: You can get a loan for a tenure that suits your needs. You must repay the loan in equated monthly installments or EMIs in a fixed sum every month. The EMI will depend on the loan amount, tenure, and interest rate.",
        ],
      },
    ],
  },
  "Business Loan": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Fbusiness_loan.webp?alt=media&token=1285f907-e77b-436e-981e-0eb050ccfc66",
    head: "Business Loan",
    msme: "",
    regular: "",
    documentsRequired: [
      "Entity proof",
      "Address Proof of Entity",
      "Last 3 years Audited/Provisional Financials",
      "Current year performance",
      "Last 1 year Income Tax returns",
      "Latest Bank Statements for last 6 months",
      "Copy of PAN Card",
    ],
    description: [
      {
        title: "What is a Business Loan? Meaning & Definition",
        para: [
          "A Business Loan is an amount of cash lent by a financial institution to a business for economic goals such as extra investment, expansion, and so on. This sort of financing helps businesses meet their immediate expansion and growth necessities.",
          "Business Loan definition can be best explained in this way, ‘funds availed for sustaining and expanding business operations, products, and services.’",
        ],
      },
      {
        title: "Why do you need a Business Loan?",
        para: [
          "A Business Loan from Poonawalla Fincorp can help your business expand. You can use the money toward business equipment, developing your infrastructure or operations, hiring additional people, or managing day-to-day cash flow. Business Loans can help you keep your company running smoothly while also taking advantage of market opportunities. India's small and medium businesses continue to rely on the banking sector.",
        ],
      },
      {
        title: "MSME Loans",
        para: [
          "The ongoing search for sources of working capital is one of the most worrying areas for an entrepreneur's growth. Finding means to raise finance for a small or medium-sized business (MSMEs) with few assets or collateral can be challenging.",
          "MSME Loans, also known as Micro, Small, and Medium Enterprise Loans, are loans you can get if you run a company or are an entrepreneur. This form of loan gives you working capital that you can use to buy new goods, acquire new equipment, pay your employees' salaries, or expand your business. MSME loans are available from a variety of lenders in India. Interest rates vary from lender to lender.",
          "Micro",
          "Investment is limited to Rs.1crore, while revenue is limited to Rs.5crores.",
          "Small",
          "Investment is limited to Rs.10 crores, while revenue is limited to Rs.50 crores.",
          "Medium",
          "Investment is limited to Rs.20 crores, while revenue is limited to Rs.100 crores.",
          "Interest rates on MSME loans begin at 7.65% per annum. Depending on the lender, the loan amount might range from Rs.50,000 to several crores, and the loan repayment period can be as long as 15 years.",
        ],
      },
      {
        title: "How to get a Business Loan under MSME?",
        para: [
          "To answer the basic question, ‘How can I get a Business Loan?’ begin with assessing your eligibility for a loan.",
          "Check Your Loan Eligibility",
          "You can apply for a loan if you are one of the following individuals:",
          "Self-employed",
          "Proprietors",
          "Private limited companies that are involved in the business of manufacturing",
          "Traders",
          "Partnership firms",
          "People dealing in services",
          "To make the procedure go smoothly, keep the following general loan eligibility conditions in mind:",
          "At the time of applying for the loan, you must be at least 22 years old.",
          "At the time of maturity, you should not be older than 62 years old.",
          "Your company must have been in operation for a minimum of 3 years, and you must have a minimum annual turnover of Rs.20 lakhs.",
        ],
      },
      {
        title: "Steps to Apply for a Business Loan:",
        para: [
          "Step 1: Log on to Poonawalla Fincorp’s website",
          "Step 2: Navigate to the Business Loans Page",
          "Step 3: Click on the Apply Now button",
          "Step 4: Register for an account with your basic details",
          "Step 5: Fill in the application form with your financial information and requirements.",
          "Step 6: Upload the required documents",
          "Step 7: Click on Submit",
          "Our representatives will get in touch with you to take the process forward.",
        ],
      },
      {
        title: "How Can You Ensure That Your Loan Is Approved?",
        para: [
          "To be eligible for a Business Loan, you must be able to demonstrate your ability to repay the loan. In addition to this, you need to be fully informed about what is a Business Loan in India. Here are a few suggestions for how to get it right.",
          "#1 Maintain a good credit rating.",
          "A credit score is an indicator of creditworthiness. A high credit score indicates that you are financially responsible and unlikely to default on your payments. It also demonstrates your ability to make wise financial decisions.",
          "The company's loan eligibility is influenced by your or the founders' credit score, as well as the company's payment history compiled from numerous institutions. A credit score of 700 or more will boost your creditworthiness and, as a result, your loan worthiness.",
          "#2 Show a healthy cash flow in the company.",
          "The lender will assess your company's cash flow projections to check if it is sufficient for the loan size you are looking for. Previous tax returns and other financial documents will also be analyzed. So, ensure there is sufficient cash flow within the company.",
          "#3 Keep your loan application documents ready",
          "Ensure that you compile all required paperwork to simplify the loan application process and make things easier for you and the lender.",
          "The following are some requisite verification documents:",
          "Proof of Identity & Address",
          "Proof of business",
          "Business and personal tax return records",
          "Business’s financial statements",
          "Business’s legal documents",
        ],
      },
      {
        title: "Do’s and Don’ts when applying for an MSME loan online",
        para: [
          "Here are some things to take care of when applying for an MSME loan. Continue reading to increase your chances of securing a loan for your business while lowering your risk of rejection.",
          "Do’s",
          "Calculate your loan requirements",
          "Check your credit score",
          "Collect all required documents in digital format",
          "Keep a loan repayment strategy ready",
          "Don’ts",
          "Do not apply with multiple lenders, as it will reduce your credit score by a few points.",
          "Do not delay your repayments for existing debts, as it will impact your credit score and financial goodwill.",
        ],
      },
    ],
  },
  "CC & OD Limit": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/banking%2Finvestopedia.jpg?alt=media&token=bda9d779-f69d-40d4-acb4-f26b5a6a2b05",
    head: "CC & OD Limit",
    msme: "",
    regular: "",
    documentsRequired: [
      "Residence Proof",
      "Bank Statement - Last 6 months",
      "Collateral Security",
    ],
    description: [
      {
        title: "What are cash credit and overdraft?",
        para: [
          "Cash Credit is a short-term loan offered to companies, businesses, and financial institutions for their working capital requirements. On the other hand, an Overdraft Facility refers to the credit funding offered by banks to individuals and companies. It enables them to withdraw money from banks where they have accounts, even if their account balance is not enough, zero, or negative.",
          "Both cash credit and overdraft have certain similarities between them. For instance, the interest rate is charged on the money utilised, not the amount sanctioned for both. Plus, to avail either, you must provide security of current assets. The loan amount offered remains fixed, and additional money is not provided in either case. Now that you know more about the two let’s look at the difference between cash credit and overdraft.",
        ],
      },
      {
        title: "Overdraft vs cash credit: what is the difference?",
        para: [
          "Cash credit and overdraft differ from each other in the following aspects:",
          "Interest rates: While you can avail of funds at a lower interest rate with cash credit, the rates are higher with an Overdraft facility.",
          "Security: To avail of cash credit, you must hypothecate your stocks and inventory. In contrast, overdrafts can be availed based on your financials, credit history, investments, relationship with the bank, etc.",
          "Purposes: Cash credit is usually available for business purposes, especially as working capital. Overdrafts can be availed for any general purposes, including business-related expenses.",
          "Loan amount: With cash credit, the loan amount sanctioned is based on the volume of stocks and inventory. On the other hand, the loan amount sanctioned under overdraft is based on financials and security deposits.",
          "Limit: While the limit does not reduce with cash credit, it reduces every month with an overdraft.",
          "Account: To avail of cash credit, you must open a new bank account, whereas you can access overdraft via an already existing account.",
          "Tenure: You can avail of cash credit for a minimum of 1 year, whereas the tenure of overdraft ranges from a month, a quarter to a maximum of 1 year.",
          "Applicants: Cash credit can be availed by individuals, traders, manufacturers, retailers, distributors, partnerships, companies, sole proprietorships, LLPs, etc. On the other hand, overdrafts can be applied for by account holders of the respective banks.",
        ],
      },
      {
        title:
          "Now that you know the difference between cash credit and overdraft...",
        para: [
          "Now that you know the difference between cash credit and overdraft, you must also keep certain other points in mind. Take a look at the processing fees charged by the lender for both. Similarly, take a look at the interest rates and the limit placed on loan amount utilisation. Certain banks do charge extra on the unutilised loan amount after a specific time period. Lastly, also make sure to find out if your bank levies any foreclosure charges if you want to close the amount.",
          "Looking at the difference between cash credit and overdraft, it will become easier for you to decide which option suits your business requirements better.",
          "HDFC Bank recognises the challenges entrepreneurs face while acquiring capital. Thus, HDFC Bank has created MyBusiness, a one-stop solution that gives you easy access to loans, digital solutions and provides you with the essential knowledge you need to run your business. With HDFC Bank MyBusiness, you can scale up, expand your operations, and nurture your business.",
        ],
      },
    ],
  },
};

export const gstDatabase = {
  "GST Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/gst%2FGST-registration-Part-A.webp?alt=media&token=d3b67239-116b-42b0-abeb-b0f7b111da04",
    head: "GST Registration",
    msme: "GST Registration including Government fee for individuals and SMEs.",
    regular:
      "GST Registration including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Electricity Bill",
      "Telephone Bill",
      "Property Tax Receipt",
      "Rent Agreement",
      "Passport Size Photo",
      "Partnership Deed",
      "Incorporation Certificate",
      "PAN Card",
      "Aadhar Card",
      "Consent Letter",
    ],
    description: [
      {
        title: "What is a GST Registration?",
        para: [
          "GST Registration of a business with the tax authorities implies obtaining a unique, 15-digit Goods and Service Tax Identification Number (GSTIN) from the GST authorities so that all the operations of and the data relating to the business can be collected and correlated. In any tax system this is the most fundamental requirement for identification of the business for tax purposes or for having any compliance verification program.",
        ],
      },
      {
        title: "What is advantage of taking registration in GST?",
        para: [
          "Registration under Goods and Service Tax (GST) regime will confer following advantages to the business:",
          "• Legally recognized as a supplier of goods or services.",
          "• Proper accounting of taxes paid on the input goods or services which can be utilized for payment of GST due on supply of goods or services or both by the business.",
          "• Legally authorized to collect tax from his purchasers and pass on the credit of the taxes paid on the goods or services supplied to purchasers or recipients.",
          "• Getting eligible to avail various other benefits and privileges rendered under the GST laws.",
        ],
      },
      {
        title:
          "Can a person without GST registration claim ITC and collect tax?",
        para: [
          "No, a person without GST registration can neither collect GST from his customers nor can claim any input tax credit of GST paid by him.",
        ],
      },
      {
        title: "What will be the effective date of registration?",
        para: [
          "Where the application for registration has been submitted within thirty days from the date on which the person becomes liable to registration, the effective date of registration shall be the date on which he became liable for registration.",
          "Where an application for registration has been submitted by the applicant after thirty days from the date of his becoming liable to registration, the effective date of registration shall be the date of grant of registration.",
          "In case of a person taking registration voluntarily while being within the threshold exemption limit for paying tax, the effective date of registration shall be the date of order of registration.",
        ],
      },
      {
        title: "Do I need to register under the GST Act?",
        para: [
          "Registration under the GST Act is mandatory if your aggregate annual PAN-based turnover exceeds INR 40,00,000 (Rupees Forty Lakhs) however the threshold for registration is INR 20,00,000 (Rupees Twenty Lakhs) if you have a place of business in Arunachal Pradesh, Himachal Pradesh, Jammu & Kashmir, Manipur, Meghalaya, Mizoram, Nagaland, Sikkim, Tripura, or Uttarakhand.",
          "Regardless of your turnover, registration is mandatory if",
          "- You make Inter-State Supplies",
          "- You supply goods through an E-commerce portal",
          "- You are a/an",
          "o Agent for Registered Principal",
          "o Liable to Pay Reverse Charge",
          "o Non-resident Taxable Person",
          "o Casual Taxable Person",
          "o Input Service Distributor",
          "o TDS/TCS Deductor",
          "o E-commerce Operator",
          "o An online data access and retrieval service provider",
        ],
      },
      {
        title: "What is aggregate turnover?",
        para: [
          "As per section 2(6) of the CGST/TSGST Act “aggregate turnover” includes the aggregate value of:",
          "(i) all taxable supplies,",
          "(ii) all exempt supplies,",
          "(iii) exports of goods and/or service, and,",
          "(iv) all inter-state supplies of a person having the same PAN.",
          "The above shall be computed on all India basis and excludes taxes charged under the CGST Act, TSGST Act, UTGST Act, and the IGST Act. Aggregate turnover shall include all supplies made by the Taxable person, whether on his own account or made on behalf of all his principals.",
          "Aggregate turnover does not include the value of supplies on which tax is levied on reverse charge basis, and value of inward supplies.",
          "The value of goods after completion of job work is not includible in the turnover of the job-worker. It will be treated as supply of goods by the principal and will accordingly be includible in the turnover of the Principal.",
        ],
      },
      {
        title:
          "If a person is operating in different states, with the same PAN number, whether he can operate with a single Registration?",
        para: [
          "No. Every person who is liable to take a Registration will have to get registered separately for each of the States where he has a business operation and is liable to pay GST in terms of Section 22(1) of the CGST/TSGST Act.",
        ],
      },
      {
        title: "Can a person obtain multiple registrations in a State?",
        para: [
          "Yes. In terms of the proviso to Sub-Section (2) of Section 25, a person having multiple places of businesses in a State or UT may obtain a separate registration for each such place of business, subject to such conditions as prescribed in the registration rules.",
        ],
      },
      {
        title:
          "Is there a provision for a person to get himself voluntarily registered though he may not be liable to pay GST?",
        para: [
          "Yes. In terms of Section 25(3), a person, though not liable to be registered under Section 22 or section 24 may get himself registered voluntarily, and all provisions of this Act, as are applicable to a registered taxable person, shall apply to such person. The person, once registered, will have to pay GST irrespective of his aggregate turnover.",
        ],
      },
      {
        title:
          "Is possession of a Permanent Account Number (PAN) mandatory for obtaining a Registration?",
        para: [
          "Yes. As per Section 25(6) of the CGST/SGST Act every person shall have a Permanent Account Number issued under the Income Tax Act,1961(43 of 1961) in order to be eligible for grant of registration.",
          "However as per the proviso to the aforesaid section 25(6), a person required to deduct tax under Section 51, may have, in lieu of a PAN, a Tax Deduction and Collection Account Number issued under the said Income Tax Act, in order to be eligible for grant of registration.",
          "Also, as per Section 25(7) PAN is not mandatory for a non-resident taxable person who may be granted registration on the basis of self-attested copy of valid passport.",
        ],
      },
      {
        title: "What are the prerequisites for registration on the GST Portal?",
        para: [
          "To apply for a new registration, you must have",
          "- PAN card/details of your business",
          "- Valid and accessible e-mail ID and Mobile Number",
          "- Documentary proof of the constitution of your business",
          "- Documentary proof of promoters/partners",
          "- Documentary proof of the principal place of business",
          "- Details of additional places of business, if applicable",
          "- Details of Authorized Signatories including photographs and proof of appointment",
          "- Details of Primary Authorized Signatory",
          "- Business bank account details along with a bank statement or the first page of the bank passbook",
          "- Valid Class II or Class III DSC of authorized signatory in case of companies and LLPs; valid Class II or Class III DSC or Aadhaar (for E-Sign option) in case of other entities. Note: Your mobile number should be updated with the Aadhaar authorities otherwise you cannot use E-Sign option because OTP will be sent to the number in the Aadhaar database.",
        ],
      },
      {
        title:
          "Whether the proper officer can reject an Application for Registration?",
        para: [
          "Yes. In terms of section 25(10) of the CGST Act, the proper officer can reject an application for registration after due verification.",
          "Where the application submitted is found to be deficient, the proper officer may issue a notice to the applicant electronically in FORM GST REG-03 within a period of three working days from the date of submission of the application and the applicant shall furnish such clarification, information or documents electronically, in FORM GST REG-04, within a period of seven working days from the date of the receipt of such notice.",
          "Where no reply is furnished by the applicant or where the proper officer is not satisfied with the clarification, information or documents furnished, he shall, for reasons to be recorded in writing, reject such application and inform the applicant electronically in FORM GST REG 05.",
        ],
      },
      {
        title: "Whether the Registration granted to any person is permanent?",
        para: [
          "Yes, the registration Certificate once granted is permanent unless surrendered, cancelled, suspended, or revoked.",
        ],
      },
      {
        title:
          "Will the address of business premises mentioned in the application for registration be verified physically by the department?",
        para: [
          "Only in cases where the proper officer feels the need for such verification but after the grant of registration. Wherever the proper officer feels so, he may get such verification done and the verification report along with other documents, including photographs, shall be uploaded in FORM GST REG-30 on the Common Portal within fifteen working days following the date of such verification.",
        ],
      },
      {
        title: "Who is a Casual Taxable Person?",
        para: [
          "Casual Taxable Person has been defined in Section 2 (20) of the CGST/TSGST Act meaning a person who occasionally undertakes transactions involving supply of goods and/or services in the course or furtherance of business, whether as principal, or agent or in any other capacity, in a State or a Union territory where he has no fixed place of business.",
        ],
      },
      {
        title: "Who is a Non-resident Taxable Person?",
        para: [
          "In terms of Section 2(77) of the CGST/TSGST Act, a nonresident taxable person means any person who occasionally undertakes transactions involving supply of goods and/or services whether as principal or agent or in any other capacity, but who has no fixed place of business or residence in India.",
        ],
      },
      {
        title:
          "What is the validity period of the Registration certificate issued to a Casual Taxable Person and non- Resident Taxable person?",
        para: [
          "In terms of Section 27(1) read with proviso thereto, the certificate of registration issued to a “casual taxable person” or a “non-resident taxable person” shall be valid for a period specified in the application for registration or ninety days from the effective date of registration, whichever is earlier. However, the proper officer, at the request of the said taxable person, may extend the validity of the aforesaid period of ninety days by a further period not exceeding ninety days.",
        ],
      },
      {
        title:
          "Is there any Advance tax to be paid by a Casual Taxable Person and Non-resident Taxable Person at the time of obtaining registration under this Special Category?",
        para: [
          "Yes. While a normal taxable person does not have to make any advance deposit of tax to obtain registration, a casual taxable person or a non-resident taxable person shall, at the time of submission of application for registration is required, in terms of Section 27(2) read with proviso thereto, to make an advance deposit of tax in an amount equivalent to the estimated tax liability of such person for the period for which the registration is sought. If registration is to be extended beyond the initial period of ninety days, an advance additional amount of tax equivalent to the estimated tax liability is to be deposited for the period for which the extension beyond ninety days is being sought.",
        ],
      },
      {
        title: "Who is an ISD?",
        para: [
          "ISD stands for Input Service Distributor and has been defined under Section 2(61) of the CGST/TSGST Act. It is basically an office meant to receive tax invoices towards receipt of input services and further distribute the credit to supplier units (having the same PAN) proportionately.",
        ],
      },
      {
        title:
          "Will ISD be required to be separately registered other than the existing tax payer registration?",
        para: [
          "Yes, the ISD registration is for one office of the taxpayer which will be different from the normal registration.",
        ],
      },
      {
        title:
          "What could be the liabilities (in so far as registration is concerned) on transfer of a business?",
        para: [
          "The transferee or the successor shall be liable to be registered with effect from such transfer or succession and he will have to obtain a fresh registration with effect from the date of such transfer or succession. (Section 22(3)).",
        ],
      },
      {
        title:
          "Can the registration certificate be downloaded from the GSTN portal?",
        para: [
          "In case registration is granted; applicant can download the Registration Certificate from the GST common portal.",
        ],
      },
      {
        title:
          "Whether Amendments to the Registration Certificate is permissible?",
        para: [
          "Yes. In terms of Section 28, the proper officer may, on the basis of such information furnished either by the registrant or as ascertained by him, approve or reject amendments in the registration particulars within a period of 15 common working days from the date of receipt of application for amendment.",
          "It is to be noted that permission of the proper officer for making amendments will be required for only certain core fields of information, whereas for the other fields, the certificate of registration shall stand amended upon submission of application in the GST common portal.",
        ],
      },
      {
        title:
          "Whether Cancellation of Registration Certificate is permissible?",
        para: [
          "Yes. Section 29 of the CGST/TSGST Act, provides that a taxpayer can apply for cancellation of registration in FORM GST REG-16 in the following circumstances:",
          "• Discontinuance of business or closure of business;",
          "• Transfer of business on account of amalgamation, merger, de-merger, sale, lease or otherwise;",
          "• Change in constitution of business leading to change in PAN;",
          "• Taxable person (including those who have taken voluntary registration) is no longer liable to be registered under GST;",
          "• Death of sole proprietor;",
          "• Any other reason (to be specified in the application)",
          "Application in FORM GST REG-16 has to be submitted within a period of 30 days of the “occurrence of the event warranting the cancellation”.",
        ],
      },
      {
        title:
          "Whether cancellation of registration under CGST Act means cancellation under SGST Act also?",
        para: [
          "Yes, the cancellation of registration under one Act (say SGST Act) shall be deemed to be a cancellation of registration under the other Act (i.e. CGST Act). (Section 29 (4))",
        ],
      },
      {
        title:
          "Whether cancellation of registration has any impact on the liabilities of the taxpayers?",
        para: [
          "The cancellation of registration has no effect on the liability of the taxpayer for any acts of commission/omission committed before or after the date of cancellation. As per section 29(3) of the CGST/TSGST Act, the cancellation of registration shall not affect the liability of the person to pay tax and other dues or to discharge any obligation under this Act or the rules made thereunder for any period prior to the date of cancellation whether or not such tax and other dues are determined before or after the date of cancellation.",
        ],
      },
      {
        title: "Can cancellation of registration order be revoked?",
        para: [
          "Yes, but only in cases where the initial cancellation has been done by the proper officer suo moto, and not on the request of the taxable person or his legal heirs. A person whose registration has been cancelled suo moto can apply to the proper officer for revocation of cancellation of registration within 30 days from the date of communication of the cancellation order. The proper officer may within a period of 30 days from the date of receipt of application for revocation of cancellation or receipt of information/clarification, either revoke the cancellation or reject the application for revocation of cancellation of registration.",
        ],
      },
    ],
  },
  "GST Return Filing": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/gst%2Fgst_return.webp?alt=media&token=eff705f2-e61d-4a5b-9e1b-d8bb1bfabedb",
    head: "GST Return Filing",
    msme: "GST Return Filing including Government fee for individuals and SMEs.",
    regular:
      "GST Return Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Invoice",
      "Purchase Invoice",
      "Bank Statement",
      "Sales Invoice",
      "Amended Invoice",
      "Credit Notes",
      "Debit Notes",
      "Accounting Data",
    ],
    description: [
      {
        title: "What are GST Returns?",
        para: [
          "GST returns are periodic reports that taxpayers are required to file with the government, detailing their GST liabilities and payments for a given period.",
          "The frequency of GST returns filing varies depending on the taxpayer’s business turnover, and can be monthly, quarterly, or annually.",
          "GST returns must be filed even if there is no GST liability for the period, and any GST payments made must be reported.",
        ],
      },
      {
        title: "Who is Required to File a GST Return?",
        para: [
          "Any entity with an aggregate annual turnover of Rs.5 Crores is required to file GST returns. All registered businesses are also required to file GST returns. An entity has to file two returns every month. GSTR-1 is for the Output Tax on sales, and GSTR-2A for the Input Tax on purchases. Apart from these two, GSTR-3B can be filed monthly or quarterly depending upon what you opt for. This return consists of the summary of all the annual sales and purchases data.",
        ],
      },
      {
        title: "Due Dates of GST Returns Filing",
        para: [
          "Type of Return: GSTR-1",
          "Timeline for filing: 11th of every month",
          "Type of Return: GSTR-2A",
          "Timeline: No due date. Auto populated from GSTR-1 filed by suppliers",
          "Type of Return: GSTR-3B",
          "Timeline: Monthly – 20th of every month",
          "Quarterly – 22nd of the month after quarter-end.",
        ],
      },
      {
        title: "Different Types Of GST Returns",
        para: [
          "Type of GST return: GSTR -1",
          "Due date: 10 days from the end of the month for which such a return is to be filed.",
          "Type of GST return: GSTR – 2",
          "Due date: Between the 11th and 15th day of the succeeding month for which return is to be filed.",
          "Type of GST return: GSTR – 2A",
          "Due date: Between the 11th and 15th day of the month succeeding the month for which such a return is to be filed.",
          "Type of GST return: GSTR – 3B",
          "Due date: By the 20th of the month succeeding the tax period for which GST is filed.",
          "Type of GST return: GSTR – 4",
          "Due date: The 18th of every month following the quarter for which such a return needs to be filed.",
          "Type of GST return: GSTR – 5",
          "Due date: Within 20 days after the end of the calendar month or within 7 days after the last date of validity of the registration of GST.",
          "Type of GST return: GSTR – 6",
          "Due date: On the thirteenth day of the month succeeding the month for which tax is to be paid.",
          "Type of GST return: GSTR – 7",
          "Due date: Within 10 days after the end of the month in which the deduction was made.",
          "Type of GST return: GSTR – 8",
          "Due date: The last date to file GSTR 8 is the 10th day of the month succeeding the month for which TCS (tax collected at source) is to be collected.",
          "Type of GST return: GSTR – 9",
          "Due date: On or before the",
          "31st day of December following the end of such financial year.",
          "Type of GST return: GSTR – 9A",
          "Due date: On or before December 31 succeeding the close of a particular financial year for which the return needs to be filed.",
          "Type of GST return: GSTR – 9B",
          "Due date: On or before 31st December following the close of the financial year.",
          "Type of GST return: GSTR – 9C",
          "Due date: On or before 31st December of the year subsequent to the relevant financial year.",
          "Type of GST return: GSTR – 10",
          "Due date: 3 months from the date of cancellation or the date of order cancellation.",
          "Type of GST return: GSTR – 11",
          "Due date: On the 28th of the month succeeding the month in which inward supplies are received by the UIN (unique identification number) holders.",
        ],
      },
      {
        title: "Process of Filing GST Returns Online",
        para: [
          "Filing Form GSTR-1 Online",
          "It is to be filed quarterly for those who have opted for QRMP; the rest have to file it monthly. You have to keep in mind the following 3 points before going into filing the return:",
          "If your sales invoices are more than 500, you have to download the utility and fill the form offline.",
          "You cannot perform bulk actions on the invoices.",
          "You have to fill the HSN-wise summary manually.",
          "You have to follow the below steps to file GSTR-1 online:",
          "Log in to the GST portal.",
          "You will be issued a 15-digit GST Identification Number based on State Code and PAN.",
          "You will find the Services ribbon on the top left of the page. Take your cursor there, and from the dropdown, select Returns > Returns Dashboard.",
          "It takes you to the Return Filing page. Now you have to select the Financial Year and the Month for which you have to file the return.",
          "Now click on Search.",
          "You will find a questionnaire related to filing the return monthly or quarterly.",
          "Whether your Turnover for last FY was less than Rs.1.5 Crore?",
          "Do you expect your Turnover to be less than Rs.1.5 Crore in the Current FY?",
          "If Yes, then you have the option to select for filing the GSTR-1 Quarterly.",
          "If No, you have to file the GSTR-1 monthly.",
          "Now you get the option to prepare your return online or offline. We will discuss the online GST return filing option.",
          "Click on Prepare Online. Now you have to fill in the Details of Outward Supply of Goods and Services (sales details).",
          "You have to fill the aggregate Turnover of the Last Financial Year and the Aggregate Turnover for the running quarter up to the period of return.",
          "Now you have to upload invoices and other details on the GST Portal.",
          "You have to fill the HSN-wise summary of Outward Supply.",
          "You also have the option to rectify your details online.",
          "You can download the file for offline use.",
          "Viewing Form GSTR-2A Online",
          "Go to the portal of GST and log in.",
          "Go to Services. In the dropdown, select Returns > Returns Dashboard.",
          "Select the FY and period of return. Click on Search.",
          "You see an option of Auto Drafted details for View Only, GSTR-2A. Click on View.",
          "By clicking on the GSTN of each supplier, you can view the invoices uploaded by each supplier.",
          "You have the option to download the file for offline use.",
          "Filing Form GSTR-3B Online",
          "Things to keep in mind before filing this return are:",
          "Values have to be filled in manually. Copy-paste is not allowed.",
          "GST Liability is calculated after submitting this return.",
          "Here are the steps for filing GSTR-3B:",
          "Log in to the GST Portal.",
          "Services > Returns > Returns Dashboard.",
          "Select FY and period of return.",
          "Click on Monthly Return and then Prepare Online.",
          "You are asked a set of 5 questions. Depending on these, relevant tables of GSTR-3B are visible.",
          "You have to enter values in each tile and also mention the total under each head.",
          "Once you have filled up all the data, you can click on the Submit button.",
          "You have to check the tax payable and make the payment.",
          "You then have to sign the return using the DSC of the Authorised Signatory or the EVC.",
          "After clicking the Proceed button, your return is filed successfully.",
          "These are a few steps that will guide you through the process of filing your GST returns online. Follow these steps in an orderly fashion to ensure hassle-free return filing. You can file all three returns online quickly without having to visit anywhere.",
        ],
      },
      {
        title: "How to check GST return status",
        para: [
          "Visit the common government GST portal – www.gst.gov.in and log into your account with your details",
          "Click on the ‘Returns’ option on the top menu bar and click on ‘track return status’ from the drop-down options.",
          "Next, click on the return’s filing period.",
          "Choose the financial year in which you filed the returns from the drop-down list, and click the search button.",
          "Your GST returns status for the chosen period will be shown on the screen.",
        ],
      },
      {
        title: "How do I download GST returns?",
        para: [
          "Taxpayers can download the GST return filed from their accounts on the GST Portal by following the steps below.",
          "Visit the GST portal.",
          "Navigate to “Services”.",
          "Select the financial year for which you are filing the GST return.",
          "You can then view the acknowledgment number and the date of filing of the GST return.",
          "The review page of your GST return will be displayed.",
        ],
      },
      {
        title: "Late fees for not filing GST returns",
        para: [
          "Apart from the 50% fine, taxpayers will be required to pay interest on the amount for the number of days delayed to file GST returns. This can eventually lead to a penalty which is ₹5,000 under Section 234F of the Income Tax Act. However, individuals whose total income is below ₹5 lakh, will have to pay ₹1,000 as a penalty. In the case of non-filing of GST returns, the taxpayer is not entitled to receive any interest on the refund of excess taxes paid for the delayed period.",
        ],
      },
      {
        title: "FAQs on GST return filing",
        para: [
          "How do I pay my GST return?",
          "You can pay your GST return through the www.gst.gov.in website or through a range of other payment options.",
          "Is GST payment monthly or quarterly?",
          "GST payments are done on a monthly basis. But, an individual with a turnover of 5 crore INR in the preceding year can file the return on a quarterly basis.",
          "How many returns are there in GST?",
          "There are 13 returns under GST, namely GSTR-1, GSTR-3B, GSTR-4, GSTR-5, GSTR-5A, GSTR-6, GSTR-7, GSTR-8, GSTR-9, GSTR-10, GSTR-11, CMP-08, and ITC-04.",
          "What is GSTR 3B return?",
          "GSTR-3B is a summary return of all inward and outward supplies that the Government of India has introduced as a pathway to relax the requirements for businesses that have transitioned to GST.",
        ],
      },
    ],
  },
  "GST Annual Return": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/gst%2Fgst_annual_return.webp?alt=media&token=4fe6b94d-16ca-409e-a994-947bb2a0e4dc",
    head: "GST Annual Return",
    msme: "GST Annual Return including Government fee for individuals and SMEs.",
    regular:
      "GST Annual Return including Government fee for entities NOT having MSME registration.",
    documentsRequired: ["GSTIN", "Invoice"],
    description: [
      {
        title: "What is GSTR-9 and who is liable to file GSTR 9?",
        para: [
          "GSTR-9 is an annual return to be filed by all registered taxpayers under GST irrespective of the turnover of an entity.",
          "The return consists of details such as inward/outward supplies, taxes paid, refund claimed, demand raised and ITC availed by the taxpayer.",
          "All registered taxpayers are required to file GSTR-9 except:",
          "- Casual taxpayers",
          "- Input Service Distributors",
          "- Non-resident taxpayers",
          "- Taxpayers deducting/collecting tax at source under Section 51 or Section 52",
          "(Note: Composition taxpayers have to file GSTR-9A, and E-commerce operators have to file GSTR-9B.)",
        ],
      },
      {
        title:
          "If the business has shifted from Regular to Composition Scheme or vice versa within the same FY, what are the implications in the Annual return? How are the computations to be done?",
        para: [
          "In cases where the business of an assessee is shifted from Regular to Composition or vice versa within the same financial year, the turnover and purchases have to be segregated as follows:",
          "- Turnover of a period where the assessee is registered under Regular Scheme: Should be reported in GSTR-9",
          "- Turnover of a period where the assessee is registered under Composition Scheme: Should be reported in GSTR-9A.",
          "- Segregate purchases: Compute purchases for the period during which an assessee was registered as a Regular taxpayer and report the same under GSTR-9 to claim ITC.",
          "However, ITC cannot be claimed on purchases made during the period where the assessee was registered under the Composition Scheme.",
        ],
      },
      {
        title:
          "During the Year if there is an error between CGST, SGST and IGST interchanged in reporting, but the gross value of the taxes matches, can the same be correctly reported in GSTR 9?",
        para: [
          "If CGST, SGST, and IGST are interchanged while reporting in GSTR-1, the same cannot be corrected while filing GSTR-9.",
          "Table 9 – Details of taxes paid of GSTR-9 cannot be edited except tax payable column.",
          "However, the assessee can correctly report the actual taxes payable under respective heads while updating Table 9, though this correct reporting does not account for automatic intra-adjustment of tax under CGST, SGST, and IGST.",
          "The shortfall of tax needs to be paid off while filing GSTR-3B of the subsequent month or by filing DRC-03, and the taxes paid in excess erroneously can be claimed as a refund.",
          "Also, there is no interest liability on taxes shortly paid in such cases.",
        ],
      },
      {
        title:
          "A sales figure that was originally reported as B2C sales in GSTR-1, was later corrected to B2B sales, how should the reporting be done?",
        para: [
          "Table 4 of GSTR-9 requires details of both B2B and B2C supplies.",
          "In this case, the assessee has to report sales under B2B in Table 4, as this is the correct classification even though it was wrongly reported as B2C in GSTR-1.",
        ],
      },
      {
        title:
          "A GSTR-3B was filed with wrong (excess) outward supplies and tax paid on the same, but the actual/correct supplies were declared in GSTR-1 for the same period, then in that case is there any way to get a refund of excess amount paid?",
        para: [
          "If taxes are erroneously paid in excess in GSTR-3B, the same can be claimed as a refund (as per Section 54 of CGST Act) or be adjusted against tax payments of subsequent periods.",
          "But, the same cannot be claimed through GSTR-9. The refund needs to be claimed by making an application for the same on the GST portal.",
        ],
      },
      {
        title:
          "Sales that were originally reported as 'With payment of taxes', was later corrected to 'Without payment of Taxes', should this be reported in Table 4C?",
        para: [
          "As the correct nature of sales in this case is without payment of taxes, the same should be reported in Table 5A and not Table 4C of GSTR-9.",
          "A correct reporting needs to be done in GSTR-9 irrespective of the fact that it was wrongly reported in GSTR-1 as ‘With payment of taxes’.",
        ],
      },
      {
        title:
          "If a LUT had not been executed, and sales had been reported as 'Without Payment of Taxes, should the same now be treated as Exports with payment of Taxes?",
        para: [
          "Non-execution of LUT is a procedural non-compliance. Such non-compliance cannot turn the nature of the transaction from ‘exports without payment of tax’ to ‘exports with payment of tax’.",
          "A taxpayer needs to report the transaction under Table 5 of GSTR-9 as “Exports without payment of tax”. Penal consequences, if any, need to be dealt with.",
        ],
      },
      {
        title:
          "If an advance was received in 2017-18, the invoice issued in 2017-18, but belatedly reported in GSTR 1 in FY(2018-19), how is this to be dealt with in Table 4F?",
        para: [
          "This transaction should be reported in Table 4A (in case supply is made to an unregistered person) or Table 4B (in case the supply is made to a registered person).",
          "Reporting under Table 4F is required only when the tax on advances is paid but the invoice is not issued.",
        ],
      },
      {
        title:
          "Reverse Charge had been paid, but wrongly reported in GSTR-3B in 3.1.a as against 3.1.d, how can this be rectified while filing GSTR-9?",
        para: [
          "An assessee should make a correct reporting under GSTR-9 by disclosing the reverse charge paid under Table 4G even though there is incorrect reporting under GSTR-3B.",
        ],
      },
      {
        title:
          "Can a refund be claimed for Credit Notes which cannot be adjusted in 2018-19 for FY 2017-18?",
        para: [
          "No, a refund of credit notes which cannot be adjusted in FY 2017-18 for FY 2018-19 cannot be claimed as per the GST law.",
        ],
      },
      {
        title: "Can GSTR-9 be revised or rectified after filing?",
        para: [
          "No, GSTR-9 once filed cannot be revised or rectified.",
          "It's important to ensure that the information provided in GSTR-9 is accurate and complete before filing it, as there is no provision for revision or rectification after submission.",
        ],
      },
      {
        title: "Are there any late fees for filing GSTR-9 after the due date?",
        para: [
          "Yes, there are late fees for filing GSTR-9 after the due date.",
          "As per Section 47(2) of the CGST Act, a late fee of Rs. 100 per day (Rs. 100 under CGST and Rs. 100 under SGST) is applicable for late filing, subject to a maximum of 0.25% of the taxpayer's turnover in the respective state or union territory.",
          "However, the late fee is capped at Rs. 5,000 in case of nil returns.",
        ],
      },
      {
        title:
          "Can GSTR-9 be filed online or is it required to be filed physically?",
        para: [
          "GSTR-9 can be filed online through the GST portal.",
          "There is no requirement to file it physically. Taxpayers are encouraged to use the online portal for filing to ensure accuracy and compliance with the GST law.",
        ],
      },
      {
        title:
          "Is GSTR-9 the same for all states in India, or are there any state-specific variations?",
        para: [
          "GSTR-9 is a common annual return form for all states in India under the Goods and Services Tax (GST) regime.",
          "There are no state-specific variations in the form itself. However, the information reported in GSTR-9 may vary from state to state based on the taxpayer's business operations, turnover, and other factors.",
          "It's important for taxpayers to accurately report their GST-related information in GSTR-9 based on their specific circumstances.",
        ],
      },
      {
        title: "What are the consequences of not filing GSTR-9?",
        para: [
          "Failure to file GSTR-9 can have several consequences, including:",
          "1. **Late Fees:** As mentioned earlier, late fees may apply for filing after the due date.",
          "2. **Penalties:** There can be penalties for non-compliance with GST regulations.",
          "3. **Impact on Input Tax Credit (ITC):** Non-filing or incorrect filing of GSTR-9 can lead to discrepancies in ITC, affecting the taxpayer's ability to claim eligible credits.",
          "4. **Legal Consequences:** Non-compliance with GST laws can lead to legal action and scrutiny by tax authorities.",
          "It's important for taxpayers to meet their GST compliance obligations, including filing GSTR-9 on time and accurately, to avoid these consequences.",
        ],
      },
      {
        title: "Can I make amendments to GSTR-9C after filing it?",
        para: [
          "No, GSTR-9C, which is the reconciliation statement, cannot be amended after filing.",
          "It's essential to ensure the accuracy and completeness of the information provided in GSTR-9C before submission because, once filed, it cannot be revised or rectified.",
        ],
      },
      {
        title: "What is the purpose of the GST audit conducted under GSTR-9C?",
        para: [
          "The GST audit conducted under GSTR-9C serves the purpose of reconciling the taxpayer's declared turnover in their annual return (GSTR-9) with the audited financial statements.",
          "This helps in identifying and rectifying any discrepancies or inconsistencies between the two. It also ensures that the taxpayer's GST liability and input tax credit (ITC) claims are accurately reported.",
        ],
      },
      {
        title: "Is GSTR-9C mandatory for all GST-registered businesses?",
        para: [
          "No, GSTR-9C is not mandatory for all GST-registered businesses.",
          "It is required to be filed only by specific categories of taxpayers. Currently, GSTR-9C is applicable to businesses with an annual aggregate turnover exceeding Rs. 2 crore during a financial year. However, this threshold may change, so it's advisable to check the latest notification from the GST authorities.",
        ],
      },
      {
        title:
          "Are there any penalties for not filing GSTR-9C when it's required?",
        para: [
          "Yes, there are penalties for not filing GSTR-9C when it's required.",
          "The late fee for not filing GSTR-9C is Rs. 100 per day under the Central Goods and Services Tax Act and Rs. 100 per day under the State Goods and Services Tax Act, subject to a maximum of 0.25% of the taxpayer's turnover in the respective state or union territory.",
          "However, the late fee is capped at Rs. 5,000 in case of nil returns.",
        ],
      },
      {
        title: "Can I use GSTR-9C data for filing my income tax returns?",
        para: [
          "Yes, you can use data from GSTR-9C for filing your income tax returns.",
          "The reconciliation and audit information provided in GSTR-9C can be useful for ensuring consistency between your GST and income tax returns. However, it's advisable to consult with a tax professional to ensure that all relevant data is correctly reported in both returns.",
        ],
      },
    ],
  },
  "GST E-way Bill": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2FGenerate-E-Way-Bill-Under-The-New-System-3.png?alt=media&token=0df2965b-6251-429d-a895-e5296667a0bf",
    head: "GST E-way Bill",
    msme: "GST E-way Bill including Government fee for individuals and SMEs.",
    regular:
      "GST E-way Bill including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Supply of Goods",
      "Transport ID",
      "Transport Document",
    ],
    description: [
      {
        title: "What is an eWay Bill?",
        para: [
          "EWay Bill is an Electronic Way bill for movement of goods to be generated on the eWay Bill Portal. A GST registered person cannot transport goods in a vehicle whose value exceeds Rs. 50,000 (Single Invoice/bill/delivery challan) without an e-way bill that is generated on ewaybillgst.gov.in.",
          "Alternatively, Eway bill can also be generated or cancelled through SMS, Android App and by site-to-site integration through API entering the correct GSTIN of parties. Validate the GSTIN with the help of the GST search tool before using it.",
          "When an eway bill is generated, a unique Eway Bill Number (EBN) is allocated and is available to the supplier, recipient, and the transporter.",
        ],
      },
      {
        title: "When Should eWay Bill be issued?",
        para: [
          "eWay bill will be generated when there is a movement of goods in a vehicle/ conveyance of value more than Rs. 50,000 (either each Invoice or in aggregate of all invoices in a vehicle/conveyance) –",
          "In relation to a ‘supply’",
          "For reasons other than a ‘supply’ (say a return)",
          "Due to inward ‘supply’ from an unregistered person",
          "For this purpose, a supply may be either of the following:",
          "A supply made for a consideration (payment) in the course of business",
          "A supply made for a consideration (payment) which may not be in the course of business",
          "A supply without consideration (without payment)",
          "In simpler terms, the term ‘supply’ usually means a:",
          "- Sale – sale of goods and payment made",
          "- Transfer – branch transfers for instance",
          "- Barter/Exchange – where the payment is by goods instead of in money",
          "Therefore, eWay Bills must be generated on the common portal for all these types of movements. For certain specified Goods, the eway bill needs to be generated mandatorily even if the value of the consignment of Goods is less than Rs. 50,000:",
          "- Inter-State movement of Goods by the Principal to the Job-worker by Principal/registered Job-worker",
          "- Inter-State Transport of Handicraft goods by a dealer exempted from GST registration",
        ],
      },
      {
        title: "Who should Generate an eWay Bill?",
        para: [
          "Registered Person – Eway bill must be generated when there is a movement of goods of more than Rs 50,000 in value to or from a registered person. A Registered person or the transporter may choose to generate and carry eway bill even if the value of goods is less than Rs 50,000.",
          "Unregistered Persons – Unregistered persons are also required to generate e-Way Bill. However, where a supply is made by an unregistered person to a registered person, the receiver will have to ensure all the compliances are met as if they were the supplier.",
          "Transporter – Transporters carrying goods by road, air, rail, etc. also need to generate e-Way Bill if the supplier has not generated an e-Way Bill.",
          "The transporters need not generate the Eway bill (as Form EWB-01 or EWB-02) where all the consignments in the conveyance:",
          "- Individually (single Document**) is less than or equal to Rs 50,000 BUT",
          "- In Aggregate (all documents** put together) exceeds Rs 50,000",
          "**Document means Tax Invoice/Delivery challan/Bill of supply",
          "Unregistered Transporters will be issued Transporter ID on enrolling on the e-way bill portal after which Eway bills can be generated.",
        ],
      },
      {
        title: "Cases when eWay bill is Not Required",
        para: [
          "In the following cases it is not necessary to generate e-Way Bill:",
          "- The mode of transport is non-motor vehicle",
          "- Goods transported from Customs port, airport, air cargo complex, or land customs station to Inland Container Depot (ICD) or Container Freight Station (CFS) for clearance by Customs.",
          "- Goods transported under Customs supervision or under customs seal",
          "- Goods transported under Customs Bond from ICD to Customs port or from one custom station to another.",
          "- Transit cargo transported to or from Nepal or Bhutan",
          "- Movement of goods caused by defence formation under Ministry of defence as a consignor or consignee",
          "- Empty Cargo containers are being transported",
          "- Consignor transporting goods to or from between the place of business and a weighbridge for weighment at a distance of 20 kms, accompanied by a Delivery challan.",
          "- Goods being transported by rail where the Consignor of goods is the Central Government, State Governments, or a local authority.",
          "- Goods specified as exempt from E-Way bill requirements in the respective State/Union territory GST Rules.",
          "- Transport of certain specified goods - Includes the list of exempt supply of goods, Annexure to Rule 138(14), goods treated as no supply as per Schedule III, Certain schedule to Central tax Rate notifications. (PDF of List of Goods).",
          "Note: Part B of e-Way Bill is not required to be filled where the distance between the consigner or consignee and the transporter is less than 50 Kms and transport is within the same state.",
        ],
      },
      {
        title: "State-wise e-Way Bill Rules and Limits",
        para: [
          "Inter-State movement of goods has seen a rise in the numbers of generation of e-way bills ever since its implementation began from 1st April 2018. State-wise implementation of e-way bill system has seen a good response with all the States and Union Territories joining the league in the generation of e-way bills for movement of goods within the State/UT.",
          "However, reliefs have been provided to people of few States by way of exempting them from e-way bill generation in case of monetary limits falling below the threshold amount or certain specified items. For Instance, Tamil Nadu has exempted people of its State from the generation of e-way bill if the monetary limit of the items falls below Rs. One Lakh. To know more of such reliefs for other States/UTs, visit our page on state-wise e-way bill rules and threshold limits or alternatively check the respective commercial tax websites for each of such States/UTs.",
        ],
      },
      {
        title: "How to generate eWay Bill on portal",
        para: [
          "E-Way Bill and the e-way bill number can be generated on the e-Way Bill Portal. All you need is a Portal login. For a detailed step-by-step guide on e-Way Bill Generation check out our article – Guide to generate e-Way Bill online.",
        ],
      },
      {
        title: "SMS e-way bill generation on mobile",
        para: [
          "You can generate e-way bills via SMS using a registered mobile phone. All can begin by enabling SMS e-way bill generate facility. Register the mobile phone to be used for SMS facility of e-way bill generation. Thereafter, send simple SMS codes to a particular mobile number managed by the e-way bill portal/GSTN to generate, manage, and cancel e-way bills. For more details, read our article on SMS mode of e-way bill generation.",
        ],
      },
      {
        title: "Validity of eWay Bill",
        para: [
          "An e-way bill is valid for periods as listed below, which is based on the distance traveled by the goods. Validity is calculated from the date and time of generation of e-way bill-",
          "Type of conveyance: Other than Over dimensional cargo",
          "Distance: Less Than 200 Kms",
          "Validity of EWB: 1 Day",
          "For every additional 200 Kms or part thereof, additional 1 Day",
          "Type of conveyance: For Over dimensional cargo",
          "Distance: Less Than 20 Kms",
          "Validity of EWB: 1 Day",
          "For every additional 20 Kms or part thereof, additional 1 Day",
          "Validity of Eway bill can be extended also. The generator of such Eway bill has to either eight hours before expiry or within eight hours after its expiry can extend Eway bill validity.",
        ],
      },
      {
        title: "Documents or Details required to generate eWay Bill",
        para: [
          "Documents required to generate eWay Bill:",
          "- Invoice/Bill of Supply/Challan related to the consignment of goods",
          "Transport by road – Transporter ID or Vehicle number",
          "Transport by rail, air, or ship – Transporter ID, Transport document number, and date on the document",
        ],
      },
    ],
  },
  "GST E-Invoice": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2Fgst_einvoice.png?alt=media&token=7002a802-55f8-40b7-bedc-a7318c7af0a8",
    head: "GST E-Invoice",
    msme: "GST E-Invoice including Government fee for individuals and SMEs.",
    regular:
      "GST E-Invoice including Government fee for entities NOT having MSME registration.",
    documentsRequired: ["ID Proof", "HSN Code"],
    description: [
      {
        title: "What is e-invoicing?",
        para: [
          "An e-invoice, or electronic invoice, is a digital document that is exchanged between a supplier and buyer and validated by the government tax portal. E-invoicing is the proposed system where business-to-business (B2B) invoices are digitally prepared in an e-invoicing format and authenticated by the Goods and Services Tax Network (GSTN). This system ensures that a common format is followed by all businesses before reporting invoices to the GST portal.",
          "In August 2019, the government shared a draft of an e-invoice for public view, which was later modified by the GST Council to be compliant according to their regulations. Not only does the standard format make compliance easier, but because it’s followed across industries, interoperability between GST ecosystems is ensured.",
        ],
      },
      {
        title: "The current system",
        para: [
          "Today, an invoice generated by the seller needs to be prepared and reported to two different systems: GST Portal and e-Way Bill.",
          "Businesses generate invoices using different software and the invoice details are entered using a suitable API by the taxpayer in a GSTR-1 return. The same information is reflected in a GSTR-2A and made available to recipients with ‘view only’ permission. Simultaneously, transporters are required to generate the e-Way Bill, either directly or by importing the invoices into an excel sheet or JSON manually.",
          "To put an end to this endless trail of paperwork, the GST council introduced the new return system.",
        ],
      },
      {
        title: "The need for a standard e-invoicing system",
        para: [
          "Tax departments internationally are eager to make the e-invoicing system a success for two reasons:",
          "- Following a standard format allows invoices to be shared easily",
          "- Invoices can be read by the central system",
          "With e-invoicing, fields will be pre-populated while filing returns, thus avoiding discrepancies during data entry and reconciliation.",
        ],
      },
      {
        title: "How to generate an e-invoice under GST",
        para: [
          "The flow of a GST e-invoice system has two parts:",
          "- Communication between the business and the Invoice Registration Portal (IRP)",
          "- Interaction between the IRP, the GST/ e-Way Bill systems, and the buyer.",
          "Generating an e-invoice",
          "Taxpayers will generate invoices like they normally do, except reporting will now be done electronically. Taxpayers will have to follow the e-invoice schema and submit mandatory details accordingly. Here is a list of mandatory and optional parameters:",
          "- Transaction details like tax scheme, supplier type",
          "- Document details: type, number and date",
          "- Supplier information like legal name, GSTN address, location, PIN code and state code.",
          "- Buyer information like legal name, GSTN number, address, location, state code and PIN code",
          "- Dispatch from address details (mandatory if it is different from supplier details)",
          "- Shipping details (mandatory if it is different from buyer address)",
          "- Item related details like service/ goods, HSN code, total amount, GST rate, Assessable amount, total item value.",
          "- If the items are being moved in batches, then add batch number.",
          "- Invoice details include assessable values and total invoice value.",
          "Besides the mandatory parameters, the Council also listed optional parameters, which are subject to change based on the needs of the business. Once the invoice fields have been finalised, a taxpayer has to decide if the accounting or billing software is capable of creating a JSON file, which can then be upload to the IRP.",
        ],
      },
      {
        title: "Creating a unique IRN",
        para: [
          "The IRP will generate a hash parameter based on the details submitted by the seller, like GSTIN, document type, document number, and fiscal year. The IRP will then check if the same invoice exists in the Central Registry, and after confirming there are no duplicates, the IRP will add its signature and a QR code in the invoice’s JSON data. The hash generated by the IRP will be the Invoice Reference Number (IRN) for the e-invoice.",
        ],
      },
      {
        title: "Updating the invoice to the GST and e-way bill systems",
        para: [
          "The digitally signed JSON with the IRN is sent to the seller. The uploaded invoice data is then shared with the GST and e-Way Bill system. The GST system will update the GSTR-1 for the seller and the GSTR-2A for the buyer, which will help in determining the liability and ITC. The GST e-invoice schema will contain details like ‘Transporter ID’ or ‘Vehicle number,’ which will be used to generate an e-Way Bill.",
          "The government’s tax portal is not responsible for generating the e-invoice. In fact, it will be created with the aid of the seller’s accounting/billing software and their respective ERP systems. The IRP will just receive, validate, and digitally sign the invoices uploaded by the seller.",
        ],
      },
      {
        title: "How to register for an e-invoicing system",
        para: [
          "Registering on the e-invoicing portal is a very simple process if you are a GST-registered taxpayer with a valid GSTIN number.",
          "If you have registered your business on the e-way bill (EWB) portal, then you can use the same credentials to log in to the e-invoicing portal as well.",
          "If you have not registered in the EWB portal, then you can register in the e-invoicing system directly. The taxpayer should have a GSTIN and mobile number registered in the GST portal.",
          "Here’s a flow diagram that you can follow to register:",
        ],
      },
      {
        title: "How will it curb tax fraud?",
        para: [
          "With the introduction of e-invoicing, tax authorities will now have access to a complete trail of B2B invoices from taxpayers since they will be uploaded in the GST portal. Because invoices are created before any actual transaction takes place, opportunities to manipulate the invoices decrease. The system can identify fake invoices by matching the input tax credit to output tax on the GSTN portal, preventing tax crimes.",
        ],
      },
      {
        title: "Benefits of having an e-invoicing system",
        para: [
          "Generating a GST e-invoice is usually the responsibility of a taxpayer who reports to the IRP in GST. Next, the IRP will generate a unique IRN and a QR code. The taxpayer will be able to scan this QR code, extract the IRN, and fetch invoice details.",
          "Here are some benefits of e-invoicing for businesses:",
          "- E-invoicing helps you with data reconciliation and accuracy during manual data entry.",
          "- It allows interoperability across businesses.",
          "- You can track the e-invoices in real-time.",
          "- The e-invoice details will be auto-populated on tax return forms and e-way bills, making the tax return process easy.",
          "- All transaction details will be available online at all times. This would eliminate the need for frequent audits and surveys. Differences in data can be caught by comparing input credit and output tax.",
          "- This initiative will also build efficiency within the tax administration by helping to identify fake invoices.",
        ],
      },
    ],
  },
  "GST Refund": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2FGST-RFD-10-Form.png?alt=media&token=4db5530e-5e74-4f77-aa57-6f493d9155ba",
    head: "GST Refund",
    msme: "GST Refund including Government fee for individuals and SMEs.",
    regular:
      "GST Refund including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "The type of business",
      "Aadhaar number",
      "Income tax details",
      "Export data",
      "Expenditure",
      "Investment",
    ],
    description: [
      {
        title: "What is the GST Refund?",
        para: [
          "A taxpayer should follow elaborate steps while applying for the GST refund process. They must submit documents & declarations to GST authorities and claim the refund. Note that the refunds under GST might be the balance in cash in the e-cash ledger that gets deposited in excess.",
          "It may happen due to the mistakenly paid tax. Besides, it results from the ITC or accumulated Input Tax Credit that was unable to get utilised for tax payments because of an inverted tax structure or zero-rated sales.",
        ],
      },
      {
        title: "How to Claim GST Refund?",
        para: [
          "Want to claim a GST refund? You need to submit a refund pre-application form. It's the form where taxpayers need to fill in the important information about the following:",
          "- The type of business",
          "- Aadhaar number",
          "- Income tax details",
          "- Export data",
          "- Expenditure",
          "- Investment",
          "Taxpayers need to file the pre-application form for all forms of GST refund. This form does not require to get signed. Note that it cannot be edited too once it is submitted. So, the applicant needs to be meticulous while filling up the form.",
          "Here are the key steps involved in the GST refund procedure. Please ensure you pay extra attention to their work accordingly:",
          "Step 1: Visit the GST portal and go to the tab that mentions 'Services.' Tap on the 'Refunds' option and choose the option that mentions: 'Refund pre-application form.'",
          "Step 2: On the page displaying this: 'Refund pre-application form', please fill in all details before clicking on 'Submit'. The submission confirmation will get displayed on your screen.",
          "Please keep the following things in mind before you apply for the refund process under GST:",
          "- Your business's nature (Manufacturer, trader, service provider, or merchant exporter)",
          "- Date of issue of IEC",
          "- The primary authorised signatory's Aadhaar number is compulsory",
          "- Income tax paid in the recent financial year",
          "- Advance tax paid in the recent financial year",
          "- Value of exports made in the recent financial year",
          "- Capital expenditure & investment made in the recent financial year",
        ],
      },
      {
        title: "Order for GST Refund Claims",
        para: [
          "What if the refund is on the export account of the GST? In that circumstance, the authorised office refund around 90% of the amount claimed as the refund in Form GST RFD-4. So, after due verification of the documents furnished, an office issues the order for the refund claim's final settlement. Note that a provisional refund gets granted subject to a few conditions as mentioned in the following:",
          "- The individual who claims the refund hasn't been prosecuted for the tax evasion of an amount that exceeds Rs. 250 Lakhs during the preceding five years",
          "- The individual's GST compliance rating isn't less than five on a scale of 10.",
          "- There are no pending appeal, revision, or review existing on the refund amount",
          "If an officer is convinced that the entire amount claimed as the refund is refundable, he issues the order in Form GST RFD-5. That gets performed within 60 days from the application receipt's date.",
          "And if the refund is not sanctioned within sixty days, the interest on the amount gets paid for a period after the 60-day timespan until the date of the actual refund of tax.",
          "One quick note: There will be no refund if the refund claim is less than Rs. 1000.",
        ],
      },
      {
        title: "Situations that can lead to Refund Claims",
        para: [
          "Did you know that a proper refund mechanism is compulsory for effective tax administration? That's because the trade gets facilitated through the blocked finances' release for the expansion, modernisation, as well as working capital needs of any business. The scenarios may contribute to refund claims, including these:",
          "- Deemed exports",
          "- Exports of services or commodities",
          "- Refund of taxes if embassies opt for a purchase",
          "- Supplies to developers as well as units in the special economic zones",
          "- Refund of any accrued input tax credit on the inverted duty structure's account",
          "- Pre-deposit refund",
          "- Refund arising from any judgment, order, decree, or direction of an Appellate Tribunal, Appellate Authority or court of law",
          "- Refund of taxes if embassies make the purchase",
          "- Excess payment due to errors",
          "- Refund because of the refund vouchers' issuance for taxes paid on advances (note that services or commodities aren't supplied on these)",
          "- Refunds to overseas travellers of commodities within any country carried to any international region after departing India",
          "- Refund of CGST and SGST paid by considering the overall supply in the course of inter-state trade or commerce",
        ],
      },
      {
        title: "Exceptional Circumstances of GST Refund Claims",
        para: [
          "Given below are the GST refund rules for the exceptional GST refund you need to understand:",
          "- Tax on the supply of goods is deemed exports. The best examples of these taxes are the supply of goods or services to the SEZ or EOU ((or Special Economic Zone or Export Oriented Unit respectively)",
          "- Tax can be refundable due to a judgement, order, decree, or on directions of the Appellate Authority, Appellate Tribunal or the court",
          "- Tax can be paid on the supply which hasn't been provided, partially or wholly. For this, the invoice does not need to be issued.",
          "- When the individual has paid SGST or CGST on the interstate supply or the IGST, they have the GST return eligibility criteria for the refund of any amount once the tax gets remitted appropriately.",
          "- IGST can be paid on the goods' supply to the tourists visiting outside India (when the goods are exported to a different country)",
          "- Considering the 'relevant date' in such a scenario, note down the following things:",
          "- Goods as deemed exports (the date of return relating to deemed exports get filed",
          "- Tax refundable because of any decree, judgement, order or on a direction of the Appellate Authority, Appellate Tribunal or any court (date of communication of a judgement, order, direction, or decree)",
          "- Tax paid provisionally – The date of the adjustment of tax upon the assessment",
          "- For the person or other supplier, the receipt of services or goods' date by that person",
          "- Considering the other cases: the date of the tax payment",
          "- Other than these, the overall refund procedure under the GST stays the same for regular & exceptional circumstances",
        ],
      },
      {
        title: "Refund Process under GST",
        para: [
          "Claimants who wish to make a claim need to file elaborate documents besides the refund claim. The documents prescribed are standard. In general, applicants need to submit relevant invoices related to the claim. So, what happens when a refund is made on the export account of the GST? In that circumstance, the relevant bank realisation certificates will verify the receipt of payment in overseas currency.",
          "When the claim is made by SEZ unit's supplier, an authorised office makes the endorsement verifying the receipt of these services or commodities in SEZ. They submit the same alongside the other documents. In addition, the SEZ unit needs to offer a declaration that states the ITC of tax paid by a supplier hasn't been availed.",
          "So, when you intend to refund the claim, please adhere to the following GST refund procedure:",
          "- First things first, please visit the official GSTN portal & fill in your application form",
          "- You get an SMS or email that contains the acknowledgement number after the application's filing gets performed electronically",
          '- The cash & return ledger will get adjusted & the "carry-forward input tax credit" will get reduced.',
          "- Application for the refund alongside documents gets scrutinised by authorities within 30 days after you file the application",
          '- "Unjust enrichment" is a concept that gets thoroughly scrutinised by authorities. If the application doesn\'t qualify, refunds get transferred to the CWF or Consumer Welfare Fund',
          "- Wh the refund is claimed by someone in excess of its predetermined amount, the pre-audit process gets conducted before a refund gets sanctioned",
          "- The refund's credit will be performed electronically to an applicant's account via NEFT, ECS or RTGS",
          "- Individuals can make applications for their refund at each quarter's end",
          "- If the amount is less than Rs.1000, no refund gets provided to that individual",
        ],
      },
      {
        title: "Conclusion",
        para: [
          "So, you have read what exactly the GST refund proves and the necessary documents required. This post also gave you an insight into the situations that result in refund claims, orders for GST refund claims and more. Now that you have learned the GST return process, it is time to apply for the GST return at your earliest.",
        ],
      },
    ],
  },
  "TDS Return Filing": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2Ftds_return.jpg?alt=media&token=c7d4425b-b6d7-43c3-aaa6-901eabfe44d9",
    head: "GST Refund",
    msme: "GST Refund including Government fee for individuals and SMEs.",
    regular:
      "GST Refund including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "TDS Certificate",
      "Tax Payment Challan",
      "Bank Statement",
    ],
    description: [
      {
        title: "What is TDS? – TDS Meaning and Full Form",
        para: [
          "TDS or Tax Deducted at Source is income tax reduced from the money paid at the time of making specified payments such as rent, commission, professional fees, salary, interest etc. by the persons making such payments. Usually, the person receiving income is liable to pay income tax. But the government with the help of Tax Deducted at Source provisions makes sure that income tax is deducted in advance from the payments being made by you. The recipient of income receives the net amount (after reducing TDS). The recipient will add the gross amount to his income and the amount of TDS is adjusted against his final tax liability. The recipient takes credit for the amount already deducted and paid on his behalf.",
          "For instance: Shine Pvt Ltd make a payment for office rent of Rs 80,000 per month to the owner of the property. TDS is required to be deducted at 10%. Shine Pvt ltd must deduct TDS of Rs 8000 and pay the balance of Rs 72,000 to the owner of the property. Thus, the recipient of income i.e. the owner of the property in the above case receives the net amount of Rs 72,000 after deduction of tax at the source. He will add the gross amount i.e. Rs 80,000 to his income and can take credit of the amount already deducted i.e. Rs 8,000 by Shine Pvt ltd against his final tax liability.",
        ],
      },
      {
        title: "When should TDS be deducted and by whom?",
        para: [
          "Any person making specified payments mentioned under the Income Tax Act is required to deduct TDS at the time of making such specified payment. But no TDS has to be deducted if the person making the payment is an individual or HUF whose books are not required to be audited.",
          "However, in case of rent payments made by individuals and HUF exceeding Rs 50,000 per month, are required to deduct TDS @ 5% even if the individual or HUF is not liable for a tax audit. Also, such Individuals and HUF liable to deduct TDS @ 5% need not apply for TAN. Your employer deducts TDS at the income tax slab rates applicable. Banks deduct TDS @10%. Or they may deduct @ 20% if they do not have your PAN information.",
          "For most payments rates of TDS are set in the income tax act and TDS is deducted by the payer basis of these specified rates. If you submit investment proofs (for claiming deductions) to your employer and your total taxable income is below the taxable limit – you do not have to pay any tax. And therefore no TDS should be deducted from your income.",
          "Similarly, you can submit Form 15G and Form 15H to the bank if your total income is below the taxable limit so that they don’t deduct TDS on your interest income. In case you have not been able to submit proofs to your employer or if your employer or bank has already deducted TDS and your total income is below the taxable limit) – you can file a return and claim a refund of this TDS. The complete list of Specified Payments eligible for TDS deduction along with the rate of TDS.",
        ],
      },
      {
        title: "What is the due date for depositing the TDS to the government?",
        para: [
          "The Tax Deducted at Source must be deposited to the government by the 7th of the subsequent month.",
          "For instance: TDS deducted in the month of June must be paid to the government by the 7th of July. However, the TDS deducted in the month of March can be deposited till 30th April. For TDS deducted on rent and purchase of property, the TDS payment due date is 30 days from the end of the month in which TDS is deducted.",
        ],
      },
      {
        title: "How to deposit TDS?",
        para: [
          "Tax Deducted at Source has to be deposited using Challan ITNS-281 on the government portal. Read our article for a step by step guide for depositing TDS payment online.",
        ],
      },
      {
        title: "How and when to file TDS returns?",
        para: [
          "Filing Tax Deducted at Source returns is mandatory for all the persons who have deducted TDS. TDS return is to be submitted quarterly and various details need to be furnished like TAN, amount of TDS deducted, type of payment, PAN of deductee, etc. Also, different forms are prescribed for filing returns depending upon the purpose of the deduction of TDS. Various types of return forms are as follows:",
          "Form 26Q TDS on all payments except salaries Q1 – 31st July Q2 – 31st October Q3 – 31st January Q4 – 31st May",
          "Form 24Q TDS on Salary Q1 – 31st July Q2 – 31st October Q3 – 31st January Q4 – 31st May",
          "Form 27Q TDS on all payments made to non-residents except salaries Q1 – 31st July Q2 – 31st October Q3 – 31st January Q4 – 31st May",
          "Form 26QB TDS on sale of property 30 days from the end of the month in which TDS is deducted",
          "Form 26QC TDS on rent 30 days from the end of the month in which TDS is deducted",
        ],
      },
      {
        title: "What is a TDS certificate?",
        para: [
          "Form 16, Form 16A, Form 16 B and Form 16 C  are all TDS certificates. TDS certificates have to be issued by a person deducting TDS to the assessee from whose income TDS was deducted while making payment. For instance, banks issue Form 16A to the depositor when TDS is deducted on interest from fixed deposits. Form 16 is issued by the employer to the employee.",
          "Form Certificate of Frequency Due date",
          "Form 16 TDS on salary payment Yearly 31st May",
          "Form 16 A TDS on non-salary payments Quarterly 15 days from due date of filing return",
          "Form 16 B TDS on sale of property Every transaction 15 days from due date of filing return",
          "Form 16 C TDS on rent Every transaction 15 days from due date of filing return",
        ],
      },
      {
        title: "TDS credits in Form 26AS",
        para: [
          "It is important to understand how TDS is linked to your PAN. TDS deductions are linked to PAN numbers for both the deductor and deductee. If TDS has been deducted from any of your income you must go through the Tax Credit Form 26AS. This form is a consolidated tax statement that is available to all PAN holders.",
          "Since all TDS is linked to your PAN, this form lists out the details of TDS deducted on your income by each deductor for all kinds of payments made to you – whether those are salaries or interest income – all TDS linked to your PAN is reported here. This form also has income tax directly paid by you – as advance tax or self-assessment tax. Therefore, it becomes important for you to mention your PAN correctly, wherever TDS may be applicable to your income.",
        ],
      },
      {
        title: "SMS Alerts for Higher Transparency",
        para: [
          "The income tax department has been sending SMS to the taxpayers from VK-ITDEFL that mentions the amount of tax deducted at source (TDS) against the PAN (Permanent Account Number) of the taxpayer. The SMS alert will let you know the TDS credited in respect of your income from salary, interest etc., every quarter. The amount of TDS would stand accumulated in your Form 26AS for the respective financial year.",
          "This initiative was implemented by the Finance Ministry to increase transparency and reduce the cases of TDS mismatches at the time of income tax filing. Taxpayers can cross-check the information provided in the SMS with the information on the payslips to make sure that there is no mismatch. TDS mismatch could be a common reason for incorrect income tax return filing.",
        ],
      },
      {
        title:
          "Tax liability in a case where TDS is already deducted from Income",
        para: [
          "On salary, TDS is deducted based on the income tax slab applicable to you. In the case of other income types, the TDS rates are fixed and vary between 10% and 20%. The tax rates are not based on your total income. Hence, you would suffer a TDS on your receipts in certain cases. Separately, you would be required to calculate your annual income by aggregating income from all sources.",
          "Your actual tax liability would be calculated on the total taxable income. From the taxes calculated, you can claim credit for TDS deducted on your various receipts. Reduce the tax deducted at source from your actual tax liability to know the balance to be paid to the income tax department. You may have a refund too. In both cases, you have to file an income tax return and pay the tax due or claim a refund.",
        ],
      },
    ],
  },
};

export const otherIPR = {
  "Bar code": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/otheripr%2Fbarcode.png?alt=media&token=a3841e02-eda7-41b8-a59d-84573af8350d",
    head: "Bar code",
    msme: "Bar code including Government fee for individuals and SMEs.",
    regular:
      "Bar code including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN card",
      "Copy of balance sheet",
      "Letter on company letterhead",
      "GST/VAT registration certificate",
    ],
    description: [
      {
        title: "Barcode Registration",
        para: [
          "With industrialisation, the increase in the number of products has made identification a tedious task, and hence there was a need for devising a system that would help in easy identification. And therefore in the year 1970, George J Laurer invented the system of the Universal Bar Code in the USA.",
          "Barcode is a machine-readable image that is characterised by parallel lines varying in width and spacing between them and digits. Barcode is used to encode product details such as product numbers, serial numbers and batch numbers instantly.",
          "It is used for product identification and used widely in supermarkets, clothing stores, malls, etc. and has inherent benefits like the elimination of errors, cost-effectiveness, time-saving and ease of managing inventory. This global identification system assists all the parties in the supply chain like manufacturers, logistics and wholesalers to identify the products easily.",
        ],
      },
      {
        title: "How Do Businesses Use Barcodes?",
        para: [
          "Barcodes are used to provide a unique symbol, often parallel lines and a number, that can be read by the scanner to populate all the information of the product from the database. Business houses use the barcodes for the following:",
          "Inventory database – Large departmental stores with thousands of products manage their inventory through the barcode system. All the phases of the product cycle from manufacture to sale are tracked through the code.",
          "Asset tracking – All businesses today hold a large chunk of IT assets, and hence the barcode system is used for tagging and tracking the assets in the asset software.",
          "Tracking returns – The barcode system can be used for tracking returns, especially so in the online shopping world. Barcodes can also be attached to invoices so as to ease the tracking of payments from customers.",
        ],
      },
      {
        title: "Types of Barcode",
        para: [
          "EAN -13 – International Article Number which is now called the European Article Number is a 13-digit number used for barcodes in Europe, India, and other nations.",
          "UPC- A – Universal Product Code or UPC – A is a 12 – digit barcode generally used in the US, Canada, UK, Australia, and New Zealand.",
          "Databar barcodes – These barcodes are generally used for fresh food products and contain additional information like a batch number or expiry date and weight of the product.",
          "1D barcodes – The GS1 128 and ITF – 14 are highly versatile 1D barcodes through which items of the global supply chains can be tracked. The GS1 128 barcodes can carry any of the ID keys and additional information like serial number, expiration dates, etc. The ITF-14 holds the Global Trade Item Number (GTIN) and is ideal for being applied to corrugated materials.",
          "2D barcodes – These barcodes have gained popularity with online payment wallets coming into the picture. These can hold a lot of information and are widely used by businesses to even share websites and videos. It can hold around 7000 digits or 4000 characters. It can also be used to integrate data with programs like MS Office, MS SQL, Servers, and other database files.",
        ],
      },
      {
        title: "Process For Obtaining Barcode Registration",
        para: [
          "The process for Barcode Registration is completely online. In India, the authority for registering Barcodes is GS1 India. The registration application can be made online on their website – https://www.gs1india.org.",
          "The steps involved are:",
          "Step 1 – Fill the application",
          "Step 2 – Upload all the relevant documents",
          "Step 3 – Choose the type and number of barcodes required",
          "Step 4 – Confirm all the details",
          "Step 5- Pay the applicable fees",
          "Step 6- Track the status",
        ],
      },
      {
        title: "Documents For Barcode Registration",
        para: [
          "Letter requesting allotment of barcode",
          "PAN card of the entity",
          "Copy of audited balance sheet",
          "GST/VAT registration certificate",
          "Certificate of incorporation/partnership deed",
          "Memorandum of Association/Articles of Association",
          "Copy of cancelled cheque",
        ],
      },
      {
        title: "Fees For Barcode Registration",
        para: [
          "The fees for barcode registration is dependent on the following:",
          "Turnover of the company",
          "Number of barcodes required",
          "Subscription period",
          "Depending on all these factors, the fees vary from around Rs. 44,000 to Rs. 2,20,000.",
        ],
      },
      {
        title: "Advantages of Barcode",
        para: [
          "Reduces error – Since the information on the barcode is all automated, the chances of errors are reduced since there is no manual intervention in the form of data entry.",
          "Cost-effective and convenient – Barcodes are easy to obtain, cost-effective, and convenient to print, and it can also be customised.",
          "Detailed information – Barcodes have the information related to the product like product numbers, serial numbers, batch numbers, and hence ensures the genuineness of the products.",
          "Optimised inventory – The movement of the products becomes smooth due to all information of the products being captured in the barcode and makes the movement through the supply chain hassle-free.",
          "Time saving – Registering a barcode saves a lot of time. Using a barcode reader, the code can be scanned, and the details can be obtained.",
          "Accurate and faster billing – The information linked to the barcode is all automated and accurate and helps in good and efficient decision making.",
          "Unique and global identification – The barcoding system facilitates unique identification that is globally recognised, and hence there will be no two products that will have the same code.",
        ],
      },
    ],
  },
  Copyright: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/otheripr%2Fcopyright.jpg?alt=media&token=8c2e6940-8cd2-470e-a162-7261ce37708f",
    head: "Copyright",
    msme: "Copyright including Government fee for individuals and SMEs.",
    regular:
      "Copyright including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Two Soft copies of work",
      "Demand Draft",
      "NOC from author / Applicant",
      "NOC from publisher",
      "Search Certificate issued by Registrar of Trademarks",
      "NOC from person whose photograph appears on the work",
      "NOC from Various Copyright Holders / Agreement Cop",
      "Source code and object code of work for verification",
    ],
    description: [
      {
        title: "Copyright Registration in India Explained – What , Why and How",
        para: [
          "In this article, we will understand the process of copyright registration in India in detail.",
        ],
      },
      {
        title: "What is Copyright?",
        para: [
          "A copyright is essentially a right not to copy someone’s work. A copyright gives the owner of the subject an exclusive right over his work. If a work is protected by copyright, no one can imitate, copy or reproduce the original work in any other way. A term of copyright in India is 60 years. Copyright can be taken for the following works:",
          "Music",
          "Books",
          "Manuscripts",
          "Films",
          "Fashion Designs",
          "Training Manuals",
          "Software",
          "Literary Work",
          "Performance",
          "Paintings, etc.",
        ],
      },
      {
        title: "Categories in the Registrar of Copyrights",
        para: [
          "The register of the Registrar of Copyrights is divided into 6 categories:",
          "1: Literary works other than computer Programs",
          "2: Musical Works",
          "3: Artistic Works",
          "4: Cinematography Films",
          "5: Sound Recording",
          "6: Computer Programs, tables & Compilations",
          "Copyrights are protected by “THE COPYRIGHT ACT, 1957” though there have several amendments to the act.",
        ],
      },
      {
        title:
          "Why should one get their work registered under the copyright law?",
        para: [
          "It is not mandatory to get copyright protection but always advisable to do so because it will give the owner a certain set of minimum rights over his work and the protection that no one will be able to copy his work for a minimum period of time. This satisfaction will always motivate the owner to do more work and create more items.",
        ],
      },
      {
        title: "Procedure to obtain a copyright registration",
        para: [
          "To obtain the copyright registration the following process has to be followed:",
          "1. An application (including all the particulars and the statement of the particulars) in the format of FORM IV has to have to be sent to the registrar along with the requisite fees (mentioned in the Schedule 2 of the act.). A separate application has to be made for separate works",
          "2. Fees for different works have been given by the government in this link: http://copyright.gov.in/frmFeeDetailsShow.aspx",
          "3. Every application has to be signed by the applicant as well as an Advocate in whose favor a Vakalatnama or a POA has been executed",
          "4. The registrar will issue a Dairy No. and then there is a mandatory waiting time for a period of 30 days for any objections to be received",
          "5. If there are no objections received within 30 days, the scrutinizer will check the application for any discrepancy and if no discrepancy is there, the registration will be done and an extract will be sent to the registrar for the entry in the Register of Copyright.",
          "6. If any objection is received, the examiner will send a letter to both the parties about the objections and will give them both a hearing.",
          "7. After the hearing, if the objections are resolved the scrutineer will scrutinize the application and approve or reject the application as the case may be.",
        ],
      },
    ],
  },
  Patent: {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/otheripr%2Fpatent.jpg?alt=media&token=667c0314-a7c0-475c-903a-8ed4c9542cb1",
    head: "Patent",
    msme: "Patent including Government fee for individuals and SMEs.",
    regular:
      "Patent including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Form 1: Application for Grant of Patent",
      "Form 2: Provisional / Complete Specification",
      "Form 3: Statement and Undertaking under Section 8",
      "Form 5: Declaration as to Inventor-ship",
      "Form 26: Power of attorney",
    ],
    description: [
      {
        title: "Patent Registration in India – Procedure, Requirements & Fees",
        para: [
          "The complete patent registration process involves a series of steps which are to be mandatorily followed to get a patent in India. In this post, we will discuss the registration process involved in registering a patent in India and the costs involved.",
        ],
      },
      {
        title:
          "Checking the patentability of the invention by performing a search",
        para: [
          "Before filing a patent application in India, one should perform a detailed patentability search to determine whether a patent for it will be available or not. You can use the below link to conduct the patentability search http://ipindiaservices.gov.in/publicsearch It is to be noted here that this step in itself is not mandatory.",
        ],
      },
      {
        title: "Drafting of patent application",
        para: [
          "Once the search is complete and through, the next step involved is to prepare an application form in form 1.",
          "Each application has to be accompanied by a patent specification. This has to be prepared in form 2 where one has to provide the complete or provisional specification depending upon the state of the invention(Whether it's partially completed or completed). In case one files a provisional application, a time gap of 12 months is provided to finalize the invention and file the complete application.",
          "A patent draft will also be required to be submitted along with the application. The patent draft is considered a very important document as the same will be used by the patent office in deciding whether or not the patent should be granted.",
        ],
      },
      {
        title: "Filing the patent application in India",
        para: [
          "No Stages of the patent process Form No.",
          "1. Application for grant of patent Form 1",
          "2. Provisional/complete specification Form 2",
          "3. Statement and undertaking under section 8 (this is only required where a patent application is already filed in the country other than India) Form 3",
          "4. Declaration as to inventorship Form 5",
          "5. Forms submitted only by start-ups and small entities. Form 28",
        ],
      },
      {
        title: "Publication of patent application",
        para: [
          "Patent application filed with the Indian patent office will be published in the official patent journal. This is generally done after 18 months of filing the application. In case one wants to get it published earlier, he can make a request in form 9 for early publication. When a restriction is placed by the Indian patent act with regards to the publishing of the patent, the same will not be published in the journal.",
        ],
      },
      {
        title: "Examining of patent application",
        para: [
          "Every application filed for protection will be examined before a patent is finally granted. The application has to be made for examination in form 18. The earlier one makes a request, the earlier the application will be examined by the examiner. Once the application is filed, it is transferred to the patent officer who will examine the application to ensure the same is in accordance with the patent act and rules. A thorough search is conducted by the officer where he/she analyses the relevant technology in depth and the objections, if any, will be communicated. The report issued in this case is called the First Examination Report(FER).",
        ],
      },
      {
        title: "Grant of patent",
        para: [
          "The patent is granted once all the objections raised by the officer are resolved.",
        ],
      },
      {
        title: "Rules to keep in mind while filing the patent application",
        para: [
          "The fees payable with respect to the grant of patents and applications therefor, and in respect of other matters for which fees are required to be payable are specified in the First Schedule.",
          "An additional fee of 10% is payable when the applications for patent and other documents are filed physically.",
          "The fees payable under the Act or rules may be paid at the appropriate office either in cash, or through electronic means, or may be sent by bank draft, or banker‘s cheque. The amount is payable to the Controller of Patents and drawn on a scheduled bank at the place where the appropriate office is situated. If the draft or banker‘s cheque is sent by post, the fees shall be deemed to have been paid on the date on which the draft or banker‘s cheque has actually reached the Controller.",
          "Where a fee is payable with respect to a document, the entire fee shall accompany the document.",
          "In case of transfer of application from a natural person to other than a natural person, the difference, if any between the fee shall be paid by the new applicant with the request for transfer.",
          "In case an application by a small entity is fully or partly transferred to a person other than a natural person, the difference, if any, between the fee shall be paid by the new applicant with the request for transfer.",
          "In case an application processed by a start-up is fully or partly transferred to any person other than a natural person or a start-up, the difference, if any, can be charged from a start-up and such person to whom the application is transferred. In short this shall be paid by the new applicant along with the request for transfer:",
          "Explanation: Where the start-up ceases to be a start-up after having filed an application for patent due to lapse of more than five years from the date of its incorporation or registration or the turnover subsequently crosses the financial threshold limit as defined, no such difference in the scale of fees shall be payable.",
          "Fees once paid in respect of any proceeding shall not ordinarily be refunded irrespective of whether the proceeding has taken place or not:",
          "Exception: If the Controller is satisfied that during the online filing process, the fee was paid more than once for the same, the excess fee shall be refunded.",
          "A refund is initiated only if an applicant withdraws his request for examination before the first statement of objection is issued. Please note that this refund is only to the extent prescribed in the First Schedule.",
          "Any person may deposit money in advance and request the Controller to realize any fee payable by him from the said deposit.",
          "Subject to the approval of the Controller, any person may discontinue the deposit of money in advance and in such case the balance, if any, shall be refunded.",
        ],
      },
    ],
  },
};

export const designRegistration = {
  "Design Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/design%2Fdesign.jpg?alt=media&token=29c24c4d-18f3-4c79-91f9-c49ce892c58e",
    head: "Design Registration",
    msme: "Design Registration including Government fee for individuals and SMEs.",
    regular:
      "Design Registration including Government fee for entities NOT having MSME registration.",
    documentsRequired: [],
    description: [
      {
        title: "What is meant by ‘Design’ under the Designs Act, 2000?",
        para: [
          "‘Design’ means only the features of shape, configuration, pattern or ornament or composition of lines or colour or combination thereof applied to any article whether two dimensional or three dimensional or in both forms, by any industrial process or means, whether manual, mechanical or chemical, separate or combined, which in the finished article appeal to and are judged solely by the eye, but does not include any mode or principle or construction or anything which is in substance a mere mechanical device, and does not include any trade mark, as define in clause (v) of sub-section of Section 2 of the Trade and Merchandise Marks Act, 1958, property mark or artistic works as defined under Section 2(c) of the Copyright Act, 1957.",
        ],
      },
      {
        title: "What is meant by an article under the Designs Act, 2000?",
        para: [
          "Under the Designs Act, 2000 the 'article' means any article of manufacture and any substance, artificial, or partly artificial and partly natural; and includes any part of an article capable of being made and sold separately.",
        ],
      },
      {
        title: "What is the object of registration of Designs?",
        para: [
          "Object of the Designs Act is to protect new or original designs so created to be applied or applicable to a particular article to be manufactured by Industrial Process or means. Sometimes the purchase of articles for use is influenced not only by their practical efficiency but also by their appearance. The important purpose of design Registration is to see that the artisan, creator, originator of a design having aesthetic look is not deprived of his bonafide reward by others applying it to their goods.",
        ],
      },
      {
        title:
          "Can stamps, labels, tokens, cards be considered an article for the purpose of registration of Design?",
        para: [
          "No. Because once the alleged Design i.e., ornamentation is removed only a piece of paper, metal or like material remains and the article referred ceases to exist. Article must have its existence independent of the Designs applied to it. [Design with respect to label was held not registrable, by an Order on civil original case No. 9-D of 1963, Punjab, High Court]. So, the Design as applied to an article should be integral with the article itself.",
        ],
      },
      {
        title:
          "When does the Applicant for Registration of Design get the registration certificate?",
        para: [
          "When an application for registration of a Design is in order, it is accepted and registered and then a certificate of registration is issued to the applicant. However, a separate request should be made to the Controller for obtaining a certified copy of the certificate for legal proceeding with requisite fee.",
        ],
      },
      {
        title: "What is a Register of Designs?",
        para: [
          "The Register of Designs is a document maintained by The Patent Office, Kolkata as a statutory requirement. It contains the design number, class number, date of filing (in this country) and reciprocity date (if any), name and address of Proprietor and such other matters as would affect the validity of proprietorship of the design and it is open for public inspection on payment of prescribed fee & extract from register may also be obtained on request with the prescribed fee.",
        ],
      },
      {
        title: "What is the effect of registration of design?",
        para: [
          "The registration of a design confers upon the registered proprietor ‘Copyright’ in the design for the period of registration. ‘Copyright’ means the exclusive right to apply a design to the article belonging to the class in which it is registered.",
        ],
      },
      {
        title:
          "What is the duration of the registration of a design? Can it be extended?",
        para: [
          "The duration of the registration of a design is initially ten years from the date of registration, but in cases where claim to priority has been allowed the duration is ten years from the priority date. This initial period of registration may be extended by further period of 5 years on an application made in Form-3 accompanied by prescribed fees to the Controller before the expiry of the said initial period of ten years. The proprietor of a design may make application for such extension even as soon as the design is registered.",
        ],
      },
      {
        title: "What is the date of registration?",
        para: [
          "The date of registration except in case of priority is the actual date of filing of the application. In case of registration of design with priority, the date of registration is the date of making an application in the reciprocal country.",
        ],
      },
      {
        title:
          "Is it possible to re-register a design in respect of which Copyright has expired?",
        para: [
          "No. a registered design, the copyright of which has expired cannot be re-registered.",
        ],
      },
      {
        title:
          "How one can ascertain whether registration subsists in respect of any design?",
        para: [
          "For ascertaining whether registration subsists in respect of a design, a request should be made to the Patent Office, Kolkata. If the Design number is known, the request should be made on Form 6, otherwise on Form 7, together with prescribed fees. Each such request should be confined to information in respect of a single design.",
        ],
      },
      {
        title: "What is piracy of a Design?",
        para: [
          "Piracy of a design means the application of a design or its imitation to any article belonging to a class of articles in which the design has been registered for the purpose of sale or importation of such articles without the written consent of the registered proprietor. Publishing such articles or exposing terms for sale with knowledge of the unauthorized application of the design to them also involves piracy of the design.",
        ],
      },
      {
        title: "What is the penalty for the piracy of a registered Design?",
        para: [
          "If anyone contravenes the copyright in a design he is liable for every offence to pay a sum not exceeding Rs. 25,000/- to the registered proprietor subject to a maximum of Rs. 50,000/- recoverable as contract debt in respect of any one design. The registered proprietor may bring a suit for the recovery of the damages for any such contravention and for injunction against repetition of the same. Total sum recoverable shall not exceed Rs. 50,000/-as contract debt as stated in Section 22(2)(a). The suit for infringement, recovery of damage etc should not be filed in any court below the court of District Judge.",
        ],
      },
      {
        title:
          "Is marking of an article compulsory in the cases of article to which a registered design has been applied?",
        para: [
          "Yes, it would be always advantageous to the registered proprietors to mark the article so as to indicate the number of the registered design except in the case of Textile designs. Otherwise, the registered proprietor would not be entitled to claim damages from any infringer unless the registered proprietor establishes that the registered proprietor took all proper steps to ensure the marking of the article, or unless the registered proprietor shows that the infringement took place after the person guilty thereof knew or had received notice of the existence of the copyright in the design.",
        ],
      },
      {
        title: "Can the Registration of a Design be cancelled?",
        para: [
          "The registration of a design may be cancelled at any time after the registration of design on a petition for cancellation in form 8 with prescribed fee to the Controller of Designs on the following grounds:\n\n1. That the design has been previously registered in India or\n2. That it has been published in India or elsewhere prior to date of registration or\n3. The design is not new or original or\n4. Design is not registrable or\n5. It is not a design under Clause (d) of Section 2.",
        ],
      },
      {
        title:
          "Is it mandatory to make the article by industrial process or means before making an application for registration of design?",
        para: [
          "No, design means a conception or suggestion or idea of a shape or pattern which can be applied to an article or capable to be applied by industrial process or means. Example- a new shape which can be applied to a pen thus capable of producing a new appearance of a pen on the visual appearance. It is not mandatory to produce the article first and then make an application.",
        ],
      },
      {
        title:
          "Why is it important for filing the application for registration of design at the earliest possible?",
        para: [
          "First-to-file rule is applicable for registrability of design. If two or more applications relating to an identical or a similar design are filed on different dates only first application will be considered for registration of design.",
        ],
      },
      {
        title:
          "Can the same applicant make an application for the same design again, if the prior application has been abandoned?",
        para: [
          "Yes, the same applicant can apply again since no publication of the abandoned application is made by the Patent Office, provided the applicant does not publish the said design in the meanwhile.",
        ],
      },
      {
        title: "How to get information on the registration of design?",
        para: [
          "After registration of designs the best view of the article along with other bibliographic data will be notified in the Official Journal of The Patent Office, which is being published every Friday.",
        ],
      },
      {
        title: "Whether it is possible to transfer the right of ownership?",
        para: [
          "Yes, it is possible to transfer the right through assignment, agreement, transmission with terms and condition in writing or by operation of law. However, certain restrictive conditions not being the subject matter of protection relating to registration of design should not be included in the terms and condition of the contract/agreement etc. An application in form-10, with prescribed fees in respect of one design and appropriate fees for each additional design, for registration of the transfer documents is required to be made by the beneficiary to the Controller within six months from the date of execution of the instruments or within further period not exceeding six months in aggregate. An original/notarized copy of the instrument to be registered is required to be enclosed with the application.",
        ],
      },
      {
        title: "What is meant by priority claim?",
        para: [
          "India is one of the countries party to the Paris Convention so the provisions for the right of priority are applicable. On the basis of a regular first application filed in one of the contracting states, the applicant may within the six months apply for protection in other contracting states, latter application will be regarded as if it had been filed on the same day as the first application.",
        ],
      },
      {
        title:
          "How it is possible to restore the lapsed design due to non-payment of extension fee within prescribed time?",
        para: [
          "A registration of design will cease to be effective on non-payment of extension fee for further term of five years if the same is not paid before the expiry of the original period of 10 years. However, lapsed designs may be restored provided the following conditions are satisfied:\n\n1. Application for restoration in Form-4 with prescribed fees is filed within one year from the date of lapse stating the ground for such non-payment of extension fee with sufficient reasons.\n2. If the application for restoration is allowed the proprietor is required to pay the prescribed extension fee and requisite additional fee and finally the lapsed registration is restored.",
        ],
      },
      {
        title:
          "Can the name, address of proprietor or address for service be altered in the register of design?",
        para: [
          "Name and address of the registered proprietor, or address for service can be altered in the register of designs provided this alteration is not made by way of change of ownership through conveyance i.e. deed of assignment, transmission, licence agreement or by any operation of law. Application in form-22 with prescribed fee of should be filed to the Controller of Designs with all necessary documents in support of the application as required.",
        ],
      },
      {
        title: "Are the registered designs open for public inspection?",
        para: [
          "Yes, registered designs are open for public inspection only after publication in the official journal on payment of prescribed fee on a request in Form-5.",
        ],
      },
      {
        title:
          "Can the application for registration of design be filed by the applicant himself only or through a professional person?",
        para: [
          "The application for registration of design can be filed by the applicant himself or through a professional person (i.e. patent agent, legal practitioner). However, for the applicants not resident of India an agent residing in India has to be employed.",
        ],
      },
      {
        title:
          "What are the important criteria for determining a 'set of article'?",
        para: [
          "If a group of articles meets the following requirements then that group of articles may be regarded as a set of articles under the Designs Act, 2000:\n\n1. Ordinarily on sale or intended to be used together.\n2. All having common design even though articles are different (same class).\n3. Same general character.\n\nGenerally, an article having the same design and sold in different sizes is not considered as a set of articles. Practical example: 'Tea set', 'Pen set', 'Knife set' etc.",
        ],
      },
      {
        title:
          "What is an artistic work which is not subject matter of registration?",
        para: [
          "An artistic work as defined under Section 2(c) of the Copyright Act, 1957 is not a subject matter for registration which reads as follows:\n\n'Artistic works' means:\n\n1. A painting, a sculpture, a drawing (including a diagram, map, chart or plan) on engraving or a photograph, whether or not such work possesses artistic quality.\n2. An work of architecture and\n3. Any other work of artistic craftsmanship.",
        ],
      },
      {
        title:
          "What is meant by classification of goods mentioned in the Third Schedule?",
        para: [
          "The applications for registration of Designs applied to articles are classified according to the Third Schedule of Designs Rules, 2001 for its classification. This is mainly based on the International Classification System for Industrial Designs known as Locarno Classification. Only one class number is to be mentioned in one particular application which is mandatory under the Rules. This classification has been made on the basis of Articles on which the design is applied.",
        ],
      },
      {
        title:
          "What is meant by Property mark as per the Indian Penal Code, Sec. 479?",
        para: [
          "A mark used for denoting that movable property belongs to a particular person is called a property mark. It means that marking any movable property or goods, or any case, package or receptacle containing goods; or using any case, package or receptacle, with any mark thereon. Practical example: The mark used by the Indian Railway on their goods may be termed as a Property Mark for the purpose of easy identification of the owner.",
        ],
      },
      {
        title: "What is meant by Intellectual Property?",
        para: [
          "Intellectual Property is the Property, which has been created by the exercise of Intellectual Faculty. It is the result of persons Intellectual Activities. Thus Intellectual Property refers to creation of mind such as inventions, designs for industrial articles, literary, artistic work, symbols which are ultimately used in commerce. Intellectual Property rights allow the creators or owners to have the benefits from their works when these are exploited commercially. These rights are statutory rights governed in accordance with the provisions of corresponding legislations. Intellectual Property rights reward creativity & human endeavor which fuel the progress of humankind.\n\nThe intellectual property is classified into seven categories i.e.\n\n1. Patent\n2. Industrial Design\n3. Trade Marks\n4. Copyright\n5. Geographical Indications\n6. Lay out designs of integrated circuits\n7. Protection of undisclosed information/Trade Secret according to TRIPs agreements.",
        ],
      },
      {
        title:
          "What are the essential requirements for the registration of ‘design’ under the Designs Act, 2000?",
        para: [
          "The design should be new or original, not previously published or used in any country before the date of application for registration. The novelty may reside in the application of a known shape or pattern to new subject matter.\nThe design should relate to features of shape, configuration, pattern or ornamentation applied or applicable to an article.\nThe design should be applied or applicable to any article by any industrial process.\nThe features of the design in the finished article should appeal to and are judged solely by the eye. This implies that the design must appear and should be visible on the finished article, for which it is meant.\nAny mode or principle of construction or operation or any thing which is in substance a mere mechanical device, would not be a registrable design. For instance, a key having its novelty only in the shape of its corrugation or bent at the portion intended to engage with levers inside the lock associated with, cannot be registered as a design under the Act.\nThe design should not include any Trade Mark or property mark or artistic works as defined under the Copyright Act, 1957.",
        ],
      },
    ],
  },
  "Logo Design": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/design%2Flogo_design.jpg?alt=media&token=96c66a13-7627-4ba1-9a09-b6217f63a719",
    head: "Logo Design",
    msme: "Logo Design including Government fee for individuals and SMEs.",
    regular:
      "Logo Design including Government fee for entities NOT having MSME registration.",
    documentsRequired: [],
    description: [
      {
        title: "What is a Logo Design?",
        para: [
          "The average consumer takes 7 seconds to form a judgment about your brand.",
          "Admittedly, that's a small window for you to condense everything you offer as a company and set you apart from the competition. It takes longer to reheat a pop tart in the toaster. But there is something you can do to aid that process.",
          "With logo design, you have a visual communication tool to help you get your message across to the audience. Logos are composed of text, color, shape, and illustrations. It serves as a self-explanatory image that people can use to connect with your brand. This informs the audience while giving them something to associate your brand and ideas with.",
          "Graphic marks have been long used by humans to signify their products, work, and even their bloodline. You may know them through hieroglyphics, royal family crests, and the like.",
          "This article will teach you everything you need to know about logos. View the overview of topics we will tackle here and get excited to learn more about this important matter of your business.",
        ],
      },
      {
        title: "What is the purpose of a logo?",
        para: [
          "Your brand mark is more than just decor to make your brand assets and packaging pretty. It's something that you can keep in your arsenal to improve brand engagement.",
          "Apart from being the face of your business, it allows you to influence consumer decisions. A research found that consumers value the visual identity of a company. 74% of consumers cite this as a deciding factor when making a purchase decision.",
          "It's the first thing they see and invites them to learn more about a company's goods and services.",
          "An established brand image lets you cultivate brand loyalty, too. It positively impacts both existing and potential leads. You can use this as a competitive advantage and drive brand consistency, which can boost your revenue up by 33%.",
        ],
      },
      {
        title: "Four Common Types of Logos",
        para: [
          "This art of creating a brand identity takes on different forms. There is more than one way for you to present yourself in the market. Getting more familiar with the different types of brand marks lets you widen your options, allowing you to figure out which one is the most appropriate for your business.",
          "1. Wordmark logo",
          "The wordmark logo is a design that depicts the entirety of your business name. It lets your audience become more familiar with your full name, making this an excellent option for new companies. This design is used by big brands such as Coca-Cola and Google.",
          "2. Lettermark logo",
          "Also known as monogram logos or initial logos, this design consists of a brand's abridged name. This is done for businesses with long names or brands that want a visually concise identity. It is also a good choice for established companies that have been around for a while. AdAge, Dunkin', and Weight Watchers are some of the big brands that switched to this type of design.",
          "3. Combination mark logo",
          "The combination mark pairs both of the designs mentioned above. Balmain Paris and Adobe have some of the most recognizable combination mark logos today. This design helps introduce your brand more directly as it sets forth two varying versions of your identity at once.",
          "4. Brand mark",
          "Not every logo comes with text. Contrary to the designs we've discussed so far, a brand mark logo uses drawings as the brand's identifier and nothing else. Famous companies like Starbucks and Apple put this style in use.",
        ],
      },
      {
        title: "What to Consider When Choosing a Logo",
        para: [
          "Besides choosing between logo design types, other factors come into play when creating a logo. You also want to think about other important aspects, whether you are using a logo creation tool or working with a branding firm.",
          "It's not enough to pick the best-looking designs.",
          "It is also essential to make sure you end up with the logo that will allow you to achieve your objectives. This section will show three important things to help you pick the suitable logo for your brand.",
          "You don't want to forget about the following things.",
          "1. Scalability",
          "An effective logo is a functional one. You want to make sure that your logo displays well in different dimensions or sizes. This allows you to have an easier time applying it to other materials like business cards or advertisements. Regardless of the material you are putting your logo on, it must be legible and easy to understand.",
          "2. Brand goals",
          "Do you want your brand to be recognized as edgy? Do you want it to appear sleek? Identifying your brand goals plays a huge role in choosing a logo. It is best to study your market to further understand how you can present your company uniquely and memorably.",
          "3. Competitors",
          "They say imitation is the best compliment. But this is not true with logo design. This may confuse customers and dampen the impact of your brand identity. You want to make sure that your logo doesn't look similar to other brands in the market to avoid this. Plus, this also prevents you from getting caught up in unwanted lawsuits related to trademark infringement.",
        ],
      },
      {
        title: "How to Get a Logo",
        para: [
          "Today, brands have a plethora of options to use in sourcing a brand identity. Each has its unique perks as well as price points. Find out which method is the right one for you.",
          "1. Work with a design consulting firm",
          "Tapping an agency to create a brand identity may be costly for some. However, with this method, you can make sure that experts can guide your every step. These firms often have other branding solutions like social media marketing, advertising, and other offerings for a full service.",
          "2. Crowdsource",
          "There are a ton of crowdsourcing platforms on the internet right now. You can use this to run logo design contests. In terms of costs, this option is mid-range. It connects you with freelance designers who will submit design proposals for you to choose from. From the submission pool, you can find the one that you like the most and use that for your identity.",
          "3. Take the DIY route",
          "For those with not a lot of budget for professional assistance, logo makers or generators are a good choice. This is a do-it-yourself option. However, it doesn't mean you'll start entirely from scratch. Online logo generators offer design libraries as well as tools for customizing design elements according to your liking. Learn more about online logo makers on BrandCrowd.",
        ],
      },
      {
        title: "Conclusion",
        para: [
          "Logos allow businesses of various sizes to present their brand strategically. This relatively small piece of graphic design dramatically impacts the way you are perceived. When done right, it can do wonders for your brand and foster business growth.",
        ],
      },
    ],
  },
  "Logo Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/design%2Flogo_registration.jpg?alt=media&token=28330b60-6f68-4e0e-8805-dc57a66d0044",
    head: "Logo Registration",
    msme: "Logo Registration including Government fee for individuals and SMEs.",
    regular:
      "Logo Registration including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Address Proof of Signatory",
      "Identity Proof of Signatory",
      "Incorporation Certificate or Partnership Deed",
      "Signed Form-48",
      "Copy of Logo (Optional)",
      "Udyog Aadhar Registration Certificate",
    ],
    description: [
      {
        title: "How Can I Register My Logo Online?",
        para: [
          "Because of its simplicity and straightforward strategies, online logo registration is a popular option for business visionaries.",
          "A trademark lawyer or legal professional can help you navigate the process of logo registration. The most important part of the whole process is logo search. One should be clear about the requirements for a particular logo design that suits the business needs.",
        ],
      },
      {
        title: "How to Register Logos in India?",
        para: [
          "A trademark is a combination of characters or numerals used to distinguish its products or services from others on the market. Trademark registration can help prevent a product or service from being copied or duplicated by other entities or competitors.",
          "Trademarks in India: The Advantages. Do Benefit!",
          "A registered trademark has the advantage of protecting the brand and business and adding to the goodwill of the company.",
          "A trademark acts as a bridge between customers and the brand, ensuring loyalty to the company for many years to come.",
          "A trademark is a guarantee of quality.",
          "This credit is given to the source of the product/service.",
          "It aids in the sale of goods or services.",
        ],
      },
      {
        title: "Trademark Registration Process Components",
        para: [
          "Trademark Search",
          "To pinpoint a name, you need to use a systematic approach. This aspect will ensure that you don’t pick a name already in use. You can create your brand name by using a combination of generic words.",
          "Trademark Application",
          "It is essential to include the correct information in your application to avoid complaints from the trademark office. Therefore, the following supporting documents should be submitted along with your application:",
          "- Based on the registered business, proof of identity and address must be provided if the sole proprietorship is a business, ID proof of the owner viz. Aadhar Card Documents or PAN (Permanent Account Number) card can be submitted. In the case of companies, however, the address proof for the company must be provided.",
          "- Copy the Logo",
          "A proof of claim for the proposed mark (if applicable) can be used in another nation.",
          "- Lettered authorization in Form TM 48 to the trademark attorney. To be trademarked by Applicant.",
          "Examining the Trademark Registry’s Application",
          "After the application has been submitted, the Registrar verifies the accuracy of the information, trade description, selected class, and generosity of the brand name. The brand name must not be identical to any trademark registered or applied. The Applicant shall notify any discrepancy in or similarity of the brand names. The Applicant, through their Attorney, shall legally reply to any objections raised within 30 days of the date of examination.",
          "Advertisement in Trademark Journal",
          "The Indian Trademark Journal must be published if the Trademark is approved for registration. This aspect is the most crucial phase in the registration of a trademark. Rival brands should file no opposition within four months (120 days).",
          "Register Certificate",
          "If there is no opposition within 120 days, the Registrar must register a trademark application. The Trademark Registry seal is attached to the Registration Certificate issued by the Registrar. Once you have received your certificate, you can use the registered trademark logo or next to your brand name. The certificate is valid for 10 years and can be renewed every 10 years.",
        ],
      },
      {
        title: "These Are the Stages of Trademark Registration (In detail)",
        para: [
          "Trademark Search",
          "An exhaustive Trademark Search is the first thing. An exhaustive search will give you a lot of information about similar TradeMarks, which can help you choose the right Trademark to register. An experienced Trademark lawyer will help you choose the right Trademark.",
          "Trademark Application",
          "After the Trademark has been finalized, it can be filed electronically or at the Trademark registry. Five Trademark Registry offices in India are located in Delhi (Mumbai), Kolkata, Chennai, and Ahmedabad.",
          "The fees charged by the government depend on the type of application and the filing method. For natural persons, the fee is INR 5500 if filed in person. Electronic filings are available at a 10% discount. You can file your application in one or more classes, depending on the nature of the goods and services. The fee is charged per class.",
          "Reception of the Filing Receipt",
          "A trademark application must be filed with the Trademark Registration Office. This number allows you to track the status of your application online.",
          "Vienna Codification and Formality Check Pass",
          "After a trademark application has been filed, it is subject to several formality checks. If the application has technical flaws, a formality fail is issued. The Applicant must correct the problem within the prescribed time. A trademark that includes figurative elements of artistic work or a logo must undergo Vienna Codification. Vienna Code search can only be made possible by following the formalities set forth at Trademark Registry.",
          "Examination of Trademark Application and Issuing Examination Report",
          "A Trademark application will usually be examined within one month of the filing date. A Trademark Examiner examines Trademark applications under both absolute and relative grounds of objection under Section 9&11 of the Trademarks Act 1999. An Examiner can either accept or object to the application under Section 9 and 11.",
          "Response to the Examination Report",
          "If the Examiner objects to a Trademark application under Section 9 or both, it must reply to the examination report within a stipulated period of 30 days. The application will be discarded if not replied to in the prescribed time. Therefore, a detailed reply to the examination report is recommended with strong averments and supporting pieces of evidence.",
          "Auditory",
          "The examination report’s reply may not convince the Examiner. If that happens, a hearing will most likely be scheduled. A hearing is not subject to a time limit, but the Applicant and their agent must attend when it is scheduled. The Examiner will accept or reject the Trademark based on the evidence provided at a hearing.",
          "Advertisement in an Official Journal About a Trademark",
          "After a mark has been accepted or permitted by the Examiner at the examination, or after hearing it, it is published in an official journal. Advertisement serves the purpose of communicating to the public that a mark is soon registered. If anyone has any problems with registration, they can file Opposition within four months of the publication date.",
          "Certificate of Trademark Registration",
          "A Trademark is granted if there is no opposition within the four months of advertisement. A mark can be registered for 10 years starting from the date it was filed. You can renew your registration during the last year by paying the appropriate fees.",
          "A skilled Trademark Lawyer will make your Trademark Registration process very easy and help you get your mark registered in as little as six months.",
        ],
      },
    ],
  },
};

export const isoDatabase = {
  "ISO 9001": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/iso%2Fiso_9001.jpg?alt=media&token=ff47e368-5301-49a3-8aed-f4e8d6543efc",
    head: "ISO 9001 (Quality Management System)",
    msme: "ISO Filing including Government fee for individuals and SMEs.",
    regular:
      "ISO Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Bank Statement Year-1",
      "Nature of Firm",
      "Address Proof (Rent Agreement, Electricity Bill, Telephone, etc.)",
    ],
    description: [
      {
        title: "Why ISO 9001?",
        para: [
          "ISO 9001 sets out the criteria for a quality management system and is the only standard in the family that can be certified to (although this is not a requirement). It can be used by any organization, large or small, regardless of its field of activity. In fact, there are over one million companies and organizations in over 170 countries certified to ISO 9001.",
          "This standard is based on a number of quality management principles including a strong customer focus, the motivation and implication of top management, the process approach and continual improvement. These principles are explained in more detail in ISO’s quality management principles. Using ISO 9001 helps ensure that customers get consistent, good-quality products and services, which in turn brings many business benefits.",
        ],
      },
    ],
  },
  "ISO 14001": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/iso%2Fiso_14001.jpg?alt=media&token=242d6548-c337-4e54-88eb-bf00b88fc72b",
    head: "ISO 14001 (Environment Management System)",
    msme: "ISO Filing including Government fee for individuals and SMEs.",
    regular:
      "ISO Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Bank Statement Year-1",
      "Nature of Firm",
      "Address Proof (Rent Agreement, Electricity Bill, Telephone, etc.)",
    ],
    description: [
      {
        title: "What Is ISO 14001?",
        para: [
          "ISO 14001 is a set of standards put forward by the International Organization for Standardization (ISO). Its purpose is to clarify the best practices for organizations that wish to reduce their environmental footprint by adopting an effective environmental management system (EMS).",
          "EMS systems are designed to monitor and report upon the environmental sustainability of a firm, for both internal and external stakeholders. They are used by firms both for compliance purposes—allowing them to avoid fines or public relations (PR) scandals—and to increase business efficiency, such as by reducing waste through the manufacturing or distribution cycle.",
        ],
      },
      {
        title: "How ISO 14001 Works",
        para: [
          "ISO 14001 is just one of the over 23,000 standards that have been put forward by the ISO since its formation in 1947. They are part of a family of standards designed for organizations that wish to decrease pollution and waste by introducing an environmental management system. As with all ISO standards, the ISO 14001 is not a specific set of instructions to be narrowly executed by the participating firm. Rather, it is a set of guidelines and standards that must be tailored to the specific needs and circumstances of the organization in question.",
          "Of course, not all applications of ISO 14001 are equally robust. In order to maintain the integrity of the standards, companies can obtain certification by third-party organizations that will assess their specific implementation of the ISO 14001 standards and determine whether that application meets the international best practices set out in the standard. Companies that obtain certification in this manner will often display this fact as a way to substantiate and advertise their commitment to environmental sustainability.",
        ],
      },
      {
        title: "Example of ISO 14001",
        para: [
          "Originally launched in September 1996, ISO 14001 has since been adopted by over 300,000 organizations worldwide.",
          "The core concept of ISO 14001 is that organizations must develop a clear set of environmental policies, use a set of best practices to proactively monitor whether those policies are being adhered to, and continuously improve the system based on ongoing feedback and results.",
          "By design, the standards are intended to be flexible and applicable to organizations ranging from small businesses to multinational corporations. By way of example, the famous Hilton hotel chain gained certification in ISO 14001 and was featured by ISO for the impressive environmental improvements that it achieved through this process. According to the company’s spokesman, the company achieved over $1 billion in energy savings between 2008 and 2018, due to changes implemented as part of the ISO 14001 process.",
          "In addition to cost savings, there are many other reasons why companies might wish to adopt the ISO 14001 standards. These include benefits to their brand awareness, reduced risk of transgressing environmental regulations, improvements to employee relations and morale, and increased operational efficiencies, among others.",
        ],
      },
    ],
  },
  "ISO 45001": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/iso%2Fiso_45001.jpg?alt=media&token=7262d638-d93f-4aa6-9e57-5007af3158ae",
    head: "ISO 45001 (Occupational Health & Safety)",
    msme: "ISO Filing including Government fee for individuals and SMEs.",
    regular:
      "ISO Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Bank Statement Year-1",
      "Nature of Firm",
      "Address Proof (Rent Agreement, Electricity Bill, Telephone, etc.)",
    ],
    description: [
      {
        title: "What is ISO 45001?",
        para: [
          "ISO 45001 is the world’s international standard for occupational health and safety, issued to protect employees and visitors from work-related accidents and diseases.",
          "ISO 45001 certification was developed to mitigate any factors that can cause employees and businesses irreparable harm.",
          "Its standards are the result of great effort by a committee of health and safety management experts who looked closely at a number of other approaches to system management — including ISO 9001 and ISO 14001.",
          "In addition, ISO 45001 was designed to take other existing occupational health and safety standards, such as OHSAS 18001, into account — as well as the ILO’s labor standards, conventions, and safety guidelines.",
        ],
      },
      {
        title: "Goal of ISO 45001",
        para: [
          "Especially geared toward senior management, ISO 45001 has the ultimate goal of helping businesses provide a healthy and safe working environment for their employees and everyone else who visits the workplace.",
          "This goal can be achieved by controlling factors that could potentially lead to injury, illness, and — in extreme situations — even death.",
          "As a result, ISO 45001 is concerned with mitigating any factors that are harmful or that pose a danger to workers’ physical and/or mental well-being.",
        ],
      },
      {
        title: "Workplace Safety Statistics",
        para: [
          "Sadly, thousands of workers lose their lives each day to preventable instances of adverse workplace conditions.",
          "In fact, according to the ISO and International Labour Organization — or ILO — more than 2.7 million deaths occur globally due to occupational accidents.",
          "And in addition to that, there are 374 million non-fatal injuries each year, resulting in 4 or more days absences from work.",
        ],
      },
      {
        title: "Significance of ISO 45001",
        para: [
          "According to many health and safety experts — including the professionals who worked on the ISO committee — ISO 45001 represents a landmark breakthrough.",
          "For the first time internationally, businesses of all sizes can now access a single framework that offers them a clear pathway to developing better and more robust occupational health and safety measures.",
          "ISO 45001 has seen a 97.3% increase in worldwide certificates in 2020, showing the growth and importance of UKAS accredited certification in recent times. Statistics straight from the most recent ISO Survey.",
          "ISO 45001 is heavily informed by OHSAS 18001 — not a simple revision or brief update. Read on to see what organizations of all types and sizes need to do to maintain compliance and achieve ISO 45001 certification.",
        ],
      },
    ],
  },
  "ISO 22000": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/iso%2Fiso_22000.jpg?alt=media&token=f7b2f7b4-7c2c-49db-973e-8bc3817026de",
    head: "ISO 22000 (Food Safety Management System)",
    msme: "ISO Filing including Government fee for individuals and SMEs.",
    regular:
      "ISO Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Bank Statement Year-1",
      "Nature of Firm",
      "Address Proof (Rent Agreement, Electricity Bill, Telephone, etc.)",
    ],
    description: [
      {
        title: "Why ISO 22000?",
        para: [
          "ISO 22000 sets out the requirements for a food safety management system and can be certified to it. It maps out what an organization needs to do to demonstrate its ability to control food safety hazards in order to ensure that food is safe. It can be used by any organization regardless of its size or position in the food chain.",
        ],
      },
    ],
  },
  "ISO 27001": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/iso%2Fiso_270001.jpg?alt=media&token=d2b4ff7f-c67e-4d6d-be2a-a6cd79a8bf2a",
    head: "ISO 27001 (Information Security)",
    msme: "ISO Filing including Government fee for individuals and SMEs.",
    regular:
      "ISO Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Bank Statement Year-1",
      "Nature of Firm",
      "Address Proof (Rent Agreement, Electricity Bill, Telephone, etc.)",
    ],
    description: [
      {
        title: "What is ISO/IEC 27001?",
        para: [
          "ISO/IEC 27001 is the world's best-known standard for information security management systems (ISMS). It defines requirements an ISMS must meet.",
          "The ISO/IEC 27001 standard provides companies of any size and from all sectors of activity with guidance for establishing, implementing, maintaining and continually improving an information security management system.",
          "Conformity with ISO/IEC 27001 means that an organization or business has put in place a system to manage risks related to the security of data owned or handled by the company, and that this system respects all the best practices and principles enshrined in this International Standard.",
        ],
      },
      {
        title: "Why is ISO/IEC 27001 important?",
        para: [
          "With cyber-crime on the rise and new threats constantly emerging, it can seem difficult or even impossible to manage cyber-risks. ISO/IEC 27001 helps organizations become risk-aware and proactively identify and address weaknesses.",
          "ISO/IEC 27001 promotes a holistic approach to information security: vetting people, policies and technology. An information security management system implemented according to this standard is a tool for risk management, cyber-resilience and operational excellence.",
        ],
      },
    ],
  },
  "ISO 13845": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/iso%2Fiso_13845.jpg?alt=media&token=e5352c89-ddc2-402c-b5bc-8502c0251bef",
    head: "ISO 13845 (Medical Device QMS)",
    msme: "ISO Filing including Government fee for individuals and SMEs.",
    regular:
      "ISO Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Bank Statement Year-1",
      "Nature of Firm",
      "Address Proof (Rent Agreement, Electricity Bill, Telephone, etc.)",
    ],
    description: [
      {
        title: "What You Need to Know About ISO 13485",
        para: [],
      },
      {
        title: "What is a Medical Device?",
        para: [
          "A medical device is a product, such as an instrument, machine, implant, or in vitro reagent, that is intended for use in the diagnosis, prevention, and treatment of diseases or other medical conditions.",
        ],
      },
      {
        title: "Who is ISO 13485 For?",
        para: [
          "ISO 13485 is designed to be used by organizations involved in the design, production, installation, and servicing of medical devices and related services.",
          "It can also be used by internal and external parties, such as certification bodies, to help them with their auditing processes.",
        ],
      },
      {
        title: "Certification to ISO 13485",
        para: [
          "Like other ISO management system standards, certification to ISO 13485 is not a requirement of the standard, and organizations can reap many benefits from implementing the standard without undergoing the certification process.",
          "However, third-party certification can demonstrate to regulators that you have met the requirements of the standard.",
          "ISO does not perform certification.",
          "Read more about certification to ISO’s management system standards.",
        ],
      },
      {
        title: "Why Was ISO 13485 Revised and What Are the Main Improvements?",
        para: [
          "All ISO standards are reviewed every five years to establish if a revision is required in order to keep it current and relevant for the marketplace.",
          "ISO 13485:2016 is designed to respond to the latest quality management system practices, including changes in technology and regulatory requirements and expectations.",
          "The new version has a greater emphasis on risk management and risk-based decision making, as well as changes related to the increased regulatory requirements for organizations in the supply chain.",
        ],
      },
    ],
  },
};

export const trademarkDatabase = {
  "Trademark Registration": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/trademark%2Ftrademark_registration.webp?alt=media&token=36fdecfc-f542-4fb9-a5ae-7292aada3081",
    head: "Trademark Registration",
    msme: "Trademark Filing including Government fee for individuals and SMEs.",
    regular:
      "Trademark Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "PAN Card",
      "Aadhar Card",
      "Logo",
      "MSME Certificate",
      "Firm Registration",
      "PartnerShip Deed",
      "Incorportation Certificate",
      "Form-48",
    ],
    description: [
      {
        title: "What is a trademark?",
        para: [
          "A trademark can be any word, phrase, symbol, design, or a combination of these things that identifies your goods or services. It’s how customers recognize you in the marketplace and distinguish you from your competitors.",
          "The word “trademark” can refer to both trademarks and service marks. A trademark is used for goods, while a service mark is used for services.",
          "A common misconception is that having a trademark means you legally own a particular word or phrase and can prevent others from using it. However, you don’t have rights to the word or phrase in general, only to how that word or phrase is used with your specific goods or services.",
          "For example, let's say you use a logo as a trademark for your small woodworking business to identify and distinguish your goods or services from others in the woodworking field. This doesn't mean you can stop others from using a similar logo for non-woodworking related goods or services.",
        ],
      },
      {
        title: "Owning a trademark vs. having a registered trademark",
        para: [
          "You become a trademark owner as soon as you start using your trademark with your goods or services. You establish rights in your trademark by using it, but those rights are limited, and they only apply to the geographic area in which you’re providing your goods or services. If you want stronger, nationwide rights, you’ll need to apply to register your trademark with us.",
          "You’re not required to register your trademark. However, a registered trademark provides broader rights and protections than an unregistered one.",
          "For example, you use a logo as a trademark for the handmade jewelry you sell at a local farmer’s market. As your business grows and you expand online, you might want more protection for your trademark and decide to apply for federal registration. Registering your trademark with us means that you create nationwide rights in your trademark.",
        ],
      },
      {
        title: "Strong trademarks",
        para: [
          "It’s important to have a strong trademark that is inherently distinctive. This means your trademark quickly and clearly identifies you as the source of your goods or services. The stronger your trademark is, the more easily you can prevent others from using it without your permission. Weak trademarks can be difficult and costly for you to defend because they don’t have the same legal protections as stronger trademarks.",
        ],
      },
      {
        title: "Why register your trademark?",
        para: [
          "It is your choice whether to file for federal trademark registration. The benefits include:",
          "Trademark is listed in our database of registered and pending trademarks. This provides public notice to anyone searching for similar trademarks. They will see your trademark, the goods and services on your registration, the date you applied for trademark registration, and the date your trademark registered.",
          "Legal presumption that you own the trademark and have the right to use it. So, in federal court, your registration certificate proves ownership, eliminating the need for copious amounts of evidence.",
          "Can use your registration as a basis for filing for trademark protection in foreign countries.",
          "Right to bring a lawsuit concerning the trademark in federal court.",
          "May use the federal trademark registration symbol, ®, with your trademark to show that you are registered with us. This may help deter others from using your trademark or one too similar to yours.",
        ],
      },
      {
        title: "How long does it take to register?",
        para: [
          "There are many factors that affect how long it takes to register a trademark. In fact, there’s no guarantee your trademark will ever register, as it may be refused for various legal reasons. Usually, the process takes 12 to 18 months. Registering your trademark is a complex procedure that involves your application moving through various stages.",
          "Learning about each stage in the process will help you understand why getting a trademark takes as long as it does. Look at our timelines for the trademark application and post-registration process.",
          "You can check the current processing wait times to find out how long your trademark filing could take. Filing your initial application, response form, and post registration accurately can speed up the process. You can help by reading these tips on avoiding processing delays.",
        ],
      },
      {
        title: "Receiving your trademark registration",
        para: [
          "If your trademark registers, we’ll upload your electronic certificate of registration to the Trademark Status and Document Retrieval (TSDR) system. You’ll see a registration notification in the prosecution history, as well as the certificate icon.  ",
        ],
      },
    ],
  },
  "Trademark Objection": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/trademark%2Ftrademark_objection.webp?alt=media&token=9d4518d7-4b46-403f-bd00-cba98edfccdb",
    head: "Trademark Objection",
    msme: "Trademark Filing including Government fee for individuals and SMEs.",
    regular:
      "Trademark Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Advertisement Copy",
      "Product Images",
      "Sale Invoice",
      "Domain Registration Copy",
      "Website Screenshot",
    ],
    description: [
      {
        title: "Understanding Trademark Objection",
        para: [
          "Trademark objection must be handled cautiously and is considered as the most crucial stage of trademark registration. Trademark Registration application procedure includes trademark objection. Trademark examiner raises the objection on the grounds of finding during the examination procedure on certain goods. It should be handled with care, and convincing reply should be provided on the raised queries; otherwise, it can lead to the rejection of trademark application. The trademark should not be similar or identical to any third-party mark; it should be distinguished in every possible way.",
          "Trademark is one of the rights granted to protect intellectual property in India. Rights covered under Trademark Act 1999 include protection, registration, and objection of a trademark with government authority.",
        ],
      },
      {
        title: "Trademark Registration undergoes the following steps:",
        para: [
          "Trademark verification/search",
          "Filing of application",
          "Formality inspection",
          "Publication in the journal",
          "Grant of registration",
          "A registered trademark cannot be used by any third person without authority; thus it protects it from any infringement.",
        ],
      },
      {
        title: "What is Trademark Objection?",
        para: [
          "Once the trademark is applied for registration, it can be objected by the public, examiner or any other third party. It happens during the initial stage of the trademark registration process when the examiner objects the trademark application due to several reasons. The objection cannot be the reason for rejection; however, registrar seeks further clarification or explanations about the logo and its legality.",
          "The third-party can also object trademark in the name of public interest. Two ways when a public or third party can object the registration of trademark:",
          "When the trademark is published on Trademark Journal",
          "In case the applicant uses the mark before its registration",
          "When the objection is received, the applicant shall file a comprehensive objection reply within a month or in the prescribed time period.",
        ],
      },
      {
        title: "Reasons for Trademark Objections",
        para: [
          "Upon receipt of the application, the trademark examiner scrutinizes the application for its correctness. Objections can be received for the following reasons:",
          "Incorrect form",
          "Incorrect applicant name",
          "Usage of deceptive words",
          "Offensive terms",
          "Insufficient information on goods or services",
          "Identical logo already registered",
        ],
      },
      {
        title: "Respond to Objection Raised",
        para: [
          "Once the objection is filed, the applicant is given notice stating the ground of such objection. Once the objection is received, the applicant needs to follow the following steps thereafter:",
          "Receipt of Trademark Objection",
          "Objection reply",
          "Hearing",
          "Trademark Journal",
          "Review Petition",
          "Registration",
        ],
      },
      {
        title:
          "What to do when the online status of the Trademark Registration application says “objected”?",
        para: [
          "Get in touch with a trademark expert consultancy",
          "Obtain the examination report and go through it thoroughly. React to the report and must mention how and why objections are not tenable.",
          "Give a befitting reply to the objections raised within 30 days from the date of receipt otherwise application will be considered as abandoned. It is crucial to submit the reply within the stipulated time period and in an expedited manner.",
          "Frame your reply in a clear and precise manner. Make it strong enough with high precision that it could establish the distinctiveness of the mark, thus making it eligible for trademark registration.",
        ],
      },
      {
        title: "What to do after filing Trademark Objection?",
        para: [
          "Once a reply has been submitted against the objection raised via examination report, the Registrar will respond to the reply either by accepting the trademark or by conducting a hearing session.",
          "In case a hearing date is given, hire a trademark consultant or lawyer who will represent you and put up your side before the authority strongly.",
          "Contact Corpbiz; we have an experienced group of people who are expert in handling trademark objection.",
        ],
      },
    ],
  },
  "Trademark Opposition": {
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/trademark%2Ftrademark_opposition.webp?alt=media&token=14492084-d232-4830-abae-84024764b485",
    head: "Trademark Opposition",
    msme: "Trademark Filing including Government fee for individuals and SMEs.",
    regular:
      "Trademark Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: [
      "Signed Evidence",
      "Sales Invoice",
      "Advertisement Copies",
      "Product Images",
      "Government Registration Certificate",
      "Social Media Accounts Screenshot",
      "Letter Head copy",
      "Visiting Card",
    ],
    description: [
      {
        title:
          "What is Trademark Opposition, Procedure for Trademark Opposition in India",
        para: [
          "The Trademarks Act, 1999, provides for the registration of a trademark in India. The owner of the trademark has to apply to the Registrar of Trademarks (‘Registrar’) for obtaining the trademark registration. Upon receiving the application for registration, the Registrar will advertise the trademark in the trademark journal.",
          "Any person can file an opposition for registration of the trademark published in the Trademark Journal. The opposition is to be filed to the Registry of Trademarks, where the trademark registration application is filed. When the trademark registry receives any kind of opposition to the trademark, it will conduct a hearing to decide the matter.",
          "The Trademark Act, 1999 and the Trade Marks Rules, 2017 provides the process of trademark opposition.",
        ],
      },
      {
        title: "Initiation of Trademark Opposition",
        para: [
          "Section 21 of the Trademarks Act, 1999, provides that any person can file a notice of opposition to the Registrar. This includes companies, individuals, trusts and partnership firms. Thus, any aggrieved third party can raise opposition to trademark registration.",
        ],
      },
      {
        title: "Grounds for Trademark Opposition",
        para: [
          "The grounds on which a person can file opposition for trademark registration are:",
          "The trademark is identical or similar to an already existing registered trademark.",
          "The trademark is descriptive in nature.",
          "The trademark is devoid of distinctive character.",
          "The trademark is customary in the present language or the established practices of business.",
          "The application for trademark registration is made with bad faith.",
          "The mark is prevented by law or contrary to the law.",
          "The trademark is likely to cause confusion or deceive the public.",
          "The trademark contains matters likely to hurt the religious feelings of any section or class of people.",
          "The trademark is prohibited as per the Emblem and Names Act, 1950.",
        ],
      },
      {
        title: "Trademark Opposition Process",
        para: ["The process of trademark opposition are as follows:"],
      },
      {
        title: "Notice of Trademark Opposition",
        para: [
          "Any person can file for trademark opposition to the Registrar within four months from the date of advertisement of the registration application in the trademark journal by giving a notice in Form TM-O and payment of fees.",
          "The notice should contain the application (trademark registration application) details, opposing party details and grounds of opposition. The Registrar should serve the copy of the notice of opposition to the applicant (person who filed the trademark registration application) within three months of receiving the opposition notice.",
        ],
      },
      {
        title: "Counterstatement for Notice of Trademark Opposition",
        para: [
          "The applicant must file his/her counterstatement to the notice of opposition to the Registrar in Form TM-O within two months of receiving the copy of the notice of opposition stating his/her facts.",
          "The Registrar will serve the copy of the counterstatement to the opposing party within two months of receiving the counterstatement.",
          "Suppose the applicant does not file the counter statement within two months of receiving the notice of opposition. In that case, the Registrar will consider that the trademark registration application is abandoned and thus will not proceed to register the trademark.",
        ],
      },
      {
        title: "Evidence For and Against Trademark Opposition",
        para: [
          "The opposing party shall provide evidence supporting his/her notice of opposition to the Registrar in less than two months of getting the copy of the counterstatement filed by the applicant.",
          "The opposing party should also send all copies of the evidence to the applicant.",
          "Upon receiving the evidence copy by the opposing party, the applicant should file his/her evidence in support of the trademark registration application within two months of receiving the opposing party’s evidence copy.",
          "The applicant must send his/her evidence to both the Registrar and the opposing party.",
          "The opposing party can file further evidence within one month of receiving the evidence copies of the applicant.",
          "The opposing party must submit further evidence to both the Registrar and the applicant.",
        ],
      },
      {
        title: "Hearing and Decision Concerning Trademark Opposition",
        para: [
          "The Registrar, after the receipt of evidence by both parties and further evidence of the opposing party, will give notice to both parties of the first date of hearing.",
          "If the opposing party is not present on the hearing date, the opposition will be dismissed, and the Registrar will register the trademark.",
          "If the applicant is not present on the hearing date, the registration application will be treated as abandoned and dismissed.",
          "The Registrar will consider the written arguments submitted by both parties to the proceeding.",
          "After hearing both parties and considering the evidence submitted by them, the Registrar will decide whether to proceed with the trademark registration or reject the trademark registration application.",
          "The decision of the Registrar will be communicated to both parties in writing at the address provided by them.",
        ],
      },
      {
        title: "Conclusion of the Trademark Opposition Process",
        para: [
          "When the Registrar decides in favor of the applicant, the trademark will be registered and will issue the trademark registration certificate.",
          "When the Registrar decides in favor of the opposing party, then the trademark registration application will be rejected.",
        ],
      },
      {
        title: "Disclaimer",
        para: [
          "The materials provided herein are solely for information purposes. No attorney-client relationship is created when you access or use the site or the materials.",
          "The information presented on this site does not constitute legal or professional advice and should not be relied upon for such purposes or used as a substitute for legal advice from an attorney licensed in your state.",
        ],
      },
    ],
  },
  "Trademark Renewal": {
    head: "Trademark Renewal",
    img: "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/trademark%2Ftrademark_renewel.jpg?alt=media&token=60c5d607-f2f5-4b1e-a158-2973d3fb783d",
    msme: "Trademark Filing including Government fee for individuals and SMEs.",
    regular:
      "Trademark Filing including Government fee for entities NOT having MSME registration.",
    documentsRequired: ["Form-48"],
    description: [
      {
        title: "What is Trademark",
        para: [
          "Trademarks are marks that are graphically represented which help people identify the company or person who produced the goods or services. These marks can be registered under the Trademark Act, 1999 with the Trademark registry offices.",
          "There are five such trademark registry offices in India, Mumbai, Delhi, Kolkata, Chennai and Ahmedabad. If a trademark is registered the holder of the trademark gets special rights to its usage and is protected by law from the infringement of such rights by others. Registered trademarks have a limitation period of ten years. If the trademark is not being used for a period of five years then it may be cancelled at the request of another party.",
          "The holder of a trademark is at the liberty to renew the trademark indefinitely every ten years. The Registrar gives a window of six months before the end of ten years to the holder of the trademark to renew their trademark and continue enjoying the rights awarded. In the case of a person failing to renew a trademark, the registrar will issue an advertisement in the Trademark Journal indicating the removal of the trademark.",
          "After the period of the ten years if the trademark is not renewed the person still has the option of restoration, which means renewal itself but with a fine.",
        ],
      },
      {
        title: "Need for renewal of Trademark",
        para: [
          "There are many advantages to renewing a trademark. Registration of the trademark awards the holder of the trademark many rights that are protected by law. It prevents the infringement of the trademark and also provide for restitution in case of such infringements.",
          "It also allows the holder the right to transfer the trademark to another person or company at their will. Licensing of trademarks are only possible if the holder of the trademark is has registered the trademark, therefore a registered trademark holds immense monetary value.",
        ],
      },
      {
        title: "How to renew a Trademark",
        para: [
          "A registered trademark is only valid for 10 years, after which it needs to be renewed. The registrar of trademarks will send you a letter of reminder of the expiry of the trademark 6 months prior to its expiry. When you are going to renew a trademark you have two options:",
          "Renew the trademark as it is",
          "Renew the trademark with changes and alterations",
          "The application for the renewal of a trademark is the form TM-R.",
          "The application does not need to be filed by the registered owner of the trademark, it can be done by an authorised representative or an agent.",
          "After filing the application one has to follow the status of the application in case any opposition is filed to the registration of the trademark. Such opposition can be filed by any person of the public.",
          "When the application is approved the trademark will be published in the official gazette the Trademark Journal.",
          "If the trademark has been published then the owner of the trademark has protection for another ten years.",
          "The trademark can be indefinitely renewed.",
          "The price for the renewal of a trademark depends upon whether it has been one in-person physically or it has been done online.",
          "If done physically the filing for the renewal costs 10,000 rupees",
          "If done online via e-filing it costs 9,000 rupees",
          "For the renewal of a trademark, the following are required:",
          "A copy of the registration certificate",
          "Copy of form TM-A (form used for the original application for registering the trademark)",
          "ID and address proof of the applicant",
          "Power of attorney if the applicant is an authorised representative or an agent",
        ],
      },
      {
        title: "Restoration of Trademark",
        para: [
          "There can be times that a person forgets to renew their trademark in the given time period. No worries there is still an option available in such cases. In the case of failure to renew one can then try to restore a trademark. Restoration of a trademark is allowed by the Trademark Act, 1999 under Section 25 (4) where it allows persons to apply for the restoration of the trademark.",
          "Restoration can only be done within one year of the expiry of the registered trademark. Restoration of a trademark includes an additional fee over the renewal fee of:",
          "10,000 rupees if done by a person physically,",
          "or an additional 9,000 rupees if done online.",
        ],
      },
    ],
  },
};

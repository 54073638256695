import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { propriotiership } from "../constant/database";
import { useNavigate } from "react-router-dom";

const Startup = () => {
  const route = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-[#ececec] mt-28">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 1.5xl:grid-cols-4 gap-y-8 py-12 px-1 sm:px-3">
        {Object.entries(propriotiership)
          // .slice(0, 7)
          .map((itm, ind) => (
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.3 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0 },
              }}
              key={ind}
              onClick={() => {
                route(`/startup/${itm[0]}`);
              }}
              style={{ margin: "0 auto" }}
              className="slickSlide"
            >
              <div className="w-[90%] mx-auto flex items-center h-[250px] mt-2 justify-center">
                <img alt={itm[0].img} src={itm[1].img} />
              </div>
              <div className="p-3 flex flex-col gap-2">
                <h4 className="uppercase text-[14px] text-[#333] font-semibold">
                  Startup
                </h4>
                <h1 className="text-[#E55604] text-center text-[20px]">
                  {itm[0]}
                </h1>
                {/* <p>Upto 1 Cr loan sancation with 30% off on interest</p> */}
                <p className="text-[#223a72] cursor-pointer">Know More</p>
              </div>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default Startup;

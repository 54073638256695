import React from "react";
import { bankingDatabase } from "../constant/database";
import { useLocation } from "react-router-dom";

const SingleBanking = () => {
  const router = useLocation();
  let service = router.pathname.substring(9);
  service = service.split("%20").join(" ");
  if (!service) return;

  const currentKey = bankingDatabase[service];
  return (
    <div className="min-h-screen mt-28 bg-[#eeeeee] w-full">
      <div className="max-w-[1300px] mx-auto p-4 flex flex-col gap-4">
        <div className="w-[90%] sm:w-[75%] smd:w-[820px] smd:gap-2 gap-10 flex flex-col items-center justify-center smd:flex-row bg-white mx-auto p-2 sm:p-3 rounded-md">
          <div className="smd:mt-0 mt-4 w-[90%] smd:w-[40%]  flex items-center justify-center">
            <img
              alt="imcadage"
              width="250"
              height="300"
              className="mt-4"
              src={currentKey.img}
            />
          </div>
          <div className="w-[90%] smd:w-[60%]">
            <h1 className="font-bold text-2xl">{currentKey.head}</h1>

            <h2 className="my-2 font-semibold">Documents Required</h2>
            <div className="grid grid-cols-2">
              {currentKey.documentsRequired.map((itm, ind) => (
                <p key={ind} className="flex items-center justify-start gap-2">
                  <img
                    alt="czcs"
                    src="/check-solid.svg"
                    width={10}
                    height={10}
                  />
                  <span>{itm}</span>
                </p>
              ))}
            </div>
            <button
              onClick={() => router("http://wa.me/9269148541?text=Hello%20Sir")}
              className="xxsm:relative fixed bottom-0 xxsm:w-[200px] w-full left-0 mt-2 bg-[#128C7E] flex items-center justify-center gap-2 rounded-md p-3"
            >
              <img alt="fd" src="/whatsapp.svg" width={20} height={20} />
              <span className="text-white font-semibold">Get a quick call</span>
            </button>
          </div>
        </div>
        <div className="bg-white w-[90%] sm:w-[75%] smd:w-[820px] mx-auto p-4 rounded-md">
          {currentKey.description.map((itm, ind) => (
            <div key={ind}>
              <h1 className="text-2xl mt-2 font-medium">{itm.title}</h1>
              {itm.para.map((para, index) => (
                <p className="mt-2 text-[13px]" key={index}>
                  {para}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleBanking;

import React from "react";
import YouTube from "react-youtube";
import Carousel from "react-multi-carousel";
import { subsidySchemeData } from "../constant/database";
import "react-multi-carousel/lib/styles.css";

const Activity = () => {
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  function _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1250 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1250, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 640 },
      items: 1,
    },
    minMobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="activity border-b-2 border-b-solid border-b-black border-t-2 border-t-solid border-t-black py-12 px-4 w-full min-h-[400px] flex flex-col items-center justify-center gap-4 h-full">
      <div className="w-[90%] sm:w-[600px]">
        <Carousel infinite={false} autoPlay={false} responsive={responsive}>
          {Object.entries(subsidySchemeData).map((itm, ind) => (
            <div>
              <h1 className="text-[40px] text-center">{itm[0]}</h1>
              <YouTube
                key={ind}
                videoId={itm[1].videoId}
                opts={{ ...opts, width: "100%", height: "300px" }}
                onReady={_onReady}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Activity;

import React from "react";
import { motion } from "framer-motion";
import {
  bankingDatabase,
  incomeTax,
  gstDatabase,
  otherIPR,
  isoDatabase,
  designRegistration,
  trademarkDatabase,
  propriotiership,
} from "../constant/database";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

const OtherCarousel = ({ title }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1250 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1250, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 640 },
      items: 2,
    },
    minMobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
    },
  };
  const navigate = useNavigate();

  return (
    <div
      className={`w-full pb-16 pt-12 ${
        title === "banking" ? "" : "border-b-2 border-b-black border-b-solid"
      } `}
    >
      <h1 className="text-center my-8 font-bold capitalize text-[20px]">
        {title} Services
      </h1>
      <Carousel autoPlay={false} infinite={false} responsive={responsive}>
        {title === "startup"
          ? [...Object.entries(propriotiership)].map((itm, ind) => {
              const [key, value] = itm;
              return (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 },
                  }}
                  key={ind}
                  onClick={() => {
                    navigate(`/${title}/${key}`);
                  }}
                  className="slickSlide slickSideOther"
                >
                  <div className="w-[90%] mx-auto flex mt-4 items-center h-[200px] justify-center">
                    <img
                      alt={value.img}
                      src={value.img}
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="p-3 flex flex-col gap-2">
                    <h4 className="uppercase text-[14px] text-[#333] font-semibold">
                      {title}
                    </h4>
                    <h1 className="text-[#E55604] text-center text-[20px]">
                      {key}
                    </h1>
                    <p className="text-[#223a72] cursor-pointer">Know More</p>
                  </div>
                </motion.div>
              );
            })
          : title === "banking"
          ? [...Object.entries(bankingDatabase)].map((itm, ind) => {
              const [key, value] = itm;
              return (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 },
                  }}
                  key={ind}
                  onClick={() => {
                    navigate(`/${title}/${key}`);
                  }}
                  className="slickSlide slickSideOther"
                >
                  <div className="w-[90%] mx-auto flex items-center mt-4 h-[200px] justify-center">
                    <img
                      alt={value.img}
                      src={value.img}
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="p-3 flex flex-col gap-2">
                    <h4 className="uppercase text-[14px] text-[#333] font-semibold">
                      {title}
                    </h4>
                    <h1 className="text-[#E55604] text-center text-[20px]">
                      {key}
                    </h1>
                    <p className="text-[#223a72] cursor-pointer">Know More</p>
                  </div>
                </motion.div>
              );
            })
          : [
              ...Object.entries(isoDatabase),
              ...Object.entries(trademarkDatabase),
              ...Object.entries(gstDatabase),
              ...Object.entries(designRegistration),
              ...Object.entries(incomeTax),
              ...Object.entries(otherIPR),
            ].map((itm, ind) => {
              const [key, value] = itm;
              return (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 },
                  }}
                  key={ind}
                  onClick={() => {
                    navigate(`/${title}/${key}`);
                  }}
                  className="slickSlide slickSideOther"
                >
                  <div className="w-[90%] mx-auto flex items-center mt-4 h-[200px] justify-center">
                    <img
                      alt={value.img}
                      src={value.img}
                      style={{ height: "200px" }}
                    />
                  </div>
                  <div className="p-3 flex flex-col gap-2">
                    <h4 className="uppercase text-[14px] text-[#333] font-semibold">
                      {title}
                    </h4>
                    <h1 className="text-[#E55604] text-center text-[20px]">
                      {key}
                    </h1>
                    <p className="text-[#223a72] cursor-pointer">Know More</p>
                  </div>
                </motion.div>
              );
            })}
      </Carousel>
    </div>
  );
};

export default OtherCarousel;

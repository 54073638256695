import React from "react";
import { motion } from "framer-motion";
import { subsidySchemeData } from "../constant/database";
import { useNavigate } from "react-router-dom";

const Subsidy = () => {
  const route = useNavigate();
  return (
    <div className="bg-[#ececec] mt-28">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 1.5xl:grid-cols-4 gap-y-8 py-12 px-1 sm:px-3">
        {Object.entries(subsidySchemeData).map((itm, ind) => (
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.3 }}
            onClick={() => {
              route(`/subsidy/${itm[0]}`);
            }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            key={ind}
            style={{ margin: "0 auto" }}
            className="slickSlide"
          >
            <div className="w-[90%] mx-auto flex items-center mt-2 h-[250px] justify-center">
              <img alt={itm[0]} src={itm[1].img} />
            </div>
            <div className="p-3 flex flex-col gap-2">
              <h4 className="uppercase text-[14px] text-[#333] font-semibold">
                Schemes
              </h4>
              <h1 className="text-[#E55604] text-center text-[20px]">
                {itm[0]}
              </h1>
              <h4 className="uppercase text-[14px] text-[#3D0C11] font-semibold">
                Features
              </h4>
              <ul className="list-disc w-max px-4 max-w-[90%]">
                {itm[1].feature.split(";").map((itm, ind) => (
                  <li key={ind} className="text-[12px] m-0 p-0">
                    {itm}
                  </li>
                ))}
              </ul>
              <p className="text-[#223a72] cursor-pointer mt-auto">Know More</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Subsidy;

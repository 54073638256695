import { createContext, useContext } from "react";


export const ContextAuthProvider = createContext({
  loggedIn: false,
  uid: "",
  officeId: '',
  setofficeId: () => {},
  setUid: () => {},
  setLoader: () => {},
  setLoggedIn: () => {},
  setUSERType: () => {},
  setName: () => {},
  name: "",
  userType: "",
  loader: false,
  newsData: {
    schemes: [],
    clientCorner: [],
    singleLine: "",
    updates: [],
    ourProjects: [],
  },
});
export const UseAuthContext = () => useContext(ContextAuthProvider);

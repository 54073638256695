import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { UseAuthContext } from "../state/appContext";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { owneruid } from "../constant/services";
import Spinner from "../common/spinner";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getTeamMemberByPhoneNumber,
  handleFormSubmit,
  handleSignIn,
  initialTeam,
  deleteUser,
  updateUser,
  fetchUserQuery,
  fetchNext,
  fetchNextQuery,
  fetchTeamMembers,
} from "../functions/owner";
import { faDeleteLeft, faLink } from "@fortawesome/free-solid-svg-icons";

const Owner = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 450,
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [modalIsOpen, setIsOpen] = useState("");
  const [currentMessageView, setcurrentMessageView] = useState(-1);
  const [email, setemail] = useState("");
  const [currentSection, setcurrentSection] = useState("party");
  const [loading, setloading] = useState(false);
  const [password, setpassword] = useState("");
  const { loader, loggedIn, uid } = UseAuthContext();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFormore, setloadingFormore] = useState(false);
  const [loaderWhileAdding, setloaderWhileAdding] = useState(false);
  const [loaderWhileFetchingFromPhone, setloaderWhileFetchingFromPhone] =
    useState(false);
  const [teamDoc, setteamDoc] = useState(initialTeam);
  const [phoneForSearch, setphoneForSearch] = useState("");
  const [lastDocument, setLastDocument] = useState(null);
  const [fetchQuery, setfetchQuery] = useState([]);
  const [timeTofetchQuery, settimeTofetchQuery] = useState(false);
  const [lastQueryDoc, setlastQueryDoc] = useState(null);
  const [timeToFetchNextQueryDoc, settimeToFetchNextQueryDoc] = useState(false);
  const [timetoBlockTeam, settimetoBlockTeam] = useState(-1);
  const [timeTodelete, settimeTodelete] = useState(-1);

  useEffect(() => {
    if (!loader && !loggedIn) {
      setIsOpen("owner");
    } else if (!loader && loggedIn && uid === owneruid) {
      if (teamMembers.length === 0) {
        (async () => {
          if (phoneForSearch.length > 0) return;
          let userData = await fetchTeamMembers(
            null,
            setIsLoading,
            setLastDocument
          );
          setTeamMembers(userData);
          if (userData.length < 25) {
            setLastDocument(null);
          } else {
            setLastDocument(userData[userData.length - 1]);
          }
          closeModal();
        })();
      }
      if (fetchQuery.length === 0) {
        (async () => {
          let userData = await fetchUserQuery(null, settimeTofetchQuery);
          setfetchQuery(userData);
          if (userData.length < 25) {
            setlastQueryDoc(null);
          } else {
            setlastQueryDoc(userData[userData.length - 1]);
          }
          closeModal();
        })();
      }
    } else if (!loader && loggedIn && uid !== owneruid) {
      setIsOpen("owner");
    }
  }, [uid, loader, loggedIn, phoneForSearch]);

  function closeModal() {
    setIsOpen("");
  }

  return (
    <div className="w-full min-h-screen">
      <ToastContainer position="bottom-center" />
      <Modal
        open={modalIsOpen.length > 0}
        onClose={modalIsOpen === "owner" ? () => {} : closeModal}
        className="mt-28"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={`w-full`}>
            {modalIsOpen === "owner" ? (
              <form
                onSubmit={(event) =>
                  handleSignIn(
                    event,
                    setloading,
                    email,
                    password,
                    owneruid,
                    closeModal
                  )
                }
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              >
                <h3>Login as a Owner</h3>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    placeholder="Username"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    placeholder="******************"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="flex items-center justify-center hover:bg-blue-700 text-white bg-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {loading ? <Spinner /> : "Sign In"}
                  </button>
                </div>
              </form>
            ) : modalIsOpen === "newteam" ? (
              <form
                className="w-full max-w-lg"
                onSubmit={(e) =>
                  handleFormSubmit(
                    e,
                    teamDoc,
                    setloaderWhileAdding,
                    setTeamMembers,
                    closeModal,
                    setteamDoc,
                    setIsLoading,
                    setLastDocument
                  )
                }
              >
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-firm"
                    >
                      Firm Name
                    </label>
                    <input
                      required
                      value={teamDoc.firmName}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, firmName: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-firm"
                      type="text"
                      placeholder="Sharma Industry Pvt. Ltd."
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-name"
                    >
                      Full Name
                    </label>
                    <input
                      required
                      value={teamDoc.name}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, name: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-name"
                      type="text"
                      placeholder="Mukesh Sharma"
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-password"
                    >
                      Password
                    </label>
                    <input
                      required
                      value={teamDoc.password}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, password: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-password"
                      type="password"
                      placeholder="******************"
                    />
                    <p className="text-gray-600 text-xs italic">
                      Make it as long and as crazy as you'd like
                    </p>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      District
                    </label>
                    <input
                      required
                      value={teamDoc.district}
                      onChange={(e) =>
                        setteamDoc({ ...teamDoc, district: e.target.value })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-city"
                      type="text"
                      placeholder="District"
                    />
                  </div>
                  <div className="w-full md:w-1/2 px-2 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="perhone-numb"
                    >
                      Phone Number
                    </label>
                    <input
                      required
                      value={teamDoc.phoneNumber}
                      onChange={(e) =>
                        setteamDoc({
                          ...teamDoc,
                          phoneNumber: e.target.value,
                        })
                      }
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="perhone-numb"
                      type="number"
                      placeholder="+911234567890"
                    />
                  </div>
                </div>
                <button
                  className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                  type="submit"
                >
                  {loaderWhileAdding ? <Spinner /> : "Create"}
                </button>
              </form>
            ) : (
              currentMessageView !== -1 && (
                <div>{fetchQuery[currentMessageView]?.message}</div>
              )
            )}
          </div>
        </Box>
      </Modal>
      <div className="mt-40 flex items-center justify-center">
        <div className="w-[90%] md:w-[75%] m-auto max-w-[768px]">
          <ul className="flex items-center gap-3 my-3">
            <li
              onClick={() => setcurrentSection("party")}
              className={`${
                currentSection === "party" ? "bg-gray-400" : "bg-gray-100"
              } border hover:border-gray-100 cursor-pointer border-gray-300 rounded-l-lg  text-gray-900 px-4 py-2`}
            >
              Party
            </li>
            <li
              onClick={() => setcurrentSection("contact")}
              className={`${
                currentSection === "contact" ? "bg-gray-400" : "bg-gray-100"
              } border hover:border-gray-100 cursor-pointer border-gray-300 rounded-l-lg  text-gray-900 px-4 py-2`}
            >
              User's Query
            </li>
          </ul>
          {currentSection === "party" && (
            <div className="flex">
              <label
                htmlFor="search-dropdown"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Your Email
              </label>
              <button
                onClick={() => setIsOpen("newteam")}
                id="dropdown-button"
                data-dropdown-toggle="dropdown"
                className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                type="button"
              >
                Create New+
              </button>
              <div className="relative w-full">
                <input
                  type="search"
                  value={phoneForSearch}
                  onChange={(e) => setphoneForSearch(e.target.value)}
                  id="search-dropdown"
                  className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                  placeholder="Search by Employee ID"
                  required
                />
                <button
                  onClick={async () => {
                    let userFromPhone = await getTeamMemberByPhoneNumber(
                      phoneForSearch,
                      setloaderWhileFetchingFromPhone
                    );
                    if (userFromPhone != null) {
                      setTeamMembers([userFromPhone]);
                    } else {
                      toast.error("No data from this Number exists");
                    }
                  }}
                  className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {loaderWhileFetchingFromPhone ? (
                    <Spinner />
                  ) : (
                    <>
                      <svg
                        className="w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                      <span className="sr-only">Search</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
          {currentSection === "party" ? (
            isLoading ? (
              <Spinner />
            ) : (
              <DataGrid
                getRowClassName={(params) =>
                  `${
                    params.row.status === "active"
                      ? "bg-green-100"
                      : "bg-red-100"
                  }`
                }
                columns={[
                  {
                    field: "ind",
                    headerName: "Go to Page",
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <a
                          href={`/owner/${params.row.district}/${params.row.phoneNumber}`}
                        >
                          Vist <FontAwesomeIcon icon={faLink} />{" "}
                        </a>
                      );
                    },
                  },
                  {
                    field: "firmName",
                    headerName: "firm Name",
                    width: 150,
                  },
                  { field: "name", headerName: "Name", width: 150 },
                  {
                    field: "phoneNumber",
                    headerName: "Phone Number",
                    width: 150,
                  },
                  {
                    field: "dateOfJoined",
                    headerName: "Date of Joined",
                    width: 150,
                    renderCell: (params) => {
                      // Assuming dateOfJoined is a Firestore timestamp, you can format it using JavaScript Date methods
                      const formattedDate = new Date(
                        params.value?.seconds * 1000
                      ).toLocaleDateString();
                      return <div>{formattedDate}</div>;
                    },
                  },
                  {
                    field: "fsa",
                    headerName: "Change Status",
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <button
                          onClick={() =>
                            updateUser(
                              params.row.phoneNumber,
                              params.row.status === "active"
                                ? {
                                    status: "block",
                                  }
                                : { status: "active" },
                              params.id,
                              settimetoBlockTeam
                            )
                          }
                          className="bg-gray-400 px-4 py-2 rounded-md"
                        >
                          {timetoBlockTeam === params.id ? (
                            <Spinner />
                          ) : params.row.status === "active" ? (
                            "Block"
                          ) : (
                            "Unblock"
                          )}
                        </button>
                      );
                    },
                  },
                  {
                    field: "fds",
                    headerName: "Delete",
                    width: 150,
                    renderCell: (params) => {
                      return (
                        <button
                          onClick={() =>
                            deleteUser(
                              params.row.phoneNumber,
                              params.id,
                              settimeTodelete
                            )
                          }
                          className="bg-gray-400 px-4 py-2 rounded-md"
                        >
                          {timeTodelete === params.id ? (
                            <Spinner />
                          ) : (
                            <>
                              <span>Delete</span>{" "}
                              <FontAwesomeIcon icon={faDeleteLeft} />
                            </>
                          )}
                        </button>
                      );
                    },
                  },
                ]}
                getRowId={(row) => row.phoneNumber}
                rows={teamMembers}
              />
            )
          ) : timeTofetchQuery ? (
            <Spinner />
          ) : (
            <DataGrid
              columns={[
                { field: "name", headerName: "Name", width: 150 },
                { field: "message", headerName: "Message", width: 200 },
                {
                  field: "phoneNumber",
                  headerName: "Phone Number",
                  width: 150,
                },
                {
                  field: "createdAt",
                  headerName: "Created At",
                  width: 100,
                  renderCell: (params) => {
                    // Assuming dateOfJoined is a Firestore timestamp, you can format it using JavaScript Date methods
                    const formattedDate = new Date(
                      params.value?.seconds * 1000
                    ).toLocaleDateString();
                    return <div>{formattedDate}</div>;
                  },
                },
                {
                  field: "ind",
                  headerName: "View Full Message",
                  width: 150,
                  renderCell: (params) => {
                    return (
                      <button
                        onClick={() => {
                          setcurrentMessageView(params.tabIndex);
                          setIsOpen("message");
                        }}
                      >
                        View
                      </button>
                    );
                  },
                },
              ]}
              getRowId={(row) => row.createdAt + row.phoneNumber}
              rows={fetchQuery}
            />
          )}
          {(lastDocument !== null || lastQueryDoc !== null) && (
            <button
              onClick={() =>
                currentSection === "party"
                  ? fetchNext(
                      lastDocument,
                      setloadingFormore,
                      setTeamMembers,
                      setLastDocument,
                      setIsLoading
                    )
                  : fetchNextQuery(
                      lastQueryDoc,
                      settimeToFetchNextQueryDoc,
                      lastDocument,
                      setfetchQuery,
                      setlastQueryDoc,
                      setIsLoading,
                      setLastDocument
                    )
              }
              className="mx-auto flex self-center my-5 p-3 rounded-md bg-blue-600 text-white"
            >
              {loadingFormore || timeToFetchNextQueryDoc ? (
                <Spinner />
              ) : (
                "Fetch More"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Owner;

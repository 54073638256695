import {
  bankingDatabase,
  incomeTax,
  gstDatabase,
  otherIPR,
  isoDatabase,
  designRegistration,
  trademarkDatabase,
} from "../constant/database";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SingleTaxation = () => {
  const router = useLocation();
  const navigate = useNavigate();
  let service = router.pathname.substring(13);
  service = service.split("%20").join(" ");
  if (!service) return;

  if (service === "TAN Registration") {
    navigate(
      "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2Ftan_registration.pdf?alt=media&token=023b2f7f-ce48-490f-9b60-447ad9091733"
    );
    return <></>;
  }

    if (service === "Trademark Public Search") {
      navigate("https://ipindiaservices.gov.in/tmrpublicsearch/frmmain.aspx");
      return <></>;
    }


  if (service === "PAN Card") {
    navigate(
      "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/forms%2Fpan_form.pdf?alt=media&token=c7c17435-6627-4c7e-855e-40ec370079dd"
    );
    return;
  }

  if (service === "Form 16") {
    navigate(
      "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2Fform_16.pdf?alt=media&token=9ae1cf1d-2696-4395-aa89-cb8c2dc32884"
    );
    return;
  }

  if (service === "Business Tax Filing") {
    window.navigate(
      "https://firebasestorage.googleapis.com/v0/b/subsidyscheme-dd6a6.appspot.com/o/incomeTax%2Fbusiness_tax.pdf?alt=media&token=d0c88074-7c16-43af-8a60-bc37ce8bbc78"
    );
    return;
  }

  const singleObject = {
    ...incomeTax,
    ...bankingDatabase,
    ...isoDatabase,
    ...otherIPR,
    ...gstDatabase,
    ...designRegistration,
    ...trademarkDatabase,
  };
  const currentKey = singleObject[service];

  return (
    <div className="min-h-screen mt-28 bg-[#eeeeee] w-full">
      <div className="max-w-[1300px] mx-auto p-4 flex flex-col gap-4">
        <div className="w-[90%] sm:w-[75%] smd:w-[820px] smd:gap-2 gap-10 flex flex-col items-center justify-center smd:flex-row bg-white mx-auto p-2 sm:p-3 rounded-md">
          <div className="smd:mt-0 mt-4 w-[90%] smd:w-[40%]  flex items-center justify-center">
            <img
              alt="imagevdsa"
              width="250"
              height="300"
              className="mt-4"
              src={currentKey.img}
            />
          </div>
          <div className="w-[90%] smd:w-[60%]">
            <h1 className="font-bold text-2xl">{currentKey.head}</h1>
            <div className="flex smd:flex-row flex-col items-start mt-2 justify-center gap-2">
              <div className="bg-[#e9ecef] p-2 rounded-md w-full h-[100px]">
                <h3 className="font-semibold">MSME</h3>
                <p className="text-[12px]">{currentKey.msme}</p>
              </div>
              <div className="bg-[#e9ecef] p-2 rounded-md w-full h-[100px]">
                <h3 className="font-semibold">Regular</h3>
                <p className="text-[12px]">{currentKey.regular}</p>
              </div>
            </div>
            <h2 className="my-2 font-semibold">Documents Required</h2>
            <div className="grid grid-cols-2">
              {currentKey.documentsRequired.map((itm, ind) => (
                <p key={ind} className="flex items-center justify-start gap-2">
                  <img
                    alt="czcs"
                    src="/check-solid.svg"
                    width={10}
                    height={10}
                  />
                  <span>{itm}</span>
                </p>
              ))}
            </div>
            <button
              onClick={() => router("http://wa.me/9269148541?text=Hello%20Sir")}
              className="xxsm:relative fixed bottom-0 xxsm:w-[200px] w-full left-0 mt-2 bg-[#128C7E] flex items-center justify-center gap-2 rounded-md p-3"
            >
              <img alt="fd" src="/whatsapp.svg" width={20} height={20} />
              <span className="text-white font-semibold">Get a quick call</span>
            </button>
          </div>
        </div>
        <div className="bg-white w-[90%] sm:w-[75%] smd:w-[820px] mx-auto p-4 rounded-md">
          {currentKey.description.map((itm, ind) => (
            <div key={ind}>
              <h1 className="text-2xl mt-2 font-medium">{itm.title}</h1>
              {itm.para.map((para, index) => (
                <p className="mt-2 text-[13px]" key={index}>
                  {para}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleTaxation;

// Import the necessary functions from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import the auth function
import { getStorage } from "firebase/storage";
import { config } from "dotenv";
config(); // This will load the environment variables from .env.local

// Rest of your firebase.js code...

const firebaseConfig = {
  apiKey: "AIzaSyC5LmDISFhzNeD-dgW0LYeKKG0PDDYfNxA",
  authDomain: "subsidyscheme-dd6a6.firebaseapp.com",
  projectId: "subsidyscheme-dd6a6",
  storageBucket: "subsidyscheme-dd6a6.appspot.com",
  messagingSenderId: "101441160772",
  appId: "1:101441160772:web:dabef514d3d7a866c01e11",
  measurementId: "G-RW9EBW30QD",
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, storage, app };
